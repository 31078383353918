import { CardMain, TitleCard, CardConfig, RowConfig } from "@victorequena22/component-bootstrap";
import { ButtonColor, Guardar, SizeViewSelector, Title } from '../../../Panel/Component';
import { State } from './index';
import ReactTooltip from 'react-tooltip';
import { ColorDesplazamiento, Separacion } from '@/App/Panel/Component/Commons/Carrusel';
import { ColorFondo } from "@/App/Panel/Component/Commons/Simples";
interface Props {
    save: () => void
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, gap, color, inter, cards, text } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="300px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ReactTooltip />
                <ColorFondo gradiend setColor={color => setConfig({ color })} color={color} />
                <ColorDesplazamiento className='ml-1' setColor={inter => setConfig({ inter })} color={inter} />
                <Separacion gap={gap} setGap={gap => setConfig({ gap })} />
                <ButtonColor className='ml-1' gradiend tip='COLOR DE LAS CARTAS'
                    color={cards} setData={(cards) => setConfig({ cards })}>
                    <i className="fas fa-credit-card"></i>
                </ButtonColor>
                <ButtonColor disableAlpha className='ml-1' tip='COLOR DEL TEXTO DE LAS CARTAS'
                    color={text} setData={(text) => setConfig({ text })}>
                    <i className="fas fa-font"></i>
                </ButtonColor>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}