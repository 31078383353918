import { getImagenURL } from '@victorequena22/conneccion';
import { Invert } from '../../Component/Commons';
import { State } from './index'
export function Panel({ config, width }: { config: State, width: number }) {
    const { backgroundColor, img, padding } = config;
    return <div style={{ paddingBlock: padding + 'px', background: backgroundColor }}>
        <div className="container">
            <Invert width={width} config={config}>
                <img id='ImagenEditable' src={getImagenURL(img)} width='100%' alt='panel a' />
            </Invert>
        </div>
    </div>
}