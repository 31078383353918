import { Paralax1 } from '../EditPanel/Paralax1';
import { Paralax2 } from '../EditPanel/Paralax2';
import { Video } from '../EditPanel/Video';
import { Galeria } from '../EditPanel/Galeria';
import { Carrusel } from '../EditPanel/Carrusel';
import { Cargos } from '../EditPanel/Cargos';
import { Servicio } from '../EditPanel/Servicio';
import { Servicio2 } from '../EditPanel/Servicio2';
import { Preguntas } from '../EditPanel/Preguntas';
import { Title } from '../EditPanel/Title';
import { Imagen } from '../EditPanel/Imagen';
import { Pestanas } from '../EditPanel/Pestanas';
import { Mapa } from '../EditPanel/Mapa';
import { Contact } from '../EditPanel/Contact';
import { Suscribir } from '../EditPanel/Suscribir';
import { Route } from 'react-router-dom';
import { ImagenSola } from '../EditPanel/ImagenSola';
import { Testimonio } from '../EditPanel/Testimonio';
import { BannerImagen } from '../EditPanel/BannerImagen';
import { CardText } from '../EditPanel/CardText';
import { CarruselCardText } from '../EditPanel/CarruselCardText';
import { CardCargos } from '../EditPanel/CardCargos';


export default function Crear({ props, url, edit }: { url: string, props?: any, edit?: string }) {
    return <>
        <Route path={url + "/mapa"} exact><Mapa props={props} edit={edit} /></Route>
        <Route path={url + "/suscribir"} exact><Suscribir props={props} edit={edit} /></Route>
        <Route path={url + "/titulo"} exact><Title props={props} edit={edit} /></Route>
        <Route path={url + "/paralax1"} exact><Paralax1 props={props} edit={edit} /></Route>
        <Route path={url + "/paralax2"} exact><Paralax2 props={props} edit={edit} /></Route>
        <Route path={url + "/video"} exact ><Video props={props} edit={edit} /></Route>
        <Route path={url + "/imagen"} exact><Imagen props={props} edit={edit} /></Route>
        <Route path={url + "/imagenSola"} exact><ImagenSola props={props} edit={edit} /></Route>
        <Route path={url + "/galeria"} exact><Galeria props={props} edit={edit} /></Route>
        <Route path={url + "/carrusel"} exact><Carrusel props={props} edit={edit} /></Route>
        <Route path={url + "/cargos"} exact><Cargos props={props} edit={edit} /></Route>
        <Route path={url + "/servicio"} exact><Servicio props={props} edit={edit} /></Route>
        <Route path={url + "/servicio2"} exact><Servicio2 props={props} edit={edit} /></Route>
        <Route path={url + "/preguntas"} exact><Preguntas props={props} edit={edit} /></Route>
        <Route path={url + "/pestanas"} exact><Pestanas props={props} edit={edit} /></Route>
        <Route path={url + "/contacto"} exact><Contact props={props} edit={edit} /></Route>
        <Route path={url + "/testimonio"} exact><Testimonio props={props} edit={edit} /></Route>
        <Route path={url + "/BannerImagen"} exact><BannerImagen props={props} edit={edit} /></Route>
        <Route path={url + "/CarruselCardText"} exact><CarruselCardText props={props} edit={edit} /></Route>
        <Route path={url + "/CardText"} exact><CardText props={props} edit={edit} /></Route>
        <Route path={url + "/CardCargos"} exact><CardCargos props={props} edit={edit} /></Route>
    </>

}