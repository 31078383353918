import { Card, Row } from 'react-bootstrap';
import { SizeViewSelector, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '@/App/Panel/Component';
import { State } from './index';
import { TBElement } from '@/App/Panel/Component/SlateCustom';
interface Props { save: () => void, config: State; setConfig: (d: Partial<State>) => void }
export function Config({ config, setConfig, save }: Props) {
    const { size, background, backCard, TextCard } = config;
    return <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
        <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
            <Guardar guardar={save} />
            <CardConfig width='40px' >
                <RowConfig>
                    <ButtonColor gradiend tip='COLOR DE FONDO DE LA SECCION'
                        style={{ width: 32 }} color={background}
                        setData={background => setConfig({ background })}>
                        <i className="fas fa-paint-roller"></i>
                    </ButtonColor>
                </RowConfig>
                <RowConfig top={2}>
                    <ButtonColor gradiend tip='COLOR DE FONDO DE LAS CARTAS'
                        style={{ width: 32 }} color={backCard}
                        setData={backCard => setConfig({ backCard })}>
                        <i className="fas fa-ad"></i>
                    </ButtonColor>
                </RowConfig>
                <RowConfig top={2}>
                    <ButtonColor tip='COLOR DE FONDO DE LAS CARTAS'
                        style={{ width: 32 }} color={TextCard} 
                        setData={TextCard => setConfig({ TextCard })}>
                        <i className="fas fa-text"></i>
                    </ButtonColor>
                </RowConfig>
                <TitleCard>COLORES</TitleCard>
            </CardConfig>
            <TBElement />
            <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
        </Row >
    </Card >
}