import { Pago, conPago } from "@/App/Capacitacion/Pagos/Models";
import { FieldText } from "@/Components/Field";
import { Tooltip } from "@mui/material";
import { Tabla } from "@victorequena22/tabla-bootstrap";
import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Pagos() {
    const [pagos, setPagos] = useState<Pago[]>([]);
    useEffect(() => { conPago().lista(p => setPagos(p)) }, []);
    return <TablaPagos pagos={pagos} />
}

class TablaPagos extends Tabla<{ pagos: Pago[] }> {
    buscarEn = ['referencia', 'banco', 'monto', 'nota', 'fecha']
    fechas = ['fecha']

    Row = (d: any) => {
        const { state: { by, buscar, orden } } = this
        return <FilaPago pago={d} key={`pagos${d.id}${d.estatus}${by}${orden}${buscar}`} />
    }
    Header() {
        let { Th } = this;
        return (<tr>
            <Th width='90px' label='fecha'>Fecha</Th>
            <Th width='auto' label='banco'>Banco</Th>
            <Th width='auto' label='referencia'>Referencia</Th>
            <Th width='120px' label='monto'>Monto</Th>
            <Th width='100px'>Estado</Th>
            <Th width='auto' label='nota'>Nota</Th>
            <Th width='80px'> </Th>
        </tr>)
    }
    render() {
        const { Body } = this
        return <Row>
            <Col md={12}>
                <h1 className='w-100 text-primary'>Pagos realizados por @NombreUsuario</h1>
            </Col>
            <Col md={12} className='mt-2'>
                <FieldText variant="outlined" endAdornment={<i className="fas fa-search" />} size="small" label="Buscar" upper setData={buscar => this.setState({ buscar })} update='buscar' value={this.state.buscar} />
            </Col>
            <Col md={12} className='mt-4'><Body /></Col>
        </Row>

    }
    componentDidMount() {
        const { pagos } = this.props;
        this.setState({ by: 'id', itens: pagos });
    }
}

function FilaPago({ pago: { referencia, banco, monto, nota, fecha, estatus, model_id } }: { pago: Pago }) {
    const badge = ['danger', 'warning', 'success'];
    const estados = ['Rechazado', 'Pendiente', 'Aprobado'];
    return <tr>
        <td>{formatoFecha(fecha)}</td>
        <td>{banco}</td>
        <td>{referencia}</td>
        <td>{numberFormat(monto)}</td>
        <td><span className={`badge badge-${badge[estatus]}`}>{estados[estatus]}</span></td>
        <td>{nota}</td>
        <td>
            <Tooltip title="Ir al Evento" placement="top">
                <Button as={Link} to={`/evento/inscribir/${model_id}`}>
                    <i className="fas fa-file-certificate" /> Ir al Evento
                </Button>
            </Tooltip>
        </td>
    </tr>
}



