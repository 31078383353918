import { FilaAval } from './Fila';
import ReactTooltip from 'react-tooltip';
import { NuevoAval } from './Nuevo';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { conAval, Costo } from '../../Models';
import { InputText } from "@victorequena22/component-bootstrap";
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
export default class ListAval extends CardBuscador {
    con = conAval();
    mostrar = 6;
    placeholder = "BUSCAR AVAL"; buscarEn = ["costo", "nombre"];
    Title() { return <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR AVAL</h4> }
    Avansado() { return <></>; }
    Buscador() {
        return <>
            <ReactTooltip />
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUSCAR
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                        <InputGroup.Append>
                            <Button variant="primary"><i className="fa fa-search"></i> BUSCAR</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <NuevoAval update={this.set.bind(this)} />
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='descripcion' width='calc(100% - 270px)'>NOMBRE</Th>
            <Th label='costo' width='calc(100% - 270px)'>COSTO</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='70px'>ACCIONES</Th>
        </tr>
    }
    Row(p: Costo) {
        return <FilaAval key={'unidad' + p.id} d={p} update={this.set.bind(this)} />
    }
    set = () => this.con.lista((itens) => this.setState({ itens }))
}
