import { useSlate } from 'slate-react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { isBlockProps, setPropsOrRemove, useBlockProps } from '@victorequena22/slate-bootstrap'
import { ButtonConfig, CardConfig, InputNumber, InputText, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { useCallback } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { ButtonColor } from '../../Component/Buttons';
import { GrupPanel } from '../../Component/Inputs';
export const useVal = (props: string) => {
    const editor = useSlate()
    return useCallback((e: string) => isBlockProps(editor, props, e), [props, editor]);
}
export const useSet = (props: string) => {
    const editor = useSlate()
    return useCallback((v: string) => setPropsOrRemove(editor, props, v), [props, editor])
}
export const ButtonSelect = () => {
    const val = useVal('type');
    const set = useSet('type');
    return <ButtonConfig className='ml-1' tip='BOTON' icon='fas fa-square-xmark'
        active={val('button')} action={() => set('button')} />

}
export const ButtonProps = () => {
    const [link, setLink] = useBlockProps('link');
    const [borderSize, setBorderSize] = useBlockProps('borderSize');
    const [borderRadius, setBorderRadius] = useBlockProps('borderRadius');
    const [borderColor, setBorderColor] = useBlockProps('borderColor');
    const [borderHover, setBorderHover] = useBlockProps('borderHover');
    const [back, setBack] = useBlockProps('background');
    const [hover, setHover] = useBlockProps('hover');
    const val = useVal('type');
    if (val('button'))
        return <CardConfig width='425px'>
            <RowConfig>
                <GrupPanel start='LINK PARA EL BOTON' width="100%">
                    <InputText style={{ height: 24, fontSize: 10 }}
                        value={link} setData={setLink} />
                </GrupPanel>
            </RowConfig>
            <RowConfig top={2}>
                <GrupPanel start="GROSOR DEL BORDE" width='190px'>
                    <InputNumber max={8} step={1} min={0} decimal={0}
                        style={{ height: 24 }} value={zVall(borderSize)} setData={setBorderSize} />
                </GrupPanel>
                <GrupPanel start="REDONDEO DEL BORDE" className='ml-1' width='220px'>
                    <InputNumber max={100} step={1} min={0} decimal={0}
                        style={{ height: 24 }} value={zVall(borderRadius)} setData={setBorderRadius} />
                </GrupPanel>
            </RowConfig>
            <RowConfig top={2}>
                <GrupPanel start='COLORES DEL BOTON' width='100%'>
                    <ButtonGroup>
                        <ButtonColor key='back' tip='COLOR DE FONDO'
                            invert gradiend color={back} setData={setBack} >
                            <i className="fas fa-paint-roller" />
                        </ButtonColor>
                        <ButtonColor key='hover' tip='COLOR DE FONDO CUANDO PASA EN RATON'
                            invert gradiend color={hover} setData={setHover} >
                            <i className="fas fa-brush" />
                        </ButtonColor>
                        <ButtonColor tip='COLOR DE BORDE'
                            color={borderColor} setData={setBorderColor} >
                            <i className="fas fa-border-outer" />
                        </ButtonColor>
                        <ButtonColor tip='COLOR DE BORDE CUANDO PASA EN RATON'
                            color={borderHover} setData={setBorderHover} >
                            <i className="fas fa-border-inner" />
                        </ButtonColor>
                    </ButtonGroup>
                </GrupPanel>
            </RowConfig>
            <TitleCard>PROPIEDADES DE BOTON</TitleCard>
        </CardConfig >
    return <></>
}
function zVall(n: string) {
    return parseInt(n === '' ? '0' : n);
}


