
import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { Itens } from './Itens'
import { Cargos } from "../../SizePanel/Cargos";

export function Panel({ config, seled, }: { config: State, seled: (i: number) => void }) {
    const { color, itens, size } = config;
    return <>
        <Row className="w-100" style={{ background: color, margin: '0 0 0 0', padding: '0 0 2rem 0', borderBottom: '3px black solid' }}>
            <Container>
                <Row className="w-100">
                    {itens.map((i) => <Itens key={`iten${i.img}${i.id}`} iten={i} config={config} seled={seled} />)}
                </Row>
            </Container>
        </Row>
        <Cargos data={{ props: config, itens: itens.map(i => ({ ...i, props: i })) }} size={size} />
    </>
}