
import { useCallback } from 'react';
import { State } from '../../BaseManager'
import Draggable from 'react-draggable';
import { Iten } from '../Props';
import { getPosiccion } from '../Input';
// import { getValLeft, getValTop } from './Input';
interface Props {
    iten: Iten;
    setConfig: (d: Partial<State>) => void;
    setIten: (d: Partial<Iten>) => void;
    children: any
    click?: () => void
}
export default function Drag({ click, setConfig, iten, setIten, children }: Props) {
    const { left, top, width, id, color, border, padding, radeus, back } = iten;
    const star = useCallback(() => { setConfig({ editable: false }) }, [setConfig])
    const drag = useCallback(() => {
        const { left, top } = getPosiccion(`iten-${id}`);
        setIten({ left, top, id });
    }, [id, setIten]);
    return <Draggable position={{ x: left, y: top }} onStart={star} onDrag={drag}>
        <div onDoubleClick={click} id={`iten-${id}`} onClick={() => setIten({ id })}
            style={{
                margin: 0, padding, position: 'absolute', width, zIndex: id,
                backgroundColor: back, borderRadius: radeus,
                border: `${border}px solid ${color}`,
            }}>
            {children}
        </div>
    </Draggable>
}


