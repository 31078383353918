import { ButtonColor, RowConfig, ButtonConfig, AddItens } from '../index'
import { ButtonGroup } from 'react-bootstrap';
import { CropModal } from '../../../Media/CropModal';
import { CardConfig, TitleCard } from "@victorequena22/component-bootstrap";
import { BackButton, ColorButton as ColorText, FontStyle, MarkMenu } from "@victorequena22/slate-bootstrap";
import { AlingMenu, ListMenu, TabsMenu, TypeMenu } from "@victorequena22/slate-bootstrap";
import { Inserts } from '../SlateCustom';
import { getColors } from '../../../../Template/Colores';
interface Props {
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void,
    copy: (d: number) => void,
    setBack?: (d: string) => void,
    addItem: () => void,
    cont: number,
    alfaBack?: boolean,
    disableBack?: boolean,
    iten: any
}
export interface PImangen extends Props {
    aspect: number
    setImagen: (img: string) => void
}
export function MoveRemove({ setBack, itenUp, itenDown, removeItem, disableBack, alfaBack, iten: { id, back } }: Props) {
    return <RowConfig top={2}>
        <ButtonGroup>
            <ButtonConfig tip='MOVER IMAGEN A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
            <ButtonConfig tip='MOVER IMAGEN A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
        </ButtonGroup>
        <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
        {setBack ? <ButtonColor gradiend tip='COLOR DE FONDO' style={{ width: 32 }} disableAlpha={!alfaBack}
            color={back} setData={setBack} className='ml-1' disable={disableBack}>
            <i className="fas fa-ad" />
        </ButtonColor> : <></>}
    </RowConfig>
}
export function ItenImagen(p: PImangen) {
    const { iten: { img, id }, cont, addItem, setImagen, copy, aspect } = p
    return <CardConfig width='141px'>
        <RowConfig>
            <CropModal aspect={aspect} img={img} setImg={img => setImagen(img)} />
            <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
            <ButtonConfig className='ml-1' tip='COPIAR ELEMENTO' icon='fas fa-copy' action={() => copy(id)} />
        </RowConfig>
        <MoveRemove {...p} />
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>IMAGEN {id}</TitleCard>
    </CardConfig>
}
interface TBElementProps {
    back: string;
    setBack: (value: string) => void;
    alfaBack?: boolean;
    disableBack?: boolean;
}
export const TBElement = ({ back, setBack, alfaBack, disableBack = false }: TBElementProps) => {
    return <CardConfig width='301px'>
        <RowConfig>
            <MarkMenu />
            <ButtonGroup className='ml-2'>
                <ColorText left='0' colores={getColors()} />
                <BackButton left='0' colores={getColors()} />
            </ButtonGroup>
            <AlingMenu left={1} />
        </RowConfig>
        <RowConfig top={2}>
            <TabsMenu left={0} />
            <ListMenu left={1} />
            <TypeMenu left={1} />
            <Inserts />
        </RowConfig>
        <RowConfig top={2}>
            <FontStyle left={0} width='calc(100% - 32px - .25rem)' />
            <ButtonColor gradiend tip='COLOR DE FONDO' style={{ width: 32 }} disableAlpha={!alfaBack}
                color={back} setData={setBack} className='ml-1' disable={disableBack}>
                <i className="fas fa-ad" />
            </ButtonColor>
        </RowConfig>
        <TitleCard>FUENTE Y TEXTO</TitleCard>
    </CardConfig>
}


