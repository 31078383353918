import { CropModal } from "@/App/Media/CropModal"
import { AddItens, ButtonConfig } from "@/App/Panel/Component"
import { CardConfig, RowConfig, TitleCard } from "@victorequena22/component-bootstrap"
import { ButtonGroup } from "react-bootstrap"

interface Props {
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void,
    copy: (d: number) => void,
    addItem: () => void,
    cont: number,
    iten: any
    aspect: number
    setImagen: (img: string) => void
    setConfig: (d: any) => void
}
export function ItenImagen(p: Props) {
    const { iten: { img, id, disable }, setConfig, cont, itenUp, itenDown, removeItem, addItem, setImagen, copy, aspect } = p
    return <CardConfig width='141px'>
        <RowConfig>
            <CropModal aspect={aspect} img={img} setImg={img => setImagen(img)} />
            <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
            <ButtonConfig className='ml-1' tip='COPIAR ELEMENTO' icon='fas fa-copy' action={() => copy(id)} />
        </RowConfig>
        <RowConfig top={2}>
            <ButtonGroup>
                <ButtonConfig tip='MOVER IMAGEN A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
                <ButtonConfig tip='MOVER IMAGEN A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
            </ButtonGroup>
            <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
            <ButtonConfig icon="fas fa-ad" active={!disable} className='ml-1'
                tip={`COLOCAR FONDO PARA EL TEXTO ${disable ? 'NO' : 'SI'}`}
                action={() => setConfig({ disable: !disable })} />
        </RowConfig>
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>IMAGEN {id}</TitleCard>
    </CardConfig>
}