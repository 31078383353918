import { Link } from 'react-router-dom';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { Fila } from '../Auxiliar/Fila';
import { conNorma, TypeAuxiliarLista } from '../../Models';

export default class ListaNormas extends CardBuscador {
    con = conNorma();
    buscarEn = ['id', 'nombre'];
    Title = () => <>
        <h3>BUSCAR NORMA</h3>
        <Link to="/evento/config/norma/nueva" className="btn btn-primary ml-auto">
            <span className="fa fa-plus"></span> NUEVA NORMA
        </Link>
    </>
    Row = (d: TypeAuxiliarLista) => <Fila data={d} eliminar={this.eliminar} link='/evento/config/norma/edit/' />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='90px' label='id'>COD</Th>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>)
    }
    set = () => this.con.lista((itens) => this.setState({ itens }))
    eliminar = (id: number) => this.con.cancelar(id, () => this.set())
}
