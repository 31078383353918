import { useCallback } from "react";
import { Editable } from "slate-react";
import { Element } from "./Element";
import { Leaf } from "@victorequena22/slate-bootstrap";

export const EditArea = ({ change, value, className }: { change?: (d: any) => void, value?: any[], className?: string }) => {
    const renderElement = useCallback((props: any) => <Element {...props} />, [])
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
    // const editor = useSlate();
    return <Editable key={'' + Math.random()}
        id='EditableHTML' className={className}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck style={{ width: '100%', minWidth: '100px', minHeight: '30px' }}
        onChange={change} value={value}
    // onKeyDown={(event: any) => hotKeyDown(editor, event)}
    // onKeyUp={(event: any) => hotKeyUp(editor, event)}
    />
}