import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import { css } from "@emotion/css";
import InputFild from "@/Components/Material/InputFild";

export default function Datos() {
    return <Row>
        <Col as={FormGroup} md={12}>
            <h1 className='w-100 text-primary'>Datos de @NombreUsuario</h1>
        </Col>
        <Col as={FormGroup} md={12}><Col md={{ offset: 8, span: 4 }}>Fecha de Nacimiento*</Col></Col>
        <Col as={FormGroup} md={7}><InputFild setData={_d => { }} label="Nombre Completo*" value='' /></Col>
        <Nacimiento nacimiento='' setData={_d => { }} />
        <Col as={FormGroup} md={7}><InputFild setData={_d => { }} label="Correo Electrónico*" value='' /></Col>
        <Col as={FormGroup} md={5}><InputFild mask="9999-99999999" setData={_d => { }} label="Teléfono*" value='' /></Col>
        <Col as={FormGroup} xs={12}>
            <FormLabel>DIRECCIÓN</FormLabel>
            <Row className="mb-1">
                <Col className="mt-1" md={3}><InputFild setData={_d => { }} label="Pais*" value='' /></Col>
                <Col className="mt-1" md={3}><InputFild setData={_d => { }} label="estado*" value='' /></Col>
                <Col className="mt-1" md={6}><InputFild setData={_d => { }} label="Ciudad*" value='' /></Col>
            </Row>
            <InputFild setData={_d => { }} label="Dirección" value='' />
        </Col>
        <Col as={FormGroup} md={12}><InputFild setData={_d => { }} label="Profesión" value='' /></Col>
        <Col as={FormGroup} md={12}><InputFild setData={_d => { }} label="Ocupación" value='' /></Col>
        <Col as={FormGroup} md={12} className="text-right"><Button variant="outline-success">Guardar</Button></Col>
    </Row>
}
const meses = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export function Nacimiento({ nacimiento, setData }: { nacimiento: string, setData: (d: string) => void }) {
    let dia = 0, mes = 0, ano = 0;
    if (nacimiento !== '') {
        const f = nacimiento.split('-');
        dia = parseInt(f[2]);
        mes = parseInt(f[1]);
        ano = parseInt(f[0]);
    }
    return <Col as={FormControl} aria-describedby="nacimiento-error" md={5}>
        <Row className="mt-0 mr-0 ml-0 mb-1 p-0">
            <Col className={css`@media (min-width: 768px) {padding: 0 0 0 0;}`} md={3}><InputFild mask="99" label="Dia" value={dia + ''} setData={dia => setData(`${ano}-${mes}-${dia}`)} /></Col>
            <Col className={css`@media (max-width: 768px) {margin-top:.25rem; margin-bottom:.25rem;}`} md={5}><MesSelector value={mes} change={mes => setData(`${ano}-${mes}-${dia}`)} /></Col>
            <Col className={css`@media (max-width: 768px) {margin-bottom:  1rem;}@media (min-width: 768px) {padding: 0 0 0 0;}`} md={4}><AnoSelector value={ano} change={ano => setData(`${ano}-${mes}-${dia}`)} /></Col>
        </Row>
    </Col>
}
function MesSelector({ change, value }: { change: (i: number) => void, value: number }) {
    const set = (e: SelectChangeEvent) => change(parseInt(e.target.value as any));
    return <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="nacimiento">Mes</InputLabel>
        <Select value={value + ''} label="Mes" onChange={set}>
            {meses.map((m, i) => <MenuItem key={i} value={i}>{m}</MenuItem>)}
        </Select>
    </FormControl>
}
function AnoSelector({ change, value }: { change: (i: number) => void, value: number }) {
    const set = (e: SelectChangeEvent) => change(parseInt(e.target.value as any));
    const ano = (new Date()).getFullYear();
    const opt = [];
    for (let i = 1950; i <= ano; i++) {
        opt.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="nacimiento">Año</InputLabel>
        <Select value={value + ''} label="Año" onChange={set}>
            {opt}
        </Select>
    </FormControl>
}