import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import { CertificadoFila } from "../../Evento/Models/Model";
import { conCC } from "../../Evento/Models/Certificados";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Fila from "./Fila";


export default function CertificadosTabla() {
    const [cc, setCC] = useState<CertificadoFila[]>([]);
    const actualizar = useCallback(() => conCC().lista(setCC), []);
    useEffect(() => { actualizar(); }, [actualizar]);
    return <Tabla itens={cc} />
}
class Tabla extends CardBuscador<{ itens: CertificadoFila[] }> {
    con = conCC();
    mostrar = 6;
    placeholder = "BUSCAR CERTIFICADO";
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='id' width='300px'>CODIGO</Th>
            <Th  width='calc(100% - 450px)'>RECEPTOR</Th>
            <Th width='100px'>ACCIONES</Th>
        </tr>
    }
    Row = (p: CertificadoFila) => <Fila key={'cetificadoPE' + p.id} data={p} />
    Title = () => <>
        <Link to="/programa" className="btn btn-primary mr-4">
            <span className="fa fa-arrow-circle-left"></span> VOLVER
        </Link>
        <h3><i className="fa fa-search"></i> BUSCAR CATEGORÍA</h3>
    </>
    componentDidMount(){
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any){
        this.setState({ itens });
    }
}