import { Card } from "react-bootstrap"
import { State } from './BaseManager'
import { getStyle } from "./Styles";
import { Iten } from "./Itens";
import { Iten as i } from "./Itens/Props";
import { useCallback } from "react";
export interface Props {
    config: State;
    setConfig: (d: Partial<State>) => void;
    setIten: (d: Partial<i>) => void;
    iten: i;
}
export default function Preview(p: Props & { edit?: { id: number } }) {
    const { config, edit, setConfig, setIten, iten } = p;
    const editable = useCallback(() => { if (iten.type === "TEXT") { setConfig({ editable: !config.editable }) } }, [setConfig, iten, config]);
    return <div id='container-certificado' className="w-100" style={{ maxHeight: 'calc(100vh - 216px)', overflowY: 'scroll' }}>
        <Card onDoubleClick={editable} id='preview-certificado' className="ml-auto mr-auto" style={getStyle(config, edit)}>
            {config.itens.map(i => <Iten key={`iten-${i.id}`}
                setConfig={setConfig} setIten={setIten}
                iten={i} config={config} />)}
        </Card>
    </div >
}


