import ReactTooltip from 'react-tooltip';
import { State } from '../index'
import { ButtonColor } from '@/App/Panel/Component';
import { CardConfig, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { fontsFamily } from "@victorequena22/slate-bootstrap/dist/cjs/FontsFamily";
import { InputGroup, FormControl } from 'react-bootstrap';
interface Props {
    config: State;
    setConfig: (d: any) => void
}
export default function Formulario({ config, setConfig }: Props) {
    const { colorFF, colorFC, colorBC, colorTC, colorBT, fontC, fontB, status } = config;
    return <CardConfig width='436px' >
        <ReactTooltip />
        <RowConfig>
            <Title title='COLORES DE FONDO' width='260px'>
                <ButtonColor gradiend disableAlpha tip='FORMULARIO' invert style={{ borderRadius: 0 }}
                    color={colorFF} setData={colorFF => setConfig({ colorFF })} >
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ButtonColor gradiend disableAlpha tip='CAMPO DE TEXTO' invert style={{ borderRadius: 0 }}
                    color={colorFC} setData={colorFC => setConfig({ colorFC })} >
                    <i className="fas fa-bars"></i>
                </ButtonColor>
                <ButtonColor gradiend disableAlpha tip='BOTON' invert style={{ borderRadius: '0 .25rem .25rem 0' }}
                    color={colorBC} setData={colorBC => setConfig({ colorBC })} >
                    <i className="fas fa-paper-plane"></i>
                </ButtonColor>
            </Title>
            <Title title='ESTATUS' width='170px'>
                <Status s={status} setData={status => setConfig({ status })} />
            </Title>
        </RowConfig>
        <RowConfig top={2}>
            <Title title='FUENTE DEL CAMPO TEXTO' width='100%'>
                <ButtonColor disableAlpha tip='COLOR' style={{ borderRadius: 0 }}
                    color={colorTC} setData={colorTC => setConfig({ colorTC })}>
                    <i className="fas fa-font"></i>
                </ButtonColor>
                <FontsFamily fontFamily={fontC} setData={fontC => setConfig({ fontC })} />
            </Title>
        </RowConfig>
        <RowConfig top={2}>
            <Title title='FUENTE DEL BOTON' width='100%'>
                <ButtonColor disableAlpha tip='COLOR' color={colorBT}
                    setData={colorBT => setConfig({ colorBT })}>
                    <i className="fas fa-font"></i>
                </ButtonColor>
                <FontsFamily fontFamily={fontB} setData={fontB => setConfig({ fontB })} />
            </Title>
        </RowConfig>
        <TitleCard>CONFIGURACION DEL FORMULARIO</TitleCard>
    </CardConfig>
}
interface P {
    title: string,
    children: any,
    width: string
}
function Title({ title, width, children }: P) {
    return <InputGroup className='input-group-sm' style={{ width, height: '24px' }}>
        <InputGroup.Prepend style={{ height: '24px' }}>
            <InputGroup.Text style={{ height: '24px' }}>{title}</InputGroup.Text>
        </InputGroup.Prepend>
        {children}
    </InputGroup>
}
interface P2 {
    fontFamily: string,
    setData: (f: string) => void
}
const FontsFamily = ({ fontFamily, setData }: P2) => {
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => setData(e.currentTarget.value);
    return <FormControl data-tip='ESTILO DE FUENTE'
        style={{ fontFamily, height: '24px', borderRadius: '0 .25rem .25rem 0', padding: '0 0 0 0' }}
        className='form-control-sm' as='select' onChange={set} value={fontFamily}>
        {fontsFamily.map((c, i) => <option style={{ fontFamily: c }} key={`fuente${i}`}>{c}</option>)}
    </FormControl>
}
interface P3 {
    s: string,
    setData: (f: string) => void
}
function Status({ s, setData }: P3) {
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => setData(e.currentTarget.value);
    return <FormControl data-tip='ESTATUS DEL FORMULARIO DE SUSCRIPCION'
        style={{ height: '24px', borderRadius: '0 .25rem .25rem 0', padding: '0 0 0 0' }}
        className='form-control-sm' as='select' onChange={set} value={s}>
        <option>Formulario</option>
        <option>Login</option>
        <option>Suscrito</option>
    </FormControl>
}