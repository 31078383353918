import { useEffect, useState } from 'react'
import { conPanel } from '../../Panel/Models';
import { Link, useParams } from "react-router-dom";
import Editar from '../../Panel/Ver/Editar';
import { Eventos } from '../Custom/Eventos';
import { Programas } from '../Custom/Programas';
import { Button, Card, Row } from 'react-bootstrap';
import { Facilitadores } from '../Custom/Facilitadores';
export function EditarPanel() {
    return <Card>
        <Card.Header className='bg-info'><Row>
            <Button as={Link} className="mr-2 mb-2" data-tip='VOLVER' variant="primary" to={`/panel`}>
                <i className="fa fa-arrow-left" />
            </Button>
            <h4><b>EDITAR DE PANEL</b></h4>
        </Row></Card.Header>
        <Editar2 />
    </Card>
}
export function Editar2() {
    let { id } = useParams<{ id: any }>();
    const [panel, setPanel] = useState<any>([])
    useEffect(() => { conPanel().detalle(id, setPanel); }, [id]) 
    switch (panel.type) {
        case 'eventos': return <Eventos key={'panel' + id} data={panel} />
        case 'programas': return <Programas key={'panel' + id} data={panel} />
        case 'TEIFacil': return <Facilitadores key={'panel' + id} data={panel} />
        default: return <Editar key={'panel' + id} panel={panel} />
    }
} 