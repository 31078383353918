import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { conPago, Pago } from "../../../Pagos/Models";
import {useEvento } from "../index";
import Template from "../Template";
import TablaParticipantes from "./TablaParticipantes";
export var setPagosList = () => { }
export default function GestionPagos() {
    const evento = useEvento();
    const [pagos, setPagos] = useState<Pago[]>([]);
    setPagosList = () => { conPago().pagosEvento(evento.id, setPagos); };
    useEffect(setPagosList, [])
    return <Template icon="fa fa-tag" title="PAGOS REGISTRADOS">
        <Row className="mt-2">
            <TablaParticipantes pagos={pagos} />
        </Row>
    </Template>
}


