import { Accordion, Button, Card, Row } from "react-bootstrap";
import { Permisos } from "../../Usuarios/Usuario";

interface SelecdProps {
    permiso: string,
    listaPermisos: Permisos[];
    selecteds: number[],
    Click: (id: number) => void
    children: any,
    tip: string,
}
interface TreeProp extends SelecdProps {
    title: string
}
export function TreePermiso({ permiso, children, title, selecteds, Click, listaPermisos }: TreeProp) {
    if (listaPermisos.every(p => p.nombre !== permiso)) return <></>;
    const per = listaPermisos.filter(p => p.nombre === permiso)[0];
    const seled = selecteds.some(p => p === per.id);
    return <Accordion className='m-2' activeKey={seled ? "1" : undefined}>
        <Button as={Accordion.Toggle} onClick={() => Click(per.id)}
            style={{ borderBottomLeftRadius: seled ? 0 : undefined }}
            block eventKey='1' variant={seled ? "success" : "default"}>
            <h6>{title}</h6>
        </Button>
        <Accordion.Collapse as={Card} eventKey='1' style={{ borderLeft: "5px solid #28a745" }}>
            <Row className='p-4' style={{
                display: 'grid', gap: '.25rem', gridAutoFlow: 'dense',
                gridAutoRows: `repeat(auto-fill,minmax(40px, 1fr))`,
                gridTemplateColumns: `repeat(auto-fill, minmax(500px, 1fr))`,
            }}>{children}</Row>
        </Accordion.Collapse>
    </Accordion>
}

export function PermisoButton({ permiso, selecteds, Click, children, listaPermisos }: SelecdProps) {
    if (listaPermisos.every(p => p.nombre !== permiso)) return <></>;
    const per = listaPermisos.filter(p => p.nombre === permiso)[0];
    const seled = selecteds.some(p => p === per.id);
    return <Button variant={seled ? "success" : "default"}
        className="m-1 font-weight-bold align-self-center"
        onClick={() => Click(per.id)}>{children}</Button>
}