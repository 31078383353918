import { useEvento } from "../../..";
import { ParticipanteFila } from "../../../../../Participantes/Models";
import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { ModalReport } from "@/Components/Modals";
import { don } from "@victorequena22/conneccion";
import { CertificadoEP } from "@/App/Capacitacion/Evento/Models/Model";
import { useState } from "react";
import UploadCertificado from "./UploadCertificado";
import { Button, ButtonGroup } from "react-bootstrap";
// import { ModalReport } from "@/Components/Modals";
interface Props {
    data: ParticipanteFila
}
export default function Fila({ data }: Props) {
    const { id, nombre, correo, telefono } = data;
    const certificados = useEvento().participantes.find(p => p.id === id);
    return <tr className="info" >
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='300px'>{correo}</THFila>
        <THFila width='200px' align="center" >{telefono}</THFila>
        <THInput width='150px' align="right">
            <ButtonGroup>{certificados?.certificados.map((c, i) => <CertificadoCP key={c.codigo} i={i} c={c} n={data} />)}</ButtonGroup>
        </THInput>
    </tr>
}
const v = ["success", "info", "danger", "indigo", "purple", "pink", "orange", "teal", "navy", "olive", "lime", "maroon", "secondary", "dark", "primary"];
function CertificadoCP({ c, n, i }: { c: CertificadoEP, n: ParticipanteFila, i: number }) {
    const [open, set] = useState(false)
    return ((c.certificado === -1) === c.subido) ? <>
        <ModalReport url={`${don}/api/Evento/Certificados/pdf/certificado/${c.codigo}`}
            title={"CERTIFICADO "} open={open} close={() => set(false)} />
        <Button onClick={() => set(true)} variant={`outline-${v[i]}`}><i className="fa fa-eye" /></Button>
    </> : <UploadCertificado codigo={c.codigo} user={n} />
} 