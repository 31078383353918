import Fila from "./Fila";
import { Interesado } from "@/App/Capacitacion/Programas/Models/Models";
import { ContrySelector, StateSelector } from "@/Components/Contry";
import { CardBuscadorAvanzado } from "@victorequena22/tabla-bootstrap";
import { Col, Row } from "react-bootstrap";
interface Props {
    i: Interesado[];
}
export default function Interesados() {
    return <Tabla i={[]} />
}

class Tabla extends CardBuscadorAvanzado<Props> {
    buscarEn = ['nombre', 'correo', 'telefono', 'ciudad'];
    pais = '';
    estado = '';

    Title() {
        return <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR SUBSCIPCION</h4>

    }
    Avansado() {
        return <Row>
            <Col md={7}>
                <ContrySelector change={this.setPais} defaultValue={this.pais} />
            </Col>
            <Col md={5}>
                {this.pais === '' ? <></> : <StateSelector pais={this.pais} change={this.setEstado} defaultValue={this.estado} />}
            </Col>
        </Row>
    }
    setPais = (p: string) => {
        this.pais = p
        this.forceUpdate()
    }
    setEstado = (e: string) => {
        this.estado = e;
        this.forceUpdate();
    }
    getItens() {
        return this.state.itens.filter(i => {
            if (this.pais !== '') {
                if (this.pais === i.pais) {
                    if (this.estado !== '') {
                        return this.estado === i.estado;
                    }
                    return true;
                }
                return false;
            }
            return true;
        })
    }
    Row = (e: Interesado) => <Fila key={`Suscriptores${e.id}`} i={e} />
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='300px' label='nombre'>NOMBRE</Th>
            <Th width='200px' label='correo'>CORREO</Th>
            <Th width='150px' label='telefono'>TELEFONO</Th>
            <Th width='100px'>PAIS</Th>
            <Th width='100px'>ESTADO</Th>
            <Th width='100px'>CIUDAD</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>
    }
    componentDidMount(): void {
        const { i } = this.props;
        this.setState({ itens: i });
    }
    componentWillReceiveProps({ i }: Props): void {
        this.setState({ itens: i });
    }
}