import { Link, Route, useLocation } from "react-router-dom";
import Encuesta from "./Encuesta";
import Subcriptores from "./Subcriptores";
import Interesados from "./Interesados";
import Graficos from "./Graficos";
import Template from "../Template";
import InteresadosEvento from "./InteresadosEvento";
import SubcriptoresEvento from "./SubcriptoresEvento";
import {useEvento } from "../index";
import { Row } from "react-bootstrap";
export default function Estadisticas() {
    const { id } = useEvento();

    return <Template icon="fa fa-chart-bar" title="ESTADISTICAS DEL EVENTO">
        <nav className="taps-Participantes nav card-header-tabs nav-tabs">
            <Tab url={`/evento/estadisticas/${id}/graficos`}>GRAFICOS</Tab>
            <Tab url={`/evento/estadisticas/${id}/encuesta`}>ENCUESTA</Tab>
            <Tab url={`/evento/estadisticas/${id}/interesados`}>REGISTRADOS</Tab>
            <Tab url={`/evento/estadisticas/${id}/suscriptores`}>INTERESADOS</Tab>
            <Tab url={`/evento/estadisticas/${id}/regp`}>REGISTRADOS PROGRAMA</Tab>
            <Tab url={`/evento/estadisticas/${id}/intp`}>INTERESADOS PROGRAMA</Tab>
        </nav>
        <Row className="mt-4">
            <Route path={`/evento/estadisticas/${id}/graficos`} component={Graficos} />
            <Route path={`/evento/estadisticas/${id}/encuesta`} component={Encuesta} />
            <Route path={`/evento/estadisticas/${id}/interesados`} component={InteresadosEvento} />
            <Route path={`/evento/estadisticas/${id}/suscriptores`} component={SubcriptoresEvento} />
            <Route path={`/evento/estadisticas/${id}/regp`} component={Interesados} />
            <Route path={`/evento/estadisticas/${id}/intp`} component={Subcriptores} />
        </Row>
    </Template>
}

function Tab({ children, url }: { children: any, url: string }) {
    const { pathname } = useLocation()
    return <Link to={url} className={`nav-item nav-link ${pathname === url ? 'active' : ''}`}>
        {children}
    </Link>
}