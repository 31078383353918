import { Component } from "react";
import { useState } from 'react';
import { Button, Col, Card, Table, InputGroup, ButtonGroup, Row } from 'react-bootstrap';
import { ControlText } from "@victorequena22/component-bootstrap";
import { InputArea, InputText } from "@victorequena22/component-bootstrap";
import { conConfig, getImagenURL } from "@victorequena22/conneccion";
import { toast } from "react-toastify";

interface State {
    nombre: string
    numero_fiscal: string
    direccion: string
    telefono: string
    telefonos: telefono[];
    correos: Correo[]
    correo: string
    web: string
    pago: string
    logo: string
    eslogan: string
}
export default class Empresa extends Component<any, State> {
    con = conConfig('empresa');
    state = { correos: [], telefonos: [], nombre: '', numero_fiscal: '', direccion: '', telefono: '', correo: '', web: '', pago: '', logo: '', eslogan: '' };
    render() {
        let { telefonos, correos, nombre, numero_fiscal, direccion, web, pago, logo, eslogan } = this.state as State;
        let img = logo.length < 100 ? getImagenURL('/public/imagenes/' + logo) : logo;
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE EMPRESA </h4>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <ControlText before="NUMERO FISCAL" value={numero_fiscal} setData={setState} update="numero_fiscal" />
                </Col>
                <Col md={12}><br />
                    <ControlText before="NOMBRE" value={nombre} setData={setState} update="nombre" />
                </Col>
                <Col md={12}><br />
                    <ControlText before="WEB" value={web} setData={setState} update="web" />
                </Col>
                <Col md={12}>
                    <label className="control-label mt-1">DIRECCION:</label>
                    <InputArea rows={3} setData={this.setState.bind(this)} value={direccion} update="direccion" />
                </Col>
                <Col md={12}>
                    <label className="control-label mt-1">INFORMACION DE PAGO:</label>
                    <InputArea rows={3} setData={this.setState.bind(this)} value={pago} update="pago" />
                </Col>
                <Col md={12}>
                    <label className="control-label mt-1">ESLOGAN:</label>
                    <InputArea rows={3} setData={this.setState.bind(this)} value={eslogan} update="eslogan" />
                </Col>
                <Col md={12}>
                    <label className="control-label mt-1">DIRECCIONES DE CORREO ELECTRONICO:</label>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}>DESCRIPCION</th>
                                <th>DIRECCION</th>
                                <th style={{ width: '80px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <AddCorreo add={s => this.setState({ correos: [...correos, s] })} />
                            {correos.map((s, i) => <tr key={i}>
                                <td>
                                    <InputText upper value={s.nombre} setData={(nombre) => this.changeCorreo(i, { ...s, nombre })} />
                                </td>
                                <td>
                                    <InputText upper value={s.correo} setData={(correo) => this.changeCorreo(i, { ...s, correo })} />
                                </td>
                                <td>
                                    <ButtonGroup>
                                        <Button variant={s.visible ? 'success' : 'warning'}
                                            onClick={() => this.changeCorreo(i, { ...s, visible: !s.visible })}>
                                            <i className={`fas fa-${s.visible ? 'eye' : 'eye-slash'}`}></i>
                                        </Button>
                                        <Button variant='danger' onClick={() => this.setState({ correos: correos.filter((_s, j) => j !== i) })}>
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                <Row>
                    <Col md={7}>
                        <label className="control-label">NUMEROS DE TELEFONO:</label>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '40%' }}>DESCRIPCION</th>
                                    <th>NUMERO</th>
                                    <th style={{ width: '80px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <AddTelefono add={s => this.setState({ telefonos: [...telefonos, s] })} />
                                {telefonos.map((s, i) => <tr key={i}>
                                    <td>
                                        <InputText upper value={s.nombre} setData={(nombre) => this.changeTelefono(i, { ...s, nombre })} />
                                    </td>
                                    <td>
                                        <SetNumber numero={s.numero} setNumero={(numero) => this.changeTelefono(i, { ...s, numero })} />
                                    </td>
                                    <td>
                                        <ButtonGroup>
                                            <Button variant={s.visible ? 'success' : 'warning'}
                                                onClick={() => this.changeTelefono(i, { ...s, visible: !s.visible })}>
                                                <i className={`fas fa-${s.visible ? 'eye' : 'eye-slash'}`}></i>
                                            </Button>
                                            <Button variant='danger' onClick={() => this.setState({ telefonos: telefonos.filter((_s, j) => j !== i) })}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={5}>
                        <label className="control-label">LOGO:</label><br />
                        <label style={{ width: '100%' }}>
                            <img src={img} width="100%" alt='logo' />
                            <input style={{ display: "none" }} onChange={this.setImage.bind(this)} type="file" />
                        </label>
                    </Col>
                </Row>
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>)
    }
    changeCorreo(i: number, s: Correo) {
        this.setState({ correos: this.state.correos.map((o, j) => j !== i ? o : s) });
    }
    changeTelefono(i: number, s: telefono) {
        this.setState({ telefonos: this.state.telefonos.map((o, j) => j !== i ? o : s) });
    }
    setImage(e: React.ChangeEvent<HTMLInputElement>) {
        let t = this, f = e.currentTarget.files;
        let reader = new FileReader()
        reader.onloadend = function () {
            t.setState({ logo: reader.result as string })
        }
        if (f != null) reader.readAsDataURL(f[0]);
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, s=>{
            this.setState(s)
            toast.success('CONFIGURACION GUARDADA');
        });
    }
}
interface telefono {
    nombre: string
    numero: [string, string, string]
    visible: boolean
}
export function SetNumber({ setNumero, numero: [n1, n2, n3] }: { numero: [string, string, string], setNumero: (n: [string, string, string]) => void }) {
    return <InputGroup>
        <InputText style={{ maxWidth: '55px' }} mask="999" maxLength={3} value={n1} setData={n1 => setNumero([n1, n2, n3])} />
        <InputText style={{ maxWidth: '60px' }} mask="9999" maxLength={4} value={n2} setData={n2 => setNumero([n1, n2, n3])} />
        <InputText mask="999-9999999" maxLength={10} value={n3} setData={n3 => setNumero([n1, n2, n3])} />
    </InputGroup>
}
function AddTelefono({ add }: { add: (d: telefono) => void }) {
    const [nombre, setNombre] = useState('');
    const [numero, setNumero] = useState<[string, string, string]>(['', '', '']);
    return <tr>
        <td>
            <InputText upper maxLength={15} value={nombre} setData={setNombre} />
        </td>
        <td>
            <SetNumber numero={numero} setNumero={setNumero} />
        </td>
        <td className='text-right'>
            <Button variant='success' onClick={() => { add({ nombre, numero, visible: true }); setNombre(''); setNumero(['', '', '']); }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}
interface Correo {
    nombre: string
    correo: string
    visible: boolean
}
function AddCorreo({ add }: { add: (d: Correo) => void }) {
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    return <tr>
        <td>
            <InputText maxLength={15} value={nombre} setData={setNombre} />
        </td>
        <td>
            <InputText value={correo} setData={setCorreo} />
        </td>
        <td className='text-right'>
            <Button variant='success' onClick={() => { add({ visible: true, nombre, correo }); setNombre(''); setCorreo(''); }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}