import { ButtonGroup } from 'react-bootstrap';
import { ListButonLink } from '../../../Components/Button/ListBoton';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { CapacitacionFila } from './Models';
import Fila from './Fila'; 
import { useContext } from 'react';
import { ProgramaContext } from '../Context/Programas';
import { IE, IEL } from '../../../Config/Updater';
export default function ListaCapacitaciones() {
    const { programas } = useContext(ProgramaContext);
    return <Tabla key={IEL(programas) + 'programas'} itens={programas} />
}
class Tabla extends CardBuscador<{ itens: CapacitacionFila[] }> {
    buscarEn = ['id', 'nombre', 'descripcion'];
    Title = () => <>
        <h3>BUSCAR PROGRAMA</h3>
        <ButtonGroup className='ml-auto'>
            <ListButonLink permiso='EVE_PARTICIPANTES' tip='' variant="orange" to='/programa/cetificados' >
                <i className="fas fa-file-certificate"></i> CERTIFICADOS
            </ListButonLink>
            <ListButonLink permiso='PRO_CATEGORIA' tip='' variant="warning" to='/programa/categorias' >
                <i className="fa fa-folders"></i> CATEGORIAS
            </ListButonLink>
            <ListButonLink permiso='PRO_CREAR' tip='' variant="primary" to='/programa/nueva' >
                <i className="fa fa-plus" /> NUEVO PROGRAMA
            </ListButonLink>
        </ButtonGroup>
    </>
    Row = (d: CapacitacionFila) => <Fila key={IE(d) + 'CapacitacionFila'}  data={d} update={this.set} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='90px' label='id'>COD</Th>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='100px' label='horas' aling='right'>HORAS</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='170px'>ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}
