import Preview from '../../Panel/Ver/PreviewSize';
import { Eventos } from '../SizePanel/Eventos';
import { Facilitadores } from '../SizePanel/Facilitadores';
import { Programas } from '../SizePanel/Programas';
import { Suscribir } from '../SizePanel/Suscribir';

export function PreviewSize({ panel, size }: { panel: any, size: string }) {
    switch (panel.type) {
        case 'eventos': return <Eventos key={'panel' + panel.id} data={panel} size={size} />
        case 'programas': return <Programas key={'panel' + panel.id} data={panel} size={size} />
        case 'suscribir2': return <Suscribir key={'panel' + panel.id} data={panel} size={size} />
        case 'TEIFacil': return <Facilitadores key={'panel' + panel.id} data={panel} size={size} />
        default: return <Preview key={'panel' + panel.id} panel={panel} size={size} />
    }
}