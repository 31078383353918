import { Button } from "react-bootstrap";
import { useCallback } from "react";
import { portapapeles } from "@victorequena22/utiles";
import { toast } from "react-toastify";
import { useEmpresa } from "@/Template/Empresa";
import { nomURL } from ".";

export function Copi({ nombre }: { nombre: string; }) {
    const { web } = useEmpresa();
    const link = `https://${web.toLowerCase()}/f/${nomURL(nombre)}`;
    const copi = useCallback(() => {
        portapapeles(link);
        toast.success('LINK COPIADO!');
    }, [link]);
    return <>
        <a href={link} target="_blank" rel="noreferrer"><b>LINK:</b> {link}</a>
        <Button size="sm" className="m-1" variant='primary' data-tip='copiar al portapapeles' onClick={copi}>
            <i className="fas fa-copy"></i>
        </Button>
    </>;
}
