import { CSSProperties } from "react"



export function getBorderCard(seleted: boolean, card = 'rounded', border = false, fill: string, back: string) {
    return {
        border: seleted ? '5px solid #FF0000' : (card !== 'none' && border) ? `5px solid ${fill}` : undefined,
        background: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `.5rem` : undefined,
        padding: '10px 10px 10px 10px',
    }
}
export function getBorderCardServicio(seleted: boolean, card = 'none', border: boolean, fill: string, back: string, font: number): CSSProperties {
    return {
        border: seleted ? '5px solid #FF0000' : (card !== 'none' && border) ? `5px solid ${fill}` : undefined,
        background: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px',
    }
}