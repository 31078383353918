import { Row, Container } from 'react-bootstrap';
import { content } from '../EditPanel/Galeria/Itens/props'
import { Html } from '../Component/Commons';
import { getImagenURL } from '@victorequena22/conneccion';
export const d = ['570px', '780px'];
export function Galeria({ data: { props, itens }, size }: any) {
    const { color, width, gap } = props;
    return <Row className='m-0 p-0' style={{ background: color }}>
        <Container style={{
            display: 'grid', gap,
            gridAutoFlow: 'dense',
            gridTemplateColumns: d.some(a => a === size) ? '' : `repeat(auto-fill, minmax(${width}px, 1fr))`,
        }}>
            {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} config={props} />)}
        </Container>
    </Row>
}
function Itens({ config: { animated, width },
    iten: { html, props: { img, col, row, margin, color, back } } }: any) {
    return <div style={{
        minHeight: `${width}px`, minWidth: `${width}px`,
        gridRow: `span ${row}`, gridColumn: `span ${col}`
    }} className={content(back, color, margin, animated, col, row, false)}>
        <img style={{ display: 'block', width: '100%' }} alt="Bricks"
            src={getImagenURL(img)} />
        <Html style={{
            background: `${back}`, color: `${color}`,
            margin: `${margin}px ${margin}px ${margin}px ${margin}px`
        }} className="caption" html={html} />
    </div>
}