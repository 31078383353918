import { Col, FormControl } from 'react-bootstrap';
import RolSelector from '../Config/Rol/Selector';
export interface AvanzadosProps {
    setData: (d: any) => void
    rol: number
    status: number
}
export default function Avanzados({ rol, status, setData }: AvanzadosProps) {
    const setRol = (rol: number) => { setData({ rol }) };
    const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => { setData({ status: parseInt(e.currentTarget.value) }) };
    return <div className='row'>
        <Col data-tip="FILTRAR POR ROL" xs={7} style={{ minWidth: '320px' }} className='RangoFechas'><br />
            <RolSelector change={setRol} defaultValue={rol} />
        </Col>
        <Col style={{ minWidth: '230px' }} xs={5} className='productoStock'><br />
            <FormControl as='select' data-tip="FILTRAR POR EL ESTADO" onChange={setStatus} value={status}>
                <option value={-1}>TODOS LOS ESTADOS</option>
                <option value={0}>INACTIVO</option>
                <option value={1}>ACTIVO</option>
            </FormControl>
        </Col>
    </div>;
}