import { BlockElement, Child } from "@victorequena22/slate-bootstrap";
import { CSSProperties } from "react";
const child: Child = { color: 'currentColor', fontFamily: 'Arial', bold: false, italic: false, underline: false, text: '' };
const children: Child[] = [
    { ...child, text: "¡", bold: true, italic: true },
    { ...child, text: "Este " },
    { ...child, text: "texto ", bold: true },
    { ...child, text: "es " },
    { ...child, text: "editable", italic: true, underline: true },
    { ...child, text: "!", bold: true, italic: true }
]
export const defaultText: BlockElement[] = [{ type: "paragraph", list: 'paragraph', align: 'left', tabs: 0, children }];
export type Variant = "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "light" | "dark" | "indigo" | "purple" | "pink" | "orange" | "teal" | "white" | "navy" | "olive" | "lime" | "maroon"
export type VariantButton = Variant | "outline-primary" | "outline-secondary" | "outline-success" | "outline-info" | "outline-warning" | "outline-danger" | "outline-light" | "outline-dark" | "outline-indigo" | "outline-purple" | "outline-pink" | "outline-orange" | "outline-teal" | "outline-white" | "outline-navy" | "outline-olive" | "outline-lime" | "outline-maroon"
export type sizeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type sizeWidth = '570px' | '780px' | '990px' | '1080px' | '1320px' | '1440px';

export interface DefaultProps {
    className?: string,
    style?: CSSProperties,
    tip?: string,
    disabled?: boolean
    place?: string
}