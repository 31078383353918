import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { conLanding } from '../../../../Panel/Models/Landing';
import { Template2 } from '../Template';
import { getFacilitador } from '../index';
import { EditarLanding } from '@/App/PanelProxi/Ver/EditarLanding';
export default function EditarPanel() {
    const { id } = getFacilitador();
    let { mid } = useParams<{ mid: any }>();
    const [panel, setPanel] = useState<any>([])
    useEffect(() => { conLanding().detalle(mid, setPanel); }, [mid])
    return <Template2 icon="fas fa-browser" title="EDITAR PANEL" back={`/facilitador/landing/${id}`}>
        <Edit panel={panel} />
    </Template2>
}
function Edit({ panel: l }: { panel: any }) {
    switch (l.type) {
        default: return <EditarLanding panel={l} />
    }
}