import { getSeccionContext } from "@/Template/Seccion";
import { Cargando } from "@victorequena22/component-bootstrap";
import { don } from "@victorequena22/conneccion";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { getSizeArea } from "./GetSize";
import { AreaNull } from "./Nulos";

interface Props {
    url: string
    visits: any
}
export default function Area(p: Props) {
    const { data: { cargando } } = getSeccionContext();
    return cargando ? <></> : <Carga {...p} />
}
function Carga({ url, visits }: Props) {
    const [cargando, setCargando] = useState(true);
    const [data, setData] = useState<any[]>([]);
    useEffect(() => { pull(url, data => { setData(data); setCargando(false); }) }, [url])
    if (cargando) return <Cargando />;
    if (!data.length) return <AreaNull />;
    const minValue = getMinValue(data);
    const [width, height] = getSizeArea();
    return <Chart width={width} height={height} chartType="AreaChart"
        loader={<Cargando />} rootProps={{ 'data-testid': '1' }}
        data={[[visits.labelDate, visits.labelVisits], ...data]}
        options={{
            title: visits.labelWeek,
            hAxis: { title: visits.labelDate, titleTextStyle: { color: '#333' } },
            vAxis: { minValue },
            chartArea: { width: '85%', height: '70%' },
            legend: { position: 'none' }
        }}
    />
}
const getMinValue = (data: any[]) => {
    let minValue = 0;
    if (data.length !== 0)
        data.forEach(d => {
            let v = d[1] as number;
            let c = d[2] as number;
            if (v > minValue) minValue = v;
            if (c > minValue) minValue = c;
        })
}
const pull = async (url: string, setData: (s: any[]) => void) => {
    try {
        let token = localStorage.getItem('Authorization');
        let response = await fetch(don + url, { headers: { 'Authorization': 'Bearer ' + token } });
        setData(await response.json());
    } catch (error) {
        console.log(error);
        setData([]);
    }
}