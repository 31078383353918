import InputFild from "@/Components/Material/InputFild";
import { Button, Col, FormGroup } from "react-bootstrap";


export default function Clave() {
    return <Col md={{ offset: 3, span: 6 }}>
        <FormGroup as='h1' className='w-100 text-primary'>Clave de @NombreUsuario</FormGroup>
        <FormGroup as='h3'><b>Nombre:</b>Nombre de Usuario</FormGroup>
        <FormGroup as='h3'><b>Correo Electrónico:</b>prueba@prueba.net</FormGroup>
        <FormGroup>
            <InputFild setData={_d => { }} label="Contraseña actual" value='' />
        </FormGroup>
        <FormGroup>
            <InputFild setData={_d => { }} label="Nueva contraseña" value='' />
        </FormGroup>
        <FormGroup>
            <InputFild setData={_d => { }} label="Repetir la Nueva contraseña" value='' />
        </FormGroup>
        <FormGroup >
            <Button block variant="outline-success" className="btn-flat">CAMBIAR</Button>
        </FormGroup>
    </Col>
}