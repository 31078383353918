/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SelectorMultiple } from '../../../Components/Selectors/Multiple';
import { ProgramaContext } from '../Context/Programas';
import { CapacitacionFila } from './Models';
interface Props {
    programas: number[];
    setProgramas: (tags: number[]) => void;
}
export function ProgramaSelectorMultiple({ programas, setProgramas }: Props) {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(programas);
    const { programas: Capacitaciones, actualizar: actualizarCapacitacion } = useContext(ProgramaContext);
    const filter = useCallback((buscar: string, i: CapacitacionFila) => {
        if (values.some(a => a === i.id)) return false;
        const en = buscar.split(" ");
        const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
        return en.length === con.length;
    }, [values]);
    useEffect(() => setValues(programas), [programas, open])
    return <>
        <SelectorMultiple open={open} close={() => setOpen(false)}
            titleModal='SELECIONAR PROGAMAS' selectTitle='PROGAMAS SELECCIONADOS'
            lista={Capacitaciones.filter(c => c.estatus > 0)} values={values} buscar={filter} setValues={setValues}
            actualizar={actualizarCapacitacion} save={() => setProgramas(values)} label='id'
            row={(data: any) => data.nombre} />
        <div className="w-100 mt-1">
            <Row as={Col}>
                <label className='m-0'>PROGRAMAS</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            {useMemo(() => <Col className="w-100 h-90 mt-1"
                style={{ minHeight: '50px', borderTop: 'solid 1px #ced4da', overflowY: 'auto' }}>
                {Capacitaciones.filter((i) => programas.some(a => i.id === a)).map((p) => <li>{p.nombre}</li>)}
            </Col>, [programas, Capacitaciones])}
        </div>
    </>
}