
import {useEvento } from "../index";
import { getParticipantes } from "../../../Context/Participante";
import Template from "../Template";
import Tabla from "./Tabla";

export default function InscribirParticipante() {
    const evento = useEvento();
    const participantes = getParticipantes();
    const lista = evento.participantes;
    const p = participantes.filter(p => !(lista.some(e => e.id === p.id)));
    console.log(lista.length, p.length);
    return <Template icon="fa fa-users-class" title="PARTICIPANTES">
        <Tabla key={`inscribir${lista.reduce((a, b) => a + b.id, 0)}`} itens={p} />
    </Template >
}