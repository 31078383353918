import { Html } from '../Component/Commons';
import { css } from '@emotion/css';
import { Row, Container, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { getBorderCard, getCSSButton } from '../Props';
import { getSettingCarrusel } from '../Component/Commons/Carrusel';
export function CarruselCardText({ data: { itens, props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const sts = s2.some(s => size === s) ? 4 : s1.some(s => size === s) ? 3 : 2;
    let settings = getSettingCarrusel(itens.length, sts);
    const { color, inter } = props
    return <Row className='m-0 w-100' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container style={{ background: color }} className={getCSS(color)}>
            <Slider className={getCSSButton(inter)} {...settings}>
                {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} />)}
            </Slider>
        </Container>
    </Row>
}
function Itens({ props: { gap, card, border1 }, iten: { html, props: { color, back, border } }, col }: any) {
    const style = getBorderCard(false, card, border1, border, back);
    return <Col xs={col} className='m-0 p-0'>
        <div className='w-100 h-100  pt-2 pb-2'>
            <div style={{ height: '100%', ...style, margin: `${gap}px` }} className="c-option-2 mt-2 mb-3">
                <Html style={{ color }} html={html} />
            </div>
        </div>
    </Col >
}


function getCSS(color: string) {
    return css`.slick-prev:before, .slick-next:before{
        color: ${color};
    }`;
}