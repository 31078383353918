import { useContext } from 'react';
import { MenuContext } from './MenuContext';
import Tasa from './Tasa';
import User from './User';

export default function Header() {
    const { open } = useContext(MenuContext);
    return <nav style={{ backgroundColor: '#363738', color: '#fff' }} className="main-header navbar navbar-expand navbar-success navbar-light">
        <ul className="navbar-nav">
            <li className="nav-item">
                <div className="nav-link" onClick={open}><i className="fa fa-bars"></i></div>
            </li>
        </ul>
        <ul className="navbar-nav ml-auto">
            <Tasa /> <User />
        </ul>
    </nav >
}