import { ParticipanteFila } from "../../../Participantes/Models";
import { numberFormat } from "@victorequena22/utiles";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import VerPagos from "./Pagos";
import { useContext, useEffect, useState } from "react";
import { MetodosPagosContext } from "../../../Context/MetodosPago";
import { Pago } from "../../../Pagos/Models";
import FormParticipante from "../../../Participantes/Editar";
import { ListButonClick } from "../../../../../Components/Button/ListBoton";
import RegistrarPago from "../../../Pagos/Nuevo";
import { useEvento } from "../index";
import { LinkWhatsapp } from "../../../Suscripciones/Subcriptores/Fila";
import { toast } from "react-toastify";
import { conCP } from "../../Models/Certificados";
interface Props {
    data: ParticipanteFila,
    pagos: Pago[],
}
export default function Fila({ data, pagos }: Props) {
    const evento = useEvento();
    const { nombre, pais, correo, telefono, id } = data;
    const part = evento.participantes.find(pa => pa.id === id)
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [pago, setPago] = useState(false);
    const [monto, setMonto] = useState(0);
    const { MetodosPagos } = useContext(MetodosPagosContext)
    useEffect(() => {
        let monto = 0;
        pagos.forEach(p => {
            if (p.estatus === 2) {
                const metodo = MetodosPagos.find(m => m.id === p.metodo);
                monto += (metodo && metodo.dolar) ? p.monto : p.monto / p.dolar;
                console.log((metodo && metodo.dolar) ? p.monto : p.monto / p.dolar)
            }
        })
        setMonto(monto)
    }, [MetodosPagos, pagos])
    const badge = ['success', 'warning', 'success', 'success', 'success'];
    const estados = ['INSCRITO', 'PREINSCRITO', 'INSCRITO', 'INSCRITO', 'INSCRITO'];
    const Badge = () => part ?
        <span className={`badge badge-${badge[part.status]}`}>{estados[part.status]}</span> :
        <span className={`badge badge-danger`}>NO INSCRITO</span>
    return <tr className="info" >
        <td>{nombre}</td>
        <td>{correo}</td>
        <td><LinkWhatsapp p={pais} t={telefono} /></td>
        <td><Badge /></td>
        <td className="text-right">{numberFormat(monto)}</td>
        <td className="text-right">
            <ReactTooltip key={`data${Math.random()}`} />
            <VerPagos participante={nombre.toUpperCase()} pagos={pagos} open={open} close={() => setOpen(false)} />
            <RegistrarPago participante={data} open={pago} close={() => { setPago(false); }} />
            {edit ? <FormParticipante open={edit} title={`EDITAR ${nombre}`} participante={data} close={() => setEdit(false)} add={() => { }} /> : <></>}
            <ButtonGroup>
                <Button onClick={() => setOpen(true)} data-tip='VER PAGOS' variant="warning">
                    <i className="fa fa-eye" />
                </Button>
                <ListButonClick permiso='PAR_CREAR' tip="EDITAR" variant="primary" click={() => setEdit(true)}>
                    <i className="fa fa-edit" />
                </ListButonClick>
                <Button onClick={() => setPago(true)} data-tip='REGISTAR NUEVO PAGO' variant="dark">
                    <i className="fas fa-usd-square" />
                </Button>
                {evento.estatus === 1 ? <>
                    {part && part.status === 1 ? <></> :
                        <Button variant="info" data-tip='PREINSCRIBIR EN EL EVENTO'
                            onClick={() => conCP().preinscribir(evento.id, id, () => {
                                toast.success('PARTICIPANTE PREINSCRITO');
                                console.log('PARTICIPANTE PREINSCRITO');
                            })}>
                            <i className="fa fa-check" />
                        </Button>}
                    {part && part.status === 2 ? <></> :
                        <Button variant="success" data-tip='INSCRIBIR EN EL EVENTO'
                            onClick={() => conCP().inscribir(evento.id, id, () => {
                                toast.success('PARTICIPANTE INSCRITO');
                                console.log('PARTICIPANTE INSCRITO');
                            })}>
                            <i className="fa fa-check-double" />
                        </Button>}
                    {!!(part) ? <Button variant="danger" data-tip='SACAR DEL EVENTO'
                        onClick={() => conCP().eliminar(evento.id, id, () => { })}>
                        <i className="fa fa-ban" />
                    </Button> : <></>}
                </> : <></>}
            </ButtonGroup>
        </td>
    </tr>
}