import { useParams } from 'react-router-dom';
import { getPrograma } from '../index';
import { Template2 } from '../Template';
import CrearLanding from '@/App/PanelProxi/Ver/CrearLanding';



export default function LandingProgramaCrear() {
    return <Template2
        icon="fas fa-browser"
        title="CREAR PANEL PARA EL EVENTO">
        <Crear />
    </Template2>
}

function Crear() {
    let { type } = useParams<{ type: string }>();
    const { id } = getPrograma();
    const p = { model_id: id, model: 'evento' };
    const edit = `/programa/evento/${id}/edit/`;
    switch (type) {
        default: return <CrearLanding url={type} edit={edit} props={p} />
    }
}