
export const setImage = (e: React.ChangeEvent<HTMLInputElement>, setData: (s: string) => void) => {
    const files = e.currentTarget.files;
    if (files !== null) ToString(files[0], setData);
}
const ToString = (file: any, setData: (d: string, file: any) => void) => {
    const reader = new FileReader()
    reader.onloadend = async () => setData(await initCanvas(reader.result as string), file);
    reader.readAsDataURL(file);
}
const initCanvas = async (src: string) => {
    const img = await createImage(src);
    if (img === null) return '';
    const canvas = document.createElement('canvas') as HTMLCanvasElement;;
    const { width, height } = img;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) return '';
    ctx.drawImage(img, 0, 0, width, height);
    const data = ctx.getImageData(0, 0, width, height);
    ctx.putImageData(data, 0, 0);
    return canvas.toDataURL('image/jpeg', 1);
}
const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image as HTMLImageElement))
    image.addEventListener('error', (_error) => reject(null))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
})