import { THFila } from "@victorequena22/tabla-bootstrap";
import { useContext, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { ListButonClick } from "../../../../Components/Button/ListBoton";
import { MetodosPagosContext } from "../../Context/MetodosPago";
import EditMetodoPago from "./From/Edit";
import { conMetodoPago, MetodoPago } from "./Models";

export default function Fila({ data }: { data: MetodoPago }) {
    const { actualizarMetodosPago } = useContext(MetodosPagosContext)
    const { nombre, metodo, dolar, estatus, id } = data;
    const [open, setOpen] = useState(false);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='auto'>{metodo}</THFila>
        <THFila width='100px' align="center"><span className={`badge badge-${dolar ? 'warning' : 'primary'}`}>{dolar ? 'DOLARES' : 'BOLIVARES'}</span></THFila>
        <THFila width='100px' align="center"><span className={`badge badge-${estatus ? 'success' : 'danger'}`}>{estatus ? 'ACTIVO' : 'INACTIVO'}</span></THFila>
        <td width='140px'>
            <EditMetodoPago update={actualizarMetodosPago} data={data} open={open} close={() => setOpen(false)} />
            <ButtonGroup>
                <ListButonClick permiso="PAG_METODOS" variant="primary" click={() => setOpen(true)}>
                    <i className="fa fa-edit" />
                </ListButonClick>
                <ListButonClick permiso="PAG_METODOS" variant="danger" click={() => conMetodoPago().cancelar(id, actualizarMetodosPago)}>
                    <i className="fa fa-ban" />
                </ListButonClick>
            </ButtonGroup>
        </td>
    </tr>
}