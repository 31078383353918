import { useCallback, useMemo } from 'react'
import { Editable, withReact, Slate, ReactEditor, useSlate } from 'slate-react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history';
import { Element, Leaf } from './Element';
import { hotKeysValue } from "@victorequena22/slate-bootstrap";
const SlateProvider = ({ value, setValue, children }: { value: any, setValue: (d: any) => void, children: any }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor() as ReactEditor)), []);
    return <Slate editor={editor} value={value} onChange={setValue}>
        {children}
    </Slate>
}
export default SlateProvider;
export const EditArea = ({ change, value, width }: { width: any, change?: (d: any) => void, value?: any[] }) => {
    const renderElement = useCallback((props: any) => <Element {...props} width={width} />, [width])
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
    const editor = useSlate();
    return <Editable key={'' + Math.random()}
        id='EditableHTML'
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck style={{ width, minWidth: '100px', margin: 0, padding: 0 }}
        onChange={change} value={value}
        onKeyDown={(event: any) => hotKeysValue(editor, event)}
    />
}


export function HTMLCerti({ text, width }: { text: any[], width: number }) {
    return <>
        {text.map((e) => (
            <Element key={JSON.stringify(e)} element={e} width={width}>
                {e.children.map((a: any) => (
                    <Leaf key={a.text} leaf={a}>{a.text}</Leaf>
                ))}
            </Element>
        ))}
    </>
}
