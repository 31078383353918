import { useState, useContext, useCallback, useEffect, ChangeEvent } from "react"
import { Button, Card, Col, FormControl, FormGroup } from 'react-bootstrap'
import "./Login.css";
import { ControlText } from "@victorequena22/component-bootstrap";
import { SeccionContext } from "../../Template/Seccion";
import { conConfig, don } from "@victorequena22/conneccion";
import { getImagenURL } from "@victorequena22/conneccion";
import getLanguage from "@/languages/export";

export default function LoginC() {
    const { login: { mail, password, login: l } } = getLanguage();
    const { user } = useContext(SeccionContext) as any;
    const [tipo, setTipo] = useState(getTipo());
    const [correo, setCorreo] = useState('');
    const [clave, setClave] = useState('');
    const [error, setError] = useState<any>({});
    const login = useCallback(() => conConfig('')
        .post(don + getURL(tipo), { correo, clave }, (data) => {
            if (data.error) setError(data.error)
            else {
                localStorage.setItem('Authorization', data.token);
                localStorage.setItem('TipoUsuario', '' + tipo);
                user();
            }
        }), [clave, correo, user, tipo]);
    useEffect(() => setError({}), [clave, correo,])
    return <div className="login-layoud" style={{ backgroundImage: `url(${getImagenURL("/static/panels/Fondo.webp")})` }}>
        <div className="login-box">
            <Card border="primary" className="card-outline" style={{ backgroundColor: "rgba(200, 200, 200, 0.75)" }} >
                <Card.Header className="text-center">
                    <Card.Img variant="top" src={getImagenURL('/static/media/logo_talento_software.png')} alt="ImgLogin" />
                </Card.Header>
                <Card.Body>
                    {getAliado() ? <Col as={FormGroup} xs={12}>
                        <Selector change={setTipo} value={tipo} />
                    </Col> : <></>}
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={error} value={correo} update="correo"
                            next={() => document.getElementById('clave')?.focus()}
                            before={mail} maxLength={150} setData={d => setCorreo(d.correo)} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={error} next={login} id='clave'
                            before={password} maxLength={150} value={clave} update="clave"
                            setData={d => setClave(d.clave)} type="password" />
                    </Col>
                    <Button onClick={login} variant="primary" block>{l}</Button>
                </Card.Body>
            </Card>
        </div>
    </div>
}
function getAliado() {
    if (ifDon())
        return true;
    return false;
}
function Selector({ change, value }: any) {
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(parseInt(e.currentTarget.value));
    return <FormControl as='select' onChange={set} value={value} >
        <option value='1'>ADMINISTRADOR</option>
        <option value='0'>ALIADO</option>
    </FormControl>
}
function getTipo() {
    if (ifDon()) {
        const tipo = localStorage.getItem('TipoUsuario');
        if (tipo !== null) return parseInt(tipo);
        return 0;
    } else {
        return 1;
    }
}
function getURL(tipo: number) {
    if (tipo === 0) return '/api/Aliado/login';
    return '/api/Auth/login';
}

function ifDon() {
    return "https://admin.talentoeinnovacion.net" === don || "http://talentobackend.test" === don;
}