import { useRef } from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";
import { ParticipanteFila } from "./Models";


export default function ParticipantesExcel({ Participantes, children, className, size }: { size?: "sm" | "lg", className?: string, Participantes: ParticipanteFila[], children?: any }) {
    const re = useRef<any>();
    const excelExport = () => re.current.save();
    if (Participantes.length === 0) return <></>
    return <>
        <Button size={size} className={className} variant='success' onClick={excelExport} style={{ marginRight: '15px' }}>
            <i className="fa fa-file-excel" />{children}
        </Button>
        <ExcelExport data={Participantes} ref={re} fileName="Participantes">
            <ExcelExportColumn field="nombre" title="NOMBRE" width={500} />
            <ExcelExportColumn field="correo" title="CORREO" width={500} />
            <ExcelExportColumn field="telefono" title="TELEFONO" width={300} />
            <ExcelExportColumn field="direccion" title="DIRECCION" width={1100} />
        </ExcelExport>
    </>
}

