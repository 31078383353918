import { ChangeEvent, useMemo } from 'react';
import { FormControl } from 'react-bootstrap';
const max = (new Date()).getFullYear();
interface Props {
    change: (r: string) => void,
    value: string
    hidePresente?: boolean
}
export default function InputYear({ change, value, hidePresente }: Props) {
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(e.currentTarget.value);
    const op = useMemo(() => getOption(), []);
    return <FormControl as='select' onChange={set} value={value} style={{ width: '125px' }}>
        {hidePresente ? <></> : <option value='presente'>presente</option>}
        {op}
    </FormControl>
}
function getOption() {
    const op = [];
    for (let i = max; i > 1970; i--) {
        op.push(<option key={i} value={i}>{i}</option>);
    }
    return op;
}