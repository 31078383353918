import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useEventoFila } from "../Context/Eventos";
import ReactTooltip from 'react-tooltip';
import ModalEvento from "../Evento/Fila/Ver";
import { PagoLista } from "./Models";
import ModalPago from "./Ver/ModalVer";
import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { findParticipanteFila } from "../Context/Participante";
import VerParticipante from "../Participantes/Ver";

export default function Fila({ pago, update }: { pago: PagoLista, update: () => void }) {
    const { model, model_id, nombre, referencia, banco, monto, nota, fecha, estatus, participante } = pago;
    const badge = ['danger', 'warning', 'success'];
    const estados = ['Rechazado', 'Pendiente', 'Aprobado'];
    return <tr>
        <THFila width="120px">{formatoFecha(fecha)}</THFila>
        <THFila width="150px">{banco}</THFila>
        <THFila width="150px">{referencia}</THFila>
        <THInput width="auto"><ParticipanteNombre nombre={nombre} participante={participante} /></THInput>
        <THFila width="120px" align="right">{numberFormat(monto)}</THFila>
        <THFila width="75px" align="center"><span className={`badge badge-${badge[estatus]}`}>{estados[estatus]}</span></THFila>
        <THFila width="auto">{nota}</THFila>
        <td>
            <ReactTooltip />
            <ButtonGroup>
                <PagoButton pago={pago} update={update} />
                {model === "evento" ? <EventoButton evento={model_id} /> : <></>}
            </ButtonGroup>
        </td>
    </tr>
}

function PagoButton({ pago, update }: { pago: PagoLista, update: () => void }) {
    const [open, setOpen] = useState(false);
    return <>
        <ModalPago update={update} pago={pago} open={open} close={() => setOpen(false)} />
        <Button data-tip='VER INFORMACION DEL PAGO' onClick={() => setOpen(true)} variant='outline-dark'>
            <i className="fas fa-eye" />
        </Button>
    </>
}
function EventoButton({ evento }: { evento: number }) {
    const [open, setOpen] = useState(false);
    const e = useEventoFila(evento);
    return <>
        <ModalEvento evento={e} open={open} close={() => setOpen(false)} />
        <Button data-tip='VER INFORMACION DEL EVENTO' onClick={() => setOpen(true)} variant='outline-primary'>
            <i className="fas fa-file-certificate" />
        </Button>
    </>
}
// function ParticipanteButton({ participante }: { participante: number }) {
//     const [open, setOpen] = useState(false);
//     const p = findParticipanteFila(participante);
//     return <>
//         <VerParticipante participante={p} open={open} close={() => setOpen(false)} />
//         <Button data-tip='VER INFORMACION DEL PARTICIPANTE' onClick={() => setOpen(true)} variant='outline-warning'>
//             <i className="fas fa-users-class" />
//         </Button>
//     </>
// }
function ParticipanteNombre({ participante, nombre }: { participante: number, nombre: string }) {
    const [open, setOpen] = useState(false);
    const p = findParticipanteFila(participante);
    if (p.id === 0) return <>Participante: {nombre}</>
    return <>
        <VerParticipante participante={p} open={open} close={() => setOpen(false)} />
        <Button data-tip='VER INFORMACION DEL PARTICIPANTE' onClick={() => setOpen(true)} variant='link'>
            {p.nombre}
        </Button>
    </>
}



