import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { ModalAlert } from "../../../../../Components/Modals";
import { conConfig } from "@victorequena22/conneccion";

export const setData = (setData: (d: any) => void) => {
    conConfig('').get('/api/Commons/tagAll', setData);
}
const eliminar = (id: number, setData: () => void) => {
    conConfig('').post('/api/Commons/deleteTag', { id }, setData);
}
interface iten {
    id: number,
    nombre: string,
    use: {
        evento: number;
        programa: number;
    }
}

interface Props {
    p: iten;
    actualizar: () => void
}

export const Etiqueta = ({ p: { nombre, id, use: { evento, programa } }, actualizar }: Props) => {
    const [open, setOpen] = useState(false);
    return <Card>
        <ModalAlert accion={() => eliminar(id, actualizar)} open={open}
            close={() => setOpen(false)} title={`ELIMINAR ETIQUETA "${nombre}"`}
            msn={`ESTA SEGURO QUE DESEA ELIMINAR LA ETIQUETA "${nombre}"`} />
        <Card.Header className="bg-primary"><b>{nombre}</b></Card.Header>
        <Card.Body>
            ETIQUETA USADA EN:<br />
            <i className="fas fa-books" /> PROGRAMAS : {programa}<br />
            <i className="fas fa-file-certificate" /> EVENTOS : {evento}
        </Card.Body>
        <Card.Footer>
            <Button variant="danger" block onClick={() => setOpen(true)}>
                <i className="" /> ELIMINAR
            </Button>
        </Card.Footer>
    </Card >
}