import { useEventos } from "@/App/Capacitacion/Context/Eventos";
import { EventoLista } from "@/App/Capacitacion/Evento/Models";
import { FieldText } from "@/Components/Field";
import { Tooltip } from "@mui/material";
import { Tabla, THFila } from "@victorequena22/tabla-bootstrap";
import { formatoFecha } from "@victorequena22/utiles";
import { Button, ButtonGroup, Row, Col } from "react-bootstrap";

export default function Formaciones() {
    const eventos = useEventos();
    return <TablaPagos key={`PagosEventos${eventos.length}`} eventos={eventos} />
}
interface Props {
    eventos: EventoLista[]
}
class TablaPagos extends Tabla<Props> {
    buscarEn = ['referencia', 'banco', 'monto', 'nota', 'fecha']
    fechas = ['fecha']

    Row = (d: EventoLista) => {
        const { state: { by, buscar, orden } } = this
        return <Fila data={d} key={`pagos${d.id}${d.estatus}${by}${orden}${buscar}`} />
    }
    Header() {
        let { Th } = this;
        return (<tr>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='125px' label='inicio'>INICIO</Th>
            <Th width='100px' aling='center'>ESTATUS</Th>
            <Th width='80px'> </Th>
        </tr>)
    }
    render() {
        const { Body } = this
        return <Row>
            <Col md={12}>
                <h1 className='w-100 text-primary'>Pagos realizados por @NombreUsuario</h1>
            </Col>
            <Col md={12} className='mt-2'>
                <FieldText variant="outlined" endAdornment={<i className="fas fa-search" />} size="small" label="Buscar" upper setData={buscar => this.setState({ buscar })} update='buscar' value={this.state.buscar} />
            </Col>
            <Col md={12} className='mt-4'><Body /></Col>
        </Row>

    }
    componentDidMount() {
        const { eventos } = this.props;
        this.setState({ by: 'id', itens: eventos });
    }
}
interface PropsFila {
    data: EventoLista
}
export function Fila({ data: { inicio, nombre, descripcion } }: PropsFila) {
    const status = getRandomInt(2)
    const badge = ['danger', 'warning', 'success'];
    const estados = ['NO INSCRITO', 'PREINSCRITO', 'INSCRITO'];
    const Badge = () => <span className={`badge badge-${badge[status]}`}>{estados[status]}</span>
    return <tr>
        <THFila tip={descripcion} width='auto'>{nombre}</THFila>
        <THFila width='125px'>{formatoFecha(inicio)}</THFila>
        <td><Badge /></td>
        <th>
            <ButtonGroup>
                <Tooltip title="Ver Pagos" placement="top">
                    <Button variant='outline-dark'>
                        <i className="fas fa-eye" />
                    </Button>
                </Tooltip>
                <Tooltip title="Ir al Evento" placement="top">
                    <Button>
                        <i className="fas fa-file-certificate" />
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </th>
    </tr >
}



function getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
}