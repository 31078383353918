import { useEffect } from 'react';
import { CardConfig, RowConfig, ButtonConfig, TitleCard } from '../../../Panel/Component';
import Slider from '@mui/material/Slider';
import { State } from '../Editor';
import { CircleSlider } from '../../../../Components/circle-slider';
import { InputNumber } from "@victorequena22/component-bootstrap";
interface PropsColor {
    setConfig: (d: any) => void;
    config: State;
    draw: (r: number, g: number, b: number, h: number, s: number, l: number, c: number) => void
}
export default function ColorSeting({ setConfig, config, draw }: PropsColor) {
    const { red, green, blue, hue, saturate, ling, cont } = config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { draw(red, green, blue, hue, saturate, ling, cont) }, [red, green, blue, hue, saturate, ling, cont]);
    return <>
        <Matiz setData={(hue) => setConfig({ hue })} value={hue} defaultValue={0} />
        <CardConfig marginNull width="141">
            <RowConfig >
                <div data-tip='INTENCIDAD DEL CANAL ROJO' className='d-flex justify-content-center' style={{ width: 43, height: 150 }}>
                    <SetFilter setData={(red) => setConfig({ red })} value={red} defaultValue={100} />
                </div>
                <div data-tip='INTENCIDAD DEL CANAL VERDE' className='d-flex justify-content-center' style={{ width: 43, height: 150 }}>
                    <SetFilter setData={(green) => setConfig({ green })} value={green} defaultValue={100} />
                </div>
                <div data-tip='INTENCIDAD DEL CANAL AZUL' className='d-flex justify-content-center' style={{ width: 43, height: 150 }}>
                    <SetFilter setData={(blue) => setConfig({ blue })} value={blue} defaultValue={100} />
                </div>
            </RowConfig>
            <RowConfig >
                <div className='d-flex justify-content-center' style={{ width: 43 }}>
                    <b>R</b>
                </div>
                <div className='d-flex justify-content-center' style={{ width: 43 }}>
                    <b>G</b>
                </div>
                <div className='d-flex justify-content-center' style={{ width: 43 }}>
                    <b>B</b>
                </div>
            </RowConfig>
            <TitleCard>INTENCIDAD DE COLOR</TitleCard>
        </CardConfig>
        <div style={{ width: 5 }}></div>
        <CardConfig marginNull width="141">
            <RowConfig >
                <div data-tip='SATURACION DEL COLOR' className='d-flex justify-content-center' style={{ width: 43, height: 150 }}>
                    <SetFilter2 setData={(saturate) => setConfig({ saturate })} value={saturate} defaultValue={100} />
                </div>
                <div data-tip='BRILLO' className='d-flex justify-content-center' style={{ width: 43, height: 150 }}>
                    <SetFilter2 setData={(ling) => setConfig({ ling })} value={ling} defaultValue={100} />
                </div>
                <div data-tip='CONTRASTE' className='d-flex justify-content-center' style={{ width: 43, height: 150 }}>
                    <SetFilter2 setData={(cont) => setConfig({ cont })} value={cont} defaultValue={100} />
                </div>
            </RowConfig>
            <RowConfig >
                <div className='d-flex justify-content-center' style={{ width: 43 }}>
                    <b><i style={{ marginTop: '3px' }} className="fas fa-swatchbook"></i></b>
                </div>
                <div className='d-flex justify-content-center' style={{ width: 43 }}>
                    <b><i style={{ marginTop: '3px' }} className='fas fa-sun'></i></b>
                </div>
                <div className='d-flex justify-content-center' style={{ width: 43 }}>
                    <b><i style={{ marginTop: '3px' }} className='fas fa-adjust'></i></b>
                </div>
            </RowConfig>
            <TitleCard>CONFIGURACION DE COLOR</TitleCard>
        </CardConfig>

    </>
}
interface Props {
    setData: (d: number) => void,
    value: number,
    defaultValue: number
}

const style = { margin: '0 0 0 0', padding: '0 0 0 0', width: 32 };
function Matiz({ setData, value }: Props) {
    return <CardConfig marginNull width="141">
        <RowConfig>
            <div data-tip='ROTAR MATIS(HUE)' style={{
                margin: '0 0 0 0', padding: '0 0 0 0',
                backgroundImage: 'url("/public/panels/coloring.png")',
                backgroundSize: 'cover', height: 135
            }}>
                <CircleSlider min={0} max={360} size={134} showTooltip={true} value={value}
                    circleColor='#00000000'
                    progressWidth={0} knobRadius={10} onChange={(v: any) => setData(v)} />
            </div>
        </RowConfig>
        <RowConfig>
            <ButtonConfig icon='fas fa-redo' action={() => setData(0)} />
            <InputNumber style={{ ...style, width: 95, margin: '0 0 0 0.25rem', fontSize: '.75rem', textAlign: 'center', height: 24 }} decimal={0}  {...{ setData, value, }} />
        </RowConfig>
        <TitleCard>ROTAR MATIZ(HUE)</TitleCard>
    </CardConfig>
}
function SetFilter({ setData, value, defaultValue }: Props) {
    return <div style={style}>
        <InputNumber style={{ ...style, fontSize: '.75rem', textAlign: 'center', height: 24 }} decimal={0}  {...{ setData, value, }} />
        <div className='d-flex justify-content-center' style={{ width: 32, height: 'calc(100% - 48px)' }}>
            <Slider orientation='vertical' max={255} min={0} step={1} value={value}
                style={{ height: 'calc(100% - 20px)', margin: '5px 0 10px 0' }}
                onChange={(_e: any, d: any) => setData(d)} />
        </div>
        <ButtonConfig icon='fas fa-redo' action={() => setData(defaultValue)} />
    </div>
}
function SetFilter2({ setData, value, defaultValue }: Props) {
    return <div style={style}>
        <InputNumber style={{ ...style, fontSize: '.75rem', textAlign: 'center', height: 24 }} decimal={0}  {...{ setData, value, }} />
        <div className='d-flex justify-content-center' style={{ width: 32, height: 'calc(100% - 48px)' }}>
            <Slider orientation='vertical' max={200} min={0} step={1} value={value}
                style={{ height: 'calc(100% - 20px)', margin: '5px 0 10px 0' }}
                onChange={(_e: any, d: any) => setData(d)} />
        </div>
        <ButtonConfig icon='fas fa-redo' action={() => setData(defaultValue)} />
    </div>
}
