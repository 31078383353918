import { ContentPermisoSome } from "@/Components/Button/Content";
import Seled from "../../Panel/Utiles/SelectedCreatePAnel";
import { getImagenURL } from "@victorequena22/conneccion";
interface Props {
    to: string
}

export const EventoSeled = ({ to }: Props) => {
    return <ContentPermisoSome permiso='EVENTO'>
        <Seled to={to} src={getImagenURL("/static/panels/var/4.jpg")} title="EVENTOS" />
    </ContentPermisoSome>
}
export const ProgramaSeled = ({ to }: Props) => {
    return <ContentPermisoSome permiso='PROGRAMA'>
        <Seled to={to} src={getImagenURL("/static/panels/var/4.jpg")} title="PROGRAMAS" />
    </ContentPermisoSome>
}
export const FacilitadorSeled = ({ to }: Props) => {
    return <ContentPermisoSome permiso='FACILITADOR'>
        <Seled to={to} src={getImagenURL("/static/panels/var/4.jpg")} title="FACILITADORES" />
    </ContentPermisoSome>
}