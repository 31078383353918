import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { ModalAlert, ModalReport } from "../../../../../Components/Modals";
import { getSize, Media } from "../../../../Media/Models";
import { conRecursos } from "../../Models";
export function Boton({ recurso: { id, nombre, size }, update }: { recurso: Media, update: () => void }) {
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false);
    return <>
        <ModalAlert accion={() => conRecursos().delete(id, update, () => { })}
            close={() => setAlert(false)} title={`ELIMINAR EL RECURSO "${nombre}"`}
            open={alert} msn={`ESTA SEGURO QUE DESEA ELIMINAR EL RECURSO "${nombre}"`} />
        <ModalReport open={open} close={() => setOpen(false)} title={nombre}
            url={`/api/Programa/Recursos/ver/${id}`} />
        <ButtonGroup className="m-2">
            <Button onClick={() => setOpen(true)} variant="default">{nombre}{getSize(size)}</Button>
            <Button onClick={() => setAlert(true)} variant="danger"><i className="fa fa-trash"></i></Button>
        </ButtonGroup>
    </>
}