import { Component } from "react";
import { useState } from 'react';
import { Button, Col, Card, Row } from 'react-bootstrap';
import ColorPicker from 'react-best-gradient-color-picker'
import ReactTooltip from 'react-tooltip';
import { conConfig } from "@victorequena22/conneccion";
import { toast } from "react-toastify";

interface State {
    colores: string[]
}
export default class Colores extends Component<any, State> {
    con = conConfig('colores');
    state = { colores: ['#000000', '#FFFFFF'] };
    render() {
        let { colores } = this.state;
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i>
                    CONFIGURACION DE COLORES </h4>
            </Card.Header>
            <Card.Body>
                <Row>
                    <ReactTooltip place='bottom' key={`tooltip${colores.length}`} />
                    <AddColor setData={c => this.setState({ colores: [...colores, c] })} />
                    <Row className='d-flex' style={{ minHeight: '350px', margin: '0 0 0 0', padding: '1rem 1rem 1rem 1rem', width: 'calc(100% - 320px)' }}>
                        {colores.map((c, i) => <div style={{
                            margin: '.25rem .25rem .25rem .25rem', border: 'solid 3px #000',
                            width: 200, background: c, height: '40px', padding: '.2rem .2rem .2rem .2rem',
                        }} data-tip={hexToRGBA(c)}>
                            <Button variant='danger' onClick={() => this.setState({ colores: colores.filter((_s, j) => j !== i) })}
                                className='float-right' style={{ padding: '.2rem .2rem .2rem .2rem', fontSize: '.7rem' }}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>)}
                    </Row>
                </Row>
                <Col md={12}><br />
                    <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                        <span className="fas fa-save" /> Guardar
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
    }
    change(i: number, s: string) {
        this.setState({ colores: this.state.colores.map((o, j) => j !== i ? o : s) });
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, s=>{
            this.setState(s)
            toast.success('CONFIGURACION GUARDADA');
        });
    }
}
const AddColor = ({ setData }: { setData: (d: string) => void }) => {
    const [color, setColor] = useState('rgba(240, 240, 240, 1)')
    return <div style={{ width: '300px' }}>
        <ColorPicker value={color} onChange={setColor}
            hidePresets hideEyeDrop />
        <Button className='mt-2' variant='success' block onClick={() => setData(color)}>
            <i className="fa fa-plus"></i> NUEVO COLOR
        </Button>
    </div>
}
export function hexToRGBA(hex: string) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    }
    return hex;
}

