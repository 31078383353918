import { useContext, ChangeEvent, useEffect, CSSProperties } from 'react';
import { FormControl } from 'react-bootstrap';
import { CategoriasContext } from '../../Context/Categorias';
interface Props {
    change: (r: number) => void,
    defaultValue: number,
    width?: string
    tipo?: 'TODOS LAS ' | 'SELECCIONAR'
    isInvalid?: boolean,
    style?: CSSProperties
    className?: string
}
export default function CategoriaSelector({ className, style, change, defaultValue, width, tipo = 'TODOS LAS ', isInvalid }: Props) {
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(parseInt(e.currentTarget.value));
    const { Categorias, actualizar } = useContext(CategoriasContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => actualizar(), []);
    return <FormControl className={className} onClick={actualizar} as='select' style={{ width, ...style }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
        <option value='0'>{tipo} CATEGORIA</option>
        {Categorias.filter(c => c.estatus > 0).map((c, i) => <option key={i} value={c.id}>{c.nombre}</option>)}
    </FormControl>
}