import { Col, InputGroup, Row } from "react-bootstrap";
import { Evento } from "../../Models";
import { ContrySelector, StateSelector } from "../../../../../Components/Contry";
import { ControlArea, ControlText, InputText } from "@victorequena22/component-bootstrap";
import { useCallback } from "react";

interface Props {
    data: Evento,
    error: any,
    setData: (data: Partial<Evento>) => void,
}
export function Direccion({ data, setData, error }: Props) {
    const { pais, direccion } = data;
    const setPais = useCallback((pais:any) => {
        setData({ pais, ciudad: '', estado: '' });
        if (pais === 'ONLI') setData({ estado: pais })
    }, [setData]);
    return <Col xs={12} className='mt-2'>
        <label>DIRECCIÓN</label>
        <Row>
            <Col xs={3}><ContrySelector defaultValue={pais} change={setPais} online isInvalid={!!error['pais']} /></Col>
            {pais !== 'ONLI' ?
                <Fisica data={data} setData={setData} error={error} /> :
                <Online data={data} setData={setData} error={error} />
            }
        </Row>
        <ControlArea className='mt-1' maxLength={500} value={direccion} setData={setData} update='direccion' error={error} />
    </Col>
}
function Fisica({ data, setData, error }: Props) {
    const { ciudad, pais, estado } = data;
    return <>
        <Col xs={3}><StateSelector pais={pais} defaultValue={estado} change={estado => setData({ estado })} isInvalid={!!error['estado']} /></Col>
        <Col xs={6}><ControlText before={pais !== 'ONLI' ? 'CIUDAD' : 'NOMBRE DE LA WEB'} maxLength={100} value={ciudad} setData={setData} update='ciudad' error={error} /></Col>
    </>


}
function Online({ data, setData, error }: Props) {
    const { ciudad } = data;
    var init = '', end = '';
    if (ciudad !== '') {
        const a = ciudad.split('-')
        init = a[0];
        end = a[1];
    }
    return <Col xs={9}>
        <InputGroup hasValidation={error}>
            <InputGroup.Prepend>
                <InputGroup.Text>HORAS</InputGroup.Text>
            </InputGroup.Prepend>
            <InputText type="time" value={init} setData={init => setData({ ciudad: `${init}-${end}` })} isInvalid={error.ciudad} />
            <InputGroup.Text style={{ borderRadius: 0 }}>A</InputGroup.Text>
            <InputText type="time" value={end} setData={end => setData({ ciudad: `${init}-${end}` })} isInvalid={error.ciudad} />
        </InputGroup>
    </Col>

}