
import { TitleCard, CardConfig, RowConfig, ButtonConfig, ButtonColor } from '../../../Component';
import { AddItens } from '../../../Component';
import { ButtonGroup } from 'react-bootstrap';
import { BlockElement, defaultText } from '../../../ProtoPanes/DefaultData';
import { iten } from '../index';
interface Props {
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void,
    addItem: () => void,
    copy: (id: number) => void,
    cont: number,
    iten: iten,
    setIten: (iten: Partial<iten>) => void
}
interface BProps {
    icon: 'up' | 'down' | 'left' | 'right'
    html: false | BlockElement[]
    set: (s: false | BlockElement[]) => void
    tip: 'top' | 'bottom' | 'left' | 'right';
}
export function ConfigIten(p: Props) {
    const { setIten, iten, cont, addItem, copy, itenUp, itenDown, removeItem } = p
    const { id, bottom, left, right, top, back } = iten;
    const BotonText = ({ icon, html, set, tip }: BProps) => {
        const h = !!html;
        return <ButtonConfig tip={getTips(h, tip)}
            icon={`fas fa-arrow-${icon}`} active={h}
            action={() => set(h ? false : defaultText)} />
    }
    return <CardConfig width='177px'>
        <RowConfig>
            <ButtonGroup>
                <ButtonConfig tip='MOVER IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
                <ButtonConfig tip='MOVER DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
            </ButtonGroup>
            <AddItens tip='AGREGAR UN TESTIMONIO' add={addItem} />
            <ButtonConfig className='ml-1' tip='COPIAR' icon='fas fa-copy' action={() => copy(id)} />
            <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
        </RowConfig>
        <RowConfig top={2}>
            <ButtonGroup className='mr-auto'>
                <BotonText html={top} icon='up' tip='top' set={top => setIten({ top })} />
                <BotonText html={bottom} icon='down' tip='bottom' set={bottom => setIten({ bottom })} />
                <BotonText html={left} icon='left' tip='left' set={left => setIten({ left })} />
                <BotonText html={right} icon='right' tip='right' set={right => setIten({ right })} />
            </ButtonGroup>
            <ButtonColor gradiend tip='COLOR DE FONDO DE LA CARTA' style={{ width: 32 }}
                color={back} setData={(back) => setIten({ back })} >
                <i className="fas fa-ad" />
            </ButtonColor>
        </RowConfig>
        <RowConfig top={2}><span className='ml-auto mr-auto'>{id} DE {cont}</span></RowConfig>
        <TitleCard>ITEN {id}</TitleCard>
    </CardConfig>
}

const getTips = (h: boolean, l: 'top' | 'bottom' | 'left' | 'right') => {
    switch (l) {
        case 'top': return h ? 'REMOVER EL TEXTO DE ARRIBA' : 'AGREGAR TEXTO ARRIBA';
        case 'bottom': return h ? 'REMOVER EL TEXTO DE ABAJO' : 'AGREGAR TEXTO ABAJO';
        case 'left': return h ? 'REMOVER EL TEXTO A LA IZQUIERDA DE LA IMAGEN' : 'AGREGAR TEXTO A LA IZQUIERDA DE LA IMAGEN';
        case 'right': return h ? 'REMOVER EL TEXTO A LA DERECHA DE LA IMAGEN' : 'AGREGAR TEXTO A LA DERECHA DE LA IMAGEN';
    }
}
