import { CSSProperties } from 'react';
import { Card, Col } from 'react-bootstrap';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { getImagenURL } from '@victorequena22/conneccion';
import { TextToHTML } from '@/App/Panel/Component/SlateCustom/Html';
interface Props {
    iten: iten,
    config: State,
    seled: (i: number) => void
}
export function Itens({ config: { seleted, size, color: c },
    iten: { id, html, img, color, back }, seled }: Props) {
    const s2 = ['1440px', '1320px'];
    const style: CSSProperties = { background: back, color };
    const s = seleted === id ? { border: '5px solid #FF0000' } : { border: '5px solid ' + c };
    return <Col style={{ ...s }} xs={s2.some(s => size === s) ? 4 : 6}>
        <Card onClick={() => seled(id)} style={{ height: '100%' }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(img)} />
            {seleted === id ?
                <Card.Body style={style}> <EditArea key={'edit' + id} /></Card.Body> :
                <Card.Body style={style} key={'html' + id}><TextToHTML text={html} /></Card.Body>
            }
        </Card>
    </Col >
}
