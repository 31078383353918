import { ConexionCompleta } from "@victorequena22/conneccion";

class ShortConeccion extends ConexionCompleta {
    clase = 'Web/Short';
    persistencia = 1000;
    delete = (id: number, setData: () => void, setError: () => void) => {
        this.get(`/api/${this.clase}/delete/${id}`, setData, setError)
    }
}
export function conShort() {
    return new ShortConeccion();
}
export interface Shor {
    id: number
    type: string
    descripcion: string
    html: string
    clave: string
    estatus: number
    data: any
}