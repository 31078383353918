import Crear from '@/App/Panel/Ver/CrearLanding';
import Eventos from '../Landing/Eventos';
import Programas from '../Landing/Programas';
import Suscribir from '../Custom/Subcribirse';
import Facilitadores from '../Landing/Facilitadores';
export default function CrearLanding(p: { url: string, props?: any, edit?: string }) {
    const { props, url, edit } = p;
    switch (p.url) {
        case 'eventos': return <Eventos key={'panel' + url} props={props} edit={edit} />
        case 'programas': return <Programas key={'panel' + url} props={props} edit={edit} />
        case 'suscribir2': return <Suscribir key={'panel' + url} props={props} edit={edit} />
        case 'TEIFacil': return <Facilitadores key={'panel' + url} props={props} edit={edit} />
        default: return <Crear {...p} />
    }
}