import { Panel } from '../../Panel/Models';
import Suscribir from '../Custom/Subcribirse';
import Eventos from '../Landing/Eventos';
import Facilitadores from '../Landing/Facilitadores';
import Programas from '../Landing/Programas';
import CopiarLanding2 from '@/App/Panel/Ver/CopiarLanding';
export function CopiarLanding({ panel: p, props, edit }: { panel: Panel, props?: any, edit?: string }) {
    switch (p.type) {
        case 'eventos': return <Eventos key={'panel' + p.id} copy={p} props={props} edit={edit} />
        case 'programas': return <Programas key={'panel' + p.id} copy={p} props={props} edit={edit} />
        case 'suscribir2': return <Suscribir key={'panel' + p.id} copy={p} props={props} edit={edit} />
        case 'TEIFacil': return <Facilitadores key={'panel' + p.id} copy={p} props={props} edit={edit} />
        default: return <CopiarLanding2 key={'panel' + p.id} panel={p} props={props} edit={edit} />
    }
} 