import { CardConfig, RowConfig, TitleCard } from "@victorequena22/component-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import { ButtonConfig } from "./Buttons";
import { sizeWidth } from "./DefaultData";

interface Props { setSize: (d: sizeWidth) => void; size: string; }
export function SizeViewSelector(p: Props) {
    return <CardConfig className='ml-auto' width='100px'>
        <RowConfig>
            <ButtonGroup size='sm'>
                <Button {...p} tip='TAMAÑO PEQUENO(CELULARES)' active='570px' icon='fas fa-mobile-alt' />
                <Button {...p} tip='TAMAÑO PEQUENO(TABLET VERTICAL)' active='780px' icon='fas fa-tablet-alt' />
                <Button {...p} tip='TAMAÑO MEDIANO(TABLET HORIZONTAL)' active='990px' icon='fas fa-tablet-alt fa-rotate-90' />
            </ButtonGroup>
        </RowConfig>
        <RowConfig top={2}>
            <ButtonGroup size='sm'>
                <Button {...p} tip='TAMAÑO MEDIANO(LAPTOP)' active='1080px' icon='fas fa-chalkboard' />
                <Button {...p} tip='TAMAÑO GRANDE(MONITOR 17")' active='1320px' icon='fas fa-desktop' />
                <Button {...p} tip='TAMAÑO GRANDE(MONITOR 19" O MAS)' active='1440px' icon='fas fa-tv' />
            </ButtonGroup>
        </RowConfig>
        <TitleCard>RESOLUCION</TitleCard>
    </CardConfig >
}

interface PropsButton extends Props {
    tip: string
    icon: string
    active: sizeWidth
}
const Button = ({ setSize, size, tip, icon, active }: PropsButton) => <ButtonConfig tip={tip} active={size === active} click={() => setSize(active)}>
    <i className={icon} />
</ButtonConfig>