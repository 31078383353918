import { Redirect } from "react-router-dom";
import Config from './Config';
import Preview from './Preview';
import SlateProvider from "./Stale";
import ItensManager from "./ItensManager";
export default class FromCertificado extends ItensManager {
    render = () => {
        const { props: { data: edit } } = this;
        const { data, id } = this.getText();
        const iten = this.getIten();
        if (this.state.Redirect) return <Redirect to='/evento/config/certificado' />
        return <div key={this.random} style={{ height: '100%', maxHeight: 'calc(100vh - 57px)' }}>
            <SlateProvider key={`panel${id}`} value={data} setValue={data => this.setIten({ data })}>
                <Config {...this.getItenManager()} iten={iten} setIten={this.setIten} setConfig={this.setData} config={this.state} save={this.save} />
                <Preview key='preview-component' iten={iten} setIten={this.setIten} setConfig={this.setData} config={this.state} edit={edit} />
            </SlateProvider>
        </div>
    }
}
