import { Col, Row } from "react-bootstrap";
import { Exports } from "./Menus/Exports";
import { useEvento } from "../index";
import { Copi } from "../Ver/Exports";
import { useState } from "react";
import Avanzar from "./Menus/Avanzar";
import Filtros from "./Menus/Filtros";
import Template from "../Template";
import { getParticipantes } from "../../../Context/Participante";
import Tabla from "./Tabla";
import ReactTooltip from 'react-tooltip';
export default function EventoParticipantes() {
    const { estatus, participantes: p } = useEvento();
    const l = getParticipantes();
    const [select, setSelect] = useState('all');
    const f = p.filter(e => {
        switch (select) {
            case 'all': return true;
            case 'app': return e.status === 3;
            case 'rep': return e.status === 0;
            case 'eva': return e.status === 2;
            default: return false;
        }
    })
    return <Template icon="fa fa-users-class" title='EVALUAR PARTICIPANTES'>
        <ReactTooltip />
        <Row><Copi /><Exports /></Row>
        {estatus > 1 ? <Filtros select={select} setSelect={setSelect} /> : <></>}
        <Row className='m-3'><Tabla itens={l.filter(e => f.some(k => k.id === e.id))} estatus={estatus} /></Row>
        <Row className='m-3'><Col className='text-right'><Avanzar /></Col></Row>
    </Template>
}