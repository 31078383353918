import { Button, InputGroup } from 'react-bootstrap';
import { CardConfig, CardMain, RowConfig, TitleCard, InputText } from "@victorequena22/component-bootstrap";
import { Guardar } from '@/App/Panel/Component';
import { setImage } from './SetImagen';
import { Props as p, Iten as i, ItenType } from '../Itens/Props'
import Iten from './Iten';
import { ButtonNew } from './Conponents';

export interface Props extends p {
    save: () => void
    removeItem: () => void
    setIten: (t: Partial<i>) => void;
    addIten: (t: ItenType) => void;
    itenDown: () => void;
    itenUp: () => void;
}
//qr, qrt, qrl, qrw,
export default function Config(p: Props) {
    const { save, setConfig, config: { aspect, nombre, errors } } = p;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='330px'>
            <RowConfig className='mb-2'>
                <InputGroup style={{ width: '100%' }} className='input-group-sm'>
                    <InputGroup.Prepend><InputGroup.Text>
                        NOMBRE:
                    </InputGroup.Text></InputGroup.Prepend>
                    <InputText data-tip={errors.nombre} data-type='error' isInvalid={errors.nombre} maxLength={100}
                        className='form-control-sm' setData={nombre => setConfig({ nombre })} value={nombre} />
                </InputGroup>
            </RowConfig>
            <RowConfig className='mt-2'>
                <Button data-tip="IMAGEN QUE SERA UTILIZADA COMO FONDO DEL DOCUMENTO" style={{ margin: '0 0 0 0', height: '48px', width: '125px' }}
                    as='label' className='mt-auto mr-1 d-flex' variant={errors.img ? 'danger' : 'primary'}>
                    <input style={{ display: "none" }} type="file" accept='image/*' onChange={(e) => setImage(e, setConfig)} />
                    <i className="fa fa-upload mr-1" style={{ fontSize: '30px' }} /><p style={{ fontSize: '10px' }}> SELECCIONAR  <br /> IMAGEN</p>
                </Button>
                <small style={{ width: 180 }} className={errors.img ? 'text-danger' : undefined}>
                    {errors.img ? errors.img : <>DIMENCIONES: {aspect.replace('/', 'X')} <br /> TAMAÑO MAXIMO: 1MB</>}
                </small>
            </RowConfig>
            <TitleCard>OBLIGATORIO</TitleCard>
        </CardConfig>
        <CardConfig width='100px'>
            <RowConfig>
                <ButtonNew click={() => p.addIten('TEXT')}>TEXTO</ButtonNew>
            </RowConfig>
            <RowConfig className='mt-2'>
                <ButtonNew click={() => p.addIten('IMG')}>IMAGEN</ButtonNew>
            </RowConfig>
            <RowConfig className='mt-2'>
                <ButtonNew click={() => p.addIten('QR')}>QR CODIGO</ButtonNew>
            </RowConfig>
            <TitleCard>NUEVO ELEMNTO</TitleCard>
        </CardConfig>
        <Iten {...p} />
    </CardMain>
}