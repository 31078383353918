import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { conEvento, EventoLista, eventoNull } from "../Models";

interface Props {
    evento: EventoLista
    open: boolean
    close: () => void
}
export default function ModalEvento({ evento: e, open, close }: Props) {
    const [evento, setEvento] = useState(eventoNull);
    const { precios, } = evento;
    useEffect(() => {
        if (e.id !== 0) conEvento().detalleData(e.id, ['precios',], setEvento);
    }, [e, setEvento]);
    return <Modal show={open} size='xl' centered onHide={() => { close(); }}>
        <Modal.Header closeButton>
            <h3 className='text-primary'>{e.codigo}</h3>
            <h3 className='ml-2 text-info'>{e.nombre}</h3>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}><h3><b>Fecha:</b></h3></Col>
                <Col md={12}>
                    <Row className='pl-3 pr-3'>
                        <Col md={8}>{e.fecha}</Col>
                        <Col md={2}>{formatoFecha(e.inicio)}</Col>
                        <Col md={2}>{formatoFecha(e.final)}</Col>
                    </Row>
                </Col>
                <Col md={12}><h3 className='ml-2'><b>Precios:</b></h3></Col>
                <Col md={12}>
                    <Row className='pl-3 pr-3'>
                        {precios.map(p => <>
                            <Col md={6}>{p.nombre}</Col>
                            <Col md={2}>{formatoFecha(p.inicio)}</Col>
                            <Col md={2}>{formatoFecha(p.final)}</Col>
                            <Col md={2}>{numberFormat(p.precio)}</Col>
                        </>)}
                    </Row>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}