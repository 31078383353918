import Drag from './Drag';
import { getWidth, Props } from '../Props';
import { getImagenURL as u } from '@victorequena22/conneccion';
import { useMemo } from 'react';

export default function Img({ iten, setConfig, setIten }: Props) {
    const { data, aspect } = iten;
    const img = data !== '' ? (data as string) : u(`/static/media/logo_talento_software.png`);
    return <Drag iten={iten} setConfig={setConfig} setIten={setIten}>
        {useMemo(() => {
            return <div key={`image-${getWidth(iten)}-${img}`} style={{
                background: `url(${img})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
                width: getWidth(iten),
                aspectRatio: aspect
            }} />
        }, [aspect, iten, img])}
    </Drag>
}
