import { Button, ButtonGroup } from 'react-bootstrap';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useEvento } from '../../index';
import { conEvento } from '../../../Models';
export default function Avanzar() {
    const { id, estatus, participantes } = useEvento();
    const participantesEnCurso = participantes.filter(p => p.status === 2);
    const save = useCallback(() => {
        conEvento().avanzar(id, () => {
            if (estatus === 1) toast.success('EVENTO INICIADO');
            if (estatus === 2) toast.success('EVENTO FINALIZADO');
        });
    }, [id, estatus])
    if (estatus < 3 && estatus > 0)
        return <ButtonGroup className="float-right">
            {estatus === 1 ? <Button variant='primary' onClick={() => save()}>
                <i className='fa fa-check' /> INICIAR EVENTO
            </Button> : participantesEnCurso.length > 0 ? <></> : <Button variant='success' onClick={() => save()}>
                <i className='fa fa-check-double' /> FINALIZAR EVENTO
            </Button>}
        </ButtonGroup>
    return <></>
}