import { useContext } from "react";
import { Button, Col, FormGroup, Image as Img, Row } from "react-bootstrap";
import { inRangeFechas, numberFormat } from "@victorequena22/utiles";
import { DolarContext } from "../../../../Template/Dolar";
import { MetodoPago, partialPago } from "../MetodosPago/Models";
import { ControlDate, ControlNumber, ControlText } from "@victorequena22/component-bootstrap";
import { MetodoMostrar } from "./Metodos";
import { setImage } from "./utils";
import { useEvento } from "../../Evento/GestionarEvento";
interface Props {
    setMetodo: (n: number) => void
    metodo: MetodoPago
    data: partialPago,
    setData: (p: Partial<partialPago>) => void
    error: any
    save: () => void
}
export function From({ save, metodo, setMetodo, error, data: { banco, captura, fecha, monto, referencia }, setData }: Props) {
    const { tasa } = useContext(DolarContext)
    const { precios } = useEvento();
    const p = precios.find(p => inRangeFechas(p.inicio, p.final));
    const precio = p ? p : precios[precios.length - 1];
    return <>
        <Col md={4}>
            <MetodoMostrar precio={precio.precio} dolar={tasa} metodo={metodo} setData={setMetodo} />
        </Col>
        <Col className={error.captura ? 'bg-danger' : ''} as='label' md={4}>
            <Row className="pl-5">{error.captura ? <b className="text-white">{error.captura}</b> : <b>Subir captura</b>}</Row>
            <input style={{ display: "none" }} type="file" accept='image/*'
                onChange={(e) => setImage(e, captura => setData({ captura }))} />
            <Img fluid src={captura !== '' ? captura : '/public/Productos/Add.svg'} />
        </Col>
        <Col md={4}>
            <Row>
                <Col as={FormGroup} md={12}>
                    <ControlDate error={error} update="fecha" setData={setData} before="Fecha de la operación*" value={fecha} />
                </Col>
                <Col as={FormGroup} md={12}>
                    <ControlText error={error} update="banco" setData={setData} before="Banco emisor*" value={banco} />
                </Col>
                <Col as={FormGroup} md={12}>
                    <ControlText error={error} update="referencia" setData={setData} before="Referencia de la operación*" value={referencia} />
                </Col>
                <Col as={FormGroup} md={12}>
                    <ControlNumber error={error} update="monto" setData={setData} before="Monto de la operación*" value={monto} />
                </Col>
                {metodo.dolar ? <></> : <Col as={FormGroup} md={12}><Row>
                    <Col className="text-right" xs={7}><b>Monto en dolares:</b></Col>
                    <Col className="text-right" xs={5}>{numberFormat(monto / tasa)}</Col>
                    <Col className="text-right" xs={7}><b>Tasa de cambio:</b></Col>
                    <Col className="text-right" xs={5}>{numberFormat(tasa)}</Col>
                </Row></Col>}
                <Col as={FormGroup} md={12} className="text-right">
                    <Button block onClick={save} variant="outline-success">Registrar</Button>
                </Col>
            </Row>
        </Col>
    </>
}