import { useCallback, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import { conCP } from "@/App/Capacitacion/Evento/Models/Certificados";
import { useEvento } from "../../../index";
import { ModalAlert } from "@/Components/Modals";
import { ParticipanteFila } from "@/App/Capacitacion/Participantes/Models";

interface Props {
    data: ParticipanteFila;
}
export default function Calificar({ data }: Props) {
    const { id, nombre, correo, telefono } = data;
    const s = useStatus(id);
    const info = useCallback((par: string) => {
        return `<div class='col'>
            <p><b>NOMBRE: </b>${nombre} </p>
            <p><b>CORREO: </b>${correo} </p>
            <p><b>TELEFONO: </b>${telefono} </p> 
            <p>ESTA SEGURO QUE ${par}  A ESTE PARTICIPANTE</p></div>`
    }, [nombre, correo, telefono])
    return s === 2 ? <ButtonGroup className="float-right">
        <Aprobar msn={info('APROBAR')} participante={id} />
        <ReactTooltip />
        <Reprobar msn={info('REPROBARA')} participante={id} />
    </ButtonGroup> : <></>
}
interface PropsB {
    msn: string;
    participante: number
}
function Aprobar({ msn, participante }: PropsB) {
    const { id } = useEvento();
    const [open, set] = useState(false);
    const send = useCallback(() => {
        conCP().aprobar({ evento: id, participante }, () => { })
    }, [id, participante])
    return <>
        <ModalAlert open={open} close={() => set(false)} title='APROBAR'
            msn={msn} accion={send} variant="success" />
        <Button variant="primary" onClick={() => set(true)}>
            <i className="fa fa-check" />
        </Button>
    </>
}
function Reprobar({ msn, participante }: PropsB) {
    const { id } = useEvento();
    const [open, set] = useState(false);
    const send = useCallback(() => {
        conCP().reprobar({ evento: id, participante }, () => { })
    }, [id, participante])
    return <>
        <ModalAlert open={open} close={() => set(false)} title='REPROBAR'
            msn={msn} accion={send} />
        <Button variant="danger" onClick={() => set(true)}>
            <i className="fa fa-ban" />
        </Button>
    </>
}
export function useStatus(id: number) {
    const s = useEvento().participantes.find(p => p.id === id)?.status;
    return s ? s : 0;
}


