import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { conLanding } from '../../../../Panel/Models/Landing';
import { Template2 } from '../Template';
import { getPrograma } from '../index';
import { CopiarLanding } from '@/App/PanelProxi/Ver/CopiarLanding';
export default function CopiarPanel() {
    let { mid } = useParams<{ mid: any }>();
    const [panel, setPanel] = useState<any>([])
    useEffect(() => { conLanding().detalle(mid, setPanel); }, [mid])
    return <Template2 icon="fas fa-browser" title="COPIAR PANEL DEL PROGRAMA">
        <Edit panel={panel} />
    </Template2>
}
function Edit({ panel: l }: { panel: any }) {
    const { id } = getPrograma();
    const p = { model_id: id, model: 'programa' };
    const edit = `/programa/landing/${id}/edit/`;
    switch (l.type) {
        default: return <CopiarLanding key={'panel' + l.type} panel={l} props={p} edit={edit} />
    }
}