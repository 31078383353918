import { SizeViewSelector } from '@/App/Panel2/Component/Menus';
import { BoxConfig, CardMain, RowConfig } from '@victorequena22/component-bootstrap';
import { Guardar, Tap } from '@/App/Panel2/Component/Buttons';
import { State } from '../index';
import { Generales } from './Generales';
import { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { TBElement } from '@/App/Panel2/Stale';
export interface Props {
    config: State
    setConfig: (d: any) => void
}
export const Config = (p: Props & { save: () => void }) => {
    const [seled, set] = useState('general');
    const { config, setConfig } = p;
    return <RowConfig>
        <BoxConfig className='p-1 mt-1'>
            <Guardar />
        </BoxConfig>
        <BoxConfig width='calc(100% - 7rem)'>
            <RowConfig className='ml-2 mr-2'>
                <ButtonGroup>
                    <Tap active={seled} seled='general' set={set}>
                        CONFIGURACION GENERAL
                    </Tap>
                    <Tap active={seled} seled='texto' set={set}>
                        TEXTO Y FUENTE
                    </Tap>
                </ButtonGroup>
            </RowConfig>
            <CardMain className='p-1'>
                <RowConfig>
                    <Seleted seled={seled} {...p} />
                </RowConfig>
                <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={config.size} />
            </CardMain>
        </BoxConfig>
    </RowConfig>
}


const Seleted = (p: Props & { seled: string }) => {
    switch (p.seled) {
        case 'general': return <Generales {...p} />;
        case 'texto': return <TBElement />
        default: return <TBElement />
    }
}

