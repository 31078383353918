import { InputLabel, FormControl, Select, FormHelperText } from '@mui/material';
import { CountryList, dataContry } from '@victorequena22/conneccion';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';

interface Props {
    change: (r: string, phone?: number) => void,
    defaultValue: string,
    width?: string
    error?: any;
    online?: boolean
    size?: "small"
}
export function ContrySelector({ change, defaultValue, width = '100%', error, size }: Props) {
    const [dataList, setData] = useState<CountryList[]>([]);
    const set = useCallback((e: any) => {
        const contry = dataList.find(d => d.iso2 === e.currentTarget.value)
        if (contry !== undefined) { change(contry.iso2, contry.phone); }
    }, [change, dataList]);
    const cargar = useCallback(async () => {
        const data = await dataContry().getCountryList();
        if (data && data !== null) setData(data);
    }, [])
    // eslint-disable-next-line
    useEffect(() => { cargar() }, []);
    return useMemo(() => <FormControl error={error} style={{ width }} size={size}>
        <InputLabel style={{ backgroundColor: 'white', padding: '0 .25rem 0 .25rem' }} htmlFor='ContrySelector'>País*</InputLabel>
        <Select native value={defaultValue} onChange={set} label="Pais"
            inputProps={{ id: 'ContrySelector' }}>
            {dataList.map(p => <option key={p.iso2} value={p.iso2}> {p.name} </option>)}
            <option aria-label="" value="" />
        </Select>
        {error ? <FormHelperText id={`ContrySelector-error`}>{error}</FormHelperText> : <></>}
    </FormControl>, [error, width, size, defaultValue, set, dataList]);
}



export function SelectBanderaFronTel({ change, value }: { change: (n: number) => void, value: number }) {
    const [dataList, setData] = useState<CountryList[]>([]);
    const [ban, setBan] = useState<string>('VE');
    const cargar = useCallback(async () => {
        const data = await dataContry().getCountryList();
        if (data && data !== null) setData(data);
    }, [])
    useEffect(() => {
        const contry = dataList.find(d => d.phone === value)
        console.log(contry, contry !== undefined)
        if (contry !== undefined) { setBan(contry.iso2); }
    }, [dataList, value]);
    // eslint-disable-next-line
    useEffect(() => { cargar() }, []);
    return <Dropdown>
        <Dropdown.Toggle id="paises" style={{ margin: 0, padding: 0, backgroundColor: 'transparent', border: 'none' }} >
            {useMemo(() => <img key={`selec${ban}`} height="38px" src={`https://flagsapi.com/${ban}/flat/64.png`} alt={ban} />, [ban])}
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0" style={{ overflowY: 'scroll', height: '30vh' }}>
            {dataList.map(p => <Dropdown.Item key={p.iso2} onClick={() => change(p.phone)}>
                <img height="38px" src={`https://flagsapi.com/${p.iso2}/flat/64.png`} alt={p.iso2} />
                +{p.phone}  {p.name}
            </Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>
}