import { ButtonColor, InputNumber } from "@victorequena22/component-bootstrap";
import { TitleInput } from "../Inputs";
import { getColors } from "@/Template/Colores";

const b = 'solid 3px currentColor';
const icon = { borderLeft: b, borderRight: b, paddingTop: 2 }

export const Separacion = ({ gap, setGap }: { gap: number, setGap: (g: number) => void }) => {
    return <TitleInput title={<i style={icon} className="fas fa-arrows-h" />}
        tip='SEPARACION ENTRE CARTAS' className='ml-1' width={85}>
        <InputNumber max={50} step={1} min={0} decimal={0} style={{ height: 24 }} value={gap} setData={setGap} />
    </TitleInput>
}

export function ColorDesplazamiento({ color, setColor, className }: { className?: string, color: string, setColor: (color: string) => void }) {
    const colores = getColors();
    return <ButtonColor className={className} colores={colores} disableAlpha
        tip='COLOR DE BOTONES DE DESPLAZAMIENTO' setData={setColor} color={color}>
        <i className="fas fa-arrows-h"></i>
    </ButtonColor>
}

export function getSettingCarrusel(l: number, s: number) {
    return {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToScroll: 1,
        slidesToShow: l < s ? l : s,
    }
}