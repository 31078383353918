import ReactTooltip from 'react-tooltip';
import { ButtonGroup } from 'react-bootstrap';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { CapacitacionFila, conCapacitacion } from './Models';
import { ListButonClick, ListButonLink } from '../../../Components/Button/ListBoton';
interface Props {
    data: CapacitacionFila
    update: () => void
}
export default function Fila({ data: { id, codigo, nombre, descripcion, horas, estatus: e }, update }: Props) {
    const span = ['danger', 'primary', 'success']
    const text = ['INACTIVO', 'ACTIVO', 'PUBLICADO']
    return <tr>
        <THFila width='90px'>{codigo}</THFila>
        <THFila tip={descripcion} width='auto'>{nombre}</THFila>
        <THFila width='100px' align='right'>{horas}</THFila>
        <THFila width='100px'><span className={`badge badge-${span[e]}`}>{text[e]}</span></THFila>
        <th style={{ minWidth: "170px", maxWidth: "170px" }}>
            <ButtonGroup className="float-right">
                <ListButonLink permiso='PROGRAMA' tip='VER' variant="info" to={'/programa/ver/' + id} >
                    <i className="fa fa-eye" />
                </ListButonLink>
                <ReactTooltip />
                {e < 2 ? <ListButonClick permiso='PRO_BORRAR' tip={e === 0 ? 'ACTIVAR' : "INACTIVAR"} variant={e === 0 ? 'primary' : 'danger'}
                    click={() => conCapacitacion().cancelar(id, update)}>
                    <i className={`fa fa-${e === 0 ? 'check' : 'trash'}`} />
                </ListButonClick> : <></>}
                {e > 0 ? <ListButonClick permiso='PRO_BORRAR' tip={e === 2 ? "OCULTAR" : 'PUBLICAR'} variant={e === 2 ? 'primary' : 'success'}
                    click={() => conCapacitacion().publicar(id, update)}>
                    <i className={`fa fa-${e === 2 ? 'eye-slash' : 'eye'}`} />
                </ListButonClick> : <></>}
            </ButtonGroup>
        </th>
    </tr >
}