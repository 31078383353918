import { useCallback, useState } from "react";
import { Button, ButtonGroup, Row, Table } from "react-bootstrap";
import { THFila } from "@victorequena22/tabla-bootstrap";
import { MetodoPago, conMetodoPago } from "../../../Pagos/MetodosPago/Models";
import EditMetodoPago from "../../../Pagos/MetodosPago/From/Edit";
import NuevoMetodoPago from "../../../Pagos/MetodosPago/From/Nuevo";
import { ListButonClick } from "../../../../../Components/Button/ListBoton";
import { MetodosPagoSelectorMultiple } from "../../../Pagos/MetodosPago/SelectorMultiple";
import {useEvento } from "..";
export default function SelectMetodoPago() {
    const { id: evento, metodos } = useEvento();
    const [nuevo, setNuevo] = useState(false);
    const [open, setOpen] = useState(false);
    const add = useCallback(({ id }: MetodoPago) => conMetodoPago().addEvento(id, evento, () => { }), [evento])
    return <>
        <MetodosPagoSelectorMultiple metodos={metodos.map(m => m.id)} setMetodos={useCallback(m => {
            conMetodoPago().multiAddEvento(m, evento, () => { })
        }, [evento])} open={open} close={() => setOpen(false)} />
        <NuevoMetodoPago add={add} open={nuevo} close={() => setNuevo(false)} />
        <Row>
            <h5 className='text-info'>MÉTODOS DE PAGOS</h5>
            <ButtonGroup className="ml-auto">
                <Button variant="primary" onClick={() => setOpen(true)}>BUSCAR</Button>
                <ListButonClick permiso="PAG_METODOS" variant="success" click={() => setNuevo(true)}>NUEVO</ListButonClick>
            </ButtonGroup>
        </Row>
        <Table className="w-100">
            <tr><th>NOMBRE</th><th>MÉTODOS DE PAGO</th></tr>
            {metodos.map(p => <Fila data={p} />)}
        </Table>
    </>
}
function Fila({ data }: { data: MetodoPago }) {
    const { id: evento } = useEvento();
    const { nombre, metodo, id } = data;
    const [open, setOpen] = useState(false);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='auto'>{metodo}</THFila>
        <td width='140px'>
            <EditMetodoPago update={() => { }} data={data} open={open} close={() => setOpen(false)} />
            <ButtonGroup>
                <ListButonClick permiso="PAG_METODOS" variant="primary" click={() => setOpen(true)}>
                    <i className="fa fa-edit" />
                </ListButonClick>
                <Button variant="danger" onClick={() => conMetodoPago().removeEvento(id, evento, () => { })}>
                    <i className="fa fa-ban" />
                </Button>
            </ButtonGroup>
        </td>
    </tr>
}

