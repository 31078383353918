import Fila from "./Fila";
import { Suscriptores } from "../../Programas/Models/Models";
import { CardBuscadorAvanzado } from "@victorequena22/tabla-bootstrap";
import { Accordion, Button, Card, Col, InputGroup, Row } from "react-bootstrap";
import { ContrySelector, StateSelector } from "../../../../Components/Contry";
import { InputText } from "@victorequena22/component-bootstrap";
import SuscriptoresExcel from "./Export";
interface Props {
    s: Suscriptores[];
    evento?: number;
}
export default class Subcriptores extends CardBuscadorAvanzado<Props> {
    buscarEn = ['nombre', 'correo', 'telefono', 'ciudad'];
    pais = '';
    estado = '';
    render() {
        let { Body, Buscador, Avansado } = this;
        return <Accordion>
            <Buscador />
            <Accordion.Collapse eventKey='0'><Card>
                <Card.Body><Avansado /></Card.Body>
            </Card></Accordion.Collapse>
            <Col md={12}><Body /></Col>
        </Accordion>
    }
    Avansado() {
        return <Row>
            <Col md={7}>
                <ContrySelector change={this.setPais} defaultValue={this.pais} />
            </Col>
            <Col md={5}>
                {this.pais === '' ? <></> : <StateSelector key={this.pais} pais={this.pais} change={this.setEstado} defaultValue={this.estado} />}
            </Col>
        </Row>
    }
    setPais = (p: string) => {
        this.pais = p;
        this.estado = '';
        this.forceUpdate()
    }
    setEstado = (e: string) => {
        this.estado = e;
        this.forceUpdate();
    }
    getItens() {
        return this.state.itens.filter(i => {
            if (this.pais !== '') {
                if (this.pais === i.pais) {
                    if (this.estado !== '') {
                        return this.estado === i.estado;
                    }
                    return true;
                }
                return false;
            }
            return true;
        })
    }
    Row = (e: Suscriptores) => <Fila key={`Suscriptores${e.id}`} s={e} e={this.props.evento} />
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='300px' label='nombre'>NOMBRE</Th>
            <Th width='200px' label='telefono'>TELEFONO</Th>
            <Th width='200px' label='correo'>CORREO</Th>
            <Th width='100px'>PAIS</Th>
            <Th width='100px'>ESTADO</Th>
            <Th width='100px'>CIUDAD</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>
    }
    componentDidMount(): void {
        const { s } = this.props;
        this.setState({ itens: s });
    }
    componentWillReceiveProps({ s }: Props): void {
        if (JSON.stringify(this.state.itens) !== JSON.stringify(s))
            this.setState({ itens: s });
    }
    Buscador() {
        return <Row className='d-flex' style={{ paddingBottom: '15px' }}>
            <Col md={9} className='mr-auto'>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>{this.buscarLabel}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText name='buscar' upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <InputGroup.Append>
                        <Button variant='primary'> <i className='fa fa-search'></i> BUSCAR </Button>
                        <Button as={Accordion.Toggle} eventKey='0' variant='default' data-tip='BUSQUEDA DETALLADA'>
                            <i className='fa fa-wrench'></i> DETALLADO
                        </Button>
                        <SuscriptoresExcel Suscriptores={this.getItens()}>
                             EXPORTAR EN EXCEL
                        </SuscriptoresExcel>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
        </Row>
    }
}