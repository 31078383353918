import { CardConfig, RowConfig, TitleCard, ButtonConfig } from '@victorequena22/component-bootstrap'
import { Props } from '../Itens/Props'
import { AlingMenu, MarkMenu } from '@victorequena22/slate-bootstrap'
import { ButtonGroup } from 'react-bootstrap'
import { BackButton, ColorButton, FontsFamily, FontSize, Inserts, ListaButton } from '../Stale/MarkProps';
import { TitleInput } from '@/App/Panel/Component/Inputs';

export function Editable({ setConfig, config: { editable } }: Props) {
    return editable ? <ButtonConfig className="ml-auto p-0" tip='CERRAR EDICION DE TEXTO'
        icon='fas fa-ban' action={() => setConfig({ editable: !editable })} variant='outline-danger'
    /> : <ButtonConfig className="ml-auto p-0" tip='HABILITAR EDICION DE TEXTO'
        icon='fas fa-text' action={() => setConfig({ editable: !editable })} variant='outline-info'
    />
}
export function Text() {
    return <CardConfig width='250px'>
        <RowConfig>
            <MarkMenu />
            <ButtonGroup className='ml-auto mr-auto'>
                <ColorButton />
                <BackButton />
            </ButtonGroup>
            <ListaButton />
            <Inserts />
        </RowConfig>
        <RowConfig top={2}>
            <AlingMenu left={0} />
            <span className="m-auto" />
            <TitleInput width={90} tip='TAMAÑO DE LA FUENTE'
                title={<i className="fa-regular fa-text-size" />}>
                <FontSize />
            </TitleInput>
        </RowConfig>
        <RowConfig top={2}>
            <TitleInput width='100%' tip='ESTILO DE LA FUENTE' title={<span>
                <i className="fa-regular fa-s" />
                <i className="fa-regular fa-text" />
            </span>}><FontsFamily /></TitleInput>
        </RowConfig>
        <TitleCard>FUENTE Y TEXTO</TitleCard>
    </CardConfig>
}