import { Row } from "react-bootstrap";
import getLanguage from "@/languages/export";
import Torta from "@/Components/Graficos/Torta";

export default function Dipositivos() {
    const { dashboard: { devices } } = getLanguage();
    return <Row>
        <div style={{ textAlign: 'center' }}>
            <Torta url='/api/Web/Metricas/Dispositivos'
                labels={[devices.labelDevices, devices.labelCant]}
            />
            <h5>{devices.title}</h5>
        </div>
    </Row>
}