import { Props } from "../Props";


export const SVG = ({ iten: { color, back, width, border, padding } }: Props) => {
	const correccion = (border + padding) * 2
	return <svg width={width - correccion} height={width - correccion} viewBox="0 0 264 264">
		<rect x="0" y="0" width="264" height="264" fill={back} stroke='#000' stroke-width={4} />
		<defs><rect id="p" width="8" height="8" /></defs>
		<g fill={color}>
			<use href="#p" x="32" y="32" />
			<use href="#p" x="32" y="40" />
			<use href="#p" x="32" y="48" />
			<use href="#p" x="32" y="56" />
			<use href="#p" x="32" y="64" />
			<use href="#p" x="32" y="72" />
			<use href="#p" x="32" y="80" />
			<use href="#p" x="32" y="96" />
			<use href="#p" x="32" y="112" />
			<use href="#p" x="32" y="120" />
			<use href="#p" x="32" y="128" />
			<use href="#p" x="32" y="136" />
			<use href="#p" x="32" y="144" />
			<use href="#p" x="32" y="160" />
			<use href="#p" x="32" y="176" />
			<use href="#p" x="32" y="184" />
			<use href="#p" x="32" y="192" />
			<use href="#p" x="32" y="200" />
			<use href="#p" x="32" y="208" />
			<use href="#p" x="32" y="216" />
			<use href="#p" x="32" y="224" />
			<use href="#p" x="40" y="32" />
			<use href="#p" x="40" y="80" />
			<use href="#p" x="40" y="96" />
			<use href="#p" x="40" y="104" />
			<use href="#p" x="40" y="128" />
			<use href="#p" x="40" y="152" />
			<use href="#p" x="40" y="160" />
			<use href="#p" x="40" y="176" />
			<use href="#p" x="40" y="224" />
			<use href="#p" x="48" y="32" />
			<use href="#p" x="48" y="48" />
			<use href="#p" x="48" y="56" />
			<use href="#p" x="48" y="64" />
			<use href="#p" x="48" y="80" />
			<use href="#p" x="48" y="96" />
			<use href="#p" x="48" y="112" />
			<use href="#p" x="48" y="120" />
			<use href="#p" x="48" y="128" />
			<use href="#p" x="48" y="136" />
			<use href="#p" x="48" y="144" />
			<use href="#p" x="48" y="160" />
			<use href="#p" x="48" y="176" />
			<use href="#p" x="48" y="192" />
			<use href="#p" x="48" y="200" />
			<use href="#p" x="48" y="208" />
			<use href="#p" x="48" y="224" />
			<use href="#p" x="56" y="32" />
			<use href="#p" x="56" y="48" />
			<use href="#p" x="56" y="56" />
			<use href="#p" x="56" y="64" />
			<use href="#p" x="56" y="80" />
			<use href="#p" x="56" y="96" />
			<use href="#p" x="56" y="104" />
			<use href="#p" x="56" y="112" />
			<use href="#p" x="56" y="136" />
			<use href="#p" x="56" y="176" />
			<use href="#p" x="56" y="192" />
			<use href="#p" x="56" y="200" />
			<use href="#p" x="56" y="208" />
			<use href="#p" x="56" y="224" />
			<use href="#p" x="64" y="32" />
			<use href="#p" x="64" y="48" />
			<use href="#p" x="64" y="56" />
			<use href="#p" x="64" y="64" />
			<use href="#p" x="64" y="80" />
			<use href="#p" x="64" y="104" />
			<use href="#p" x="64" y="144" />
			<use href="#p" x="64" y="176" />
			<use href="#p" x="64" y="192" />
			<use href="#p" x="64" y="200" />
			<use href="#p" x="64" y="208" />
			<use href="#p" x="64" y="224" />
			<use href="#p" x="72" y="32" />
			<use href="#p" x="72" y="80" />
			<use href="#p" x="72" y="128" />
			<use href="#p" x="72" y="136" />
			<use href="#p" x="72" y="160" />
			<use href="#p" x="72" y="176" />
			<use href="#p" x="72" y="224" />
			<use href="#p" x="80" y="32" />
			<use href="#p" x="80" y="40" />
			<use href="#p" x="80" y="48" />
			<use href="#p" x="80" y="56" />
			<use href="#p" x="80" y="64" />
			<use href="#p" x="80" y="72" />
			<use href="#p" x="80" y="80" />
			<use href="#p" x="80" y="96" />
			<use href="#p" x="80" y="112" />
			<use href="#p" x="80" y="128" />
			<use href="#p" x="80" y="144" />
			<use href="#p" x="80" y="160" />
			<use href="#p" x="80" y="176" />
			<use href="#p" x="80" y="184" />
			<use href="#p" x="80" y="192" />
			<use href="#p" x="80" y="200" />
			<use href="#p" x="80" y="208" />
			<use href="#p" x="80" y="216" />
			<use href="#p" x="80" y="224" />
			<use href="#p" x="88" y="128" />
			<use href="#p" x="88" y="136" />
			<use href="#p" x="88" y="144" />
			<use href="#p" x="88" y="160" />
			<use href="#p" x="96" y="32" />
			<use href="#p" x="96" y="48" />
			<use href="#p" x="96" y="56" />
			<use href="#p" x="96" y="64" />
			<use href="#p" x="96" y="80" />
			<use href="#p" x="96" y="96" />
			<use href="#p" x="96" y="104" />
			<use href="#p" x="96" y="112" />
			<use href="#p" x="96" y="128" />
			<use href="#p" x="96" y="168" />
			<use href="#p" x="96" y="200" />
			<use href="#p" x="96" y="208" />
			<use href="#p" x="96" y="216" />
			<use href="#p" x="96" y="224" />
			<use href="#p" x="104" y="32" />
			<use href="#p" x="104" y="56" />
			<use href="#p" x="104" y="64" />
			<use href="#p" x="104" y="72" />
			<use href="#p" x="104" y="88" />
			<use href="#p" x="104" y="104" />
			<use href="#p" x="104" y="128" />
			<use href="#p" x="104" y="152" />
			<use href="#p" x="104" y="160" />
			<use href="#p" x="104" y="168" />
			<use href="#p" x="104" y="192" />
			<use href="#p" x="104" y="200" />
			<use href="#p" x="104" y="216" />
			<use href="#p" x="112" y="64" />
			<use href="#p" x="112" y="80" />
			<use href="#p" x="112" y="136" />
			<use href="#p" x="112" y="168" />
			<use href="#p" x="112" y="176" />
			<use href="#p" x="112" y="200" />
			<use href="#p" x="112" y="224" />
			<use href="#p" x="120" y="48" />
			<use href="#p" x="120" y="56" />
			<use href="#p" x="120" y="64" />
			<use href="#p" x="120" y="96" />
			<use href="#p" x="120" y="104" />
			<use href="#p" x="120" y="112" />
			<use href="#p" x="120" y="160" />
			<use href="#p" x="120" y="168" />
			<use href="#p" x="120" y="192" />
			<use href="#p" x="120" y="200" />
			<use href="#p" x="120" y="208" />
			<use href="#p" x="120" y="216" />
			<use href="#p" x="120" y="224" />
			<use href="#p" x="128" y="48" />
			<use href="#p" x="128" y="56" />
			<use href="#p" x="128" y="72" />
			<use href="#p" x="128" y="80" />
			<use href="#p" x="128" y="88" />
			<use href="#p" x="128" y="104" />
			<use href="#p" x="128" y="120" />
			<use href="#p" x="128" y="136" />
			<use href="#p" x="128" y="144" />
			<use href="#p" x="128" y="168" />
			<use href="#p" x="128" y="176" />
			<use href="#p" x="128" y="184" />
			<use href="#p" x="128" y="216" />
			<use href="#p" x="128" y="224" />
			<use href="#p" x="136" y="40" />
			<use href="#p" x="136" y="48" />
			<use href="#p" x="136" y="56" />
			<use href="#p" x="136" y="72" />
			<use href="#p" x="136" y="112" />
			<use href="#p" x="136" y="120" />
			<use href="#p" x="136" y="128" />
			<use href="#p" x="136" y="144" />
			<use href="#p" x="136" y="160" />
			<use href="#p" x="136" y="168" />
			<use href="#p" x="136" y="176" />
			<use href="#p" x="136" y="216" />
			<use href="#p" x="136" y="224" />
			<use href="#p" x="144" y="56" />
			<use href="#p" x="144" y="72" />
			<use href="#p" x="144" y="80" />
			<use href="#p" x="144" y="88" />
			<use href="#p" x="144" y="96" />
			<use href="#p" x="144" y="104" />
			<use href="#p" x="144" y="120" />
			<use href="#p" x="144" y="128" />
			<use href="#p" x="144" y="136" />
			<use href="#p" x="144" y="152" />
			<use href="#p" x="144" y="168" />
			<use href="#p" x="144" y="184" />
			<use href="#p" x="144" y="192" />
			<use href="#p" x="144" y="216" />
			<use href="#p" x="152" y="48" />
			<use href="#p" x="152" y="64" />
			<use href="#p" x="152" y="88" />
			<use href="#p" x="152" y="120" />
			<use href="#p" x="152" y="136" />
			<use href="#p" x="152" y="152" />
			<use href="#p" x="152" y="168" />
			<use href="#p" x="152" y="184" />
			<use href="#p" x="152" y="200" />
			<use href="#p" x="152" y="208" />
			<use href="#p" x="160" y="32" />
			<use href="#p" x="160" y="40" />
			<use href="#p" x="160" y="64" />
			<use href="#p" x="160" y="80" />
			<use href="#p" x="160" y="104" />
			<use href="#p" x="160" y="112" />
			<use href="#p" x="160" y="136" />
			<use href="#p" x="160" y="144" />
			<use href="#p" x="160" y="152" />
			<use href="#p" x="160" y="160" />
			<use href="#p" x="160" y="168" />
			<use href="#p" x="160" y="176" />
			<use href="#p" x="160" y="184" />
			<use href="#p" x="160" y="192" />
			<use href="#p" x="160" y="200" />
			<use href="#p" x="160" y="208" />
			<use href="#p" x="168" y="96" />
			<use href="#p" x="168" y="112" />
			<use href="#p" x="168" y="120" />
			<use href="#p" x="168" y="128" />
			<use href="#p" x="168" y="136" />
			<use href="#p" x="168" y="144" />
			<use href="#p" x="168" y="160" />
			<use href="#p" x="168" y="192" />
			<use href="#p" x="168" y="200" />
			<use href="#p" x="168" y="208" />
			<use href="#p" x="168" y="216" />
			<use href="#p" x="168" y="224" />
			<use href="#p" x="176" y="32" />
			<use href="#p" x="176" y="40" />
			<use href="#p" x="176" y="48" />
			<use href="#p" x="176" y="56" />
			<use href="#p" x="176" y="64" />
			<use href="#p" x="176" y="72" />
			<use href="#p" x="176" y="80" />
			<use href="#p" x="176" y="128" />
			<use href="#p" x="176" y="136" />
			<use href="#p" x="176" y="144" />
			<use href="#p" x="176" y="152" />
			<use href="#p" x="176" y="160" />
			<use href="#p" x="176" y="176" />
			<use href="#p" x="176" y="192" />
			<use href="#p" x="176" y="200" />
			<use href="#p" x="176" y="208" />
			<use href="#p" x="176" y="224" />
			<use href="#p" x="184" y="32" />
			<use href="#p" x="184" y="80" />
			<use href="#p" x="184" y="104" />
			<use href="#p" x="184" y="128" />
			<use href="#p" x="184" y="136" />
			<use href="#p" x="184" y="152" />
			<use href="#p" x="184" y="160" />
			<use href="#p" x="184" y="192" />
			<use href="#p" x="184" y="224" />
			<use href="#p" x="192" y="32" />
			<use href="#p" x="192" y="48" />
			<use href="#p" x="192" y="56" />
			<use href="#p" x="192" y="64" />
			<use href="#p" x="192" y="80" />
			<use href="#p" x="192" y="96" />
			<use href="#p" x="192" y="112" />
			<use href="#p" x="192" y="128" />
			<use href="#p" x="192" y="136" />
			<use href="#p" x="192" y="144" />
			<use href="#p" x="192" y="152" />
			<use href="#p" x="192" y="160" />
			<use href="#p" x="192" y="168" />
			<use href="#p" x="192" y="176" />
			<use href="#p" x="192" y="184" />
			<use href="#p" x="192" y="192" />
			<use href="#p" x="192" y="200" />
			<use href="#p" x="192" y="208" />
			<use href="#p" x="192" y="216" />
			<use href="#p" x="192" y="224" />
			<use href="#p" x="200" y="32" />
			<use href="#p" x="200" y="48" />
			<use href="#p" x="200" y="56" />
			<use href="#p" x="200" y="64" />
			<use href="#p" x="200" y="80" />
			<use href="#p" x="200" y="96" />
			<use href="#p" x="200" y="120" />
			<use href="#p" x="200" y="160" />
			<use href="#p" x="200" y="192" />
			<use href="#p" x="200" y="200" />
			<use href="#p" x="200" y="224" />
			<use href="#p" x="208" y="32" />
			<use href="#p" x="208" y="48" />
			<use href="#p" x="208" y="56" />
			<use href="#p" x="208" y="64" />
			<use href="#p" x="208" y="80" />
			<use href="#p" x="208" y="96" />
			<use href="#p" x="208" y="120" />
			<use href="#p" x="208" y="128" />
			<use href="#p" x="208" y="144" />
			<use href="#p" x="208" y="160" />
			<use href="#p" x="208" y="168" />
			<use href="#p" x="208" y="176" />
			<use href="#p" x="208" y="200" />
			<use href="#p" x="208" y="208" />
			<use href="#p" x="208" y="216" />
			<use href="#p" x="208" y="224" />
			<use href="#p" x="216" y="32" />
			<use href="#p" x="216" y="80" />
			<use href="#p" x="216" y="104" />
			<use href="#p" x="216" y="128" />
			<use href="#p" x="216" y="144" />
			<use href="#p" x="216" y="160" />
			<use href="#p" x="216" y="176" />
			<use href="#p" x="216" y="184" />
			<use href="#p" x="216" y="192" />
			<use href="#p" x="216" y="200" />
			<use href="#p" x="216" y="208" />
			<use href="#p" x="216" y="224" />
			<use href="#p" x="224" y="32" />
			<use href="#p" x="224" y="40" />
			<use href="#p" x="224" y="48" />
			<use href="#p" x="224" y="56" />
			<use href="#p" x="224" y="64" />
			<use href="#p" x="224" y="72" />
			<use href="#p" x="224" y="80" />
			<use href="#p" x="224" y="96" />
			<use href="#p" x="224" y="128" />
			<use href="#p" x="224" y="136" />
			<use href="#p" x="224" y="152" />
			<use href="#p" x="224" y="160" />
			<use href="#p" x="224" y="168" />
			<use href="#p" x="224" y="176" />
			<use href="#p" x="224" y="192" />
			<use href="#p" x="224" y="200" />
			<use href="#p" x="224" y="224" />
		</g>
		<g></g>
	</svg>
}