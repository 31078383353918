import { InputText } from "@victorequena22/component-bootstrap";
import { useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { getSMS } from "./Enviar";
import { getImagenURL } from "@victorequena22/conneccion";

export function Send({ url, id, update }: { url: string, id: any, update: () => void }) {
    const [sms, setSMS] = useState('');
    const enviar = useCallback(() => { setSMS(''); getSMS(url, id, sms, update) }, [id, sms, update, url])
    return <div className="text-muted d-flex w-100 justify-content-start align-items-center p-3 pt-3 mt-2">
        <img src={getImagenURL("/logos/logo.png")} alt="avatar 3" style={{ width: '38px', height: '100%' }} />
        <InputText style={{ width: '100%', borderColor: 'transparent' }}
            next={enviar}
            value={sms} setData={setSMS} placeholder="ESCRIBE TU MENSAJE" />
        <Button variant="primary" onClick={enviar}>
            <i className="fas fa-paper-plane"></i>
        </Button>
    </div>
}