import Template from '../Template';
import { PreviewSize } from '../../../../PanelProxi/Ver/PreviewSize';
import { getFacilitador } from '../index';
import { VerLanding } from '@/App/PanelProxi/SizeLanding/Ver';
export function Ver() {
    const { id } = getFacilitador();
    const url = `/facilitador/landing/${id}`;
    return <Template icon="fas fa-browser" title="VER LANDING DEL PROGRAMA">
        <VerLanding url={url} Preview={PreviewSize} />
    </Template>
}