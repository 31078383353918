import { Component } from "react";
import { Modal, Row, Button, Col } from 'react-bootstrap'
import { ControlText } from "@victorequena22/component-bootstrap";
import { conUsuario } from '../../Usuarios/Usuario';
import { SelectorPermisos } from './SelectorPermisos';
interface Props {
    open: boolean
    close: () => void
    actualizar: () => void
}
interface State {
    nombre: string
    permisos: number[]
}
export default class NewRol extends Component<Props, State> {
    con = conUsuario();
    state = { permisos: [], nombre: '' };
    render() {
        const { props: { close, open }, state: { permisos } } = this;
        return <Modal size='xl' animation centered show={open} onHide={close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                    NUEVO ROL
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <ControlText before="MONBRE DEL ROL" update="nombre" setData={this.setState.bind(this)} value={this.state.nombre} /><br />
                        <SelectorPermisos selecteds={permisos} Click={this.click.bind(this)} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.save.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal >
    }
    click(id: number) {
        const { permisos } = this.state;
        if (permisos.every(p => p !== id)) this.setState({ permisos: [...permisos, id] });
        else this.setState({ permisos: permisos.filter(p => p !== id) });
    }
    save() {
        let { nombre, permisos } = this.state;
        this.con.nuevoRol({ nombre, permisos }, (_data) => {
            this.props.actualizar()
            this.props.close()
        });
    }
}