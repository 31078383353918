import Drag from './Drag';
import { getWidth, Props } from '../Props';
import { EditArea, HTMLCerti } from "../../Stale";
import { BlockElement } from "@victorequena22/slate-bootstrap";
export default function Text({ iten, config, setConfig, setIten }: Props) {
    const { left, top, id, data, border, padding, back, radeus, color } = iten;
    const { editable, selected } = config;
    const { fontSize = 10 } = (data as BlockElement[])[0].children[0];
    if (selected === id && editable) return <div id={`iten-${id}`}
        onDoubleClick={() => setConfig({ editable: false })}
        style={{
            left, top, margin: 0, padding,
            position: 'absolute', fontSize,
            width: getWidth(iten), zIndex: id,
            backgroundColor: back, borderRadius: radeus,
            border: `${border}px solid ${color}`
        }}>
        <EditArea width={getWidth(iten)} />
    </div>
    return <Drag iten={iten} setConfig={setConfig} setIten={setIten}>
        <div style={{ fontSize }}>
            <HTMLCerti text={data as BlockElement[]} width={getWidth(iten)} />
        </div>
    </Drag >
}

