import { ButtonColor as BC } from "@victorequena22/component-bootstrap";
import { DefaultProps, VariantButton } from "./DefaultData"
import { Button, ButtonGroup } from "react-bootstrap"
import { getColors } from "@/Template/Colores"

interface Props extends DefaultProps {
    active?: boolean
    variant?: VariantButton;
    children: any;
}
const styleButton = { width: 32, height: 24, fontSize: 10, padding: 0 }
export function ButtonConfig({ disabled, click, place = 'bottom', tip, className, active, style, variant = 'outline-dark', children }: Props & { click: () => void }) {
    return <Button  {...{ disabled, className, active, variant, style: { ...styleButton, ...style, } }}
        size='sm' data-place={place} data-tip={tip} onClick={click}>{children}</Button>;
}
interface colorProps extends Props {
    setData: (d: string) => void,
    invert?: boolean
    color: string,
    disableAlpha?: boolean
    click?: () => void
    gradiend?: boolean
}
export function ButtonColor(p: colorProps) {
    const colores = getColors();
    return <BC colores={colores} {...p} />
}

export function Guardar() {
    return <ButtonGroup vertical className="m-1 bg-white">
        <Button className='p-0' onClick={() => { }} data-tip='GUARDAR' variant='outline-success' >
            <i className="fa-solid fa-save fa-3x"></i>
        </Button>
        <ButtonGroup >
            <ButtonConfig click={() => { }} variant="outline-info">
                <i className="fa-solid fa-file"></i>
            </ButtonConfig>
            <ButtonConfig click={() => { }} variant="outline-primary">
                <i className="fa-solid fa-folder"></i>
            </ButtonConfig>
            <ButtonConfig click={() => { }} variant="outline-orange">
                <i className="fa-solid fa-trash-alt"></i>
            </ButtonConfig>
        </ButtonGroup>
    </ButtonGroup>
}


export function Tap({ children, set, seled, active }: { children: any, set: (d: string) => void, seled: string, active: string }) {
    return <ButtonConfig active={active===seled} click={() => set(seled)} variant="outline-info" style={{ width: 'fit-content', paddingInline: '.25rem' }}>
        {children}
    </ButtonConfig>
}