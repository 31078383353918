import { CardMain, TitleCard, CardConfig, RowConfig } from "@victorequena22/component-bootstrap";
import { Guardar, SizeViewSelector, Title } from '../../../Panel/Component';
import { State } from './index';
import ReactTooltip from 'react-tooltip';
import CategoriaSelector from '../../../Capacitacion/Programas/Categorias/Selector';
import ProgramaSelector from '../../../Capacitacion/Programas/Selector';
import { ColorDesplazamiento, Separacion } from '@/App/Panel/Component/Commons/Carrusel';
import { ColorFondo } from '@/App/Panel/Component/Commons/Simples';
import { propsSelect, Select } from '../../Utils/Select';
interface Props {
    save: () => void
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, gap, color, filtro, filtroType, inter } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="250px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ReactTooltip />
                <ColorFondo gradiend setColor={color => setConfig({ color })} color={color} />
                <ColorDesplazamiento className='ml-1' setColor={inter => setConfig({ inter })} color={inter} />
                <Separacion gap={gap} setGap={gap => setConfig({ gap })} />
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width="500px">
            <RowConfig >
                <Select value={filtroType} setData={filtroType => setConfig({ filtro: 0, filtroType })}>
                    <option value='none'>SIN FILTROS</option>
                    <option value='program'>POR PROGRAMA</option>
                    <option value='categoria'>POR CATEGORIA</option>
                </Select>
            </RowConfig>
            <RowConfig top={2} style={{ height: '24' }}>
                {filtroType === 'categoria' ? <CategoriaSelector {...propsSelect} change={filtro => setConfig({ filtro })} defaultValue={filtro} /> : <></>}
                {filtroType === 'program' ? <ProgramaSelector {...propsSelect} change={filtro => setConfig({ filtro })} defaultValue={filtro} /> : <></>}
            </RowConfig>
            <RowConfig top={2} style={{ height: '24' }}><></></RowConfig>
            <TitleCard>FILTROS</TitleCard>
        </CardConfig>
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}