import { Component } from "react";
import { Modal, Row, Button, Col } from 'react-bootstrap'
import { conUsuario, Rol } from '../../Usuarios/Usuario'
import { SelectorPermisos } from './SelectorPermisos'
interface Props {
    rol: Rol
    title: string
    open: boolean
    add: (d: any) => void
    close: () => void
}
interface State {
    permisos: number[],
}
export default class EditarRol extends Component<Props, State> {
    con = conUsuario();
    state = { permisos: [] };
    componentDidMount() {
        this.con.PermisosRol(this.props.rol.id, (rol: Rol) => {
            this.setState({ permisos: rol.permisos.map(d => { return d.id }) })
        });
    }
    render() {
        let { props: { close, open, title }, state: { permisos } } = this;
        return <Modal size='xl' animation centered show={open} onHide={close}>
            <Modal.Header closeButton>
                {title}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <SelectorPermisos selecteds={permisos} Click={this.click.bind(this)} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.save.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal >
    }
    click(id: number) {
        const { permisos } = this.state;
        if (permisos.every(p => p !== id)) this.setState({ permisos: [...permisos, id] });
        else this.setState({ permisos: permisos.filter(p => p !== id) });
    }
    save() {
        let { props: { rol: { id } }, state: { permisos } } = this;
        this.con.actualizarRol(id,
            { permisos }, data => {
                this.props.add(data)
                this.props.close()
            })
    }
}