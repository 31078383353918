import { useRef, useEffect, useCallback, useState } from "react";
import { Col } from "react-bootstrap";
import Mensaje from "./Mensaje";
import { Send } from "./Send";
import { getChat } from "./Enviar";
export function Content({ url, id }: { url: string, id: any }) {
    const ref = useRef<HTMLDivElement>();
    const [mensajes, setMensajes] = useState<any[]>([]);
    const update = useCallback(() => getChat(url, id, m => {
        if (mensajes.length !== m.length) setMensajes(m)
    }), [id, mensajes.length, url])
    useEffect(() => {
        const c = ref.current;
        if (c) c.scrollTop = c.scrollHeight;
    }, [mensajes])
    useEffect(() => {
        const inter = setInterval(update, 1000);
        return () => clearInterval(inter)
    }, [update])
    return <div className="row">
        <Col xs={12} className="pt-3 pe-3" ref={ref as any}
            style={{ position: 'relative', maxHeight: '400px', overflowY: 'scroll' }}>
            {mensajes.map(m => <Mensaje sms={m } />)}
        </Col>
        <Send url={url} id={id} update={update} />
    </div>
}