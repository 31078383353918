import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import { IE } from "../../../../../Config/Updater";
import { EventoLista } from "../../../Evento/Models";
import { Fila } from "../../../Evento/Fila";

export default class Tabla extends CardBuscador<{ itens: EventoLista[] }> {
    buscarEn = ['id', 'nombre', 'descripcion'];
    render() {
        let { Body, Buscador, } = this;
        return <><Buscador /><Body /></>
    }
    Row = (d: EventoLista) => <Fila key={'evento' + IE(d) + d.publicacion} data={d} update={this.set} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='150px' label='codigo'>COD</Th>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='125px' label='inicio'>INICIO</Th>
            <Th width='100px' aling='center'>ESTATUS</Th>
            <Th width='120px' aling='center'>PUBLICADO</Th>
            <Th width='150px' aling='center'>ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}