import { Button, Table } from "react-bootstrap"
import { useState, useCallback } from "react"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { ControlText } from "@victorequena22/component-bootstrap"
import { conAval } from "../../Models"
interface Props {
    update?: () => void
}
export function NuevoAval({ update }: Props) {
    const [nombre, setNombre] = useState('');
    const nuevo = useCallback(() => {
        if (nombre === '') {
            toast.error('DEBE COLOCAR UNA DESCRIPCION');
        } else {
            conAval().nuevo({ nombre, costo: 1 }, () => {
                if (update) update();
                setNombre('');
                toast.success('ACTUALIZADO EXITOSAMENTE');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre]);
    return <>
        <label>NUEVA AVAL</label>
        <Table>
            <tr>
                <th style={{ width: "calc(100% - 145px)" }}>
                    <ReactTooltip />
                    <ControlText before='NOMBRE' setData={setNombre} value={nombre} />
                </th>
                <th style={{ width: "145px" }}>
                    <Button data-tip="GUARDAR" variant="primary" onClick={nuevo}>
                        <i className="fa fa-edit"></i> GUARDAR
                    </Button>
                </th>
            </tr>
        </Table>
    </>
}