import { Component } from "react";
import { Row, Col, FormGroup, FormLabel, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ControlArea, ControlText, ControlNumber } from "@victorequena22/component-bootstrap";
import { GrupoPago } from "../Models/Model";
import { conGrupoPago } from "../Models";
interface Props {
    model?: string;
    id?: number;
    grupo?: GrupoPago;
    add?: (c: GrupoPago) => void
}
interface State {
    nombre: string;
    nota: string;
    precio: number;
    cupos: number;
}
export default class FormGrupoPago extends Component<Props, State> {
    error: any = [];
    grupo: GrupoPago | null;
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        if (this.props.grupo !== undefined) {
            this.grupo = this.state = this.props.grupo;
        } else {
            this.state = { nombre: '', nota: '', precio: 0, cupos: 0 };
            this.grupo = null;
        }
    }
    render() {
        let { setData, state: s } = this
        return <Row>
            <Col as={FormGroup} hasValidation={this.error['nombre'] ? true : false} xs={12}>
                <ControlText error={this.error} before="NOMBRE COMPLETO" maxLength={200}
                    value={s.nombre} setData={setData} update="nombre" />
            </Col>
            <Col xs={3} as={FormGroup} hasValidation={this.error['cupos'] ? true : false} >
                <ControlNumber error={this.error} before="CUPOS" step={1}
                    value={s.cupos} setData={setData} update="cupos" />
            </Col>
            <Col xs={4} as={FormGroup} hasValidation={this.error['precio'] ? true : false} >
                <ControlNumber error={this.error} before="PRECIO POR CUPO" 
                    value={s.precio} setData={setData} update="precio" />
            </Col>
            <Col xs={5} as={FormGroup}>
                <ControlNumber before="PAGO TOTAL" disabled
                    value={s.precio * s.cupos} setData={_S => { }} />
            </Col>
            <Col as={FormGroup} xs={12} hasValidation={this.error['nota'] ? true : false} >
                <FormLabel>NOTA</FormLabel>
                <ControlArea error={this.error} value={s.nota}
                    className='mt-1' placeholder='' upper
                    setData={setData} update="nota" maxLength={300} />
            </Col>
            <Col xs={12} as={FormGroup} className="text-right">
                <Button onClick={() => this.get()} variant="success">Guardar</Button>
            </Col>
        </Row>
    }
    isInvalid(s: string) {
        if (s === "") return true
        return false
    }
    get = () => {
        let error: any = {};
        const s = this.getData();
        let procesar = true;
        if (s.nombre === "") error.nombre = 'DEBE COLOCAR UN NOMBRE AL GRUPO DE PAGO';
        if (s.cupos === 0) error.cupos = 'DEBE COLOCAR EL NUMERO DE CUPOS DISPONIBLES PARA EL GRUPO DE PAGO';
        if (s.precio === 0) error.precio = 'DEBE COLOCAR UN PRECIO POR CUPO';
        if (Object.keys(error).length > 0) { this.setError([error]); procesar = false; }
        if (procesar) {
            const con = conGrupoPago();
            if (this.grupo === null) con.nuevo(s, this.action, this.setError)
            else con.actualizar(this.grupo.id, s, this.action, this.setError)
        }
    }
    setError = (err: any) => {
        err.forEach((err: any) => {
            if (err.sql) {
                err.sql.forEach((er: string) => toast.error(er));
            } else {
                Object.keys(err).forEach(t => this.error[t] = err[t])
            }
        })
        this.forceUpdate();
    }
    action = (data: any) => {
        if (this.props.add) this.props.add(data);
        if (this.grupo === null) this.setData({ nombre: '', nota: '', precio: 0, cupos: 0 });
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
    getData = () => {
        const { nombre, nota, precio, cupos } = this.state;
        const { model, id: model_id } = this.props;
        return { nombre, nota, precio, cupos, model, model_id };
    }

}
