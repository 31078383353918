import { css } from '@emotion/css';
import Slider from '@mui/material/Slider'
import { Button, ButtonGroup, ProgressBar } from "react-bootstrap";
import { CardMain, CardConfig, RowConfig, ButtonConfig, TitleCard } from '../../../Panel/Component';
import { State } from '../Editor';
import CropEdit, { Zoom } from './CropEdit';
import FilterEdit from './FilterEdit';
import ColorSeting from './ColorEdit';
import { GPUTrans } from "../GPU/Manager";
import { useEffect, useState } from 'react';
interface Props {
    config: State;
    setConfig: (d: any) => void,
    save: () => void
    crop: () => void
    close: () => void
    default: () => void
    trans: (s: GPUTrans) => void
    draw: (r: number, g: number, b: number, h: number, s: number, l: number, c: number) => void
}
export function Config(p: Props) {
    const { edit, calc } = p.config;
    const [car, setCar] = useState(100);
    useEffect(() => {
        if (calc) {
            let b = 1;
            const cargando = (c: number) => {
                setCar(b)
                b += c;
                if (b < 99 && b > 0) setTimeout(() => cargando(c - 2), b * 10);
            }
            if (car === 100) cargando(20);
        } else {
            if (car !== 100) setCar(100)
        }
    }, [calc, car])
    return <CardMain className='mr-1' width="151px">
        <RowConfig >
            <Guardar guardar={p.save} />
            <Close close={p.close} />
        </RowConfig>
        <Retaurar default={p.default} />
        <RowConfig top={1}>
            <Data data={'H:' + p.config.hsl.length} />
            <Data css='margin: 0 0 0 5px;' data={'W:' + p.config.hsl[0].length} />
        </RowConfig>
        <CardConfig marginNull width="140px">
            <RowConfig>
                <ProgressBar className='w-100' now={car} />
            </RowConfig>
            <TitleCard>{calc ? 'CARGANDO' : 'COMPLETO'}</TitleCard>
        </CardConfig>
        <CardConfig marginNull width="140px">
            <RowConfig >
                <ButtonGroup>
                    <ButtonConfig tip='CORTAR IMAGEN' style={{ width: 43 }}
                        icon='far fa-crop-alt' active={edit === 'crop'}
                        action={() => p.setConfig({ edit: 'pre-crop' })} />
                    <ButtonConfig tip='RETOCAR COLORES' style={{ width: 43 }}
                        icon='fas fa-brush' active={edit === 'color'}
                        action={() => p.setConfig({ edit: 'color' })} />
                    <ButtonConfig tip='APLICAR FILTROS' style={{ width: 43 }}
                        icon='fas fa-filter' active={edit === 'filter'}
                        action={() => p.setConfig({ edit: 'filter' })} />
                </ButtonGroup>
            </RowConfig>
            <TitleCard>OPCIONES DE EDICION</TitleCard>
        </CardConfig>
        <Interfaz {...p} />
    </CardMain>
}
interface PropsFiltro {
    setData: (d: number) => void
    value: number
    max: number
    min: number
    sal: number
    step: number
    orientation?: 'vertical'
}
export function Filtro({ setData, value, max, min, sal, step, orientation }: PropsFiltro) {
    const slider = orientation ?
        { height: 'calc(100% - 20px)', margin: '5px 0 10px 0' } :
        { width: 'calc(100% - 20px)', margin: '0 10px 0 10px' };
    const div = orientation ?
        { width: 32, height: 'calc(100% - 48px)' } :
        { height: 24, width: 'calc(100% - 64px)' };
    return <div style={{ margin: '0 0 0 0', padding: '0 0 0 0', width: 32 }}>
        <ButtonConfig icon='fas fa-search-plus' action={() => setData(value + sal)} />
        <div className='d-flex justify-content-center' style={div}>
            <Slider style={slider} {...{ value, max, min, orientation, step }}
                onChange={(_e: any, d: any) => { setData(d) }} />
        </div>
        <ButtonConfig icon='fas fa-search-minus' action={() => setData(value - sal)} />
    </div>
}
function Guardar({ guardar }: { guardar: () => void }) {
    const CN = css`
    color: #28a745;width: 65px;padding: .3rem .3rem .3rem .3rem;border-color: #28a745;
    &:hover{color: white;background-color: #28a745;}`;
    return <Button data-tip='GUARDAR' variant='default' onClick={guardar} className={CN} >
        <i className="fa fa-save fa-3x"></i>
    </Button>
}
function Close({ close }: { close: () => void }) {
    const CN = css`
    color: #dc3545;width: 65px;padding: .3rem .3rem .3rem .3rem;border-color: #dc3545;margin: 0 0 0 5px;
    &:hover{color: white;background-color: #dc3545;}`;
    return <Button data-tip='CANCELAR' variant='default' onClick={close} className={CN} >
        <i className="fas fa-window-close fa-3x"></i>
    </Button>
}
function Retaurar(p: { default: () => void }) {
    const CN = css`
    color: #ffc107;border-color: #ffc107;margin: 5px 0 0 0;
    &:hover{color: white;background-color: #ffc107;}`;
    return <Button data-tip='RESTAURAR VALORES' variant='default' block onClick={p.default} className={CN} >
        RESTAURAR
    </Button >
}
function Interfaz({ config, setConfig, crop, draw, trans }: Props) {
    const { edit } = config;
    switch (edit) {
        case 'crop': return <CropEdit setData={(rotate) => setConfig({ rotate })} value={config.rotate} onCrop={crop} />
        case 'color': return <ColorSeting setConfig={setConfig} config={config} draw={draw} />
        case 'filter': return <FilterEdit trans={trans} filters={config.trans} />
        default: return <></>
    }
}
export { Zoom };

function Data(p: any) {
    const CN = css`
    background-color: #fff; border-color: #17a2b8; padding: 0.1rem 0.1rem 0.1rem 0.1rem;
    color: #17a2b8; height: 30px; width: 65px; ${p.css}
    &:hover{ color: #17a2b8; border-color: #17a2b8; background-color: #fff; }`;
    return <Button variant='info' onClick={p.default} className={CN} >
        {p.data}
    </Button >
}