/* eslint-disable jsx-a11y/anchor-is-valid */
import { Cargando } from "@victorequena22/component-bootstrap";
import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { Col } from "react-bootstrap";

export default function Programa({ programa }: { programa: any }) {
    if (programa.id === 0) return <Cargando />
    return <>
        <Col md={12}>
            <h3 className='w-100 text-info'><b>Resumen</b></h3>
            {programa.resumen ? <TextToHTML text={programa.resumen} /> : <></>}
        </Col>
        <Col md={12}>
            <h3 className='w-100 text-info'><b>Contenidos</b></h3>
            {programa.contenido ? <TextToHTML text={programa.contenido} /> : <></>}
        </Col>
    </>
} 