import { Cargos } from '../SizePanel/Cargos';
import { Carrusel } from '../SizePanel/Carrusel';
import { Galeria } from '../SizePanel/Galeria';
import { Imagen } from '../SizePanel/Imagen';
import { Mapa } from '../SizePanel/Mapa';
import { Paralax1 } from '../SizePanel/Paralax1';
import { Paralax2 } from '../SizePanel/Paralax2';
import { Pestanas } from '../SizePanel/Pestanas';
import { Preguntas } from '../SizePanel/Preguntas';
import { Servicios } from '../SizePanel/Servicios';
import { Servicio2 } from '../SizePanel/Servicio2';
import { Suscribir } from '../SizePanel/Suscribir';
import { Title } from '../SizePanel/Title';
import { Video } from '../SizePanel/Video';
import { Cargando } from '@victorequena22/component-bootstrap';
import { Contact } from '../SizePanel/Contact';
import { Testimonio } from '../SizePanel/Testimonio';
import { ImagenSola } from '../SizePanel/ImagenSola';
import { BannerImagen } from '../SizePanel/BannerImagen';
import { CardText } from '../SizePanel/CardText';
import { CarruselCardText } from '../SizePanel/CarruselCardText';
import { CardCargos } from '../SizePanel/CardCargos';

export default function PreviewSize({ panel, size }: { panel: any, size: string }) {
    switch (panel.type) {
        case 'cargos': return <Cargos key={'panel' + panel.id} data={panel} size={size} />
        case 'CardText': return <CardText key={'panel' + panel.id} data={panel} size={size} />
        case 'cargosCT': return <CarruselCardText key={'panel' + panel.id} data={panel} size={size} />
        case 'carrusel': return <Carrusel key={'panel' + panel.id} data={panel} size={size} />
        case 'galeria': return <Galeria key={'panel' + panel.id} data={panel} size={size} />
        case 'imagen': return <Imagen key={'panel' + panel.id} data={panel} size={size} />
        case 'imagenSola': return <ImagenSola key={'panel' + panel.id} data={panel} size={size} />
        case 'mapa': return <Mapa key={'panel' + panel.id} data={panel} size={size} />
        case 'paralax1': return <Paralax1 key={'panel' + panel.id} data={panel} size={size} />
        case 'paralax2': return <Paralax2 key={'panel' + panel.id} data={panel} size={size} />
        case 'pestanas': return <Pestanas key={'panel' + panel.id} data={panel} size={size} />
        case 'preguntas': return <Preguntas key={'panel' + panel.id} data={panel} size={size} />
        case 'servivio1': return <Servicios key={'panel' + panel.id} data={panel} size={size} />
        case 'servivio2': return <Servicio2 key={'panel' + panel.id} data={panel} size={size} />
        case 'suscribir': return <Suscribir key={'panel' + panel.id} data={panel} size={size} />
        case 'titulo': return <Title key={'panel' + panel.id} data={panel} size={size} />
        case 'video': return <Video key={'panel' + panel.id} data={panel} size={size} />
        case 'contacto': return <Contact key={'panel' + panel.id} data={panel} size={size} />
        case 'testimonio': return <Testimonio key={'panel' + panel.id} data={panel} size={size} />
        case 'BannerIMG': return <BannerImagen key={'panel' + panel.id} data={panel} size={size} />
        case 'CardCargos': return <CardCargos key={'panel' + panel.id} data={panel} size={size} />
        default: return <Cargando />
    }
}