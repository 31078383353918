import { Table } from "react-bootstrap";
import Fila from "./Fila";
import { Interesado } from "../../Programas/Models/Models";
interface Props {
    i: Interesado[];
    url: string;
}
export default function Interesados({ i, url }: Props) {
    return <Table>
        <thead>
            <tr>
                <th>NOMBRE</th>
                <th>CORREO</th>
                <th>TELEFONO</th>
                <th>PAIS</th>
                <th>ESTADO</th>
                <th>CIUDAD</th>
                <th>ESTATUS</th>
                <th>ACCIONES</th>
            </tr>
        </thead>
        <tbody>
            {i.map(e => <Fila i={e} url={url}  />)}
        </tbody>
    </Table>
}