import { ParticipanteFila } from "@/App/Capacitacion/Participantes/Models";
import { useEvento } from "../../../index";
import Calificar, { useStatus } from "./Calificar";
import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
interface Props {
    data: ParticipanteFila
}
export default function Fila({ data }: Props) {
    const { nombre, correo, telefono, id } = data;
    const s = useStatus(id);
    return <tr className="info" >
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='300px'>{correo}</THFila>
        <THFila width='200px'>{telefono}</THFila>
        <THFila width='100px' align='center'><Status s={s} /></THFila>
        <Calificar2 data={data} />
    </tr>
}
function Calificar2({ data }: Props) {
    const { estatus } = useEvento();
    return estatus === 2 ? <THInput width='150px' align="right">
        <Calificar data={data} />
    </THInput> : <></>;
}
function Status({ s }: { s: number }) {
    const span = ['danger', 'info', 'primary', 'success']
    const text = ['REPROBADO', 'PREINSCRITO', 'INSCRITO', 'APROBADO']
    return <span className={`badge badge-${span[s]}`}>{text[s]}</span>;

}