// import { CSSProperties } from 'react';
import { Card, Col } from 'react-bootstrap';
// import { TextToHTML } from '@/App/Panel/Component/SlateCustom/Html';
import { State } from '../index';
import { getImagenURL } from '@victorequena22/conneccion';
import { FacilitadorFila } from '@/App/Capacitacion/Facilitadores/Models';
interface Props {
    iten: FacilitadorFila,
    config: State
}
export function Itens({ config: { gap, size, cards, text }, iten: { foto, nombre } }: Props) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    return <Col style={{ margin: '0 0 0 0', padding: '0 0 0 0' }} xs={s2.some(s => size === s) ? 3 : s1.some(s => size === s) ? 4 : 6}>
        <Card style={{ background: cards, margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(foto)} />
            <Card.Body style={{ color: text }}>
                <b>{nombre}</b>
            </Card.Body>
        </Card>
    </Col >
}
