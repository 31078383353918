import { Link } from 'react-router-dom';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { FacilitadorFila } from './Models';
import { Fila } from './Fila';
import { useContext } from 'react';
import { FacilitadorContext } from '../Context/Facilitadores';
import { IE, IEL } from '../../../Config/Updater';
export default function ListaFacilitadores() {
    const { Facilitadores } = useContext(FacilitadorContext);
    return <Tabla key={IEL(Facilitadores) + 'Facilitadores'} itens={Facilitadores} />
}
class Tabla extends CardBuscador<{ itens: FacilitadorFila[] }> {
    buscarEn = ['nombre', 'descripcion'];
    Title = () => <>
        <h3>BUSCAR FACILITADOR</h3>
        <Link to="/facilitador/nueva" className="btn btn-primary ml-auto">
            <span className="fa fa-plus"></span> NUEVO FACILITADOR
        </Link>
    </>
    Row = (d: FacilitadorFila) => <Fila key={'Facilitador' + IE(d)} data={d} update={this.set} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='100px' label='horas' aling='right'>EXP</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}
