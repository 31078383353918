import { findParticipanteFila } from "../../../../Context/Participante";
import { Encuesta } from "../../../Models/Model";

export default function Fila({ encuesta: { participante: p, respuesta } }: { encuesta: Encuesta }) {
    const { nombre, correo, telefono } = findParticipanteFila(p);
    return <tr>
        <th>{nombre}</th>
        <th>{correo}</th>
        <th>{telefono}</th>
        <th>{respuesta}</th>
    </tr>
}