import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useEvento } from "../index"
import { ParticipanteFila } from "@/App/Capacitacion/Participantes/Models";
import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import Tabla2 from "./Tabla";
import { GrupoPago } from "@/App/Capacitacion/Pagos/Models/Model";
import ReactTooltip from "react-tooltip";
import { conGrupoPago } from "@/App/Capacitacion/Pagos/Models";
interface Props {
    participante: ParticipanteFila
}
export default function AddGrupoPago(p: Props) {
    const { gruposPago, estatus } = useEvento();
    return (gruposPago.length && estatus === 1) ? <Asignar {...p} /> : <></>
}
function Asignar({ participante: { id, nombre } }: Props) {
    const [open, setOpen] = useState(false)
    const { gruposPago } = useEvento();
    return <>
        <Button onClick={() => setOpen(true)} variant="success">
            <i className="fa fa-user-group" />
        </Button>
        <Modal show={open} size='xl' centered onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b> {nombre} AL UN GRUPO DE PAGOS</b></span>
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Tabla itens={gruposPago} part={id} />
            </Modal.Body>
        </Modal>
    </>
}

class Tabla extends Tabla2<{ part: number }> {
    Row = (g: GrupoPago) => <Fila data={g} part={this.props.part} />
}
interface PFila {
    data: GrupoPago
    part: number
}
function Fila({ data: { id, nombre, cupos, participantes, precio, pagos }, part }: PFila) {
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila align="right" width='100px'>{cupos}</THFila>
        <THFila align="right" width='100px'>{participantes.length}</THFila>
        <THFila align="right" width='100px'>{precio}</THFila>
        <THFila align="right" width='150px'>{cupos * precio}</THFila>
        <THFila align="right" width='100px'>{pagos.length}</THFila>
        <THInput width='100px'>
            <ReactTooltip />
            {participantes.some(p => p === part) ?
                <Button data-tip='SACARLO' variant="danger" size="sm"
                    onClick={() => conGrupoPago().removeParticipante(id, part)}>
                    <i className="fa fa-trash" />
                </Button> : <Button data-tip='ASIGNAR' variant="success" size="sm"
                    onClick={() => conGrupoPago().addParticipante(id, part)}>
                    <i className="fa fa-check" />
                </Button>}
        </THInput>
    </tr>
}


