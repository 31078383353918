import { Cargando } from "@victorequena22/component-bootstrap";
import { useCallback, useEffect, useState } from "react";
import {  Row } from "react-bootstrap";
import { Media } from "../../../../Media/Models";
import { Boton } from "./Boton";
import { conRecursos } from "../../Models";
import { Nuevo } from "./Nuevo";
import { getPrograma } from "../index";
import Template from "../Template";
export default function Recursos() {
    const { id } = getPrograma();
    const [c, set] = useState<Media[] | undefined>(undefined);
    const update = useCallback(() => { conRecursos().withProgram(id, set, () => { }); }, [id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(update, [])
    if (c) return <Data data={c} id={id} update={update} />
    return <Cargando />
}
interface props {
    data: Media[]
    id: any
    update: () => void
}
function Data({ data, id, update }: props) {
    return <Template icon="fa fa-file" title="RECURSOS DEL PROGRAMA">
        <Row>
            <h4 className="mr-auto"> </h4> <Nuevo update={update} id={id} />
        </Row>
        {data.map(d => <Boton update={update} recurso={d} />)}
    </Template >
}