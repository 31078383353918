/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext } from "react";
import { Ruta } from "../../../../Template/Ruta";
import { Capacitacion, CapacitacionNull, conCapacitacion } from "../../Programas/Models";
import { Cargando } from "@victorequena22/component-bootstrap";
import Editar from "./From/Edit";
import Recursos from "./Recursos";
import VerPrograma from "./Ver";
import ProgramasParticipantes from "./Participante";
import Estadisticas from "./Estadisticas";
import LandingProgama from "./Landing";
import EventoProgama from "./Evento";
import { getUpdater } from "../../../../Config/Utiles";
import ProgramasEventos from "./Eventos";
export interface ProgramaType {
    programa: Capacitacion
    update: () => void
}
export var update = () => { };
export const ProgramaContext = createContext<ProgramaType>({ programa: CapacitacionNull, update: () => { } });
export default function GestionPrograma() {
    const [programa, u] = getUpdater<Capacitacion>(conCapacitacion(), CapacitacionNull);
    update = u;
    return programa.id ? <ProgramaContext.Provider value={{ programa, update }}>
        <Ruta permiso="PROGRAMA" path="/programa/participantes/:id" component={ProgramasParticipantes} />
        <Ruta permiso="PROGRAMA" path="/programa/estadisticas/:id" component={Estadisticas} />
        <Ruta permiso="PROGRAMA" path="/programa/landing/:id" component={LandingProgama} />
        <Ruta permiso="EVENTO" path="/programa/evento/:id" component={EventoProgama} />
        <Ruta permiso="PRO_EDITAR" path="/programa/Recursos/:id" component={Recursos} />
        <Ruta permiso="PROGRAMA" path="/programa/ver/:id" component={VerPrograma} />
        <Ruta permiso="PRO_EDITAR" path="/programa/edit/:id" component={Editar} />
        <Ruta permiso="EVENTO" path="/programa/eventos/:id" component={ProgramasEventos} />
    </ProgramaContext.Provider> : <Cargando />
}
export const SeccionConsumer = ProgramaContext.Consumer;
export function getPrograma() {
    return useContext(ProgramaContext).programa;
}