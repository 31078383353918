import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { useContext } from "react";
import { Itens } from "./Itens";
import { ProgramaContext } from "../../../Capacitacion/Context/Programas";
import { EditArea } from "../../../Panel/Component/SlateCustom";
import { Programas } from "../../SizePanel/Programas";
export function Panel({ config }: { config: State }) {
    const { color, filtroType, filtro, size } = config;
    const { programas } = useContext(ProgramaContext);
    return <>
        <Row style={{ background: color, margin: '0 0 0 0', padding: '0 0 2rem 0', borderBottom: '3px black solid' }}>
            <div style={{ display: 'none' }}><EditArea /></div>
            <Container><Row>
                {programas.filter(e => e.estatus === 2)
                    .filter(e => {
                        if (filtroType !== 'none') {
                            if (filtroType === 'categoria' && filtro !== 0)
                                return e.categoria === filtro;
                        }
                        return true;
                    }).map(e => <Itens iten={e} config={config} />)}
            </Row></Container>
        </Row>
        <Programas data={{ props: config }} size={size} />
    </>
}