import { useContext } from "react";
import { Button } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";
import { Link } from "react-router-dom";
import { SeccionContext, SeccionType } from "../../Template/Seccion";
interface Props {
    permiso: string;
    tip?: string;
    variant: ButtonVariant;
    children: any,
    className?: string;
}
export function ListButonLink({ tip, variant, children, to, permiso, className }: Props & { to: string }) {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => p === permiso))
        return <Button as={Link} className={className} data-tip={tip} variant={variant} to={to}>
            {children}
        </Button>
    return <></>
}
export function ListButonClick({ tip, variant, children, click, permiso, className }: Props & { click: () => void }) {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => p === permiso))
        return <Button className={className} data-tip={tip} variant={variant} onClick={click}>
            {children}
        </Button>
    return <></>
}