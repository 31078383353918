import { Component, createContext, useContext, useMemo } from 'react';
import { withReact, Slate, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
interface Props {
    value: any;
    setValue: (d: any) => void;
    children: any;
}
interface StaleType {
    resaltar: boolean
}
export const StaleContext = createContext<StaleType & { setStale: (s: Partial<StaleType>) => void }>({ resaltar: false, setStale: _s => { } });
export const StaleConsumer = StaleContext.Consumer;
class SlateProvider2 extends Component<{ children: any }, StaleType> {
    state = { resaltar: false }
    render = () => <StaleContext.Provider value={{ ...this.state, setStale: s => this.setState(s as any) }}>
        {this.props.children}
    </StaleContext.Provider>
}
export const useStaleContext = () => useContext(StaleContext);
export const SlateProvider = ({ value, setValue, children }: Props) => {
    const editor = useMemo(() => withHistory(withReact(createEditor() as ReactEditor)), []);
    return <Slate editor={editor} value={value} onChange={setValue}>
        <SlateProvider2>{children}</SlateProvider2>
    </Slate>
}
