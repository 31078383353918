import { Button, Modal } from "react-bootstrap";
import { Content } from "./Content";
import { useState } from "react";
export function Chat({ url, id }: { url: string, id: any }) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="ABRIR CHAT" variant="primary" size="sm" onClick={() => setOpen(true)}>
            <i className="fas fa-comment" />
        </Button>
        <Modal show={open} size='xl' centered onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>Chat </b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                {open ? <Content url={url} id={id} /> : <></>}
            </Modal.Body>
        </Modal>
    </>
}