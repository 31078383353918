/* eslint-disable jsx-a11y/anchor-is-valid */
import { MobileUserAgent } from '@victorequena22/utiles'
import MenuIten from './MenuIten'
import { Link } from "react-router-dom"
import { useContext } from 'react';
import { MenuContext } from './MenuContext';
import { don, getImagenURL } from '@victorequena22/conneccion';
import getLanguage from '@/languages/export';
import Footer from './Footer';

export default function Menu() {
    const { open } = useContext(MenuContext);
    const { template: { menu } } = getLanguage(); 
    return <aside className="main-sidebar sidebar-dark-primary" style={{ backgroundColor: '#2196f3', maxHeight: 'calc(100vh - 57px)', zIndex: 2 }}>
        <Link to="/" className="brand-link" style={{ backgroundColor: '#363738', width: '100%', height: '57px' }}>
            {(ifDon()) ? <>
                <img width={33} height={33} src={getImagenURL("/logos/logo.png")} alt="logo" className="brand-image" />
                <img src={getImagenURL("/logos/texto.png")} alt="letras" className="brand-image brand-text" style={{ transitionDelay: '.2s' }} />
            </> : <>
                <img width={33} height={33} src={getImagenURL("/static/media/logo.png")} alt="logo" className="brand-image" />
                <img style={{ marginBlock: 'auto', transitionDelay: '.2s' }} src={getImagenURL("/static/media/texto.png")} alt="letras" className="brand-image brand-text" />
            </>}
        </Link>
        <div className="sidebar">
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">
                            <i className="nav-icon fa fa-home"></i> <p>{menu.init}</p>
                        </Link>
                    </li>
                    <MenuIten to="/programa" permiso="PROGRAMA">
                        <i className="nav-icon fas fa-books"></i> <p> {menu.schedule}</p>
                    </MenuIten>
                    <MenuIten to="/facilitador" permiso="FACILITADOR">
                        <i className="nav-icon fas fa-chalkboard-teacher"></i> <p> {menu.facilitators}</p>
                    </MenuIten>
                    <MenuIten to="/aliado" permiso="ALIADO">
                        <i className="nav-icon fas fa-user"></i> <p> {menu.allied}</p>
                    </MenuIten>
                    <MenuIten to="/media" permiso="MEDIA">
                        <i className="nav-icon fas fa-image"></i> <p>{menu.media}</p>
                    </MenuIten>
                    <MenuIten to="/evento" permiso="EVENTO">
                        <i className="nav-icon fas fa-file-certificate"></i> <p> {menu.event}</p>
                    </MenuIten>
                    <MenuIten to="/pago" permiso="PAGOS">
                        <i className="nav-icon fas fa-money-bill-wave"></i> <p>{menu.paid}</p>
                    </MenuIten>
                    <MenuIten to="/participante" permiso="PARTICIPANTE">
                        <i className="nav-icon fas fa-users-class"></i> <p> {menu.clients}</p>
                    </MenuIten>
                    <MenuIten to="/panel" permiso="PANEL">
                        <i className="nav-icon fas fa-browser"></i> <p>{menu.main}</p>
                    </MenuIten>
                    <MenuIten to="/Contacto/subs" permiso="PANEL">
                        <i className="nav-icon fas fa-comment-alt-smile"></i> <p>{menu.contact}</p>
                    </MenuIten>
                    <MenuIten to="/usuarios" permiso="USUARIO">
                        <i className="nav-icon fa fa-users"></i><p> {menu.users}</p>
                    </MenuIten>
                    <MenuIten to="/reporte" permiso="REPORTE">
                        <i className="nav-icon fa fa-chart-line"></i><p> {menu.reports}</p>
                    </MenuIten>
                    <MenuIten to="/config" permiso="CONFIG">
                        <i className="nav-icon fa fa-wrench"></i><p> {menu.config}</p>
                    </MenuIten>
                    {MobileUserAgent() ? <li className="nav-item mt-2" onClick={open}>
                        <a className="nav-link fa-2x" >
                            <i className="fas fa-caret-down fa-rotate-90" /> <p>{menu.close}</p>
                        </a>
                    </li> : <></>}
                </ul>
                <Footer />
            </nav>
        </div>
    </aside>
}
function ifDon() {
    return "https://admin.talentoeinnovacion.net" === don;
}