import { Row } from "react-bootstrap";
import { getFacilitador } from "../index";
import Template from "../Template";
import { MenuCreacion2 } from "@/App/Panel/Utiles/MenuCreacion";



export default function MenuCreacion() {
    const { id } = getFacilitador();
    return <Template icon="fas fa-browser" title="CREAR PANEL PARA EL FACILITADOR">
        <Row><h3 className='ml-2 text-info'>Paneles por defecto</h3></Row>
        <MenuCreacion2 url={`/facilitador/landing/${id}/crear`} />
    </Template>
}