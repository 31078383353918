import { Paginador } from "@victorequena22/tabla-bootstrap";
import { useEffect, useState } from "react";
import { Row, Col, Image, Modal } from "react-bootstrap";
import { don, getImagenURL } from "@victorequena22/conneccion";
import { CertificadoFila } from "@/App/Capacitacion/Evento/Models/Model";
import { conCC } from "@/App/Capacitacion/Evento/Models/Certificados";

export default function Certificados() {
    const [cert, setCert] = useState<CertificadoFila[]>([])
    useEffect(() => { conCC().lista(p => setCert(p)) }, []);
    return <Row>
        <Col md={12}>
            <h1 className='w-100 text-primary'>Certificados de @NombreUsuario</h1>
        </Col>
        <Col md={12} className='mt-4'>
            <Paginador itens={cert} mostrar={20} row={d => <Fila certificado={d} />} bodyStyle={{
                display: 'grid', gap: 5, gridAutoFlow: 'dense',
                gridAutoRows: `repeat(auto-fill,minmax(15rem, 1fr))`,
                gridTemplateColumns: `repeat(auto-fill,minmax(15rem, 1fr))`
            }} />
        </Col>
    </Row>
}

function Fila({ certificado: { codigo } }: { certificado: CertificadoFila }) {
    const [open, setOpen] = useState(false);
    return <div>
        <ModalPrint url={`/api/Evento/Certificados/pdf/certificado/${codigo}`}
            open={open} close={() => setOpen(false)} />
        <div onClick={() => setOpen(true)} className="gal-iten">
            <Image fluid className='img img-fluid' alt="Bricks" src={getImagenURL(`/api/Evento/Certificados/Certificado/ver/${codigo}`)} />
            <div style={{ background: '#000C', color: '#FFF', margin: `27% 27% 27% 27%` }} className="caption">
                <i className="fas fa-print fa-3x m-auto" />
                <b>Ver Certificado</b>
            </div>
        </div>
    </div>
}

interface Props {
    open: boolean,
    close: () => void,
    url: string,
}
function ModalPrint({ close, url, open }: Props) {
    return <Modal show={open} size='xl' centered onHide={close}>
        <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>NOMBRE DEL USUARIO</b></span>
            </h6>
        </Modal.Header>
        <Modal.Body style={{ height: '90vh' }}>
            <iframe src={`${don}/${url}`} title="print" width='100%' height='100%' />
        </Modal.Body>
    </Modal>
}

