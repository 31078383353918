/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SelectorMultiple } from '../../../Components/Selectors/Multiple';
import { EventoLista } from './Models';
import { useEventosActivos } from '../Context/Eventos';
interface Props {
    eventos: number[];
    set: (tags: number[]) => void;
}
export function EventoSelectorMultiple({ eventos, set }: Props) {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(eventos);
    const activos = useEventosActivos()
    const filter = useCallback((buscar: string, i: EventoLista) => {
        if (values.some(a => a === i.id)) return false;
        const en = buscar.split(" ");
        const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
        return en.length === con.length;
    }, [values]);
    useEffect(() => setValues(eventos), [eventos, open])
    return <>
        <SelectorMultiple open={open} close={() => setOpen(false)}
            titleModal='SELECIONAR EVENTOS' selectTitle='EVENTOS SELECCIONADOS'
            lista={activos.filter(c => c.estatus > 0)} values={values} buscar={filter} setValues={setValues}
            actualizar={() => { }} save={() => set(values)} label='id'
            row={(data: any) => data.nombre} />
        <div className="w-100 mt-1">
            <Row as={Col}>
                <label className='m-0'>EVENTOS</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            {useMemo(() => <Col className="w-100 h-90 mt-1"
                style={{ minHeight: '50px', maxHeight: '100px', borderTop: 'solid 1px #ced4da', overflowY: 'auto' }}>
                {activos.filter((i) => eventos.some(a => i.id === a)).map((p) => <li>{p.nombre}</li>)}
            </Col>, [eventos, activos])}
        </div>
    </>
}