import { ChangeEvent } from "react";
import { FormControl } from "react-bootstrap";


export function Select({ value, setData, children }: { children: any; value: string, setData: (s: string) => void }) {
    const setType = (e: ChangeEvent<HTMLSelectElement>) => setData(e.currentTarget.value)
    return <FormControl {...propsSelect} as='select' onChange={setType} value={value} >
        {children}
    </FormControl>
}

export const propsSelect = { style: { height: 24, fontSize: 10, padding: '0 0 0 0' }, className: 'input-group-sm' }
