import { semaforo } from '@victorequena22/utiles';
import { FacilitadorFila } from './Model';
import { ConexionCompleta, setCambio } from '@victorequena22/conneccion';
class facilitadorConecction extends ConexionCompleta {
    clase = 'Facilitador';
    publicar = async (id: number, setData: (d: any) => void, setError?: (d: any) => void) => {
        try {
            setData(await this.getProxi(`/publicar/${id}`));
        } catch (error) {
            if (setError) setError(error)
        }
    }
    especialidades = async (set: (a: any) => void) => {
        set(await this.getProxi(`/especialidad/lista`));
    }
    nuevoEspecialidad = (nombre: string, set: (a: any) => void) => {
        this.postProxi('/especialidad/nuevo', { nombre }, set, () => { });
    }
}
export const facilitadorUpdate = (c: facilitadorConecction, u: FacilitadorFila[], setData: (c: FacilitadorFila[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Facilitador')) c.cambios(data => {
        if (data.length) setData(setCambio(data, u));
        s.next();
    }, () => s.next());
}
export function conFacilitador() {
    return new facilitadorConecction();
}
class PublicacionConecction extends ConexionCompleta {
    clase = 'Facilitador/Publicacion';
}
export function conPublicacion() {
    return new PublicacionConecction();
}