import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import CopiarEvento from "./From/Copiar";
import EditarEvento from "./From/Edit";
import GestionPagos from "./GestionPagos";
import PagosEvento from "./InformacionPagos";
import EventoParticipantes from "./Participantes";
import VerEvento from "./Ver";
import { Evento, eventoNull } from "../Models";
import { Ruta } from "../../../../Template/Ruta";
import Estadisticas from "./Estadisticas";
import { Capacitacion, CapacitacionNull } from "../../Programas/Models";
import { Cargando } from "@victorequena22/component-bootstrap";
import InscribirParticipante from "./Inscribir";
import { findProgramUpdate } from "../../Context/Programas";
import GestionGrupoPago from "./GruposPago";
import Certicados from "./Certificados";
import { useFindEventoUpdate } from "../../Context/Eventos";
export interface EventoType {
    evento: Evento
    programa: Capacitacion
}
export const EventoContext = createContext<EventoType>({ evento: eventoNull, programa: CapacitacionNull });
export default function GestionEvento() {
    let { opcion } = useParams<any>();
    if (opcion === 'config' || opcion === 'certificado') return <></>
    return <GEvento />
}

function GEvento() {
    let { id } = useParams<any>();
    const evento = useFindEventoUpdate(id);
    const programa = findProgramUpdate(evento.programa)
    return evento.id ? <EventoContext.Provider value={{ evento, programa }}>
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/gestionPagos/:id" component={GestionPagos} />
        <Ruta permiso="EVE_PRECIO" path="/evento/pagos/:id" component={PagosEvento} />
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/inscribir/:id" component={InscribirParticipante} />
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/participantes/:id" component={EventoParticipantes} />
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/grupoPago/:id" component={GestionGrupoPago} />
        <Ruta permiso="EVE_CREAR" path="/evento/copiar/:id" component={CopiarEvento} />
        <Ruta permiso="EVE_EDITAR" path="/evento/edit/:id" component={EditarEvento} />
        <Ruta permiso="EVENTO" path="/evento/ver/:id" component={VerEvento} />
        <Ruta permiso="EVENTO" path="/evento/estadisticas/:id" component={Estadisticas} />
        <Ruta permiso="EVENTO" path="/evento/certificados/:id" component={Certicados} />
    </EventoContext.Provider> : <Cargando />
}
export const SeccionConsumer = EventoContext.Consumer;
export function useEvento() {
    return useContext(EventoContext).evento;
}
export function usePrograma() {
    return useContext(EventoContext).programa;
}