import { Component } from "react";
import { Button, Col, Card, InputGroup } from 'react-bootstrap';
import { InputText } from "@victorequena22/component-bootstrap";
import { conConfig } from "@victorequena22/conneccion";
import { toast } from "react-toastify";

interface State {
    whatsapp: [string, string, string]
}
export default class Contacto extends Component<any, State> {
    con = conConfig('contacto');
    constructor(p: any) {
        super(p);
        this.con = conConfig('contacto');
        this.state = { whatsapp: ['', '', ''] };
    }
    render() {
        let { whatsapp } = this.state as State;
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE CONTACTO </h4>
            </Card.Header>
            <Card.Body>
                <Col md={6}>
                    <SetNumber numero={whatsapp} setNumero={whatsapp => setState({ whatsapp })} />
                </Col>
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar = () => {
        this.con.push(this.state, s => {
            this.setState(s)
            toast.success('CONFIGURACION GUARDADA');
        });
    }
}
export function SetNumber({ setNumero, numero: [n1, n2, n3] }: { numero: [string, string, string], setNumero: (n: [string, string, string]) => void }) {
    return <InputGroup>
        <InputGroup.Prepend>
            <InputGroup.Text>NUMERO DE WHATSAPP</InputGroup.Text>
        </InputGroup.Prepend>
        <InputText style={{ maxWidth: '55px' }} mask="999" maxLength={3} value={n1} setData={n1 => setNumero([n1, n2, n3])} />
        <InputText style={{ maxWidth: '60px' }} mask="9999" maxLength={4} value={n2} setData={n2 => setNumero([n1, n2, n3])} />
        <InputText mask="999-9999999" maxLength={10} value={n3} setData={n3 => setNumero([n1, n2, n3])} />
    </InputGroup>
}