import { useCallback, useState } from "react";
import { ModalReport } from "../../../../../Components/Modals";
import { Button, Col } from "react-bootstrap";
import { useEvento } from "..";
import { getParticipantes } from "../../../Context/Participante";
import ParticipantesExcel from "../../../Participantes/Excel";
import { portapapeles } from "@victorequena22/utiles";
import { toast } from "react-toastify";
import { useEmpresa } from "@/Template/Empresa";
import { don } from "@victorequena22/conneccion";

export function Asistencia({ size }: { size?: "sm" | "lg", }) {
    const { id } = useEvento();
    const [open, setOpen] = useState(false);
    return <>
        <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`${don}/api/Participante/PDF/ListaEvento/${id}`} />
        <Button size={size} variant="info" onClick={() => setOpen(true)}><i className='fas fa-users-class'></i> VER ASISTENCIA</Button>
    </>
}
export function Aprobados() {
    const { id } = useEvento();
    const [open, setOpen] = useState(false);
    return <>
        <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`${don}/api/Participante/PDF/Aprobados/${id}`} />
        <Button className="ml-1 mr-1 p-2" variant="success" onClick={() => setOpen(true)}><i className='fas fa-file-certificate'></i> VER APROBADOS</Button>
    </>
}
export function Excel() {
    const { participantes: p } = useEvento();
    const l = getParticipantes();
    return <ParticipantesExcel className='p-2' Participantes={l.filter((i) => p.some(e => e.id === i.id))} />
}
export function Copi() {
    const { web } = useEmpresa();
    const { id } = useEvento();
    const link = `https://${web.toLowerCase()}/evento/Inscribir/${id}`;
    const copi = useCallback(() => {
        portapapeles(link)
        toast.success('LINK COPIADO!');
    }, [link])
    return <Col>
        <a href={link} className="p-2" target="_blank" rel="noreferrer"><b>LINK DE INSCRIPCIÓN:</b> {link}</a>
        <Button className="m-1 p-1 pr-2 pl-2" variant='primary' data-tip='copiar al portapapeles' onClick={copi}>
            <i className="fas fa-copy"></i>
        </Button>
    </Col>
}
export function LinkEvento() {
    const { web } = useEmpresa();
    const { codigo } = useEvento();
    const link = `https://${web.toLowerCase()}/evento/${codigo}`;
    const copi = useCallback(() => {
        portapapeles(link)
        toast.success('LINK COPIADO!');
    }, [link])
    return <Col>
        <a href={link} className="p-2" target="_blank" rel="noreferrer"><b>LINK DEL EVENTO:</b> {link}</a>
        <Button className="m-1 p-1 pr-2 pl-2" variant='primary' data-tip='copiar al portapapeles' onClick={copi}>
            <i className="fas fa-copy"></i>
        </Button>
    </Col>
}