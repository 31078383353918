import { Row, Col } from 'react-bootstrap';
import { State } from '../index'
import { BlockElement } from '@victorequena22/slate-bootstrap';
import { EditArea } from '@/App/Panel/Component/SlateCustom';
import { Html } from '@/App/Panel/Component/Commons';
import { getImagenURL } from '@victorequena22/conneccion';
import Formulario  from './Formulario';
import { cols } from './props';
export function Panel({ config, setConfig }: { config: State; setConfig: (d: Partial<State>) => void }) {
    const { backgroundColor, img, html, hideText, cardTitle, select, size } = config;
    const IsHTML = ({ html, select: s }: { html: BlockElement[], select: number }) => {
        return select === s ? <Row className='m-0 p-0 w-100' style={{ border: 'red solid 1px' }}>
            <EditArea />
        </Row> : <Row className='m-0 p-0  w-100' onClick={() => setConfig({ select: s })}>
            <Html html={html} />
        </Row>
    }
    const { i, l, r, t, h } = cols(size, !!img);
    return <div style={{ padding: '60px 0 0 0', background: backgroundColor }}>
        <div className="container">
            <Row>
                <Col md={r}><Row className='d-flex flex-row-reverse text-center'>
                    <Col md={t}>
                        <Row className='m-2 p-2'><IsHTML html={html} select={1} /></Row>
                        {h ? <Row className='m-2 p-2'><IsHTML html={hideText} select={2} /></Row> : <></>}
                    </Col>
                    {img ? i ? <Col md={i}><img loading="lazy" key={img} src={getImagenURL(img)} width='100%' alt='panel a' /></Col> : <></> : <></>}
                </Row></Col>
                <Col md={l} style={{ padding: '30px 0' }}>
                    <Formulario config={config}>
                        <IsHTML html={cardTitle} select={3} />
                    </Formulario>
                </Col>
            </Row>
        </div>
    </div>
}
