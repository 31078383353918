import { Panel } from './Panel';
import { Config } from './Config';
import { StateS } from '@/App/Panel/ProtoPanes/Single';
import { Tempate } from '@/App/Panel/ProtoPanes/Tempate';
export interface S {
    background: string;
    btn: string
    select: string
}
export type State = StateS & S;
export class Participantes extends Tempate<S> {
    titulo = 'EDITAR SECCION DE MIS DATOS';
    data = 'mis-datos';
    randon = Math.random();
    constructor(p: any) {
        super(p);
        this.init({ select: 'datos', background: '#f0f0f0', btn: '#000000' } as State);
    }
    Config = () => <Config save={this.guardar} setConfig={this.setData} config={this.state} />
    Panel = () => <Panel config={this.state} setData={this.setData} />
}