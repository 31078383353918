import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { Button } from "react-bootstrap";
import { Pago } from "../Models";

interface Props{
    pago: Pago
    select: (d: number) => void
}
export default function Fila({ select, pago: { id, referencia, banco, monto, fecha, estatus, nota } }: Props) {
    const badge = ['danger', 'warning', 'success'];
    const estados = ['Rechazado', 'Pendiente', 'Aprobado'];
    return <tr>
        <td>{formatoFecha(fecha)}</td>
        <td>{banco}</td>
        <td>{referencia}</td>
        <td>{numberFormat(monto)}</td>
        <td><span className={`badge badge-${badge[estatus]}`}>{estados[estatus]}</span></td>
        <td>{nota}</td>
        <td><Button onClick={() => select(id)}>VER MAS</Button></td>
    </tr>
}