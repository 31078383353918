import Template from '../Template';
import { PreviewSize } from '@/App/PanelProxi/Ver/PreviewSize';
import { getPrograma } from '../index';
import { VerLanding } from '@/App/PanelProxi/SizeLanding/Ver';
export function Ver() {
    const { id } = getPrograma();
    const url = `/programa/evento/${id}`;
    return <Template icon="fas fa-browser" title="VER LANDING DEL EVENTO">
        <VerLanding url={url} Preview={PreviewSize} />
    </Template>
}