import { useEffect, useState } from "react";
import Chart from "react-google-charts"
import { don } from "@victorequena22/conneccion";
import { Cargando } from "@victorequena22/component-bootstrap";
import { getSeccionContext } from "@/Template/Seccion";
import { getSizeTorta } from "./GetSize";
import { TortaNull } from "./Nulos";
interface Props {
    url: string
    labels: string[]
}
export default function Torta(p: Props) {
    const { data: { cargando } } = getSeccionContext();
    if (cargando) return <></>;
    return <Carga {...p} />
}
function Carga({ labels, url }: Props) {
    const [cargando, setCargando] = useState(true);
    const [data, setData] = useState<any[]>([]);
    useEffect(() => { pull(url, data => { setData(data); setCargando(false); }) }, [url])
    if (cargando) return <Cargando />;
    if (!data.length) return <TortaNull />;
    const [width, height] = getSizeTorta();
    return <Chart width={width} height={height} chartType="PieChart"
        loader={<Cargando />}
        data={[labels, ...data]} options={{ title: '' }}
        rootProps={{ 'data-testid': '3' }}
    />
}
const pull = async (url: string, setData: (s: any[]) => void) => {
    try {
        let token = localStorage.getItem('Authorization');
        let response = await fetch(don + url, { headers: { 'Authorization': 'Bearer ' + token } });
        setData(await response.json());
    } catch (error) {
        console.log(error);
        setData([]);
    }
}