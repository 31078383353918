import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import { ParticipanteFila } from "../../../Participantes/Models";
import { Fila } from "./Fila";
import { IE } from "../../../../../Config/Updater";

export default class Tabla extends CardBuscador<{ itens: ParticipanteFila[] }> {
    buscarEn = ['nombre', 'correo', 'telefono'];
    render() {
        let { Body, Buscador, } = this;
        return <><Buscador /><Body /></>
    }
    Row = (d: ParticipanteFila) => <Fila key={IE(d) + 'participante'} data={d} update={this.set} />
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='300px' label='correo'>CORREO</Th>
            <Th width='200px' label='telefono'>TELEFONO</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}