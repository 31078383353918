import { EditArea } from '../../Component/SlateCustom'
import { Navbar, Nav, Container, Dropdown, NavItem, Image, FormGroup, ButtonGroup, Button } from 'react-bootstrap';
import { getCSS, getCSS2 } from './props';
import { State } from './index';
import { useEmpresa } from '@/Template/Empresa';
import { getImagenURL } from '@victorequena22/conneccion';
import InputFild from '@/Components/Material/InputFild';
import "./menu.css";
export function Panel({ config: { height, orientacion, backgroundColor, color, backgroundColorHover, colorHover, img } }: { config: State, setData: (s: Partial<State>) => void }) {

    const { web } = useEmpresa();
    const link = `https://${web.toLowerCase()}/`;
    return <Navbar expand="lg" className={getCSS(backgroundColor, color, backgroundColorHover, colorHover)}>
        <Container>
            <div className='d-flex navbar-brand'>
                <img height={height + 'px'} className="img-responsive" src={img} alt="Jango" />
                <div>
                    <EditArea />
                </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`m${orientacion ? 'l' : 'r'}-auto`}>
                    <a href={link} className='dropdown-item' rel="noreferrer" target="_blank">
                        <b>Inicio</b>
                    </a>
                    <a href={link + 'programas'} rel="noreferrer" className='dropdown-item' target="_blank">
                        <b>Programas</b>
                    </a>
                    <a href={link + 'eventos'} rel="noreferrer" className='dropdown-item' target="_blank">
                        <b>Eventos</b>
                    </a>
                    <Dropdown as={NavItem} className='ml-1'>
                        <Dropdown.Toggle variant="outline-dark" >
                            <Image roundedCircle height={27} src={getImagenURL("/logos/user.jpg")} className="elevation-2" alt="Logo2" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`p-3 menu-login ${getCSS2(backgroundColor, color)}`}
                            style={{ transform: 'translateX(calc(70px - 100%))' }}>
                            <li className="user-header pb-3 text-center">
                                <Image roundedCircle height={200} src={getImagenURL("/logos/user.jpg")} className="elevation-2" alt="Logo" />
                            </li>
                            <FormGroup as='li'>
                                <InputFild maxLength={200} setData={_s => { }} label="Usuario o correo" value='' />
                            </FormGroup>
                            <FormGroup as='li'>
                                <InputFild type="password" maxLength={200} setData={_s => { }} label="Contraseña" value='' />
                            </FormGroup>
                            <li className="user-footer">
                                <ButtonGroup vertical className="w-100">
                                    <ButtonGroup className="w-100">
                                        <Button variant="outline-primary" className="btn-flat w-50">Registrarse</Button>
                                        <Button variant="outline-success" className="btn-flat  w-50">Login</Button>
                                    </ButtonGroup>
                                    <Button block variant="outline-info" className="btn-flat">Recuperar clave</Button>
                                </ButtonGroup>
                            </li>
                        </Dropdown.Menu>
                    </Dropdown >
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}


