import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { InputText } from "@victorequena22/component-bootstrap";
import { conConfig } from "@victorequena22/conneccion";
interface Props {
    tip?: string;
    variant?: string;
    click?: (i: number) => void;
    p: any;
}
const BTN = ({ variant, click, p, tip }: Props) => <Button data-tip={tip}
    style={{ borderRadius: '.25rem', height: 40 }} className="ml-1 mb-1"
    onClick={() => click ? click(p.id) : {}} variant={`outline-${variant}`}>{p.nombre}</Button>
export const SelecMe = (p: Props) => <BTN {...p} variant="default" tip="AGREGAR ETIQUETA" />;
export const Herencia = (p: Props) => <BTN {...p} variant="warning" tip="ETIQUETA HEREDADA" />;
export const Selected = (p: Props) => <BTN {...p} variant="success" tip="ETIQUETA SELECCIONADA" />;
export const setData = (setData: (d: any) => void) => {
    conConfig('').get('/api/Commons/tags', setData);
}
export const nuevoTag = (nombre: string, setData: () => void) => {
    conConfig('').post('/api/Commons/newTag', { nombre }, setData);
}
export const NuevoTag = ({ actualizar }: { actualizar: () => void }) => {
    const [nuevo, setNuevo] = useState('');
    return <Row style={{ width: '271px' }} className="ml-auto pb-1 pt-1">
        <small className='m-auto'>NUEVA ETIQUETA</small>
        <InputText className='form-control-sm' style={{ width: '120px' }} maxLength={20} upper setData={setNuevo} value={nuevo} /><br />
        <Button onClick={() => { if (nuevo !== '') nuevoTag(nuevo, actualizar); setNuevo('') }} size='sm' variant='success' >
            <i className='fas fa-save' />
        </Button>
    </Row>

}