import { ParticipanteFila } from "../../../Participantes/Models";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import { useState } from "react";
import FormParticipante from "../../../Participantes/Editar";
import { ListButonClick } from "../../../../../Components/Button/ListBoton";
import { useEvento } from "../index";
import RegistrarPago from "../../../Pagos/Nuevo";
import { toast } from "react-toastify";
import { LinkWhatsapp } from "../../../Suscripciones/Subcriptores/Fila";
import { conCP } from "../../Models/Certificados";
import AddGrupoPago from "../GruposPago/Add";
interface Props {
    data: ParticipanteFila
}
export default function Fila({ data }: Props) {
    const evento = useEvento();
    const { nombre, pais, correo, telefono, id } = data;
    const [edit, setEdit] = useState(false);
    const [pago, setPago] = useState(false);
    return <tr className="info" >
        <td>{nombre}</td>
        <td>{correo}</td>
        <td><LinkWhatsapp p={pais} t={telefono} /></td>
        <td className="text-right"> 
            <ReactTooltip key={`data${Math.random()}`} />
            <RegistrarPago participante={data} open={pago} close={() => { setPago(false); }} />
            {edit ? <FormParticipante open={edit} title={`EDITAR ${nombre}`} participante={data} close={() => setEdit(false)} add={() => { }} /> : <></>}
            <ButtonGroup>
                <AddGrupoPago participante={data} />
                <ListButonClick permiso='PAR_CREAR' tip="EDITAR" variant="primary" click={() => setEdit(true)}>
                    <i className="fa fa-edit" />
                </ListButonClick>
                <Button onClick={() => setPago(true)} data-tip='REGISTAR NUEVO PAGO' variant="dark">
                    <i className="fas fa-usd-square" />
                </Button>
                {evento.estatus === 1 ? <>
                    <Button variant="info" data-tip='PREINSCRIBIR EN EL EVENTO'
                        onClick={() => conCP().preinscribir(evento.id, id, () => {
                            toast.success('PARTICIPANTE PREINSCRITO');
                        })}>
                        <i className="fa fa-check" />
                    </Button>
                    <Button variant="success" data-tip='INSCRIBIR EN EL EVENTO'
                        onClick={() => conCP().inscribir(evento.id, id, () => {
                            toast.success('PARTICIPANTE INSCRITO');
                        })}>
                        <i className="fa fa-check-double" />
                    </Button>
                </> : <></>}
            </ButtonGroup>
        </td>
    </tr>
}