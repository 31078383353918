import CP from "./Categorias";
import EP from "./Eventos";
import FP from "./Facilitadores";
import MPP from "./MetodosPago";
import PP from "./Programas";
import TP from "./Tags";
import TaP from "./Participante";

export default function ContextProvider({ children }: { children: any }) {
    return <CP><TP><EP><PP><MPP><TaP><FP>
        {children}
    </FP></TaP></MPP></PP></EP></TP></CP>
}