import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { THFila } from "@victorequena22/tabla-bootstrap";
import { TypeAuxiliarLista } from '../../Models';
import ReactTooltip from 'react-tooltip';

interface Props {
    data: TypeAuxiliarLista
    link: string
    eliminar: (id: number) => void
}
export function Fila({ data: { id, nombre, estatus }, link, eliminar }: Props) {
    return <tr>
        <THFila width='90px'>
            {id}
        </THFila>
        <THFila width='auto'>
            {nombre}
        </THFila>
        <THFila width='100px'>
            {estatus ? <span className="badge badge-success">ACTIVO</span> : <span className="badge badge-danger">INACTIVO</span>}
        </THFila>
        <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <Button as={Link} data-tip="EDITAR" variant="primary" to={link + id}>
                    <i className="fa fa-edit"></i>
                </Button>
                <ReactTooltip />
                <Button data-tip="INACTIVAR" variant="danger" onClick={() => eliminar(id)} >
                    <i className="fa fa-ban"></i>
                </Button>
            </ButtonGroup>
        </th>
    </tr >
}