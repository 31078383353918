import { Row } from "react-bootstrap";
import getLanguage from "@/languages/export";
import Torta from "@/Components/Graficos/Torta";

export default function Pais() {
    const { dashboard: { contry } } = getLanguage();
    return <Row>
        <div style={{ textAlign: 'center' }}>
            <Torta url='/api/Web/Metricas/pais'
                labels={[contry.labelContry, contry.labelCant]}
            />
            <h5>{contry.title}</h5>
        </div>
    </Row>
}