import { DefaultProps } from "./DefaultData"



interface Props extends DefaultProps {
    size: string,
    children: any,
    top?: JSX.Element,
    left?: JSX.Element,
}
export function SizeViewConteiner({ size, children, top, left, }: Props) {
    return <div className='d-flex justify-content-center' style={{ height: 'calc(100vh - 250px)' }}>
        <div className='d-flex justify-content-center' style={{ width: `calc(${size} + 30px)` }}>
            <div style={{ width: size }}>{top}
                <div className="card" style={{ height: 'calc(100vh - 255px)', width: '100%', border: '1px solid #555', overflowY: 'auto', marginBottom: 0, overflowX: 'hidden' }}>
                    {children}
                </div>
            </div>{left}
        </div>
    </div>
}