import { MobileUserAgent } from "@victorequena22/utiles";

export function getSizeTorta() {
    if (MobileUserAgent()) {
        return ['100vw', '60vw']
    }
    return ['30vw', '18vw']
}
export function getSizeTortaNull() {
    if (MobileUserAgent()) {
        return ['100vw', '60vw']
    }
    return ['30vw', '18vw']
}
export function getSizeArea() {
    if (MobileUserAgent()) {
        return ['90vw', '63vw']
    }
    return ['50vw', '35vw']
}

