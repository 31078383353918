import { Cargando } from "@victorequena22/component-bootstrap";
import { setData } from "@victorequena22/conneccion";
import { useEffect, useState } from "react";

export function IFrame({ url: u }: { url: string }) {
    const [url, setURL] = useState('');
    useEffect(() => { getBlod(u, setURL) }, [u]);
    if (url === '') return <div className="text-center w-100"><Cargando /></div>
    return <iframe src={`${url}`} title="print" frameBorder='0' width='100%' height='700wh' scrolling='no' />
}
async function getBlod(url: string, set: setData) {
    const u = (new URL(document.location.href)).hostname;
    if (u === 'localhost') try {
        const token = localStorage.getItem('Authorization');
        const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
        const blod = await response.blob();
        set(URL.createObjectURL(blod));
    } catch (e) {
        console.error(e);
    } else set(url);
}