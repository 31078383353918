import { semaforo } from '@victorequena22/utiles';
import { EventoLista, Evento } from './Model';
import { ConexionAvanzada, setCambio, setData, } from '@victorequena22/conneccion';
class EventoConecction extends ConexionAvanzada<Evento, EventoLista> {
    clase = 'Evento';
    delete = async (id: number, setData: setData) => {
        setData(await this.getProxi(`/delete/${id}`));
    }
    publicar = async (id: number, setData: setData) => {
        setData(await this.getProxi(`/publicar/${id}`));
    }
    cohorte = async (programa: number, setData: setData) => {
        setData(await this.getProxi(`/cohorte/${programa}`));
    }
    avanzar = async (id: number, setData: setData) => {
        setData(await this.getProxi(`/avanzar/${id}`));
    }

}
export function conEvento() {
    return new EventoConecction();
}
export function arrayToEvento(f: any[]): EventoLista[] {
    return f.map((a) => {
        return {
            id: a[0],
            short: a[1],
            codigo: a[2],
            programa: a[3],
            cohorte: a[4],
            nombre: a[5],
            descripcion: a[6],
            fecha: a[7],
            inicio: a[8],
            final: a[9],
            pais: a[10],
            ciudad: a[11],
            estado: a[12],
            direccion: a[13],
            estatus: a[14],
            usuario: a[15],
            publicacion: a[16],
            lista: a[17],
            facilitadores: a[18],
            avales: a[19],
            tags: a[20]
        }
    });
}
function setCambioEvento(data: any[], c: EventoLista[]) {
    return setCambio(arrayToEvento(data), c);
}
export const eventoUpdate = (c: EventoConecction, u: EventoLista[], setData: (c: EventoLista[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Evento')) c.cambios(data => {
        if (data.length) setData(setCambioEvento(data, u));
        s.next();
    }, () => s.next());
}
export const eventoUpdate2 = async (c: EventoConecction, u: EventoLista[], setData: (c: EventoLista[]) => void) => {
    await c.cambios(data => {
        if (data.length) setData(setCambioEvento(data, u));
    });
}
