import { useState } from "react";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip"
import { toast } from "react-toastify";
import { THFila } from "@victorequena22/tabla-bootstrap";
import { conSub, Sub } from "../../Models";
import { ModalAccion } from "@victorequena22/component-bootstrap";

export default function Fila({ data, add }: { data: Sub, add: () => void }) {
    return <tr>
        <THFila width='60%'>{data.nombre}</THFila>
        <THFila width='calc(40% - 70px)'>{data.correo}</THFila>
        <td style={{ textAlign: 'right', width: '100px' }}>
            <ReactTooltip />
            <BTNCancelar data={data} add={add} />
        </td>
    </tr>
}

function BTNCancelar({ add, data: { id, nombre } }: { data: Sub, add: () => void }) {
    const con = conSub();
    const [open, setOpen] = useState(false);
    const cancelar = () => con.cancelar(id, () => {
        add();
        toast.success(`EL SUSCRIPCTOR ${nombre} FUE ELIMINADO`);
    });
    return <>
        <ModalAccion open={open} accion={cancelar} close={() => setOpen(false)}
            title={`ELIMINAR EL SUSCRIPCTOR ${nombre}`} key={`ModalAlertMSN${id}`}
            msn={`ESTA SEGURO QUE ELIMINARA EL SUSCRIPCTOR ${nombre}?`} />
        <Button data-tip="ELIMINAR" onClick={() => setOpen(true)} variant="danger" >
            <i className="fa fa fa-ban"></i>
        </Button>
    </>
}