import { Row } from "react-bootstrap";
import Torta from "@/Components/Graficos/Torta";

interface Props {
    model: string
    id: number
}
export default function Pais({ model, id }: Props) {
    return <Row>
        <div style={{ textAlign: 'center' }}>
            <h5>PAISES</h5>
            <Torta labels={['PAIS', 'CANT']} url={`/api/Web/Metricas/paisNav/${model}/${id}`} />
        </div>
    </Row>
}