/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Facilitador, conFacilitador, facilitadorNull } from "../../../../Facilitadores/Models";
export default function Facilitadores({ ids }: { ids: number[] }) {
    return <ul>
        {ids.map(id => <li key={`facilitadore${id}`}>
            <FacilitadorVer id={id} />
        </li>)}
    </ul>
}
export function FacilitadorVer({ id }: { id: number }) {
    const [a, seta] = useState<Facilitador>(facilitadorNull);
    useEffect(() => {
        if (id !== 0) {
            conFacilitador().detalle(id, seta);
        }
    }, [id])
    return <>{a.nombre}</>;
} 