import { Col, Row } from "react-bootstrap";
import Precios from "./Precios";
import SelectMetodoPago from "./Metodos";
import Template from "../Template";
export default function PagosEvento() {
    return <Template icon="fa fa-fa-usd-square" title="GESTION DE PRECIOS Y MÉTODOS DE PAGO">
        <Row>
            <Col md={12}><Precios /></Col>
            <Col md={12}><SelectMetodoPago /></Col>
        </Row>
    </Template>
}
