import ReactTooltip from 'react-tooltip';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { conFacilitador, FacilitadorFila } from './Models';
import { ListButonClick } from '@/Components/Button/ListBoton';

interface Props {
    data: FacilitadorFila
    update: () => void
}

export function Fila({ data: { id, nombre, descripcion, experiencia, estatus: e }, update }: Props) {
    const a = (new Date()).getFullYear();
    const span = ['danger', 'primary', 'success']
    const text = ['INACTIVO', 'ACTIVO', 'PUBLICADO']
    return <tr>
        <THFila tip={descripcion} width='auto'>{nombre}</THFila>
        <THFila width='100px' align='right'>{a - experiencia}</THFila>
        <THFila width='100px'><span className={`badge badge-${span[e]}`}>{text[e]}</span></THFila>
        <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <Button as={Link} data-tip="EDITAR" variant="info" to={'/facilitador/ver/' + id}>
                    <i className="fa fa-edit" />
                </Button>
                <ReactTooltip type='light' html />
                {e < 2 ? <ListButonClick permiso='FAC_BANEAR' tip={e === 0 ? 'ACTIVAR' : "INACTIVAR"} variant={e === 0 ? 'primary' : 'danger'}
                    click={() => conFacilitador().cancelar(id, update)}>
                    <i className={`fa fa-${e === 0 ? 'check' : 'trash'}`} />
                </ListButonClick> : <></>}
                {e > 0 ? <ListButonClick permiso='FAC_BANEAR' tip={e === 2 ? "OCULTAR" : 'PUBLICAR'} variant={e === 2 ? 'primary' : 'success'}
                    click={() => conFacilitador().publicar(id, update)}>
                    <i className={`fa fa-${e === 2 ? 'eye-slash' : 'eye'}`} />
                </ListButonClick> : <></>}
            </ButtonGroup>
        </th>
    </tr >
}