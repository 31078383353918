import { InputNumber, InputText } from "@victorequena22/component-bootstrap";
import { InputGroup } from "react-bootstrap";
interface Props {
    start?: any;
    end?: any;
    children?: any;
    width?: string | number;
    className?: string;
    tip?: string;
}
export const GrupPanel = ({ start, width, children, tip, className = '', end }: Props) => {
    return <InputGroup data-tip={tip} className={'input-group-sm ' + className} style={{ width, height: '24px' }}>
        {start ? <InputGroup.Prepend style={{ height: '24px' }}>
            <InputGroup.Text style={{ height: '24px' }}>{start}</InputGroup.Text>
        </InputGroup.Prepend> : <></>}
        {children}
        {end ? <InputGroup.Append style={{ height: '24px' }}>
            <InputGroup.Text style={{ height: '24px' }}>{end}</InputGroup.Text>
        </InputGroup.Append> : <></>}
    </InputGroup>
}
interface Inputs extends Props {
    setData: (s: any) => void
    update?: string
    textClass?: string
}
interface Text extends Inputs {
    value: string
}
export function PanelText(p: Text) {
    const { textClass = '', value, setData, update } = p;
    return <GrupPanel {...p}>
        <InputText style={{ height: '24px' }} className={"input-group-sm p-0 " + textClass} setData={setData} value={value} update={update} />
    </GrupPanel>
}
interface Number extends Inputs {
    value: number
    max: number
}
export function PanelNumber(p: Number) {
    const { textClass = '', max, value, setData, update } = p;
    return <GrupPanel {...p}>
        <InputNumber decimal={0} min={1} max={max} style={{ height: '24px' }} className={"input-group-sm p-0 " + textClass} setData={setData} value={value} update={update} />
    </GrupPanel>
}
export function Title(p: Text) {
    return <PanelText width='100%' {...p} tip='NOMBRE CON EL QUE SALDRA EN EL MENU' start='TITULO MENU:' />
}