/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext } from "react";
import { Ruta } from "../../../../Template/Ruta";
import { Facilitador, facilitadorNull, conFacilitador } from "../Models";
import { Cargando } from "@victorequena22/component-bootstrap";
import EditarFa from "./From/Edit";
import VerFacilitador from "./Ver";
import { getUpdater } from "../../../../Config/Utiles";
import LandingFacilitador from "./Landing";
export interface FacilitadorType {
    facilitador: Facilitador
    update: () => void
}
export var update = () => { };
export const FacilitadorContext = createContext<FacilitadorType>({ facilitador: facilitadorNull, update: () => { } });
export default function GestionFacilitador() {
    const [facilitador, u] = getUpdater<Facilitador>(conFacilitador(), facilitadorNull);
    update = u;
    return facilitador.id ? <FacilitadorContext.Provider value={{ facilitador, update }}>
        <Ruta permiso="FACILITADOR" path="/facilitador/ver/:id" component={VerFacilitador} />
        <Ruta permiso="FAC_EDITAR" path="/facilitador/edit/:id" component={EditarFa} />
        <Ruta permiso="FAC_EDITAR" path="/facilitador/landing/:id" component={LandingFacilitador} />
    </FacilitadorContext.Provider> : <Cargando />
}
export const SeccionConsumer = FacilitadorContext.Consumer;
export function getFacilitador() {
    return useContext(FacilitadorContext).facilitador;
}


