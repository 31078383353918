
import { Row, Col, Card } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { Component } from 'react';
import { SocialList } from '../../../../Config/Web/Social';
import { ProgramaSelectorMultiple } from '../../../Programas/SelectorMultiple';
import SeleccionarEspecialidades from './Especialidades';
import { conFacilitador, Facilitador } from '../../Models';
import { ImageSelector } from '../../../../Media/Selector';
import { ControlArea, ControlNumber, ControlText } from "@victorequena22/component-bootstrap";
import { AlcanceSelector, ContrySelector, StateSelector } from '../../../../../Components/Contry';
import { Texto } from "@victorequena22/slate-bootstrap";
import { getText } from '../../../../Panel/ProtoPanes/DefaultData';
import { getColors } from '../../../../../Template/Colores';
import { EstudioList } from './Estudios';
import { TrabajosList } from './Trabajos';
import { PremiosList } from './Premios';
interface Props {
    data: Facilitador,
    error: any,
    setData: (data: Partial<Facilitador>) => void,
    children?: any;
    codigo?: string;
}
const a = (new Date()).getFullYear();
export function Form({ data: { premios, trabajos, especialidades, programas, estado, ciudad, nombre, experiencia, estudios, descripcion, foto, pais, direccion, alcance, redes }, setData, error, children }: Props) {
    return <Card.Body style={{ minHeight: 'calc(100vh - 210px)' }}>
        <ReactTooltip />
        <Row>
            <Col style={{ minWidth: '20%' }} as={Row}>
                <ImageSelector img={foto ? foto : ''} setImg={foto => setData({ foto })} />
            </Col>
            <Col style={{ minWidth: '80%' }} as={Row}>
                <Col xs={9} className='mt-2'>
                    <ControlText maxLength={150} before='NOMBRE' value={nombre} setData={setData} update='nombre' error={error} />
                </Col>
                <Col xs={3} className='mt-2'>
                    <ControlNumber decimal={0} before='AÑOS DE EXP' value={a - experiencia} setData={b => setData({ experiencia: a - b })} error={error['experiencia']} />
                </Col>
                <Col xs={12} className='mt-2'>
                    <label>DIRECCION</label>
                    <Row>
                        <Col xs={3}><ContrySelector defaultValue={pais} change={pais => setData({ pais })} /></Col>
                        <Col xs={3}><StateSelector pais={pais} defaultValue={estado} change={estado => setData({ estado })} /></Col>
                        <Col xs={6}><ControlText maxLength={100} before='CIUDAD' value={ciudad} setData={setData} update='ciudad' error={error} /></Col>
                    </Row>
                    <ControlArea className='mt-1' maxLength={500} value={direccion} setData={setData} update='direccion' error={error} />
                </Col>
                <Col xs={12} className='mt-2'>
                    <AlcanceSelector alcance={alcance} setAlcanse={alcance => setData({ alcance })} />
                </Col>
            </Col>
        </Row>
        <Row>
            <label>DESCRIPCION</label>
            <Texto colores={getColors()} error={error['descripcion']} value={descripcion} onChange={(descripcion) => setData({ descripcion })} />
        </Row>
        <Row className='mt-2'>
            <Col xs={6}>
                <SeleccionarEspecialidades especialidades={especialidades} setEspecialidades={especialidades => setData({ especialidades })} />
            </Col>
            <Col xs={6}>
                <ProgramaSelectorMultiple programas={programas} setProgramas={programas => setData({ programas })} />
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col xs={12}>
                <label>REDES SOCIALES</label>
                <SocialList socials={redes} setData={redes => setData({ redes })} />
            </Col>
            <Col xs={12}>
                <label>ESTUDIOS REALIZADOS</label>
                <EstudioList estudios={estudios} setData={estudios => setData({ estudios })} />
            </Col>
            <Col xs={12}>
                <label>EXPERIENCIA LABORAL</label>
                <TrabajosList trabajos={trabajos} setData={trabajos => setData({ trabajos })} />
            </Col>
            <Col xs={12}>
                <label>PREMIOS RECIBIDOS</label>
                <PremiosList premios={premios} setData={premios => setData({ premios })} />
            </Col>
        </Row>
        {children}
    </Card.Body>
}
export class Prototipo<p> extends Component<p, Facilitador & { Redirect: boolean }> {
    error: any = [];
    procesar = true;
    con = conFacilitador();
    validar = () => {
        this.error = [];
        let procesar = true;
        return procesar;
    }
    getDataSave = () => {
        const { premios, trabajos, especialidades, programas, estado, ciudad, nombre, experiencia, estudios, descripcion, foto, pais, direccion, alcance, redes } = this.state;
        return { premios, trabajos, especialidades, programas, estado, ciudad, nombre, experiencia, estudios, descripcion: getText(descripcion), foto, pais, direccion, alcance, redes };
    }
    setError = (err: any[]) => {
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast.error(er));
            } else {
                Object.keys(e).forEach(t => this.error[t] = e[t])
            }
        })
        this.procesar = true;
        this.forceUpdate();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}