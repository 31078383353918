import Crear from '@/App/Panel/Ver/Crear';
import { Button, Card, Row } from 'react-bootstrap';
import { Link, Route, useParams } from 'react-router-dom';
import { Eventos } from '../Custom/Eventos';
import { Programas } from '../Custom/Programas';
import { Facilitadores } from '../Custom/Facilitadores';


export default function Crear2({ props, url, edit }: { url: string, props?: any, edit?: string }) {
    const { type } = useParams<any>()
    switch (type) {
        case 'crear': case 'short': case 'pie': case 'menu': case 'chats': return <></>
        default: return <Card>
            <Card.Header className='bg-info'><Row>
                <Button as={Link} className="mr-2 mb-2" data-tip='VOLVER' variant="primary" to={`/panel/crear`}>
                    <i className="fa fa-arrow-left" />
                </Button>
                <h4><b>CREAR DE PANEL</b></h4>
            </Row></Card.Header>
            <Crear url={url} edit={edit} props={props} />
            <Route path="/panel/eventos" exact><Eventos /></Route>
            <Route path="/panel/programas" exact><Programas /></Route>
            <Route path="/panel/TEIFacil" exact><Facilitadores /></Route>
        </Card>
    }

}