import { ColorContext } from "@/Template/Colores";
import { ButtonColor } from "@victorequena22/component-bootstrap";
import { setData } from "@victorequena22/conneccion";
import { useContext } from "react";
import { Button } from "react-bootstrap";
export function ButtonNew({ click, children }: { click: () => void, children: any }) {
    return <Button variant="outline-primary" onClick={click} className='p-0' style={{ height: 24 }} size='sm' block>
        <b> {children}</b>
    </Button>
}
export const ColorButton = ({ color, set, children, tip }: { color: string, tip?: string, set: setData, children: any }) => {
    const { colores } = useContext(ColorContext);
    const style = { width: 32, height: 24, margin: '0 0 0 0', padding: '.25rem .5rem .25rem .5rem' }
    return <ButtonColor tip={tip} colores={colores} className="ml-0"
        style={style} color={color} invert={true} setData={set} disableAlpha>
        {children}
    </ButtonColor>
}