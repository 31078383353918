export const setImage = (e: React.ChangeEvent<HTMLInputElement>, setData: (d: any) => void, max = 1500) => {
    let f = e.currentTarget.files;
    if (f?.length) {
        if (f !== null) ToString(f[0], setData, max);
    }
}
export function ToString(file: any, setData: (d: any) => void, max = 1500) {
    let reader = new FileReader()
    reader.onloadend = () => TransForm(reader.result as string, setData, max);
    reader.readAsDataURL(file);
}
export const TransForm = (s: string, setData: (d: any) => void, max = 1500) => {
    var img = new Image();
    img.src = s;
    img.onload = function () {
        setData(resizeImage(img, max));
    };
}
export const resizeImage = (image: any, max = 1500) => {
    var canvas = document.createElement('canvas');
    const [width, height] = val1500(image.width, image.height, max);
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext('2d');
    if (ctx !== null) ctx.drawImage(image, 0, 0, width, height);
    return {
        data: canvas.toDataURL('image/png', 1),
        aspect: `${width}/${height}`, width, height
    };
}
export const val1500 = (width: number, height: number, max = 1500) => {
    if (width > height) {
        if (width > max) {
            const aspect = (height / width);
            return [max, Math.floor(aspect * max)]
        }
    } else if (height > width) {
        if (height > max) {
            const aspect = (width / height);
            return [Math.floor(aspect * max), max]
        }
    }
    if (height > max) return [max, max]
    return [width, height];
}