
import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { useContext } from "react";
import { EventoContext } from "../../../Capacitacion/Context/Eventos";
import { Itens } from "./Itens";
import { ProgramaContext } from "../../../Capacitacion/Context/Programas";
import { fechaToNumber } from '@victorequena22/utiles';
import { EditArea } from "../../../Panel/Component/SlateCustom";
import { Eventos } from "../../SizePanel/Eventos";
export function Panel({ config }: { config: State }) {
    const { color, filtroType, filtro, size } = config;
    const { eventos } = useContext(EventoContext);
    const { programas } = useContext(ProgramaContext);

    return <>
        <Row style={{ background: color, margin: '0 0 0 0', padding: '0 0 2rem 0', borderBottom: '3px black solid' }}>
            <div style={{ display: 'none' }}><EditArea /></div>
            <Container><Row>
                {eventos.filter(e => e.publicacion && e.estatus && e.estatus < 3)
                    .filter(e => {
                        if (filtro) {
                            if (filtroType === 'program') return e.programa === filtro;
                            if (filtroType === 'categoria') return programas.filter(p => p.categoria === filtro).some(p => e.programa === p.id);
                            if (fechaToNumber() > fechaToNumber(e.final)) return false;
                        }
                        return true;
                    }).map(e => <Itens iten={e} config={config} />)}
            </Row></Container>
        </Row>
        <Eventos data={{ props: config }} size={size} />
    </>
}