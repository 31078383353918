import { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SelectorMultiple } from '../../../Components/Selectors/Multiple';
import { conFacilitador, FacilitadorFila } from './Models';
interface Props {
    facilitadores: number[];
    setFacilitadores: (tags: number[]) => void;
}
export function FacilitadorSelectorMultiple({ facilitadores, setFacilitadores }: Props) {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(facilitadores);
    const [facils, setFacils] = useState<FacilitadorFila[]>([]);
    const filter = useCallback((buscar: string, i: FacilitadorFila) => {
        if (values.some(a => a === i.id)) return false;
        const en = buscar.split(" ");
        const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
        return en.length === con.length;
    }, [values]);
    const actualizarFacils = useCallback(() => { conFacilitador().lista(setFacils) }, [])
    useEffect(() => setValues(facilitadores), [facilitadores, open])
    return <>
        <SelectorMultiple open={open} close={() => setOpen(false)}
            titleModal='SELECIONAR FACILITADORES' selectTitle='FACILITADORES SELECCIONADOS'
            lista={facils} values={values} buscar={filter} setValues={setValues}
            actualizar={actualizarFacils} save={() => setFacilitadores(values)} label='id'
            row={(data: any) => data.nombre} />
        <div className="w-100 mt-1">
            <Row as={Col}>
                <label className='m-0'>FACILITADORES</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            {useMemo(() => <Col className="w-100 h-90 mt-1"
                style={{ minHeight: '50px', borderTop: 'solid 1px #ced4da', overflowY: 'auto' }}>
                {facils.filter((i) => facilitadores.some(a => i.id === a)).map((p) => <li>{p.nombre}</li>)}
            </Col>, [facilitadores, facils])}
        </div>
    </>
}