import { Ver } from './Ver';
import ShortsNuevo from '../Panel/Shorts/Nuevo';
import { Route } from "react-router-dom";
import Selector from './Ver/Selector';
import ListShorts from '../Panel/Shorts';
import PanelProvider from '../Panel/Context/Panel';
import ShortProvider from '../Panel/Context/Shorts';
import { Edit } from '../Panel/Shorts/Nuevo/Edit';
import { CopiarPanel } from './Ver/Copiar';
import { EditarPanel } from './Ver/Editar';
import PageProvider from './PageContext';
import { Footer } from '../Panel/Template/Footer';
import { Menu } from '../Panel/Template/Menu';
import { Chats } from '../Panel/Template/Chats';
import Crear from './Ver/Crear';
import { Eventos } from './Secciones/Eventos';
import { Programas } from './Secciones/Programas';
import { Participantes } from './Secciones/Participante';
import { Paralax1 } from '../Panel2/Panel/Paralax1/Edit';
export default function Panels() {
    return <PageProvider><PanelProvider><ShortProvider>
        <Route path="/panel/edit/:id" exact><EditarPanel key={`panel${Math.random()}`} /></Route>
        <Route path="/panel/copy/:id" exact><CopiarPanel key={`panel${Math.random()}`} /></Route>
        <Route path="/panel/:type" exact><Crear url='/panel' /></Route>
        <Route path="/panel/crear" exact><Selector /></Route>
        <Route path="/panel/template/pie" exact><Footer /></Route>
        <Route path="/panel/template/menu" exact><Menu /></Route>
        <Route path="/panel/template/chats" exact><Chats /></Route>
        <Route path="/panel/template/eventos" exact><Eventos /></Route>
        <Route path="/panel/template/programas" exact><Programas /></Route>
        <Route path="/panel/template/mis-datos" exact><Participantes /></Route>
        <Route path="/panel/short" exact><ListShorts /></Route>
        <Route path="/panel/short/nueva" exact><ShortsNuevo /></Route>
        <Route path="/panel/short/edit/:id" exact component={Edit} />
        <Route path="/panel" exact component={Ver} />
        <Route path="/panel2" exact component={() => <Paralax1 />} />
    </ShortProvider></PanelProvider></PageProvider>
}