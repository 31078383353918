import { Row } from "react-bootstrap";
import Seled from "../../../../Panel/Utiles/SelectedCreatePAnel";
import { getPrograma } from "../index";
import Template from "../Template";
import { MenuCreacion2 } from "@/App/Panel/Utiles/MenuCreacion";
import { getImagenURL } from "@victorequena22/conneccion";
import { EventoSeled, FacilitadorSeled, ProgramaSeled } from "@/App/PanelProxi/Utils/Botones";



export default function MenuCreacion() {
    const { id } = getPrograma();
    return <Template icon="fas fa-browser" title="CREAR PANEL PARA EL EVENTO">
        <Row><h3 className='ml-2 text-info'>Paneles por defecto</h3></Row>
        <MenuCreacion2 url={`/programa/evento/${id}/crear`} />
        <hr />
        <Row><h3 className='ml-2 text-info'>Mis Paneles</h3></Row>
        <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
            <EventoSeled to={`/programa/evento/${id}/crear/eventos`} />
            <ProgramaSeled to={`/programa/evento/${id}/crear/programas`} />
            <FacilitadorSeled to={`/programa/evento/${id}/crear/TEIFacil`} />
            <Seled to={`/programa/evento/${id}/crear/suscribir2`} src={getImagenURL("/static/panels/var/sub2.png")} title="SUSCRIBIRSE" />
        </Row>
    </Template>
}