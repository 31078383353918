import { Col, Image, Row } from "react-bootstrap";
import Template from "../Template";
import { getPrograma } from "../index";
import { TextToHTML } from "@victorequena22/slate-bootstrap";

export default function VerPrograma() {
    const programa = getPrograma();
    return <Template icon="fa fa-eye" title="INFORMACIÓN DEL PROGRAMA">
        <Row>
            <Col md={4}>
                <Image fluid src={programa.short} />
            </Col>
            <Col md={8} as={Row}>
                <Col md={9}><b>Programa: </b>{programa.nombre}</Col>
                <Col md={12}><b>Descripcion: </b>{programa.descripcion}</Col>
            </Col>
            <Col md={12}>
                <h3 className='w-100 text-info'><b>Resumen</b></h3>
                <TextToHTML text={programa.resumen} />
            </Col>
            <Col md={12}>
                <h3 className='w-100 text-info'><b>Contenidos</b></h3>
                <TextToHTML text={programa.contenido} />
            </Col>
        </Row>
    </Template >
}