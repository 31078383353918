/* eslint-disable jsx-a11y/anchor-is-valid */
import { Cargando } from "@victorequena22/component-bootstrap";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { TypeAuxiliar, auxiliarNull, conNorma } from "../../../Models";
import { TextToHTML } from "@victorequena22/slate-bootstrap";

export default function Normas({ id }: { id: number }) {
    const [normas, setNormas] = useState<TypeAuxiliar>(auxiliarNull);
    useEffect(() => {
        if (id !== 0) {
            conNorma().detalle(id, setNormas);
        }
    }, [id])
    if (normas.id === 0) return <Cargando />
    return <Col md={12}>
        <h3 className='w-100 text-info'><b>Normas</b></h3>
        {normas.id !== 0 ? <TextToHTML text={normas.texto} /> : <Cargando />}
    </Col>
} 