import { Row } from "react-bootstrap";
import Seled from "./SelectedCreatePAnel";
import { getImagenURL } from "@victorequena22/conneccion";

export default function MenuCreacion({ url }: { url: string }) {
    return <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
        <Seled to={url + "/carrusel"} src={getImagenURL("/static/panels/var/1.jpg")} title="CARRUSEL BANNER" />
        <Seled to={url + "/paralax1"} src={getImagenURL("/static/panels/pax/3.jpg")} title="PARALAX A" />
        <Seled to={url + "/paralax2"} src={getImagenURL("/static/panels/pax/1.jpg")} title="PARALAX B" />
        <Seled to={url + "/titulo"} src={getImagenURL("/static/panels/pax/9.jpg")} title="TITULO" />
        <Seled to={url + "/video"} src={getImagenURL("/static/panels/gal/5.jpg")} title="VIDEO" />
        <Seled to={url + "/imagen"} src={getImagenURL("/static/panels/gal/5.jpg")} title="IMAGEN" />
        <Seled to={url + "/imagenSola"} src={getImagenURL("/static/media/fondo.svg")} title="IMAGEN SOLA" />
        <Seled to={url + "/galeria"} src={getImagenURL("/static/panels/gal/1.jpg")} title="GALERIA" />
        <Seled to={url + "/cargos"} src={getImagenURL("/static/panels/var/4.jpg")} title="CARRUSEL CON IMAGEN" />
        <Seled to={url + "/CarruselCardText"} src={getImagenURL("/static/panels/gal/6.jpg")} title="CARRUSEL SOLO TEXTO" />
        <Seled to={url + "/CardCargos"} src={getImagenURL("/static/panels/var/4.jpg")} title="CARTAS CON IMAGEN" />
        <Seled to={url + "/CardText"} src={getImagenURL("/static/panels/gal/6.jpg")} title="CARTAS SOLO TEXTO" />
        <Seled to={url + "/servicio"} src={getImagenURL("/static/panels/ser/1.jpg")} title="CARTA CON ICONO A" />
        <Seled to={url + "/servicio2"} src={getImagenURL("/static/panels/ser/5.jpg")} title="CARTA CON ICONO B" />
        <Seled to={url + "/preguntas"} src={getImagenURL("/static/panels/var/5.jpg")} title="PREGUNTAS" />
        <Seled to={url + "/mapa"} src={getImagenURL("/static/panels/var/mapa.png")} title="MAPA" />
        <Seled to={url + "/suscribir"} src={getImagenURL("/static/panels/var/suscribir.png")} title="SUSCRIBIR" />
        <Seled to={url + "/contacto"} src={getImagenURL("/static/panels/var/contact.png")} title="CONTACTO" />
        <Seled to={url + "/testimonio"} src={getImagenURL("/static/panels/var/testimonio.jpg")} title="TESTIMONIOS" />
        <Seled to={url + "/BannerImagen"} src={getImagenURL("/static/panels/var/6.jpg")} title="BANNER ALIADOS" />
    </Row>
}
export function MenuCreacion2({ url }: { url: string }) {
    return <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
        <Seled to={url + "/carrusel"} src={getImagenURL("/static/panels/var/1.jpg")} title="CARRUSEL BANNER" />
        <Seled to={url + "/paralax1"} src={getImagenURL("/static/panels/pax/3.jpg")} title="PARALAX A" />
        <Seled to={url + "/paralax2"} src={getImagenURL("/static/panels/pax/1.jpg")} title="PARALAX B" />
        <Seled to={url + "/titulo"} src={getImagenURL("/static/panels/pax/9.jpg")} title="TITULO" />
        <Seled to={url + "/video"} src={getImagenURL("/static/panels/gal/5.jpg")} title="VIDEO" />
        <Seled to={url + "/imagen"} src={getImagenURL("/static/panels/gal/5.jpg")} title="IMAGEN" />
        <Seled to={url + "/imagenSola"} src={getImagenURL("/static/media/fondo.svg")} title="IMAGEN SOLA" />
        <Seled to={url + "/galeria"} src={getImagenURL("/static/panels/gal/1.jpg")} title="GALERIA" />
        <Seled to={url + "/cargos"} src={getImagenURL("/static/panels/var/4.jpg")} title="CARRUSEL CON IMAGEN" />
        <Seled to={url + "/CarruselCardText"} src={getImagenURL("/static/panels/gal/6.jpg")} title="CARRUSEL SOLO TEXTO" />
        <Seled to={url + "/CardCargos"} src={getImagenURL("/static/panels/var/4.jpg")} title="CARTAS CON IMAGEN" />
        <Seled to={url + "/CardText"} src={getImagenURL("/static/panels/gal/6.jpg")} title="CARTAS SOLO TEXTO" />
        <Seled to={url + "/servicio"} src={getImagenURL("/static/panels/ser/1.jpg")} title="CARTA CON ICONO A" />
        <Seled to={url + "/servicio2"} src={getImagenURL("/static/panels/ser/5.jpg")} title="CARTA CON ICONO B" />
        <Seled to={url + "/preguntas"} src={getImagenURL("/static/panels/var/5.jpg")} title="PREGUNTAS" />
        <Seled to={url + "/mapa"} src={getImagenURL("/static/panels/var/mapa.png")} title="MAPA" />
        <Seled to={url + "/testimonio"} src={getImagenURL("/static/panels/var/testimonio.jpg")} title="TESTIMONIOS" />
        <Seled to={url + "/BannerImagen"} src={getImagenURL("/static/panels/var/6.jpg")} title="BANNER ALIADOS" />
    </Row>
}