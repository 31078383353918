import { Card, ButtonGroup, FormControl, Button } from 'react-bootstrap';
import { ButtonColor, Guardar, ButtonImage, ButtonConfig, AddVideo, ButtonVideo } from './Buttons';
import { TitleCard, BoxConfig, RowConfig, CardConfig, CardMain as C } from "@victorequena22/component-bootstrap";
import { Title, SetSlider, AddItens } from './Inputs';
import ReactTooltip from 'react-tooltip';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export { ButtonColor, Guardar, ButtonImage, ButtonConfig, AddVideo, ButtonVideo };
export { TitleCard, BoxConfig, RowConfig, CardConfig };
export { Title, SetSlider, AddItens };
export const CardMain = (p: any) => <C {...p} >
  <ReactTooltip place='bottom' key={`tip${Math.random()}`} />
  {p.children}
</C>
interface Props { setSize: (d: sizeWidth) => void; size: string; }
export type sizeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type sizeWidth = '570px' | '780px' | '990px' | '1080px' | '1320px' | '1440px';
export function SizeViewSelector({ setSize, size }: Props) {
  return <CardConfig className='ml-auto' width='100px'>
    <RowConfig>
      <ButtonGroup size='sm'>
        <ButtonConfig tip='TAMAÑO PEQUENO(CELULARES)' active={size === '570px'} action={() => setSize('570px')} icon='fas fa-mobile-alt' />
        <ButtonConfig tip='TAMAÑO PEQUENO(TABLET VERTICAL)' active={size === '780px'} action={() => setSize('780px')} icon='fas fa-tablet-alt' />
        <ButtonConfig tip='TAMAÑO MEDIANO(TABLET HORIZONTAL)' active={size === '990px'} action={() => setSize('990px')} icon='fas fa-tablet-alt fa-rotate-90' />
      </ButtonGroup>
    </RowConfig>
    <RowConfig top={2}>
      <ButtonGroup size='sm'>
        <ButtonConfig tip='TAMAÑO MEDIANO(LAPTOP)' active={size === '1080px'} action={() => setSize('1080px')} icon='fas fa-chalkboard' />
        <ButtonConfig tip='TAMAÑO GRANDE(MONITOR 17")' active={size === '1320px'} action={() => setSize('1320px')} icon='fas fa-desktop' />
        <ButtonConfig tip='TAMAÑO GRANDE(MONITOR 19" O MAS)' active={size === '1440px'} action={() => setSize('1440px')} icon='fas fa-tv' />
      </ButtonGroup>
    </RowConfig>
    <RowConfig top={2}>
      <FormControl disabled style={{ height: '24px' }} className='form-control-sm' value={size} />
    </RowConfig>
    <TitleCard>RESOLUCION</TitleCard>
  </CardConfig >
}
export function SizeViewConteiner({ size, children, top, left, h1 = 'calc(100vh - 300px)', h2 = 'calc(100vh - 305px)' }: { size: string, children: any, top?: JSX.Element, left?: JSX.Element, h1?: string, h2?: string }) {
  return <div className='d-flex justify-content-center' style={{ height: h1 }}>
    <BoxConfig className='d-flex' width={`calc(${size} + 30px)`}>
      <BoxConfig width={size}>{top}
        <Card style={{ height: h2, width: '100%', border: '1px solid #555', overflowY: 'auto', marginBottom: 0, overflowX: 'hidden' }}>
          {children}
        </Card>
      </BoxConfig>{left}
    </BoxConfig>
  </div>
}

export function TempatesPage({ children, title }: { children: any, title: string }) {
  return <div style={{ height: '100%', maxHeight: 'calc(100vh - 57px)' }}>
    <Card>
      <Card.Header className='bg-info'><Row>
        <Button as={Link} className="mr-2 mb-2" data-tip='VOLVER' variant="primary" to={`/panel`}>
          <i className="fa fa-arrow-left" />
        </Button>
        <h4><b>{title}</b></h4>
      </Row></Card.Header>
      {children}
    </Card>
  </div>
}