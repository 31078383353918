import { CSSProperties } from "react";
import { BlockElement, Child } from "@victorequena22/slate-bootstrap";
import "./style.css";
export const Element = (p: any) => <List {...p} />
const Block = ({ attributes, children, element, width }: any) => {
    return <div className="element" style={getCSS(element, width)}{...attributes}>{children}</div>
}
const List = (p: any) => {
    const { attributes, children, element, width } = p;
    return <Block attributes={attributes} element={element} width={width}>{children}</Block>
}

const getCSS = ({ align, list, children }: BlockElement, width: number): CSSProperties => {
    const s = list === 'paragraph' ? {} : { display: 'list-item', listStyleType: 'disc' }
    const height = getFontSize(children);
    return {
        display: 'block',
        color: getColor(children),
        float: 'right',
        textAlign: align,
        padding: '0 0 0 0',
        width, height,
        margin: '0 0 0 0',
        ...s
    }
}
const getFontSize = (children: Child[]) => {
    const max = Math.max(...children.map(({ fontSize }) => fontSize ? fontSize : 0));
    if (max > 0) return max * 1.23;
    return 29;
}
const getColor = (children: Child[]) => {
    for (let i = 0; i < children.length; i++) {
        return children[i].color;
    }
    return '#000000';
}

export const Leaf = ({ attributes, children, leaf }: any) => {
    const { color, fontFamily, fontSize, bold, italic, underline, backgroundColor } = leaf;
    const style: CSSProperties = {
        color, display: 'ruby-text',
        backgroundColor: backgroundColor ? backgroundColor : '#00000000',
        fontFamily: fontFamily ? fontFamily : 'FormaDJRTextRegularTesting',
        fontSize: fontSize ? fontSize : 25,
        minHeight: fontSize ? fontSize : 25,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
        marginLeft:'.25rem'
    };
    return <span style={style} {...attributes}>{children}</span>
}