import ReactTooltip from 'react-tooltip';
import { ButtonGroup } from 'react-bootstrap';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { ListButonClick, ListButonLink } from '../../../../Components/Button/ListBoton';
import { EventoLista } from '../Models/Model';
import { conEvento } from '../Models/Coneccion';
import { formatoFecha } from "@victorequena22/utiles";
import { useContext } from 'react';
import { EventoContext } from '../../Context/Eventos';
interface Props {
    data: EventoLista
    update: () => void
}
export function Fila({ data: { id, codigo, inicio, nombre, descripcion, estatus: e, publicacion: p } }: Props) {
    const { actualizar } = useContext(EventoContext);
    const span = ['danger', 'info', 'primary', 'success']
    const text = ['INACTIVO', 'ACTIVO', 'INICIADO', 'FINALIZADO']
    return <tr>
        <THFila width='90px'>{codigo}</THFila>
        <THFila tip={descripcion.replaceAll('\n', '<br/>')} width='auto'>{nombre}</THFila>
        <THFila width='125px'>{formatoFecha(inicio)}</THFila>
        <THFila width='100px' align='center'><span className={`badge badge-${span[e]}`}>{text[e]}</span></THFila>
        <THFila width='100px' align='center'><span className={`badge badge-${p ? 'primary' : 'danger'}`}>{p ? 'VISIBLE' : 'OCULTO'}</span></THFila>
        <th style={{ minWidth: "150px", maxWidth: "150px" }}>
            <ButtonGroup className="float-right">
                <ListButonLink permiso='EVENTO' tip='INFORMACION DEL EVENTO' variant="primary"
                    to={`/evento/ver/${id}`}><i className="fa fa-eye" />
                </ListButonLink>
                <ListButonLink permiso='EVE_CREAR' tip='COPIAR EVENTO' variant="info"
                    to={`/evento/copiar/${id}`}><i className="fa fa-copy" />
                </ListButonLink>
                <ReactTooltip html />
                {e || e < 3 ? <ListButonClick permiso='EVE_PUBLICAR' tip={p ? 'OCULTAR' : 'VISUALIZAR'} variant={p ? 'outline-danger' : 'outline-primary'}
                    click={() => conEvento().publicar(id, actualizar)}>
                    <i className={`fa fa-${p ? 'eye-slash' : 'eye'}`} />
                </ListButonClick> : <></>}
                {e < 2 ? <ListButonClick permiso='EVE_BORRAR' tip={e ? 'INACTIVAR' : 'ACTIVAR'} variant={e ? 'danger' : 'success'}
                    click={() => conEvento().cancelar(id, actualizar)}>
                    <i className={`fa fa-${e ? 'ban' : 'check'}`} />
                </ListButonClick> : <></>}
            </ButtonGroup>
        </th>
    </tr >
}



