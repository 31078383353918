import { useEmpresa } from '@/Template/Empresa'
import { css } from '@emotion/css'
import { Child } from '@victorequena22/slate-bootstrap'
import { CSSProperties } from 'react'

export const VerLeaf = ({ leaf }: { leaf: Child }) => {
    const { color, backgroundColor, fontFamily, fontSize, bold, italic, underline, text } = leaf
    const style = {
        color,
        fontFamily,
        backgroundColor,
        fontSize: fontSize ? `${fontSize / 10}rem` : undefined,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
    }
    return <span style={style} dangerouslySetInnerHTML={{ __html: text.replaceAll('¤', '"') }} />
}
export const VerElement = (p: { element: any; children: any }) => <List {...p} />

const List = (p: any) => {
    const { children, element, } = p;
    return <Block element={element}>
        <ListElement element={element}>
            {children}
        </ListElement>
    </Block>
}
const Block = ({ element, children }: { element: any; children: any }) => {
    switch (element.type) {
        case 'heading-one':
            return <h1 style={getCSS(element)}>{children}</h1>
        case 'heading-two':
            return <h2 style={getCSS(element)}>{children}</h2>
        case 'heading-tre':
            return <h3 style={getCSS(element)}>{children}</h3>
        case 'heading-for':
            return <h4 style={getCSS(element)}>{children}</h4>
        case 'button':
            return <div style={getCSS(element)}>{children}</div>
        default:
            return <p style={getCSS(element)}>{children}</p>
    }
}
export function TextToHTML({ text }: { text: any[] }) {
    return <>
        {text.map((e) => (
            <VerElement key={JSON.stringify(e)} element={e}>
                {e.children.map((a: any) => (
                    <VerLeaf key={a.text} leaf={a} />
                ))}
            </VerElement>
        ))}
    </>
}

const getCSS = ({ align, tabs, type, children }: any): CSSProperties => {
    const font = getFontUsa(type, children[0]) / 10;
    const t2 = tabs * 2;
    const color = children[0] ? children[0].color : '#000';
    return {
        float: 'right', textAlign: align, padding: '0 0 0 0', fontSize: `${font}rem`, minHeight: `${font}rem`,
        width: `calc(100% - ${t2}rem)`, margin: '.05rem .05rem .05rem .05rem', color, display: 'flex',
        justifyContent: align
    }
}
const getFontSize = (type: any) => {
    if (type === 'heading-one') return 26
    if (type === 'heading-two') return 22
    if (type === 'heading-tre') return 18
    if (type === 'heading-for') return 14
    return 10
}
const getFontUsa = (type: any, children?: Child) => {
    const b = getFontSize(type)
    const c = children?.fontSize;
    if (c) return c < b ? b : c;
    return b;
}
const ListElement = ({ element, children }: { element: any, children: any }) => {
    const { list, type } = element;
    if (type === 'button') return <Button element={element}>{children}</Button>
    return <div style={list === 'paragraph' ? getCSSNoList(element) : getCSSList(element)}>
        {children}
    </div>
}
function Button({ element, children }: { element: any, children: any }) {
    const { link } = element;
    const url = useLink(link);
    return <a href={url} target='_blank' rel="noreferrer" className={getCSSBotton(element)}>
        {children}
    </a>
}
function useLink(url: string) {
    const { web } = useEmpresa();
    if (url && url.indexOf('https') !== -1) return url;
    return `https://${web}/${url}`.replaceAll('//', '/');
}
const getCSSList = ({ tabs, type, list, children, align }: any): CSSProperties => {
    const font = getFontUsa(type, children[0]) / 10;
    const t2 = tabs * 2;
    const t = list === 'paragraph' ? t2 : t2 + font
    const s = list === 'paragraph' ? {} : { display: 'list-item', listStyleType: list === 'bulleted-list' ? 'disc' : 'square' }
    return {
        padding: '0 0 0 0', width: `fit-content`, ...s, marginLeft: `${t}rem`, textAlign: align
    }
}
const getCSSNoList = ({ align }: any): CSSProperties => {
    return {
        padding: '0 0 0 0', width: `fit-content`, textAlign: align
    }
}
const getCSSBotton = ({ align, background, hover, borderColor, borderHover, borderSize = 0, borderRadius = 0 }: any): string => {
    return `btn ${align === 'justify' ? 'btn-block' : ''} ` + css`
    background: ${background} !important;
    border: ${borderSize}px solid ${borderColor} !important;
    border-radius: ${borderRadius}px !important;
    &:hover { 
        background: ${hover} !important; 
        border-color: ${borderHover} !important;
    }
    &:active { 
        background: ${background} !important; 
        border-color: ${borderColor} !important;
    }
    `;
}