import { useCallback, useEffect, useState } from "react";
import { FormControl } from 'react-bootstrap';
import { conUsuario, Rol } from '../../Usuarios/Usuario';
interface Props {
    change: (r: number) => void
    defaultValue: number
    isInvalid?: boolean
}
export default function RolSelector({ change, defaultValue, isInvalid }: Props) {
    const [roles, setRoles] = useState<Rol[]>([]);
    const setRol = useCallback((Rol: React.ChangeEvent<HTMLSelectElement>) => change(parseInt(Rol.currentTarget.value as string)), [change])
    useEffect(() => { conUsuario().listaRoles(setRoles); }, []);
    return <FormControl as='select' onChange={setRol}
        value={defaultValue} isInvalid={isInvalid}>
        <option value={-1}>SELECCIONAR ROL</option>
        {roles.map(c => <option key={"unids" + c.id} value={c.id}>{c.nombre}</option>
        )}
    </FormControl>
}