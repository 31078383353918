/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
import { SelectorMultiple } from '../../../../Components/Selectors/Multiple';
import { conMetodoPago, MetodoPago } from './Models';
interface Props {
    metodos: number[];
    setMetodos: (tags: number[]) => void;
    open: boolean
    close: () => void
}
export function MetodosPagoSelectorMultiple({ metodos, setMetodos, open, close }: Props) {
    const [values, setValues] = useState(metodos);
    const [Metodos, setMetodosPago] = useState<MetodoPago[]>([]);
    const filter = useCallback((buscar: string, i: MetodoPago) => {
        if (values.some(a => a === i.id)) return false;
        const en = buscar.split(" ");
        const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
        return en.length === con.length;
    }, [values]);
    const actualizarAvales = useCallback(() => { conMetodoPago().lista(setMetodosPago) }, [])
    return <SelectorMultiple open={open} close={close}
        titleModal='SELECIONAR METODOS DE PAGO' selectTitle='METODOS DE PAGO SELECCIONADOS'
        lista={Metodos} values={values} buscar={filter} setValues={setValues}
        actualizar={actualizarAvales} save={() => setMetodos(values)} label='id'
        row={(d: any) => `${d.nombre} (${d.metodo})`} />
}