import { ConexionCompleta, setData } from "@victorequena22/conneccion";


class PagoConecion extends ConexionCompleta {
    clase = 'Pagos';
    pagosEvento = async (id: number, setData: setData, setError?: setData) => {
        try {
            const data = await this.getProxi('/pagosEvento/' + id,);
            setData(data);
        } catch (e) {
            if (setError) setError(e);
        }
    }
    aprobar = (id: number, nota: string, setData: setData, setError?: setData) => {
        this.postProxi('/aprobar', { id, nota }, setData, setError);
    }
    nota = (id: number, nota: string, setData: setData, setError?: setData) => {
        this.postProxi('/nota', { id, nota }, setData, setError);
    }
    reprobar = (id: number, nota: string, setData: setData, setError?: setData) => {
        this.postProxi('/reprobar', { id, nota }, setData, setError);
    }

}
class GrupoPagoConeccion extends ConexionCompleta {
    clase = 'Pagos/Grupo';
    estatusChage = (id: number, estatus: number, setData: setData, setError?: setData) => {
        this.postProxi(`/estatusChage/${id}`, { estatus }, setData, setError);
    }
    addParticipante = async (grupo: number, par: number, setData?: setData, setError?: setData) => {
        try {
            const data = await this.getProxi(`/addParticipante/${grupo}/${par}`);
            if (setData) setData(data);
        } catch (e) {
            if (setError) setError(e);
        }
    }
    removeParticipante = async (grupo: number, par: number, setData?: setData, setError?: setData) => {
        try {
            const data = await this.getProxi(`/removeParticipante/${grupo}/${par}`);
            if (setData) setData(data);
        } catch (e) {
            if (setError) setError(e);
        }
    }
}
export function conPago() {
    return new PagoConecion();
}
export function conGrupoPago() {
    return new GrupoPagoConeccion();
}