import { CardConfig, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { Selector } from './Selector';
import { CropModal } from '@/App/Media/CropModal';
import { Title } from '@/App/Panel2/Component/Inputs';
import { ButtonColor } from '@/App/Panel2/Component/Buttons';
import { Props } from './index';
import { aspectImage } from '@/App/Panel2/Utiles/Image';
import { getImagenURL } from '@victorequena22/conneccion';

export const Generales = ({ config, setConfig }: Props) => { 
    const { title, color, variant, img, ori } = config;
    return <>
        <CardConfig width='287px' className='m-0'>
            <RowConfig>
                <Title setData={setConfig} value={title} width='281px' />
            </RowConfig>
            <RowConfig top={2}>
                <CropModal aspect={16 / 9} img={img} setImg={async (img) => setConfig({ img, aspect: await aspectImage(getImagenURL(img)) })} />
                <ButtonColor className='ml-2' tip='COLOR DE FONDO' disableAlpha={true} color={color} setData={(color) => setConfig({ color })}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width='255px'>
            <RowConfig>
                <Selector ori={!ori} set={d => setConfig({ variant: d })} variant={variant} />
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
    </>
}