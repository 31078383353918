import { useContext } from "react";
import { Dropdown, NavItem, Button } from "react-bootstrap";
import { conUsuario } from "../App/Usuarios/Usuario";
import { formatoFecha } from "@victorequena22/utiles";
import { SeccionContext, SeccionType } from "./Seccion";
import { toast } from "react-toastify";
import getLanguage from "@/languages/export";
export default function User() {
    const { data: { User }, logout } = useContext(SeccionContext) as SeccionType;
    const { template: { users } } = getLanguage();
    const cacheClear = () => conUsuario().get('/api/Auth/token', (data) => {
        const tipo = localStorage.getItem('TipoUsuario');
        localStorage.clear()
        if (tipo !== null) localStorage.setItem('TipoUsuario', '' + tipo);
        localStorage.setItem('Authorization', data.token);
        toast.success(users.toast.clean);
    });
    return <Dropdown as={NavItem} className='user-menu'>
        <Dropdown.Toggle variant="default" style={{ backgroundColor: '#2196f3', color: '#fff' }}>
            <img width={30} className="mr-1 img-circle elevation-2" alt="Logo2"
                src="/logos/user.jpg" style={{ margin: '-4px -4px -4px -4px ' }} />
            <span className="d-none d-md-inline">{User.usuario}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu >
            <li className="user-header" style={{ backgroundColor: '#2196f3', color: '#fff' }}>
                <img src="/logos/user.jpg" className="img-circle elevation-2" alt="Logo" />
                <p>
                    {User.usuario}
                    <small>{formatoFecha(User.fecha)}</small>
                </p>
            </li>
            <li className="user-footer" style={{ backgroundColor: '#363738' }}>
                <Button onClick={cacheClear} className="btn btn-warning btn-flat">{users.clean}</Button>
                <Button onClick={logout} className="btn btn-danger btn-flat float-right">{users.logout}</Button>
            </li>
        </Dropdown.Menu>
    </Dropdown >

}