import { defaultBlock } from "@victorequena22/slate-bootstrap";
import { BlockElement } from "../../../Panel/ProtoPanes/DefaultData";

export interface Suscriptores {
    id: number
    evento: number
    telefono: string
    correo: string
    nombre: string
    pais: string
    estado: string
    ciudad: string
    estatus: [string, string]
}
export interface Encuesta {
    participante: number;
    evento: number;
    respuesta: string;
}

export interface CapacitacionFila {
    id: number;
    codigo: string;
    categoria: number;
    nombre: string;
    descripcion: string;
    horas: number
    short?: string;
    estatus: number;
    usuario: number;
    lista?: any;
    tags: number[];
}
export interface Capacitacion extends CapacitacionFila {
    actualizado?: string;
    contenido: BlockElement[];
    props: any;
    data: any;
    resumen: BlockElement[];
    participantes: number[];
    interesados: Interesado[];
    suscriptores: Suscriptores[];
    encuesta: Encuesta[];
}
export const CapacitacionNull: Capacitacion = {
    id: 0,
    codigo: '',
    categoria: 0,
    nombre: '',
    descripcion: '',
    contenido: defaultBlock,
    horas: 0,
    props: {},
    data: {},
    short: '',
    resumen: defaultBlock,
    estatus: 0,
    usuario: 0,
    tags: [],
    interesados: [],
    participantes: [],
    encuesta: [],
    suscriptores: []
};

export interface Interesado {
    id: number;
    estatus: [string, string]
}