/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { Categoria, conCategoria, categoriaNull } from '../Programas/Categorias/Models';

function cargarCategoria(set: (s: any) => void) {
    conCategoria().lista(set);
}
export interface CategoriasType {
    Categorias: Categoria[]
    actualizar: () => void
}
export const initCategorias: CategoriasType = {
    Categorias: [], actualizar: () => { }
}
export const CategoriasContext = createContext<CategoriasType>(initCategorias);
export default function CategoriasProvider({ children }: { children: any }) {
    const [Categorias, setCategorias] = useState<Categoria[]>([]);
    const actualizar = useCallback(() => cargarCategoria(setCategorias), []);
    useEffect(() => { actualizar(); }, [actualizar]);
    return <CategoriasContext.Provider value={{ Categorias, actualizar }}>
        {children}
    </CategoriasContext.Provider>
}
export const SeccionConsumer = CategoriasContext.Consumer;

export function findCategoria(id: number | string) {
    const { Categorias } = useContext(CategoriasContext);
    const [programa] = useState<any>(Categorias.find(p => p.id === id || p.codigo === id));
    if (id === 0) return categoriaNull;
    if (id === '0') return categoriaNull;
    if (id === '') return categoriaNull;
    return programa ? programa : categoriaNull;
}