import { Panel } from './Panel';
import { Config } from './Config';
import { Single } from '@/App/Panel2/ProtoPanes/Single';
import { getText } from '@victorequena22/slate-bootstrap';
import { StateS } from '@/App/Panel2/ProtoPanes/Interface';
interface S {
    img: string;
    color: string;
    ori: boolean;
    variant: 'diagonal' | 'arrow' | 'semi-circle' | 'diagonal-i' | 'arrow-i' | '';
    aspect: string
}
export type State = StateS & S;
export class Paralax1 extends Single<S> {
    statePanel = { img: '/public/panels/default.jpg', color: '#ffffff', ori: false, variant: '', aspect: '16/9' };
    Config = Config;
    Panel = Panel;
    getData = () => {
        const { color, ori, variant, img, title, html } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, ori, variant, img });
        return { title, html: getText(html), data, props, type: 'paralax1' };
    }
}