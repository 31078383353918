import Chart from "react-google-charts"
export type data = string | number
export interface AreaProps {
    label: string[]
    values: data[][]
    title: string
    hAxisTitle: string
}
export default function Area({ label, values, title, hAxisTitle }: AreaProps) {
    let minValue = 0;
    if (values.length !== 0)
        values.forEach(d => {
            let v = d[1] as number;
            let c = d[2] as number;
            if (v > minValue) minValue = v;
            if (c > minValue) minValue = c;
        })
    return <Chart
        width={'1fr'}
        height={'35rem'}
        chartType="AreaChart"
        loader={<div>Loading Chart</div>}
        data={[label, ...values]}
        options={{
            title, hAxis: { title: hAxisTitle, titleTextStyle: { color: '#333' } },
            vAxis: { minValue }, chartArea: { width: '85%', height: '70%' }, legend: { position: 'none' }
        }}
        rootProps={{ 'data-testid': '1' }}
    />

}