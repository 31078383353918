import { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Template/Header';
import Menu from './Template/Menu';
import { Card, Col } from 'react-bootstrap';
import { Cargando } from '@victorequena22/component-bootstrap';
import Login from './App/Login';
import { getSeccionContext, valStorage } from './Template/Seccion';
import ListUsuario from './App/Usuarios';
import { Media } from './App/Media';
import MasterCapacitaciones, { rutasTalento as rt } from './App/Capacitacion';
import MasterConfig from './App/Config';
import { Ruta } from './Template/Ruta';
import Crear from './App/PanelProxi';
import Dasboard from './App/Dashboard';
import TemplateProvider from './Template/Context';
import ContextProvider from './App/Capacitacion/Context';
import MasterContactoProxi from './App/PanelProxi/Contacto';
// const Crear = lazy(() => import('./App/Panel'))
export default function App() {
  valStorage();
  const { data: { seccion, cargando } } = getSeccionContext();
  if (cargando)
    return <Card style={{ height: '99.9999vh', padding: '20% 20% 20% 20%' }}>
      <Cargando />
    </Card>
  if (!seccion) return <Login />
  return <TemplateProvider><Router><ContextProvider> 
    <Header key='headerBar' />
    <Menu key={'MenuAside' + Math.random()} />
    <section className="content-wrapper mb-3" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 57px)' }}>
      <Col md={12} className="mt-3" >
        <Switch><Suspense fallback={<Cargando />}>
          <Route path={rt} component={MasterCapacitaciones} />
          <Ruta permiso='USUARIO' path="/usuarios" component={ListUsuario} />
          <Ruta permiso='PANEL' path={["/panel", "/panel2",]} component={Crear} />
          <Route path="/contacto" component={MasterContactoProxi} />
          <Ruta permiso='MEDIA' path="/media" component={Media} />
          <Route path='/config' component={MasterConfig} />
          <Route path="/" exact component={Dasboard} />
        </Suspense></Switch></Col>
    </section>
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
  </ContextProvider></Router></TemplateProvider>
}