import { useState, createContext } from 'react';
import { Panel, conPanel } from '../Models';
import { useSemaforo } from '@/ReactUtils';
export interface PanelType {
    panels: Panel[];
}
export const PanelContext = createContext<PanelType>({ panels: [] })
export default function PanelProvider({ children }: { children: any }) {
    const [panels, setPanels] = useState<Panel[]>([]);
    const [con] = useState(conPanel);
    useSemaforo('Panel', async () => {
        await con.lista(data => {
            if (data.length) setPanels(data);
        });
    })
    return <PanelContext.Provider value={{ panels }}>{children}</PanelContext.Provider>
}
export const SeccionConsumer = PanelContext.Consumer;