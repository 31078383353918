import { useCallback, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Html } from '../Component/Commons';
import { getCSS, } from '../EditPanel/Carrusel/props'
import { imagen, content, posicion, } from '../EditPanel/Carrusel/Itens/props';
import { getImagenURL } from '@victorequena22/conneccion';
export function Carrusel({ data: { itens, props } }: any) {
    const [seleted, seled] = useState(1)
    const { animated } = props;
    const prev = seleted === 1 ? itens.length - 1 : seleted - 2;
    const sele = seleted - 1;
    const next = seleted === itens.length ? 0 : seleted;
    const [trans, setTrans] = useState('');
    const setTransClick = useCallback((t: string, p: number) => {
        setTrans(t);
        setTimeout(() => { setTrans(''); seled(p + 1) }, 250)
    }, []);
    return <Row className='m-0 p-0'>
        <div style={{ aspectRatio: '16/7', width: '100%' }} className={"carousel slide " + getCSS(animated)}>
            <ol className="carousel-indicators">
                {itens.map((i: any) => <li key={'itens' + i.id} className={seleted === i.indice ? 'active' : ''}></li>)}
            </ol>
            {useMemo(() => <div key={`carousel-inner${sele}`} className={"carousel-inner" + trans}>
                <Itens position="prev" key={'itenprev' + prev} iten={itens[prev]} />
                <Itens position="" key={'itensele' + sele} iten={itens[sele]} />
                <Itens position="next" key={'itennext' + next} iten={itens[next]} />
            </div>, [prev, trans, sele, next, itens])}
            <div style={{ zIndex: 5 }} className="carousel-control-prev" role="button"
                onClick={() => setTransClick(' prev', prev)}>
                <span aria-hidden="true" className="carousel-control-prev-icon"></span>
                <span className="sr-only">Previous</span>
            </div>
            <div style={{ zIndex: 5 }} className="carousel-control-next" role="button"
                onClick={() => setTransClick(' next', next)}>
                <span aria-hidden="true" className="carousel-control-next-icon"></span>
                <span className="sr-only">Next</span>
            </div>
        </div>
    </Row >

}
function Itens({
    position: p,
    iten: { html, indice, props: { position, disable, img, color, back, borde, bordeColor, redondius } } }: any) {
    return useMemo(() => <div className={`iten ${p}`}>
        <video autoPlay loop muted width='100%' poster={getImagenURL(img)}>
            <img style={imagen} alt="Bricks" src={getImagenURL(img)} />
            <source src={getImagenURL(img)} type="video/mp4"></source>
        </video>
        <Html html={html} style={posicion[position]} key={'html' + indice}
            className={`${content(back, color, bordeColor, borde, redondius, disable)} carousel-caption`} />
    </div >, [back, borde, bordeColor, color, disable, html, img, indice, p, position, redondius])
}
