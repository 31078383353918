import { Button, ButtonGroup, Col, InputGroup, Row } from 'react-bootstrap';
import Switch from '@mui/material/Switch';
import { ListButonLink } from '@/Components/Button/ListBoton';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { Fila } from './Fila';
import { EventoLista } from './Models/Model';
import { useContext, useEffect } from 'react';
import { EventoContext } from '../Context/Eventos';
import { InputText } from '@victorequena22/component-bootstrap';
import { FormControlLabel } from '@mui/material';
export default function ListaEvento() {
    const { eventos, actualizar } = useContext(EventoContext);
    useEffect(() => { actualizar() }, [actualizar])
    return <Tabla itens={eventos} />
}
class Tabla extends CardBuscador<{ itens: EventoLista[] }> {
    historicos = false;
    buscarEn = ['id', 'nombre', 'descripcion'];
    Title = () => <>
        <h3>BUSCAR EVENTO</h3>
        <ButtonGroup className='ml-auto'>
            <ListButonLink permiso='EVENTO' tip='' variant="default" to='/evento/config' >
                <i className="fa fa-folders"></i> PARAMETROS
            </ListButonLink>
            <ListButonLink permiso='EVE_CREAR' tip='' variant="primary" to='/evento/nueva' >
                <i className="fa fa-plus" /> NUEVO EVENTO
            </ListButonLink>
        </ButtonGroup>
    </>
    Row = (d: EventoLista) => <Fila key={'EF' + d.id + d.estatus + d.publicacion} data={d} update={this.set} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='150px' label='codigo'>COD</Th>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='125px' label='inicio'>INICIO</Th>
            <Th width='100px' aling='center'>ESTATUS</Th>
            <Th width='120px' aling='center'>PUBLICADO</Th>
            <Th width='150px' aling='center'>ACCIONES</Th>
        </tr>)
    }
    Buscador = () => {
        return <Row className='d-flex' style={{ paddingBottom: '15px' }}>
            <Col md={9} className='mr-auto'>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>{this.buscarLabel}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <InputGroup.Append>
                        <Button variant='primary'>
                            <i className='fa fa-search'></i> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <FormControlLabel control={<Switch
                checked={this.historicos}
                onChange={() => {
                    this.historicos = !this.historicos;
                    this.forceUpdate();
                }}
            />} label="VER HISTORICO" />
        </Row>

    }
    filter() {
        return this.ordenar().filter((e: EventoLista) => {
            if (!this.historicos) {
                const date = getMonth();
                const ini = getMonth(e.inicio);
                if (date > ini) return false;
            }
            return true;
        });
    }
    componentDidMount() {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}

function getMonth(ini?: string) {
    const date = ini ? new Date(ini) : new Date();
    const year = date.getFullYear() * 100;
    const month = date.getMonth();
    return year + month;
}
