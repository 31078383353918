/* eslint-disable react-hooks/rules-of-hooks */
import { semaforo } from '@victorequena22/utiles';
import { useState, createContext, useEffect, useCallback, useContext } from 'react'
import { Capacitacion, CapacitacionFila, CapacitacionNull, CapacitacionUpdate, conCapacitacion } from '../Programas/Models';
import { useSemaforo } from '@/ReactUtils';

export interface ProgramaType {
    programas: CapacitacionFila[]
    actualizar: () => void
}
export const ProgramaContext = createContext<ProgramaType>({
    programas: [], actualizar: () => { },
});
export default function ProgramaProvider({ children }: { children: any }) {
    const [programas, setCapacitacion] = useState<CapacitacionFila[]>([]);
    const [con] = useState(conCapacitacion());
    const actualizar = useCallback(() => CapacitacionUpdate(con, programas, setCapacitacion), [con, programas]);
    useEffect(() => {
        const s = semaforo();
        s.add('Capacitacion');
        return () => s.clean('Capacitacion');
    }, []);
    useEffect(() => {
        let interval = setInterval(actualizar, 50)
        return () => clearInterval(interval)
    }, [actualizar]);
    return <ProgramaContext.Provider value={{ programas, actualizar }}>
        {children}
    </ProgramaContext.Provider>
}
export const SeccionConsumer = ProgramaContext.Consumer;
export function findProgram(id: number | string): CapacitacionFila | Capacitacion {
    const { programas } = useContext(ProgramaContext);
    const [programa, setProgram] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    useEffect(() => { conCapacitacion().detalle(id, setProgram) }, [id]);
    if (isNull(id)) return CapacitacionNull;
    return valCapacitacion(id, programa);
}
export function findProgramUpdate(id: number | string): Capacitacion {
    const { programas } = useContext(ProgramaContext);
    const [programa, setProgram] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    const actualizar = useCallback(async () => {
        if (isNull(id)) setProgram(CapacitacionNull);
        else await conCapacitacion().detalle(id, p => {
            if (JSON.stringify(programa) !== JSON.stringify(p))
                setProgram(p)
        })
    }, [id, programa]);
    useSemaforo('programaDetalles', actualizar)
    if (isNull(id)) return CapacitacionNull;
    return valCapacitacion(id, programa);
}
export function findProgramFila(id: number | string): CapacitacionFila {
    const { programas } = useContext(ProgramaContext);
    const [programa] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    if (isNull(id)) return CapacitacionNull;
    return programa ? programa : CapacitacionNull;
}
function isNull(id: number | string) {
    if (id === 0 || id === '0' || id === '' || id === undefined) return true;
    return false;
}

function valCapacitacion(id: any, evento: Capacitacion): Capacitacion {
    if (evento) return evento;
    return { ...CapacitacionNull, id };

}