export const fontsFamily = [
    "Arial",
    "AdamScript",//legacy
    "Andika-R",
    "Angkor",
    "AnnieUseYourTelescope",
    "Anton",
    "ArchitectsDaughter",
    "Asset",
    "Bangers",
    "Bayon",
    "Bevan",
    "BigshotOne",
    "Bokor",
    "Candal",
    "CarterOne",
    "Cedarville-Cursive",
    "Chenla",
    "CoveredByYourGrace",
    "CrimsonText-Roman",
    "Dangrek",
    "DawningofaNewDay",
    "DidactGothic",
    "FrancoisOne",
    "Freehand",
    "GenBasR",
    "GenBkBasR",
    "GiveYouGlory",
    "GloriaHallelujah",
    "GoblinOne",
    "GoudyBookletter1911",
    "GravitasOne",
    "Hanuman",
    "HoltwoodOneSC",
    "IMFeDPrm28P",
    "IndieFlower",
    "JustMeAgainDownHere",
    "Khmer",
    "Koulen",
    "Kristi",
    "LaBelleAurore",
    "LateefRegOT",
    "LeagueScript",
    "Lobster",
    "Lohit-Tamil",
    "LoveYaLikeASister",
    "LovedbytheKing",
    "Meddon",
    "MedievalSharp",
    "Megrim",
    "Metrophobic",
    "Michroma",
    "Monofett",
    "Moul",
    "Neucha",
    "NothingYouCouldDo",
    "NovaCut",
    "NovaFlat",
    "NovaMono",
    "NovaOval",
    "NovaRound",
    "NovaScript",
    "NovaSlim",
    "NovaSquare",
    "OFLGoudyStMTT",
    "OdorMeanChey",
    "OvertheRainbow",
    "PTF55F",
    "Pacifico",
    "PaytoneOne",
    "Pecita",
    "PollerOne",
    "Preahvihear",
    "ReenieBeanie",
    "RuslanDisplay",
    "SansitaOne",
    "Siemreap",
    "SigmarOne",
    "SixCaps",
    "Snippet",
    "SueEllenFrancisco",
    "Suwannaphum",
    "SwankyandMooMoo",
    "Tangerine_Regular",
    "Taprom",
    "Thabit",
    "TheGirlNextDoor",
    "UnifrakturMaguntia-Book",
    "WaitingfortheSunrise",
    "WireOne",
    "Zeyada",
    "GFSNeohellenic",
    "ABeeZee",
    "Abel",
    "AbrilFatface",
    "Acme",
    "Actor",
    "Adamina",
    "AdventPro",
    "AguafinaScript",
    "Akronim",
    "AksaraBaliGalang",
    "Aladin",
    "Aldrich",
    "Alegreya",
    "AlegreyaSC",
    "AlexBrush",
    "AlfaSlabOne",
    "Alice",
    "Alike",
    "AlikeAngular",
    "Allan",
    "Allerta",
    "AllertaStencil",
    "Allura",
    "Almendra",
    "AlmendraDisplay",
    "AlmendraSC",
    "Amarante",
    "Amaranth",
    "AmaticSC",
    "Amethysta",
    "Amiri",
    "Anaheim",
    "Andada",
    "AndadaSC",
    "AnonymousPro",
    "Antic",
    "AnticDidone",
    "AnticSlab",
    "Arapey",
    "Arbutus",
    "ArbutusSlab",
    "ArchivoBlack",
    "ArchivoNarrow",
    "Arimo",
    "Arizonia",
    "Armata",
    "Artifika",
    "Arvo",
    "Asap",
    "Astloch",
    "Asul",
    "AtomicAge",
    "Aubrey",
    "Audiowide",
    "AutourOne",
    "Average",
    "AverageSans",
    "AveriaGruesaLibre",
    "AveriaLibre",
    "AveriaSansLibre",
    "AveriaSerifLibre",
    "BadScript",
    "Balthazar",
    "Basic",
    "Battambang",
    "Baumans",
    "Belgrano",
    "Belleza",
    "BenchNine",
    "Bentham",
    "BerkshireSwash",
    "BigelowRules",
    "Bilbo",
    "BilboSwashCaps",
    "Bitter",
    "BlackOpsOne",
    "Bonbon",
    "Boogaloo",
    "BowlbyOne",
    "BowlbyOneSC",
    "Brawler",
    "BreeSerif",
    "BrunoAce",
    "BrunoAceSC",
    "BubblegumSans",
    "BubblerOne",
    "Buenard",
    "Butcherman",
    "ButchermanCaps",
    "ButterflyKids",
    "Cabin",
    "CabinCondensed",
    "CabinSketch",
    "CaesarDressing",
    "Cagliostro",
    "Cambo",
    "Cantarell",
    "CantataOne",
    "CantoraOne",
    "Capriola",
    "Cardo",
    "Carme",
    "CarroisGothic",
    "CarroisGothicSC",
    "Caudex",
    "CevicheOne",
    "Changa",
    "ChangaOne",
    "Chango",
    "ChauPhilomeneOne",
    "ChelaOne",
    "ChelseaMarket",
    "CherrySwash",
    "Chicle",
    "Chivo",
    "Cinzel",
    "CinzelDecorative",
    "Clara",
    "ClickerScript",
    "Coda",
    "Codystar",
    "Combo",
    "Comfortaa",
    "ConcertOne",
    "Condiment",
    "Content",
    "ContrailOne",
    "Convergence",
    "Cookie",
    "Copse",
    "Corben",
    "Courgette",
    "Cousine",
    "Coustard",
    "Creepster",
    "CreteRound",
    "CroissantOne",
    "Cuprum",
    "Cutive",
    "CutiveMono",
    "Damion",
    "DancingScript",
    "DaysOne",
    "Delius",
    "DeliusSwashCaps",
    "DeliusUnicase",
    "DellaRespira",
    "DenkOne",
    "Devonshire",
    "Dhyana",
    "Diplomata",
    "DiplomataSC",
    "Domine",
    "DonegalOne",
    "DoppioOne",
    "Dorsa",
    "Dosis",
    "DrSugiyama",
    "DuruSans",
    "Dynalight",
    "EBGaramond",
    "EagleLake",
    "Eater",
    "EaterCaps",
    "Economica",
    "Electrolize",
    "Elsie",
    "ElsieSwashCaps",
    "EmblemaOne",
    "EmilysCandy",
    "Engagement",
    "Englebert",
    "Enriqueta",
    "EricaOne",
    "Esteban",
    "EuphoriaScript",
    "Ewert",
    "Exo",
    "ExpletusSans",
    "FanwoodText",
    "Fascinate",
    "FascinateInline",
    "FasterOne",
    "Fasthand",
    "Federant",
    "Federo",
    "Felipa",
    "Fenix",
    "FingerPaint",
    "FjallaOne",
    "FjordOne",
    "Flamenco",
    "Flavors",
    "Fondamento",
    "Forum",
    "FreckleFace",
    "FrederickatheGreat",
    "FredokaOne",
    "Fresca",
    "Frijole",
    "Fruktur",
    "FugazOne",
    "GFSDidot",
    "Gafata",
    "Galdeano",
    "Galindo",
    "Geo",
    "Geostar",
    "GeostarFill",
    "GermaniaOne",
    "GildaDisplay",
    "GlassAntiqua",
    "Glegoo",
    "GochiHand",
    "Gorditas",
    "Graduate",
    "GrandHotel",
    "GreatVibes",
    "Griffy",
    "Gruppo",
    "Gudea",
    "Habibi",
    "HammersmithOne",
    "Hanalei",
    "HanaleiFill",
    "Handlee",
    "HappyMonkey",
    "HeadlandOne",
    "HennyPenny",
    "HermeneusOne",
    "HerrVonMuellerhoff",
    "Homenaje",
    "Iceberg",
    "Iceland",
    "Imprima",
    "Inconsolata",
    "Inder",
    "Inika",
    "IstokWeb",
    "Italiana",
    "Italianno",
    "JacquesFrancois",
    "JacquesFrancoisShadow",
    "JimNightshade",
    "JockeyOne",
    "JollyLodger",
    "JosefinSans",
    "JosefinSlab",
    "JotiOne",
    "Judson",
    "Julee",
    "JuliusSansOne",
    "Junge",
    "Jura",
    "Kameron",
    "Karla",
    "KarlaTamilInclined",
    "KarlaTamilUpright",
    "KaushanScript",
    "Kavoon",
    "KeaniaOne",
    "KellySlab",
    "Kenia",
    "KiteOne",
    "Knewave",
    "KottaOne",
    "Kreon",
    "KronaOne",
    "Lancelot",
    "Lato",
    "LeckerliOne",
    "Ledger",
    "Lekton",
    "Lemon",
    "LemonOne",
    "LibreBaskerville",
    "LifeSavers",
    "LilitaOne",
    "Limelight",
    "LindenHill",
    "LobsterTwo",
    "LondrinaOutline",
    "LondrinaShadow",
    "LondrinaSketch",
    "LondrinaSolid",
    "Lora",
    "LoversQuarrel",
    "Lusitana",
    "Lustria",
    "Macondo",
    "MacondoSwashCaps",
    "Magra",
    "Mako",
    "Marcellus",
    "MarcellusSC",
    "MarckScript",
    "Margarine",
    "MarkoOne",
    "Marmelad",
    "Marvel",
    "Mate",
    "MateSC",
    "MavenPro",
    "McLaren",
    "MedulaOne",
    "MeieScript",
    "MergeOne",
    "Merienda",
    "MeriendaOne",
    "Merriweather",
    "MerriweatherSans",
    "MervaleScript",
    "MetalMania",
    "Metamorphous",
    "Miama",
    "Milonga",
    "Miltonian",
    "MiltonianTattoo",
    "Miniver",
    "MissFajardose",
    "ModernAntiqua",
    "Molengo",
    "Molle",
    "Monda",
    "Monoton",
    "MonsieurLaDoulaise",
    "Montaga",
    "Montserrat",
    "MontserratAlternates",
    "MontserratSubrayada",
    "MouseMemoirs",
    "MrBedfort",
    "MrDafoe",
    "MrDeHaviland",
    "MrsSaintDelafield",
    "MrsSheppards",
    "Muli",
    "MysteryQuest",
    "NanumBrushScript",
    "NanumGothic",
    "NanumGothicCoding",
    "NanumMyeongjo",
    "NanumPenScript",
    "Neuton",
    "NewRocker",
    "NewsCycle",
    "Niconne",
    "NixieOne",
    "Nobile",
    "Norican",
    "Nosifer",
    "NosiferCaps",
    "NoticiaText",
    "Numans",
    "Nunito",
    "Offside",
    "OldStandard",
    "Oldenburg",
    "OleoScript",
    "OleoScriptSwashCaps",
    "Oranienbaum",
    "Orbitron",
    "Oregano",
    "Orienta",
    "OriginalSurfer",
    "Oswald",
    "Overlock",
    "OverlockSC",
    "Ovo",
    "Oxygen",
    "OxygenMono",
    "PT_Sans-Caption-Web",
    "PT_Sans-Narrow-Web",
    "PT_Sans-Web",
    "PT_Serif-Caption-Web",
    "PT_Serif-Web",
    "Padauk",
    "Paprika",
    "Parisienne",
    "PasseroOne",
    "PassionOne",
    "PatrickHand",
    "PatrickHandSC",
    "PatuaOne",
    "Peralta",
    "PetitFormalScript",
    "Petrona",
    "Phetsarath",
    "Philosopher",
    "Piedra",
    "PinyonScript",
    "PirataOne",
    "Plaster",
    "Play",
    "Playball",
    "PlayfairDisplay",
    "PlayfairDisplaySC",
    "Podkova",
    "PoetsenOne",
    "PoiretOne",
    "Poly",
    "Pompiere",
    "PontanoSans",
    "PortLligatSans",
    "PortLligatSlab",
    "Prata",
    "PressStart2P",
    "PrincessSofia",
    "Prociono",
    "ProstoOne",
    "Puritan",
    "PurplePurse",
    "Quando",
    "Quantico",
    "Quattrocento",
    "QuattrocentoSans",
    "Questrial",
    "Quicksand",
    "Quintessential",
    "Qwigley",
    "RacingSansOne",
    "Radley",
    "Raleway",
    "RalewayDots",
    "Rambla",
    "RammettoOne",
    "Ranchers",
    "Rationale",
    "Revalia",
    "Ribeye",
    "RibeyeMarrow",
    "Righteous",
    "Risque",
    "Rokkitt",
    "Romanesco",
    "RopaSans",
    "Rosario",
    "Rosarivo",
    "RougeScript",
    "Ruda",
    "Rufina",
    "RugeBoogie",
    "Ruluko",
    "RumRaisin",
    "RussoOne",
    "Ruthie",
    "Rye",
    "Sacramento",
    "Sail",
    "Salsa",
    "Sanchez",
    "Sancreek",
    "Sansation",
    "Sarina",
    "Scada",
    "SeaweedScript",
    "Sedan",
    "SedanSC",
    "Sevillana",
    "SeymourOne",
    "ShadowsIntoLightTwo",
    "Shanti",
    "Share",
    "ShareTech",
    "ShareTechExp",
    "ShareTechMono",
    "ShareTechMonoExp",
    "Shojumaru",
    "ShortStack",
    "Signika",
    "SignikaNegative",
    "Simonetta",
    "Sintony",
    "SirinStencil",
    "Skranji",
    "Smythe",
    "Sniglet",
    "SnowburstOne",
    "SofadiOne",
    "Sofia",
    "SonsieOne",
    "SortsMillGoudy",
    "Souliyo",
    "SourceCodePro",
    "SourceSansPro",
    "SpicyRice",
    "Spinnaker",
    "Spirax",
    "SquadaOne",
    "Stalemate",
    "StalinOne",
    "StalinistOne",
    "StardosStencil",
    "StintUltraCondensed",
    "StintUltraExpanded",
    "Stoke",
    "Strait",
    "Strong",
    "SupermercadoOne",
    "Tauri",
    "Telex",
    "TenorSans",
    "TerminalDosis",
    "TextMeOne",
    "Tharlon",
    "Tienne",
    "Tinos",
    "TitanOne",
    "TitilliumWeb",
    "TradeWinds",
    "Trocchi",
    "Trochut",
    "Trykker",
    "Tuffy",
    "TulpenOne",
    "UncialAntiqua",
    "Underdog",
    "UnicaOne",
    "Unlock",
    "Unna",
    "VT323",
    "VampiroOne",
    "Varela",
    "VarelaRound",
    "VastShadow",
    "Vibur",
    "Vidaloka",
    "Viga",
    "Voces",
    "Volkhov",
    "Vollkorn",
    "Voltaire",
    "Wallpoet",
    "Warnes",
    "Wellfleet",
    "WendyOne",
    "YanoneKaffeesatz",
    "YesevaOne",
    "Yesteryear"
].sort();