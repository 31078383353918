import { Link } from "react-router-dom";
import { Auth } from '../Config/Permisos';
import './menu.css';
interface Props {
    permiso: string
    to: string
    children: any
}

export default function MenuIten({ permiso, to, children }: Props) {
    if (Auth(permiso))
        return <li className="nav-item" >
            <Link className="nav-link" to={to}>{children}</Link>
        </li>
    return <></>;
}