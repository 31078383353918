import QRImage from "./Elementos/QRImage";
import Text from "./Elementos/Text";
import Img from "./Elementos/Img";
import { Props } from "./Props";
export function Iten(p: Props) {
    const { iten: { type } } = p;
    switch (type) {
        case "QR": return <QRImage {...p} />
        case "TEXT": return <Text {...p} />
        case "IMG": return <Img {...p} />
        case "": return <></>
    }
}