import { Button, ButtonGroup, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ModalAccion } from '@victorequena22/component-bootstrap';
import { useState } from 'react';
import { Panel } from '@/App/Panel/Models';
import { conLanding } from '@/App/Panel/Models/Landing';
import { useLanding } from './context';
interface Props {
    panel: Panel;
    children?: any
    url: string
}
export default function GestionLanding({ url, panel: { id: i, estado, type, }, children }: Props) {
    const { actualizar } = useLanding();
    const [open, setOpen] = useState(false);
    const con = conLanding();
    return <Row className='m-0 p-0' style={{ position: 'absolute', right: 20, left: 20, zIndex: 500 }}>
        <ButtonGroup>
            <Button data-tip='EDITAR PANEL' as={Link} to={`${url}/edit/${i}`} variant="default">
                <i className='fas fa-edit' />
            </Button>
            <Button data-tip='COPIAR PANEL' as={Link} to={`${url}/copy/${i}`} variant="default">
                <i className='fas fa-copy' />
            </Button>
            {estado === 2 ? <></> : <>
                <Button data-tip='SUBIR PANEL' onClick={() => con.up(i, actualizar, actualizar)} variant="default">
                    <i className='fas fa-arrow-up' />
                </Button>
                <Button data-tip='BAJAR PANEL' onClick={() => con.down(i, actualizar, actualizar)} variant="default">
                    <i className='fas fa-arrow-down' />
                </Button>
            </>}
        </ButtonGroup>
        {children}
        {estado === 2 ? <></> : <>
            <ReactTooltip key={`tooltipsPanel${i}${estado}`} />
            <ModalAccion open={open} close={() => setOpen(false)}
                title={`ELIMINAR PANEL ${i}`}
                msn={`DESEA ELIMINAR PANEL ${i} de tipo ${type}`}
                accion={() => con.delete(i, actualizar, actualizar)} />
            <ButtonGroup className='ml-auto'>
                <Button data-tip={`${estado === 0 ? 'VER' : 'OCULTAR'} PANEL`}
                    onClick={() => con.cancelar(i, actualizar)}
                    variant={estado === 0 ? "warning" : "success"}>
                    <i className={`fas fa-eye${estado === 0 ? '-slash' : ''}`} />
                </Button>
                <Button data-tip='ELIMINAR PANEL' onClick={() => setOpen(true)} variant="danger">
                    <i className='fas fa-trash' />
                </Button>
            </ButtonGroup>
        </>}
    </Row>
}