import { Redirect } from "react-router-dom";
import { Interface } from './Interface';
import { SizeViewConteiner } from '../Component/Contents';
import { SlateProvider } from "../Stale";
interface Props {
    config: any
    setConfig: (d: any) => void
}
export class Single<S> extends Interface<S> {
    render() {
        const { Config, Panel, Top, Left, state: { id: i }, props: { data, edit } } = this;
        if (data === undefined && i !== undefined) return <Redirect to={(edit ? edit : '/panel/edit/') + i} />
        return <div style={{ height: '100%', maxHeight: 'calc(100vh - 57px)' }}>
            <SlateProvider value={this.getTexto()} setValue={this.setTexto}>
                <Config save={this.save} config={this.state} setConfig={this.setConfig} />
                <SizeViewConteiner size={this.state.size}
                    top={<Top config={this.state} setConfig={this.setConfig} />}
                    left={<Left config={this.state} setConfig={this.setConfig} />}>
                    <Panel config={this.state} setConfig={this.setConfig} />
                </SizeViewConteiner>
            </SlateProvider>
        </div>
    }
    Config = (_d: Props & { save: () => void }) => <></>
    Panel = (_d: Props) => <></>
    Top = (_d: Props) => <></>
    Left = (_d: Props) => <></>
}