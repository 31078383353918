import { CardConfig } from '../../../Panel/Component';
import { Button } from "react-bootstrap";
import { GPUTrans } from '../GPU/Manager';

export default function FilterEdit({ trans, filters }: { trans: (d: GPUTrans) => void, filters: GPUTrans[] }) {
    const Boton = ({ tran, children }: { tran: GPUTrans, children: any }) =>
        <Button onClick={() => trans(tran)} size='sm' block className='mt-1 mb-1'
            style={{ fontSize: '13px' }}
            variant={(filters.some(t => t === tran) ? '' : 'outline-') + 'success'}>
            <b>{children}</b></Button >
    return <CardConfig marginNull width="143">
        <Boton tran='mirrorX'>MIRROR X</Boton>
        <Boton tran='mirrorY'>MIRROR Y</Boton>
        <Boton tran='invert'>INVERTIR COLOR</Boton>
        <Boton tran='gris'>BLANCO Y NEGRO</Boton>
        <Boton tran='gris2'>BLANCO Y NEGRO</Boton>
        <Boton tran='gris3'>OPACO</Boton>
        <Boton tran='sepia'>SEPIA</Boton>
        <Boton tran='calido'>CALIDO</Boton>
        <Boton tran='frio'>FRIO</Boton>
        <Boton tran='invierno'>INVIERNO</Boton>
        <Boton tran='sepia2'>OTOÑO</Boton>
        <Boton tran='envejecer'>ENVEJECER</Boton>
    </CardConfig>
}