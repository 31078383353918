/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useCallback } from 'react'
import { Tags } from '../../Media/Models';
import { conCommos } from '@victorequena22/conneccion/lib/Coneccion';

export interface TagsType {
    Tags: Tags[]
    actualizar: () => void
}
export const TagsContext = createContext<TagsType>({
    Tags: [], actualizar: () => { },
});
export default function TagsProvider({ children }: { children: any }) {
    const [Tags, setTags] = useState<Tags[]>([]);
    const actualizar = useCallback(() => conCommos().tags(setTags), []);
    useEffect(() => { actualizar(); }, []);
    return <TagsContext.Provider value={{ Tags, actualizar }}>
        {children}
    </TagsContext.Provider>
}
export const SeccionConsumer = TagsContext.Consumer;

