import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FormGrupoPago from "./FormGrupoPago";
interface props {
    model: string;
    id: number;
}

export default function NuevoGrupoPago({ model, id }: props) {
    const [open, set] = useState(false);
    return <>
        <Button onClick={() => set(true)} variant="success">NUEVO GRUPO DE PAGOS</Button>
        <Modal animation show={open} size='xl' centered onHide={() => set(false)}>
            <Modal.Header className='bg-primary' closeButton>
                <h4 className='mr-auto'>NUEVO GRUPO DE PAGOS</h4>
            </Modal.Header>
            <Modal.Body>
                <FormGrupoPago add={() => set(false)} model={model} id={id} />
            </Modal.Body>
        </Modal>
    </>
}