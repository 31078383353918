import { ButtonGroup, Row } from "react-bootstrap";
import { ListButonClick, ListButonLink } from "../../../../Components/Button/ListBoton";
import { useHistory, useLocation } from "react-router-dom";
import { getPrograma } from "./index";


export function MemuEvento() {
    const { id } = getPrograma();
    return <ButtonGroup vertical className="float-right btn-sm btn-block">
        <ButtonLink permiso='EVENTO' variant="info" to={`/programa/ver/${id}`}>
            <i className="fa fa-eye mr-2" /> INFORMACIÓN DEL PROGRAMA
        </ButtonLink>
        <ButtonLink permiso='PROGRAMA' variant="success" to={`/programa/estadisticas/${id}`}>
            <i className="fa fa-chart-bar"></i> ESTADISTICAS DEL PROGRAMA
        </ButtonLink>
        <ButtonLink permiso='PRO_EDITAR' variant="primary" to={`/programa/edit/${id}`}>
            <i className="fa fa-edit mr-2" /> EDITAR PROGRAMA
        </ButtonLink>
        <ButtonLink permiso='EVENTO' variant="dark" to={`/programa/eventos/${id}`}>
            <i className="fa fa-file-certificate"></i> EVENTOS DEL PROGRAMA
        </ButtonLink>
        <ButtonLink permiso='PROGRAMA' variant="dark" to={`/programa/landing/${id}`}>
            <i className="fa fa-browser"></i> LANDING DEL PROGRAMA
        </ButtonLink>
        <ButtonLink permiso='EVENTO' variant="secondary" to={`/programa/evento/${id}`}>
            <i className="fa fa-browser"></i> LANDING DEL EVENTO
        </ButtonLink>
        <ButtonLink permiso='PRO_EDITAR' variant="danger" to={`/programa/Recursos/${id}`}>
            <i className="fa fa-file mr-2" /> RECURSOS PROGRAMA
        </ButtonLink>
        <ButtonLink permiso='EVE_PARTICIPANTES' variant="warning" to={`/programa/participantes/${id}`}>
            <i className="fa fa-users-class mr-2" /> PARTICIPANTES APROBADOS
        </ButtonLink>
    </ButtonGroup>
}
export function Title() {
    const history = useHistory();
    const { codigo, nombre } = getPrograma();
    return <Row className="mb-2" >
        <ListButonClick className="mb-2 float-right" permiso='EVENTO' tip='VOLVER'
            variant="primary" click={() => history.goBack()}>
            <i className="fa fa-arrow-left" />
        </ListButonClick>
        <h3 className='text-primary ml-2'>{codigo}</h3>
        <h3 className='ml-2 mr-auto text-info'>{nombre}</h3>
    </Row>
}

function ButtonLink({ to, children, variant, permiso }: { permiso: string, to: string, variant: string, children: any }) {
    const { pathname } = useLocation()
    return <ListButonLink className="text-left" permiso={permiso}
        variant={`${to === pathname ? '' : 'outline-'}${variant}`} to={to}>
        {children}
    </ListButonLink>
}
