import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import Fila from './Fila';
import { MetodoPago } from './Models';
import { useContext, useEffect, useState } from 'react';
import { MetodosPagosContext } from '../../Context/MetodosPago';
import { IE, IEL } from '../../../../Config/Updater';
import { ListButonClick } from '../../../../Components/Button/ListBoton';
import NuevoMetodoPago from './From/Nuevo';
export default function ListaMetodoPago() {
    const { MetodosPagos, actualizarMetodosPago } = useContext(MetodosPagosContext)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { actualizarMetodosPago() }, [])
    return <Tabla key={IEL(MetodosPagos) + 'MetodosPagos'} itens={MetodosPagos} />
}
class Tabla extends CardBuscador<{ itens: MetodoPago[] }> {
    placeholder = "BUSCAR METODO DE PAGO";
    buscarEn = ["nombre", "metodo"];
    Title = () => <>
        <h3 className='mr-auto'><i className="fa fa-search"></i> BUSCAR METODO DE PAGO</h3>
        <NuevoMetodo/>
    </>
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='auto' label='fecha'>nombre</Th>
            <Th width='auto' label='metodo'>MÉTODOS DE PAGO</Th>
            <Th width='100px' aling='center'>RECIBE</Th>
            <Th width='100px' aling='center'>ESTATUS</Th>
            <Th width='80px'> </Th>
        </tr>
    }
    Row = (p: MetodoPago) => <Fila key={'MetodoPago' + IE(p)} data={p} />
    componentDidMount() {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}
function NuevoMetodo() {
    const [open, setOpen] = useState(false);
    const { actualizarMetodosPago } = useContext(MetodosPagosContext)
    return <>
        <NuevoMetodoPago add={actualizarMetodosPago} open={open} close={() => setOpen(false)} />
        <ListButonClick permiso="PAG_METODOS" variant="success" click={() => setOpen(true)}>NUEVO</ListButonClick>
    </>

}