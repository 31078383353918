import { CardMain, SizeViewSelector, Title, Guardar,  TitleCard, CardConfig, RowConfig } from '../../Component';
import { State, iten } from './index'
import { ItenImagen } from '../../Component/Commons';
import { ColorFondo } from '../../Component/Commons/Simples';
import { ColorDesplazamiento, Separacion } from '../../Component/Commons/Carrusel';
import { TBElement } from '../../Component/SlateCustom';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    copy: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
}
export function Config({ save, copy, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, gap, color, inter, itens: { length } } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="227px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ColorFondo gradiend setColor={color => setConfig({ color })} color={color} />
                <ColorDesplazamiento className='ml-1' setColor={inter => setConfig({ inter })} color={inter} />
                <Separacion gap={gap} setGap={gap => setConfig({ gap })} />
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <ItenImagen copy={copy}  setBack={back => setIten({ back })}
            itenDown={itenDown} removeItem={removeItem}
            setImagen={(img) => setIten({ img })}
            cont={length} iten={iten} aspect={1}
            addItem={addIten} itenUp={itenUp} />
        <TBElement />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}