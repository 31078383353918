import { ConexionBase, ConexionCompleta, setData } from '@victorequena22/conneccion';
interface Data {
    participante: number;
    evento: number;
}
class CertificadoParticipante extends ConexionBase {
    clase = 'Evento/Certificados/Participante';
    aprobar(data: Data, setData: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi(`/aprobar`, data, setData, setError);
    }
    reprobar(data: Data, setData: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi(`/reprobar`, data, setData, setError);
    }
    preinscribir(id: number, participante: number, setData: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi(`/preinscribir/${id}`, { participante }, setData, setError);
    }
    inscribir(id: number, participante: number, setData: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi(`/inscribir/${id}`, { participante }, setData, setError);
    }
    eliminar(id: number, participante: number, setData: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi(`/eliminar/${id}`, { participante }, setData, setError);
    }
}
export function conCP() {
    return new CertificadoParticipante();
}
class Certificado extends ConexionCompleta {
    clase = 'Evento/Certificados/Certificado';
    subir = (codigo: string, image: string, setData?: setData) => {
        this.postProxi(`/subir/${codigo}`, { image }, setData);
    }
}
export function conCC() {
    return new Certificado();
}