import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { VerContry, VerEstado } from "../../../../Components/Contry/Ver";
import { useCallback, useEffect, useState } from "react";
import { chanceEvento } from "./Enviar";
import { Button, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { dataContry } from "@victorequena22/conneccion";
import { Suscriptores } from "@/App/Capacitacion/Programas/Models/Models";
import { useEventoFila } from "@/App/Capacitacion/Context/Eventos";
interface Props {
    s: Suscriptores;
    e?: number;
}
export default function Fila({ s: { id, evento, nombre, correo, telefono, pais, estado, ciudad, estatus: [bg, st] }, e }: Props) {
    // const estatus = useCallback((d: any) => getStatus(id, d, () => { }), [id]);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='auto'><LinkWhatsapp t={telefono} p={pais} /></THFila>
        <THFila width='auto'>{correo}</THFila>
        <THFila width='auto'><VerContry key={pais} pais={pais} /></THFila>
        <THFila width='auto'><VerEstado key={pais + estado} pais={pais} estado={estado} /></THFila>
        <THFila width='auto'>{ciudad}</THFila>
        <THFila width='auto'><span className={`badge bg-${bg}`}>{st}</span></THFila>
        <THInput width='auto'>
            <ReactTooltip html />
            <ButtonGroup>
                <GetEvento key={`eventoTo${evento}`} e={evento} />
                {/* <StatusChange bg={bg} status={st} update={estatus} />
                <Chat id={id} url='suscrito' /> */}
                <EnviarCorreo id={id} e={e} />
            </ButtonGroup>
        </THInput>
    </tr>
}
const GetEvento = ({ e }: { e: number }) => {
    const { nombre, fecha, cohorte } = useEventoFila(e);
    if (e > 0) {
        const tip = `${nombre}<br/>${fecha}<br/>Cohorte: ${cohorte}<br/>`;
        return <Button variant="warning" data-tip={tip} as={Link} to={`/evento/estadisticas/${e}`}>
            <i className="fas fa-file-certificate" />
        </Button>
    }
    return <></>
}
const EnviarCorreo = ({ e, id }: { e?: number, id: number }) => {
    if (e) return <EnviarCorreo2 e={e} id={id} />
    return <></>
}
const EnviarCorreo2 = ({ e, id }: { e: number, id: number }) => {
    const estatus = useCallback(() => { chanceEvento(id, e, () => { }) }, [e, id]);
    const tip = `ASOCIAR A ESTE EVENTO ENVIAR MENSAJE CON INFORMACION`;
    return <Button variant="success" data-tip={tip} onClick={estatus}>
        <i className="fas fa-envelope" />
    </Button>
}
export const LinkWhatsapp = ({ t, p }: { t: string, p: string }) => {
    const [telefono, setTelefono] = useState('');
    const [phone, setPhone] = useState('');
    useEffect(() => { setPhoneApi(t, p, setPhone) }, [p, t]);
    useEffect(() => { setTelefonoVal(t, setTelefono) }, [p, t]);
    useEffect(() => { console.log(phone) }, [phone]);
    if (telefono.length < 9) return <>{t}</>
    return <a href={`https://api.whatsapp.com/send?phone=${phone}${telefono}`} target="_blank" rel="noreferrer">{t}</a>
}
const setPhoneApi = async (telefono: string, pais: string, setPhone: (d: string) => void) => {
    const part = telefono.replaceAll('+', '').split('-');
    if (part.length === 1 && (telefono.length === 11 || telefono.length === 12)) {
        setPhone(``)
    } else if (part.length < 3) {
        const p = await dataContry().getCountry(pais)
        if (p && p !== null) setPhone(`${p.phone}`.replaceAll('undefined', ''));
    } else {
        setPhone(`${parseInt(part[0])}`.replaceAll('undefined', ''))
    }
}
const setTelefonoVal = (telefono: string, setTelefono: (d: string) => void) => {
    const part = telefono.replaceAll('+', '').split('-');
    if (part.length < 3) setTelefono(`${parseInt(part[0])}${part[1]}`.replaceAll('undefined', ''))
    else setTelefono(`${parseInt(part[1])}${part[2]}`.replaceAll('undefined', ''))
}