/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useCallback, useContext } from 'react'
import { conEvento, eventoUpdate2 } from '../Evento/Models/Coneccion';
import { Evento, EventoLista, eventoNull } from '../Evento/Models/Model';
import { useSemaforo } from '@/ReactUtils';

export interface EventosType {
    eventos: EventoLista[]
    actualizar: () => void
}
export const EventoContext = createContext<EventosType>({ eventos: [], actualizar: () => { } });
export default function EventosProvider({ children }: { children: any }) {
    const [eventos, setEventos] = useState<EventoLista[]>([]);
    const [con] = useState(conEvento());
    const actualizar = useCallback(async () => await eventoUpdate2(con, eventos, setEventos), [con, eventos]);
    useSemaforo('Evento', actualizar)
    return <EventoContext.Provider value={{ eventos, actualizar }}>
        {children}
    </EventoContext.Provider>
}
export const SeccionConsumer = EventoContext.Consumer;

export const eventoToPrograma = (id: number) => {
    return useContext(EventoContext).eventos.filter(e => e.programa === id)
}
export const useEventos = () => {
    return useContext(EventoContext).eventos;
}
export const useEventosActivos = () => {
    return useContext(EventoContext).eventos.filter((e: EventoLista) => {
        const date = getMonth();
        const ini = getMonth(e.inicio);
        if (date > ini) return false;
        return true;
    });
}
function getMonth(ini?: string) {
    const date = ini ? new Date(ini) : new Date();
    const year = date.getFullYear() * 100;
    const month = date.getMonth();
    return year + month;
}
export function useEventoFila(id: number | string): EventoLista {
    const e = useEventos().find(p => p.id === id || p.codigo === id);
    return e ? e : eventoNull;
}
export function useFindEvento(id: number | string): Evento {
    const [evento, setEvento] = useState<Evento>({ ...eventoNull, ...useEventoFila(id) });
    useEffect(() => { conEvento().detalle(id, setEvento) }, [id]);
    if (isNull(id)) return eventoNull;
    return evento;
}
export function useFindEventoUpdate(id: number | string): Evento {
    const [evento, setEvento] = useState<Evento>({ ...eventoNull, ...useEventoFila(id) });
    const actualizar = useCallback(async () => {
        await conEvento().detalle(id, e => { if (valAct(e, evento)) setEvento(e) })
    }, [id, evento]);
    useSemaforo('eventoDetalles', actualizar)
    if (isNull(id)) return eventoNull;
    return evento;
}
function valAct(e: any, ev: Evento) {
    if (e === undefined) return false;
    return ev.actualizado !== e.actualizado;
}
function isNull(id: number | string) {
    if (id === 0 || id === '0' || id === '' || id === undefined) return true;
    return false;
}