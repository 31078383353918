import { useState, createContext, useEffect } from 'react';
import { UsuarioFila, conUsuario } from '../App/Usuarios/Usuario';
import { AliadoFila, conAliado } from '../App/Capacitacion/Aliado/Aliado';
export interface UserType {
    usuarios: UsuarioFila[]
    aliados: AliadoFila[]
    tipo: string
}
export const UserContext = createContext<UserType>({ usuarios: [], aliados: [], tipo: 'TALENTO' });
export default function UsersProvider({ children }: { children: any }) {
    const [usuarios, setu] = useState<UsuarioFila[]>([]);
    const [aliados, seta] = useState<AliadoFila[]>([]);
    useEffect(() => {
        conUsuario().lista(setu);
        conAliado().lista(seta);
    }, [])
    return <UserContext.Provider value={{ usuarios, aliados, tipo: 'TALENTO' }}>
        {children}
    </UserContext.Provider>
}