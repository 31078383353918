import { Button } from 'react-bootstrap';
import { THFila, ModalBusqueda } from "@victorequena22/tabla-bootstrap";

export default class BuscarShort extends ModalBusqueda {buscarEn = ['clave', 'descripcion'];
    Title() { return <h4 className='mr-auto'>BUSCAR ATAJO</h4> }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='clave' width='200px'>CLAVE</Th>
            <Th label='descripcion' width='330px'>DESCRIPCION</Th>
            <Th width='calc(100% - 600px)'>EJEMPLO</Th>
            <Th width='70px'> </Th>
        </tr>
    }
    Row(c: any) {
        return <tr>
            <THFila width='200px'>{c.clave}</THFila>
            <THFila width='330px'>{c.descripcion}</THFila>
            <td style={{ width: 'calc(100% - 600px)' }} dangerouslySetInnerHTML={{ __html: c.html }} />
            <td style={{ width: '70px' }}>
                <Button variant='success' onClick={() => { this.props.add(c); this.props.close(); }}>
                    <i className='fas fa-check' data-tip='Editar' />
                </Button>
            </td>
        </tr>
    }
    getItens() { return shors; }
}

const shors: any[] = [
    ['participante_nombre', 'NOMBRE DEL PARTICIPANTE', 'Natali Hernández'],
    ['participante_id', 'NUMERO ID DEL PARTICIPANTE', 'V-00000000-0'],
    ['cohorte_roman', 'NÚMERO DEL EVENTO EN NÚMEROS ROMANOS', 'II'],
    ['horas', 'NÚMERO DE HORAS ACADÉMICAS', '25'],
    ['cohorte', 'NÚMERO DEL EVENTO EN NUMEROS', '2'],
    ['fecha_escrita', 'FECHA ESCRITA EN EL EVENTO', 'Del 21 al 23 de Octubre de 2022'],
    ['fecha_escrita_lower', 'FECHA ESCRITA EN EL EVENTO', 'del 21 al 23 de octubre de 2022'],
    ['fecha_escrita_upper', 'FECHA ESCRITA EN EL EVENTO', 'DEL 26 AL 28 DE AGOSTO DE 2022'],
    ['pais', 'PAIS DEL EVENTO DE SER ONLINE PONE ONLY', 'Venezuela'],
    ['estado', 'ESTADO DEL EVENTO DE SER ONLINE PONE ONLY', 'Nueva Esparta'],
    ['ciudad', 'CIUDAD DEL EVENTO DE SER ONLINE PONE EL NOMBRE DE LA WEB', 'Porlamar'],
    ['direccion', 'DIRECION DEL EVENTO DE SER ONLINE PONE EL LINK', 'Hotel Tamanaco Centro'],
    ['pais_lower', 'PAIS DEL EVENTO DE SER ONLINE PONE ONLY', 'venezuela'],
    ['estado_lower', 'ESTADO DEL EVENTO DE SER ONLINE PONE ONLY', 'nueva esparta'],
    ['ciudad_lower', 'CIUDAD DEL EVENTO DE SER ONLINE PONE EL NOMBRE DE LA WEB', 'porlamar'],
    ['direccion_lower', 'DIRECION DEL EVENTO DE SER ONLINE PONE EL LINK', 'hotel tamanaco centro'],
    ['pais_upper', 'PAIS DEL EVENTO DE SER ONLINE PONE ONLY', 'VENEZUELA'],
    ['estado_upper', 'ESTADO DEL EVENTO DE SER ONLINE PONE ONLY', 'NUEVA ESPARTA'],
    ['ciudad_upper', 'CIUDAD DEL EVENTO DE SER ONLINE PONE EL NOMBRE DE LA WEB', 'PORLAMAR'],
    ['direccion_upper', 'DIRECION DEL EVENTO DE SER ONLINE PONE EL LINK', 'HOTEL TAMANACO CENTRO']
].map(([clave, descripcion, html]) => { return { clave, descripcion, html } });