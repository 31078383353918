import { Panel } from './Panel';
import { Config } from './Config';
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { getText } from '../../ProtoPanes/DefaultData';
export interface I {
    img: string;
    border: string;
}
export interface S {
    color: string;
    gap: number;
    card: 'none' | 'rounded' | 'rectangle';
    border1: boolean;
    inter: string;
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = {
    img: '/public/panels/iten.jpg',
    border: '#00bbbb'
}
export const dIten: Iten & I = { ...aux, ...defaultIten, back: '#666666' }
export class CarruselCardText extends GalIten<S, I> {
    constructor(p: any) {
        super(p);
        this.initGal({ color: '#f0f0f0', gap: 5, inter: "rgba(0,0,0,.4)", card: 'rounded', border1: true }, [
            { ...dIten, id: 1 },
            { ...dIten, id: 2 },
            { ...dIten, id: 3 },
            { ...dIten, id: 4 },
            { ...dIten, id: 5 }
        ]);
    }
    Config = () => <Config
        copy={this.copy}
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel key='panel' config={this.state} seled={this.seleted} />
    saveItens = () => this.state.itens.map(({ img, color, id, html, back, border }) => {
        const data = JSON.stringify({});
        const props = JSON.stringify({ img, color, id, back, border });
        return { data, props, html: JSON.stringify(getText(html)), id };
    })
    getDataSave = () => {
        const { title, color, gap, inter, card, border1 } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, gap, inter, card, border1 });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'cargosCT' };
    }

}
