import { conConfig } from "@victorequena22/conneccion"
import { toast } from "react-toastify"
export const getStatus = async (id: string | number, data: any, update: () => void) => {
    conConfig('').post(`/api/Programa/Suscribirse/status/suscrito/${id}`, data,
        () => { update(); toast.success('GUARDADO EXITOSAMENTE'); },
        () => { update(); toast.error('ERROR') })
}

export const chanceEvento = async (id: string | number, evento: string | number, update: () => void) => {
    conConfig('').get(`/api/Programa/Suscribirse/chanceEvento/${id}/${evento}`,
        () => { update(); toast.success('MENSAJE ENVIADO') },
        () => { update(); toast.error('ERROR') })
}