import { permisoSome, permisoAny } from "../../Template/Seccion";
interface Props {
    permiso: string | string[];
    children: any
}
export function ContentPermisoSome({ children, permiso }: Props) {
    if (permisoSome(Array.isArray(permiso) ? permiso : [permiso]))
        return <>{children}</>
    return <></>
}
export function ContentPermisoEvery({ children, permiso }: Props) {
    if (permisoAny(Array.isArray(permiso) ? permiso : [permiso]))
        return <>{children}</>
    return <></>
}