import { Component } from "react";
import { Button, Modal, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import { ParticipanteFila, ParticipanteNull, conParticipante, Participante } from './Models';
import { toast } from 'react-toastify';
import { Cargando, ControlDate, ControlText } from "@victorequena22/component-bootstrap";
import { ContrySelector, StateSelector } from '../../../Components/Contry';
interface Props {
    open: boolean
    participante: ParticipanteFila
    title: string
    close: () => void
    add?: (c: ParticipanteFila) => void
}
interface State extends Participante {
    activeError: boolean;
}
export default class EditarParticipante extends Component<Props, State> {
    error: any = [];
    con = conParticipante();
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        this.state = { ...ParticipanteNull, activeError: false };
    }
    componentDidMount() {
        if (this.props.participante !== null)
            this.con.detalle(this.props.participante.id, this.setData)
    }
    render() {
        let { setData, state, props: { participante } } = this
        return <Modal animation show={this.props.open} size='xl' centered onHide={this.props.close}>
            <Modal.Header className='bg-primary' closeButton>
                <h4 className='mr-auto'>{this.props.title}</h4>
            </Modal.Header>
            <Modal.Body>
                {(participante !== null && state.id === 0) ? <Cargando /> :
                    <From data={state} error={this.error} setData={setData} />}
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.get.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    }
    isInvalid(s: string) {
        if (s === "") return true
        return false
    }
    get() {
        let error: Partial<State> = {};
        let { state: s } = this
        let procesar = true;
        if (s.nombre === "") error.nombre = 'DEBE COLOCAR UN NOMBRE COMPLETO';
        if (s.telefono === "") error.telefono = 'DEBE COLOCAR UN NUMERO DE TELEFONO';
        if (s.correo === "") error.correo = 'DEBE COLOCAR UN CORREO';
        if (Object.keys(error).length > 0) { this.setError([error]); procesar = false; }
        if (procesar) {
            if (this.props.participante === null) this.con.nuevo(s, this.action, this.setError)
            else this.con.actualizar(this.props.participante.id, s, this.action, this.setError)
        }
    }
    setError = (err: any) => {
        err.forEach((err: any) => {
            if (err.sql) {
                err.sql.forEach((er: string) => toast.error(er));
            } else {
                Object.keys(err).forEach(t => this.error[t] = err[t])
            }
        })
        this.forceUpdate();
    }
    action = (data: any) => {
        if (this.props.add) this.props.add(data);
        if (this.props.participante === null) this.setState(ParticipanteNull);
        this.props.close();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}
interface PropsFrom {
    data: Participante,
    error: any,
    setData: (d: Partial<Participante>) => void
}
function From({ error, data: { usuario, documento, nombre2, nombre, nacimiento, pais, estado, ciudad, direccion, telefono, correo, Profesión, Ocupación }, setData, }: PropsFrom) {
    return <Row>
        <Col as={FormGroup} md={12}><h4 className='w-100'>nombre de usuario: @{usuario}</h4></Col>
        <Col as={FormGroup} md={12}><ControlText maxLength={200} error={error} update="nombre" setData={setData} before="Nombre Completo*" value={nombre} /></Col>
        <Col as={FormGroup} md={7}><ControlText maxLength={200} error={error} update="nombre2" setData={setData} before="Nombre de Certificado" value={nombre2 === null ? nombre : nombre2} /></Col>
        <Col as={FormGroup} md={5}><ControlText maxLength={100} error={error} update="documento" setData={setData} before="Número ID" value={documento} /></Col>
        <Col as={FormGroup} md={5}><ControlText maxLength={200} error={error} update="correo" setData={setData} before="Correo Electrónico*" value={correo} /></Col>
        <Col as={FormGroup} md={3}><ControlText mask="9999-99999999" error={error} update="telefono" setData={setData} before="Teléfono*" value={telefono} /></Col>
        <Col as={FormGroup} md={4}><ControlDate error={error} update="nacimiento" setData={setData} before="Fecha de Nacimiento*" value={nacimiento} /></Col>
        <Col as={FormGroup} xs={12}>
            <FormLabel>DIRECCIÓN</FormLabel>
            <Row className="mb-1">
                <Col className="mt-1" md={3}><ContrySelector isInvalid={error.pais} defaultValue={pais} change={pais => setData({ pais })} /></Col>
                <Col className="mt-1" md={3}><StateSelector isInvalid={error.estado} pais={pais} defaultValue={estado} change={estado => setData({ estado })} /></Col>
                <Col className="mt-1" md={6}><ControlText maxLength={100} error={error} update="ciudad" setData={setData} before="Ciudad*" value={ciudad} /></Col>
            </Row>
            <ControlText maxLength={500} error={error} update="direccion" setData={setData} before="Dirección" value={direccion} />
        </Col>
        <Col as={FormGroup} md={12}><ControlText maxLength={200} error={error} update="Profesión" setData={setData} before="Profesión" value={Profesión} /></Col>
        <Col as={FormGroup} md={12}><ControlText maxLength={200} error={error} update="Ocupación" setData={setData} before="Ocupación" value={Ocupación} /></Col>
    </Row>
}
