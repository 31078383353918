import ColorProvider from "./Colores";
import DolarProvider from "./Dolar";
import EmpresaProvider from "./Empresa";
import MenuProvider from "./MenuContext";
import UsersProvider from "./UserContex";


export default function TemplateProvider({ children }: { children: any }) {
    return <ColorProvider><DolarProvider><MenuProvider><UsersProvider><EmpresaProvider>
        {children}
    </EmpresaProvider></UsersProvider></MenuProvider></DolarProvider></ColorProvider>
}