import { useContext } from "react";
import { Route } from "react-router-dom";
import { UnAutorization } from "@victorequena22/component-bootstrap";
import { SeccionContext, SeccionType } from "./Seccion";


export function Ruta({ component, path, permiso, exact }: { exact?: boolean, component: any, path: string | string[], permiso: string }) {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => p === permiso))
        return <Route exact={exact} path={path} component={component} />
    return <Route exact={exact} path={path} component={UnAutorization} />

}