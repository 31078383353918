import { Panel } from './Panel';
import { Config } from './Config';
import { StateS } from '@/App/Panel/ProtoPanes/Single';
import { Tempate } from '@/App/Panel/ProtoPanes/Tempate';
export interface S {
    img: string;
    background: string;
    backCard: string;
    TextCard: string;

}
export type State = StateS & S;
export class Programas extends Tempate<S> {
    titulo = 'EDITAR SECCION DE BUSCADOR DE PROGRAMAS';
    data = 'programas';
    randon = Math.random();
    constructor(p: any) {
        super(p);
        this.init({ img: '', background: '#f0f0f0', backCard: '#fff',TextCard:'#007bce' } as State);
    }
    Config = () => <Config save={this.guardar} setConfig={this.setData} config={this.state} />
    Panel = () => <Panel config={this.state} setData={this.setData} />
}