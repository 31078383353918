import { useContext } from "react"
import { ButtonGroup, Card, Col, Row } from "react-bootstrap"
import { Link, Route } from "react-router-dom"
import { MenuIten } from "../../../Components/Contens"
import { Ruta } from "../../../Template/Ruta"
import { SeccionContext, SeccionType } from "../../../Template/Seccion"
import ListAval from "./Parametros/Aval"
import ListaCertificados from "./Parametros/Certificado"
import ListaConsideracion from "./Parametros/Consideracion"
import EditConsideracion from "./Parametros/Consideracion/Edit"
import NuevaConsideracion from "./Parametros/Consideracion/Nuevo"
import ListaNormas from "./Parametros/Normas"
import EditNormas from "./Parametros/Normas/Edit"
import NuevaNormas from "./Parametros/Normas/Nuevo"
import Tags from "./Parametros/Tags"
export default function ConfigEvento() {
    const { data: { Permisos: p } } = useContext(SeccionContext) as SeccionType;
    return <Row style={{ minHeight: "calc(100vh - 145px)" }}>
        <Col md={3} style={{ minHeight: "calc(100vh - 145px)" }}>
            <Card>
                <Card.Header className='bg-info'>
                    <h6>CONFIGURACIONES DE EVENTOS</h6>
                </Card.Header>
                <ButtonGroup vertical style={{ width: '100%' }}>
                    <MenuIten p={p} to='/evento/config/normas' permiso='EVE_NORMAS'>NORMAS</MenuIten>
                    <MenuIten p={p} to='/evento/config/consideraciones' permiso='EVE_CONSIDERACIONES'>CONSIDERACIONES</MenuIten>
                    <MenuIten p={p} to='/evento/config/Aval' permiso='EVE_AVAL'>AVALES</MenuIten>
                    <MenuIten p={p} to='/evento/config/certificado' permiso='EVE_CERTIFICADOS'>CERTIFICADOS</MenuIten>
                    <Link className='btn btn-default' to='/evento/config/tags'>ETIQUETAS</Link>
                </ButtonGroup>
            </Card>
        </Col>
        <Col md={9} style={{ minHeight: "calc(100vh - 145px)" }}>
            <Ruta path="/evento/config/Aval" permiso='EVE_AVAL' component={ListAval} />
            <Ruta path="/evento/config/normas" permiso='EVE_NORMAS' component={ListaNormas} />
            <Ruta path="/evento/config/norma/nueva" permiso='EVE_NORMAS' component={NuevaNormas} />
            <Ruta path="/evento/config/norma/edit/:id" permiso='EVE_NORMAS' component={EditNormas} />
            <Ruta path="/evento/config/consideraciones" permiso='EVE_CONSIDERACIONES' component={ListaConsideracion} />
            <Ruta path="/evento/config/consideracion/nueva" permiso='EVE_CONSIDERACIONES' component={NuevaConsideracion} />
            <Ruta path="/evento/config/consideracion/edit/:id" permiso='EVE_CONSIDERACIONES' component={EditConsideracion} />
            <Ruta path="/evento/config/certificado" permiso='EVE_CERTIFICADOS' component={ListaCertificados} />
            <Route path="/evento/config/tags" component={Tags} />
        </Col>
    </Row>
}