/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Button, Row, Modal, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { InputText } from "@victorequena22/component-bootstrap";
import { Paginador } from "@victorequena22/tabla-bootstrap";
import { Herencia, NuevoTag, SelecMe, Selected, setData } from './BotonesTags';
interface Props {
    tags: number[];
    herencia?: number[];
    open: boolean;
    setOpen: (open: boolean) => void;
    setTags: (tags: number[]) => void;
    save: () => void;
}
export default function Tags({ herencia = [], tags, open, setOpen, save, setTags }: Props) {
    const [lista, setLista] = useState<any[]>([]);
    const [buscar, setBuscar] = useState('');
    const getList = useCallback((values: number[]) => lista.filter(l => values.some(p => p === l.id)), [lista])
    const herencias = useMemo(() => getList(herencia).map(l => <Herencia p={l} />), [herencia, lista]);
    const listaFilter = useMemo(() => lista.filter(i => !([...herencia, ...tags].some((t) => t === i.id))), [herencia, tags, lista]);
    const actualizarLista = useCallback(() => setData(setLista), []);
    useEffect(() => { actualizarLista(); }, [open]);
    return <>
        <Modal size="xl" animation centered show={open} onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                    SELECIONAR ETIQUETAS
                </h6>
            </Modal.Header>
            <Modal.Body>
                <ReactTooltip key={`tooltiptag${tags.join('')}`} />
                <Row>
                    <Col md={12}>
                        <Row className="mb-1 pl-2 pr-2">
                            <Row style={{ width: '240px' }} className="mr-auto pb-1 pt-1">
                                <small className='m-auto'>BUSCAR ETIQUETAS</small>
                                <InputText className='form-control-sm' style={{ width: '120px' }} maxLength={20} upper setData={setBuscar} value={buscar} /><br />
                            </Row>
                            <NuevoTag actualizar={actualizarLista} />
                        </Row>
                        <Row style={{ height: '300px', overflowY: 'auto' }}>
                            {herencias}
                            {useMemo(() => getList(tags)
                                .map((l) => <Selected p={l} click={id => setTags(tags.filter(p => p !== id))} />), [lista, tags])}
                            <Paginador row={(p) => <SelecMe click={id => setTags([...tags, id])} p={p} />}
                                mostrar={30} itens={listaFilter.filter(i => {
                                    const en = buscar.split(" ");
                                    const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
                                    return en.length === con.length;
                                })} />
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup className="float-right">
                    <Button onClick={() => setOpen(false)} variant="danger">
                        <span className="fa fa-remove-circle"></span> Cancelar
                    </Button>
                    <Button onClick={() => { save(); setOpen(false) }} variant="success">
                        <span className="fa fa-check-circle-o"></span> Aceptar
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal >
        <div className="w-100">
            <Row as={Col}>
                <label className="mt-1">ETIQUETAS</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            <Col className="w-100 h-90 mt-1" style={{ minHeight: '50px', borderTop: 'solid 1px #ced4da' }}>
                {herencias}
                {useMemo(() => getList(tags).map((l) => <Selected p={l} click={() => { }} />), [lista, tags])}
            </Col>
        </div>
    </>
}
