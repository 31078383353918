import { Paralax1 } from '../EditPanel/Paralax1';
import { Paralax2 } from '../EditPanel/Paralax2';
import { Video } from '../EditPanel/Video';
import { Galeria } from '../EditPanel/Galeria';
import { Carrusel } from '../EditPanel/Carrusel';
import { Cargos } from '../EditPanel/Cargos';
import { Servicio } from '../EditPanel/Servicio';
import { Servicio2 } from '../EditPanel/Servicio2';
import { Preguntas } from '../EditPanel/Preguntas';
import { Title } from '../EditPanel/Title';
import { Imagen } from '../EditPanel/Imagen';
import { Pestanas } from '../EditPanel/Pestanas';
import { Mapa } from '../EditPanel/Mapa';
import { Suscribir } from '../EditPanel/Suscribir';
import { Panel } from '../Models';
import { Cargando } from '@victorequena22/component-bootstrap';
import { Contact } from '../EditPanel/Contact';
import { ImagenSola } from '../EditPanel/ImagenSola';
import { Testimonio } from '../EditPanel/Testimonio';
import { BannerImagen } from '../EditPanel/BannerImagen';
import { CardText } from '../EditPanel/CardText';
import { CarruselCardText } from '../EditPanel/CarruselCardText';
import { CardCargos } from '../EditPanel/CardCargos';
export default function CopiarPanel({ panel: p, props }: { panel: Panel, props?: any }) {
    switch (p.type) {
        case 'cargos': return <Cargos key={'panel' + p.id} copy={p} props={props} />
        case 'carrusel': return <Carrusel key={'panel' + p.id} copy={p} props={props} />
        case 'galeria': return <Galeria key={'panel' + p.id} copy={p} props={props} />
        case 'imagen': return <Imagen key={'panel' + p.id} copy={p} props={props} />
        case 'imagenSola': return <ImagenSola key={'panel' + p.id} copy={p} props={props} />
        case 'pestanas': return <Pestanas key={'panel' + p.id} copy={p} props={props} />
        case 'mapa': return <Mapa key={'panel' + p.id} copy={p} props={props} />
        case 'paralax1': return <Paralax1 key={'panel' + p.id} copy={p} props={props} />
        case 'paralax2': return <Paralax2 key={'panel' + p.id} copy={p} props={props} />
        case 'preguntas': return <Preguntas key={'panel' + p.id} copy={p} props={props} />
        case 'servivio1': return <Servicio key={'panel' + p.id} copy={p} props={props} />
        case 'servivio2': return <Servicio2 key={'panel' + p.id} copy={p} props={props} />
        case 'suscribir': return <Suscribir key={'panel' + p.id} copy={p} props={props} />
        case 'titulo': return <Title key={'panel' + p.id} copy={p} props={props} />
        case 'video': return <Video key={'panel' + p.id} copy={p} props={props} />
        case 'contacto': return <Contact key={'panel' + p.id} copy={p} props={props} />
        case 'testimonio': return <Testimonio key={'panel' + p.id} copy={p} props={props} />
        case 'BannerIMG': return <BannerImagen key={'panel' + p.id} copy={p} props={props} />
        case 'CardText': return <CardText key={'panel' + p.id} copy={p} props={props} />
        case 'cargosCT': return <CarruselCardText key={'panel' + p.id} copy={p} props={props} />
        case 'CardCargos': return <CardCargos key={'panel' + p.id} copy={p} props={props} />
        default: return <Cargando />
    }
}