
export const getPreview = () => {
    return (document.getElementById('preview-certificado') as HTMLDivElement).getBoundingClientRect();
}
export const getIten = (id: string) => {
    return (document.getElementById(id) as HTMLDivElement).getBoundingClientRect();
}
export const getTop = (id: string) => {
    const p = getPreview();
    const q = getIten(id);
    const w = p.height - q.height;
    const top = q.y - p.y;
    if (top < 0) return 0;
    if (top > w) return w;
    return Math.ceil(top);
}
export const getLeft = (id: string) => {
    const p = getPreview();
    const q = getIten(id);
    const w = p.width - q.width;
    const left = q.x - p.x;
    if (left < 0) return 0;
    if (left > w) return w;
    return Math.ceil(left);
}
export const getPosiccion = (id: string) => ({ top: getTop(id), left: getLeft(id) });
export const getTopCenter = (id: string) => {
    const s = scroollBefore();
    const q = getIten(id);
    const c = getIten('container-certificado');
    const w = ((c.height - q.height) / 2) + s;
    // const top = q.y - p.y;
    return Math.ceil(w);
}
export const getLeftCenter = (id: string) => {
    const p = getPreview();
    const q = getIten(id);
    const w = (p.width - q.width) / 2;
    return Math.ceil(w);
}
export const scroollBefore = () => {
    const c = document.getElementById('container-certificado');
    if (c !== null) return c.scrollTop;
    return 0;
}
export const scroollAfter = (scroll: number) => {
    const c = document.getElementById('container-certificado');
    if (c !== null) c.scrollTo(0, scroll);;

}
export const getCenter = (id: string) => ({ top: getTopCenter(id), left: getLeftCenter(id) });



