import { conConfig } from '@victorequena22/conneccion';
import { useState, createContext, useEffect, useContext } from 'react';
export interface EmpresaType {
    nombre: string
    numero_fiscal: string
    direccion: string
    telefono: string
    correo: string
    web: string
    pago: string
    logo: string
    eslogan: string
    correos: { visible: boolean, nombre: string, correo: string, }[],
    telefonos: { nombre: string, numero: [string, string, string], visible: boolean }[]
}
const EmpresaNull: EmpresaType = {
    nombre: "",
    numero_fiscal: "",
    direccion: "",
    telefono: "",
    correo: "",
    web: "",
    pago: "",
    logo: "",
    eslogan: "",
    correos: [],
    telefonos: []
}
export const EmpresaContext = createContext<EmpresaType>(EmpresaNull)
export default function EmpresaProvider({ children }: { children: any }) {
    const [Empresa, setEmpresa] = useState<EmpresaType>(EmpresaNull);
    useEffect(() => { conConfig('empresa').pull((e) => setEmpresa(e)) }, []);
    return <EmpresaContext.Provider value={Empresa}>{children}</EmpresaContext.Provider>
}
export const EmpresaConsumer = EmpresaContext.Consumer;
export function useEmpresa() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useContext(EmpresaContext);
}
