import { getBlockProps, setProps, toggleMark } from '@victorequena22/slate-bootstrap'
import isHotkey from 'is-hotkey'

type hkeyM = 'ctrl+b' | 'ctrl+i' | 'ctrl+u'
type hkeyE = 'tab' | 'shift+tab' | 'Enter'
const hotKeysMarks = {
  'ctrl+b': 'bold',
  'ctrl+i': 'italic',
  'ctrl+u': 'underline',
}
const hotKeyElement = {
  tab: 'indent', 'shift+tab': 'outdent',
  Enter: 'type'
}
export const hotKeyDown = (editor: any, event: any) => {
  const [t, s] = getBlock(editor, 'tab');
  for (const hotkey in hotKeysMarks) {
    if (isHotkey(hotkey, event as any)) {
      event.preventDefault()
      const mark = hotKeysMarks[hotkey as hkeyM]
      toggleMark(editor, mark)
    }
  }
  for (const hotkey in hotKeyElement) {
    if (isHotkey(hotkey, event as any)) {
      const props = hotKeyElement[hotkey as hkeyE]
      if (props === 'indent' || props === 'outdent') {
        event.preventDefault();
        if (props === 'indent') s(t + 1); else s(t - 1);
      }
    }
  }
}
export const hotKeyUp = (editor: any, event: any) => {
  const [t, s] = getBlock(editor, 'type');
  for (const hotkey in hotKeyElement) {
    if (isHotkey(hotkey, event as any)) {
      const props = hotKeyElement[hotkey as hkeyE]
      if (props === 'type') {
        if (t === 'button') s('paragraph');
      }
    }
  }
}
const getBlock = (editor: any, props: string) => {
  const v = getBlockProps(editor, props);
  const s = (s: any) => setProps(editor, props, s);
  return [v, s];
}