import { Row, Container, Card, Image } from 'react-bootstrap';
import Slider from "react-slick";
import { css } from '@emotion/css';
import { getImagenURL } from '@victorequena22/conneccion';
import { getSettingCarrusel } from '../Component/Commons/Carrusel';

export function BannerImagen({ data: { itens, props }, size }: any) {
    const { color, inter, up, down } = props;
    const { length: l } = itens;
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    let settings = getSettingCarrusel(l, s2.some(s => size === s) ? 5 : s1.some(s => size === s) ? 4 : 3);
    return <Row>
        <div className='w-100 p-0' style={{ height: '3rem', background: up, marginBottom: '-2.5rem' }} />
        <Container className='p-0'>
            <Card className={getCSS(inter)} style={{ background: color, padding: '.5rem 2rem 1.5rem 2rem' }}>
                <Slider {...settings}>
                    {itens.map(({ props: i }: any) => <div>
                        <div>
                            <Image style={{ height: '3rem' }} className={`m-auto ${img}`} src={getImagenURL(i.img)} alt='banner' />
                        </div>
                    </div>)}
                </Slider>
            </Card>
        </Container>
        <div className='w-100 p-0' style={{ height: '3rem', background: down, marginTop: '-2.5rem' }} />
    </Row>
}
function getCSS(color: string) {
    return css`
    margin: 0 0 0 0;
    button: before{color: ${color} !important;}
    `;
}
const img = css`
    &{
        filter: grayscale(1);
        &:hover{ 
            filter: grayscale(0);
        }
    } 
`;