import { Button } from 'react-bootstrap';
import { TitleCard, CardConfig, RowConfig } from '../../Panel/Component';
import { Link } from "react-router-dom";
import { ContentPermisoSome } from '@/Components/Button/Content';
export default function MenuSecciones() {
    return <ContentPermisoSome permiso={['EVENTO', 'PROGRAMA']}>
        <CardConfig className='ml-1' width='190px'>
            <RowConfig>
                <ContentPermisoSome permiso='EVENTO'>
                    <Button data-place='bottom' variant='outline-dark'
                        size='sm' as={Link} to='/panel/template/eventos'
                        data-tip='EDITAR SECCION DE BUSCADOR DE EVENTOS'>
                        EVENTOS
                    </Button>
                </ContentPermisoSome>
                <ContentPermisoSome permiso='PROGRAMA'>
                    <Button data-place='bottom' size='sm' variant='outline-dark'
                        className='ml-1' as={Link} to='/panel/template/programas'
                        data-tip='EDITAR SECCION DE BUSCADOR DE PROGRAMAS'>
                        PROGRAMAS
                    </Button>
                </ContentPermisoSome>
            </RowConfig>
            <TitleCard>SECCIONES</TitleCard>
        </CardConfig>
    </ContentPermisoSome>
}