/* eslint-disable react-hooks/exhaustive-deps */
import { sizeWidth } from '@/App/Panel/Component';
import { css } from '@emotion/css';
export const getMenu = (w: sizeWidth) => {
    switch (w) {
        case '570px': return 12;
        case '780px': return 12;
        case '990px': return 3;
        default: return 2;
    }
}
export const getData = (w: sizeWidth) => {
    switch (w) {
        case '570px': return 12;
        case '780px': return 12;
        case '990px': return 9;
        default: return 10;
    }
}
export const getBTN = (color: string) => css`
    .btn{
        color: ${color};
        border: ${color} 2px solid  !important;
        background-color: transparent;
        &.active, &:hover {
            background: ${color}  !important;
            color: ${getBackWhite(color)} !important;
        }
    }
`;
const colorRGB = (color: string) => {
    if (color.includes('rgb')) {
        const b = color.match(/\d+/g)?.map(Number);
        return b ? b : [0, 0, 0];
    } else if (color.includes('#')) {
        const b = color.slice(1).match(/.{2}/g)?.map(x => parseInt(x, 16));
        return b ? b : [0, 0, 0];
    } else {
        return [0, 0, 0];
    }
}
const getBackWhite = (color: string) => {
    const [r, g, b] = colorRGB(color);
    const r1 = Math.round(r / 255);
    const g1 = Math.round(g / 255);
    const b1 = Math.round(b / 255);
    if ((r1 + g1 + b1) > 1) return 'rgb(0,0,0)';
    else return 'rgb(255,255,255)';
}

