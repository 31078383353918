import { useEvento } from "../../index";
import { useMemo } from "react";
import Torta2 from "@/Components/Graficos/Torta2";
import { getParticipantes } from "@/App/Capacitacion/Context/Participante";
import { Row } from "react-bootstrap";
export function Ciudad() {
    const participantes = getParticipantes();
    const lista = useEvento().participantes;
    const par = useMemo(() => participantes.filter(p => lista.some(e => e.id === p.id)), [participantes, lista])
    const values = useMemo(() => {
        const values: [string, number][] = [];
        par.forEach(e => {
            const ciudad = e.ciudad.trimStart().trimEnd().toUpperCase();
            const i = values.findIndex(x => x[0] === ciudad);
            if (i > -1) values[i][1]++;
            else values.push([ciudad, 1]);
        })
        return values;
    }, [par])
    return <Row>
        <div style={{ textAlign: 'center' }}>
            <h5>CIUDAD</h5>
            <Torta2 labels={['ESTADOS', 'CANT']} data={values} />
        </div>
    </Row>
}
