import { Tooltip } from "@mui/material";
import { Tabla } from "@victorequena22/tabla-bootstrap";
import { useContext } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { ProgramaContext } from "@/App/Capacitacion/Context/Programas";
import { CapacitacionFila } from "@/App/Capacitacion/Programas/Models";
import { FieldText } from "@/Components/Field";

export default function ProgramasAprobados() {
    const { programas } = useContext(ProgramaContext);
    return <TablaProgramasAprobados programas={programas} />
}
class TablaProgramasAprobados extends Tabla<{ programas: CapacitacionFila[] }> {
    buscarEn = ['referencia', 'banco', 'monto', 'nota', 'fecha']
    fechas = ['fecha']

    Row = (d: any) => {
        const { state: { by, buscar, orden } } = this
        return <FilaPago pago={d} key={`pagos${d.id}${d.estatus}${by}${orden}${buscar}`} />
    }
    Header() {
        let { Th } = this;
        return (<tr>
            <Th width='auto' label='nombre'>Nombre</Th>
            <Th width='80px'> </Th>
        </tr>)
    }
    render() {
        const { Body } = this
        return <Row>
            <Col md={12}>
                <h1 className='w-100 text-primary'>Programas aprobados por @NombreUsuario</h1>
            </Col>
            <Col md={12} className='mt-2'>
                <FieldText variant="outlined" endAdornment={<i className="fas fa-search" />} size="small" label="Buscar" upper setData={buscar => this.setState({ buscar })} update='buscar' value={this.state.buscar} />
            </Col>
            <Col md={12} className='mt-4'><Body /></Col>
        </Row>

    }
    componentDidMount() {
        const { programas } = this.props;
        this.setState({ by: 'id', itens: programas });
    }
}

function FilaPago({ pago: { nombre } }: { pago: CapacitacionFila }) {
    return <tr>
        <td>{nombre}</td>
        <td>
            <Tooltip title="Ver los recursos" placement="top">
                <Button><i className="fas fa-file-certificate" /> VER RECURSOS</Button>
            </Tooltip>
        </td>
    </tr>
}


