import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { conNorma } from '../../Models';
interface Props {
    change: (r: number) => void,
    defaultValue: number,
    width?: string
    tipo?: 'TODOS LAS NORMAS' | 'SELECCIONAR NORMA'
    isInvalid?: boolean
    className?: string
}
export default function NormaSelector({ className, change, defaultValue, width, tipo = 'SELECCIONAR NORMA', isInvalid }: Props) {
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(parseInt(e.currentTarget.value));
    const [normas, setNormas] = useState<any[]>([])
    const actualizarNormas = useCallback(() => { conNorma().lista(setNormas) }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => actualizarNormas(), []);
    return <InputGroup className={className}>
        <InputGroup.Prepend><InputGroup.Text>NORMA</InputGroup.Text></InputGroup.Prepend>
        <FormControl onClick={actualizarNormas} as='select' style={{ width }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
            <option value='0'>{tipo}</option>
            {normas.map((c, i) => <option key={i} value={c.id}>{c.nombre}</option>)}
        </FormControl>
    </InputGroup>
}