import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Pago } from "../../../Pagos/Models";
import ListaPagos from "../../../Pagos/Ver/Lista";
import VerPago from "../../../Pagos/Ver/Ver";
import { setPagosList } from "./index";
import { MobileUserAgent } from "@victorequena22/utiles";
interface Props {
    pagos: Pago[]
    open: boolean
    participante: string
    close: () => void
}
export default function VerPagos({ participante, pagos, open, close }: Props) {
    const [pago, setPago] = useState(0)
    const p = pagos.find(b => b.id === pago);
    let className = '';
    if (MobileUserAgent()) {
        className = 'super-modal-movil';
    }
    return <Modal className={className} show={open} size='xl' centered onHide={() => { close(); setPago(0); }}>
        <Modal.Header closeButton>
            <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>PAGOS DE {participante}</b></span>
            </h2>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}>
                    {p ? <VerPago update={setPagosList} pago={p} select={setPago} /> : <ListaPagos pagos={pagos} select={setPago} />}
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}