/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Container, Row } from 'react-bootstrap';
import { State } from '.';
import { useEventos } from '@/App/Capacitacion/Context/Eventos';
import { getImagenURL } from '@victorequena22/conneccion';
import { EditArea } from '@/App/Panel/Component/SlateCustom';
export function Panel({ config: { background, backCard, TextCard } }: { config: State, setData: (s: Partial<State>) => void }) {
    const e = useEventos();
    return <Row style={{ background }}><Container>
        <EditArea className="pl-5 pt-3" />
        <Row className="p-3 list-main col-12">
            {e.filter(e => e.publicacion && e.estatus && e.estatus < 3)
                .map(c => <Card key={`buscarEvento${c.codigo}`}
                    style={{ background: backCard, color: TextCard }}>
                    <Card.Img src={getImagenURL(c.short)} />
                    <Card.Body>{c.nombre}</Card.Body>
                </Card>)}
        </Row>
    </Container></Row>
}