import { Button, ButtonGroup } from 'react-bootstrap';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { CertificadoFila } from '../../Evento/Models/Model';
import { findParticipanteFila } from '../../Context/Participante';
import { useState } from 'react';
import { ParticipanteFila } from '../../Participantes/Models';
import { ModalReport } from '@/Components/Modals';
import UploadCertificado from '../../Evento/GestionarEvento/Certificados/Tabla/Fila/UploadCertificado';
import { don } from '@victorequena22/conneccion';
import { ListButonClick, ListButonLink } from '@/Components/Button/ListBoton';
import EditarParticipante from '../../Participantes/Editar';
interface Props {
    data: CertificadoFila
}
export default function Fila({ data }: Props) {
    const { codigo, participante, evento } = data;
    const n = findParticipanteFila(participante)
    const [edit, setEdit] = useState(false);
    return <tr>
        <THFila width='300px'>{codigo}</THFila>
        <THFila width='auto'>{n.nombre}</THFila>
        {/* <THFila width='100px' align='right'>{horas}</THFila> */}
        {/* <THFila width='100px'><span className={`badge badge-${span[e]}`}>{text[e]}</span></THFila> */}
        <th style={{ minWidth: "170px", maxWidth: "170px" }}>
            {edit ? <EditarParticipante open={edit} title={`EDITAR ${n.nombre}`} participante={n} close={() => setEdit(false)} add={() => { }} /> : <></>}
            <ButtonGroup className="float-right">
                <CertificadoCP c={data} n={n} />
                <ListButonClick permiso='PAR_CREAR' tip={`EDITAR ${n.nombre}`} variant="warning" click={() => setEdit(true)}>
                    <i className="fas fa-user" />
                </ListButonClick>
                <ListButonLink permiso='EVENTO' tip='IR EVENTO' variant="info" to={'/evento/ver/' + evento} >
                    <i className="fas fa-file-certificate" />
                </ListButonLink>
            </ButtonGroup>
        </th>
    </tr >
}

function CertificadoCP({ c, n }: { c: CertificadoFila, n: ParticipanteFila }) {
    const [open, set] = useState(false);
    return ((c.certificado === -1) === c.subido) ? <>
        <ModalReport url={`${don}/api/Evento/Certificados/pdf/certificado/${c.codigo}`}
            title={"CERTIFICADO "} open={open} close={() => set(false)} />
        <Button onClick={() => set(true)} variant={`outline-success`}><i className="fa fa-eye" /></Button>
    </> : <UploadCertificado codigo={c.codigo} user={n} />
} 