import { CardConfig, RowConfig, TitleCard } from "@victorequena22/component-bootstrap"
import { ButtonProps, ButtonSelect } from "./Boton"
import { AlingMenu, BackButton, ColorButton, FontStyle, ListMenu, MarkMenu, TabsMenu, TypeMenu } from "@victorequena22/slate-bootstrap"
import { ButtonGroup } from "react-bootstrap"
import { getColors } from "@/Template/Colores"
import { Inserts } from "@/App/Panel/Component/SlateCustom"

export const TBElement = () => {
    const colors = getColors()
    return <>
        <CardConfig width='175px' className="m-0">
            <RowConfig>
                <MarkMenu />
                <ButtonGroup className='ml-2'>
                    <ColorButton left='0' colores={colors} />
                    <BackButton left='0' colores={colors} />
                </ButtonGroup>
            </RowConfig>
            <RowConfig top={2}>
                <FontStyle left={0} />
                {/* <Inserts /> */}
            </RowConfig>
            <TitleCard>FUENTE Y TEXTO</TitleCard>
        </CardConfig>
        <CardConfig width='200px'>
            <RowConfig>
                <TabsMenu left={0} />
                <AlingMenu left={1} />
            </RowConfig>
            <RowConfig top={2}>
                <ListMenu left={0} />
                <TypeMenu left={1} />
            </RowConfig>
            <TitleCard>PARRAFO Y BLOQUE</TitleCard>
        </CardConfig>
        <CardConfig width='50px'>
            <RowConfig>
                <ButtonSelect />
            </RowConfig>
            <RowConfig top={2}>
                <Inserts />
            </RowConfig>
            <TitleCard>ESPECIALES</TitleCard>
        </CardConfig>
        <ButtonProps />
    </>
}