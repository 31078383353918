import { sizeWidth } from "@/App/Panel/Component"
import { css } from "@emotion/css"

export const cols = (s: sizeWidth, img: boolean) => {
    if (img) switch (s) {
        case '570px': case '780px': return { r: 12, l: 12, i: 10, t: 12, h: false }
        case '990px': case '1080px': return { r: 7, l: 5, i: 0, t: 12, h: true }
        case '1320px': case '1440px': return { r: 8, l: 4, i: 6, t: 6, h: true }
    }
    switch (s) {
        case '570px': case '780px': return { r: 12, l: 12, i: 0, t: 12, h: false }
        case '990px': case '1080px': return { r: 7, l: 5, i: 0, t: 12, h: true }
        case '1320px': case '1440px': return { r: 8, l: 4, i: 0, t: 12, h: true }
    }
}
export function getCSS({ colorFF, colorFC, colorBC, colorTC, colorBT, fontC, fontB }: any) {
    return css`
    background: ${colorFF};
    &>.row{
        color: ${colorTC} !important;
        &>.input{
            &>.form-control{
                color: ${colorTC} !important;
                font-family: ${fontC} !important;
                border-color: ${colorTC} !important;
            }
            &>.MuiFormControl-root{
                &>.MuiInputBase-root{
                    &>.MuiOutlinedInput-notchedOutline{
                        border-color: ${colorTC} !important;
                    }
                    &>fieldset>legend{
                        display:none!important;
                    }
                    &>fieldset{
                        background: ${colorFC} !important;
                    }
                    &>input,
                    &>select,
                    &>select>option{
                        z-index: 1;
                        background: transparent !important;
                        color: ${colorTC} !important;
                        font-family: ${fontC} !important;
                        border-color: ${colorTC} !important;
                        font-weight: bold;
                    }
                }
                &>.MuiFormLabel-root{
                    background-color: transparent !important;
                    font-weight: bold;
                }
                &>.MuiInputLabel-shrink{
                    z-index: 2;
                    transform: translateX(10px) scale(0.5);
                }
                &>.Mui-error,{
                    color: #d32f2f !important;
                    &>.MuiOutlinedInput-notchedOutline{
                            border-color: #d32f2f !important;
                    }
                }
            }
            &>.MuiBox-root{
                background: ${colorFC} !important;
                border: ${colorTC} solid 1px !important;
                margin-top: -5px;
                padding-top: 5px;
                padding-left: 5px;
                border-radius: 4px;
                &>span{
                    z-index: 1;
                    padding-top: 8px;
                    width: 45px;
                    background: transparent !important;
                    color: ${colorTC} !important;
                    font-family: ${fontC} !important;
                    font-weight: bold;
                }
                &>.MuiFormControl-root{
                    &>.MuiInputBase-root{
                        &>.MuiOutlinedInput-notchedOutline{
                            border-color: ${colorTC} !important;
                            font-weight: bold;
                        }
                        &>fieldset{
                            display:none!important;
                        }
                        &>input,
                        &>select,
                        &>select>option{
                            z-index: 1;
                            padding-left: 0;
                            background: transparent !important;
                            color: ${colorTC} !important;
                            font-family: ${fontC} !important;
                            font-weight: bold;
                        }
                    }
                    &>.MuiFormLabel-root{
                        margin-left: -51px;
                        background: ${colorFC} !important;
                        font-weight: bold;
                    }
                    &>.MuiInputLabel-shrink{
                        z-index: 2;
                        background-color: transparent !important;
                        transform: translateX(10px) scale(0.5);
                    }
                }
            }
            &>.MuiFormHelperText-root{
                color: #d32f2f !important;
                padding-left: 15px;
                &>.MuiOutlinedInput-notchedOutline{
                        border-color: #d32f2f !important;
                }
            }
        }
        &>.text{
            color: ${colorTC} !important;
            font-family: ${fontC} !important;
            font-weight: bold;
        }
        &>.boton{
            &>.btn {
                background: ${colorBC};
                border-color: ${colorBC};
                color: ${colorBT};
                font-family: ${fontB} !important;
            }
            &>.btn:hover {
                opacity: .7 !important;
            }
            &>.btn:active {
                opacity: 1 !important;
            }
        }
    }`;
}