import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import Fila from "./Fila";
import { Col } from "react-bootstrap";
import { IE } from "../../../../../../Config/Updater";
import { ParticipanteFila } from "../../../../Participantes/Models";

export default class Tabla extends CardBuscador<{ itens: ParticipanteFila[], estatus: number }> {
    buscarEn = ['nombre', 'correo', 'telefono'];
    render() {
        let { Body, Buscador, } = this;
        return <><Col md={12}><Buscador /></Col><Body /></>
    }
    Row = (d: ParticipanteFila) => <Fila key={IE(d) + 'participante'} data={d} />
    Header() {
        let { Th } = this;
        const { estatus } = this.props;
        const evaluar = this.getItens().filter(p => p.status === 2)
        return (<tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='300px' label='correo'>CORREO</Th>
            <Th width='200px' label='telefono'>TELEFONO</Th>
            <Th width='200px'>ESTATUS</Th>
            {(evaluar.length === 0 && estatus === 2) ? <Th width='150px'>ACCIONES</Th> : <></>}
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}


