import { useContext, useEffect } from 'react';
import { FilaCategoria } from './Fila';
import ReactTooltip from 'react-tooltip';
import NuevoCategoria from './Nuevo';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { Categoria, conCategoria } from './Models';
import { InputText } from "@victorequena22/component-bootstrap";
import { Link } from 'react-router-dom';
import { CategoriasContext } from '../../Context/Categorias';
import { IEL, IE } from '../../../../Config/Updater';

export default function ListCategorias() {
    const { Categorias, actualizar } = useContext(CategoriasContext)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { actualizar() }, [])
    console.log(Categorias)
    return <Tabla key={IEL(Categorias) + 'categorias'} itens={Categorias} />
}
class Tabla extends CardBuscador<{ itens: Categoria[] }>{
    con = conCategoria();
    mostrar = 6;
    placeholder = "BUSCAR CATEGORÍA";
    estatus = [{ value: 0, label: 'CANCELADO' }, { value: 1, label: 'SIN FACTURA' }, { value: 2, label: 'FACTURADA' }]; buscarEn = ["id", "nombre"];
    Buscador() {
        return <>
            <ReactTooltip />
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUSCAR
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText maxLength={150} upper setData={this.setState} update='buscar' value={this.state.buscar} />
                        <InputGroup.Append>
                            <Button variant="primary"><i className="fa fa-search"></i> BUSCAR</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <NuevoCategoria update={this.set.bind(this)} />
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='id' width='100px'>IMAGEN</Th>
            <Th label='nombre' width='calc(100% - 250px)'>NOMBRE</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>
    }
    Row = (p: Categoria) => <FilaCategoria key={'cat' + IE(p)} d={p} update={this.set.bind(this)} />
    Title = () => <>
        <Link to="/programa" className="btn btn-primary mr-2">
            <span className="fa fa-arrow-circle-left"></span> VOLVER
        </Link>
        <h3><i className="fa fa-search"></i> BUSCAR CATEGORÍA</h3>
    </>
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}