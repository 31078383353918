import { useState } from 'react'
import { Row, Card, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { useLanding } from './context';
import { CardConfig, RowConfig, SizeViewConteiner, sizeWidth, TitleCard } from '@/App/Panel/Component';
import { MenuZise } from '@/App/Panel/Utiles';
import { Link } from 'react-router-dom';
import GestionLanding from './GestionLanding';
interface Props {
    url: string
    Preview: any
}
export function VerLanding({ url, Preview }: Props) {
    const { panels } = useLanding();
    const [size, setSize] = useState<sizeWidth>('990px');
    return <>
        <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
            <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
                <CardConfig width='37px'>
                    <RowConfig>
                        <Button data-place='bottom' data-tip='CREAR UN NUEVO PANEL' as={Link}
                            to={`${url}/crear`} size='sm' variant='success'>
                            <i className='fas fa-plus' />
                        </Button>
                    </RowConfig>
                    <TitleCard>*</TitleCard>
                </CardConfig>
                <MenuZise setSize={setSize} size={size} />
            </Row>
        </Card>
        <ReactTooltip key='tooltip' />
        <SizeViewConteiner h1='calc(100vh - 375px)' h2='calc(100vh - 375px)' size={size} >
            {panels.filter(p => p.indice > 0 && p.estado > -1)
                .sort((a, b) => a.indice - b.indice)
                .map(p => <div id={`panel${p.id}${p.indice}`} className='w-100 m-0 p-0'>
                    <GestionLanding panel={p} url={url} />
                    <Preview key={p.type + p.id} panel={p} size={size} />
                </div>)}
        </SizeViewConteiner>
    </>
}