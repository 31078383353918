import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../../Panel/ProtoPanes/Single';
export interface S {
    color: string;
    gap: number;
    filtroType: string;
    filtro: number
    inter: string;
}
export type State = StateS & S;
export class Eventos extends Single<S> {
    constructor(p: any) {
        super(p);
        this.init({ filtro: 0, color: '#f0f0f0', filtroType: 'none', gap: 5, inter: '#000000' });
    }
    Config = () => <Config
        save={this.save}
        key='config'
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        config={this.state} />
    Panel = () => <Panel key='panel' config={this.state} />
    getDataSave = () => {
        const { title, color, gap, filtroType, filtro, inter } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, gap, filtroType, filtro, inter });
        return { title, html: '', data, props, type: 'eventos' };
    }

}
