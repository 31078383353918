import { ButtonGroup, Row } from "react-bootstrap";
import { ListButonLink, ListButonClick } from "../../../../Components/Button/ListBoton";
import { useHistory, useLocation } from "react-router-dom";
import { useEvento } from ".";


export function MemuEvento() {
    const { id, estatus } = useEvento();
    return <ButtonGroup vertical className="float-right btn-sm btn-block">
        <ButtonLink permiso='EVENTO' variant="info" to={`/evento/ver/${id}`}>
            <i className="fa fa-eye mr-2" /> INFORMACIÓN DEL EVENTO
        </ButtonLink>
        <ButtonLink permiso='EVENTO' variant="success" to={`/evento/estadisticas/${id}/graficos`}>
            <i className="fa fa-chart-bar"></i> ESTADISTICAS DEL EVENTO
        </ButtonLink>
        <ButtonLink permiso='EVE_EDITAR' variant="primary" to={`/evento/edit/${id}`}>
            <i className="fa fa-edit mr-2" /> EDITAR EVENTO
        </ButtonLink>
        <ButtonLink permiso='EVE_PRECIO' variant="Navy" to={`/evento/pagos/${id}`}>
            <i className="fas fa-usd-square mr-2" /> INFORMACIÓN DE PAGOS
        </ButtonLink>
        {/* <ButtonLink permiso='EVE_PARTICIPANTES' variant="orange" to={`/evento/grupoPago/${id}`}>
            <i className="fas fa-usd-square mr-2" /> GRUPOS DE PAGOS
        </ButtonLink> */}
        {(estatus < 3 && estatus > 0) ? <ButtonLink permiso='EVE_PARTICIPANTES' variant="secondary" to={`/evento/gestionPagos/${id}`}>
            <i className="fas fa-tag mr-2" /> PARTICIPANTES INSCRITOS
        </ButtonLink> : <></>}
        <ButtonLink permiso='EVE_PARTICIPANTES' variant="danger" to={`/evento/inscribir/${id}`}>
            <i className="fas fa-tag mr-2" /> INSCRIBIR PARTICIPANTES
        </ButtonLink>
        <ButtonLink permiso='EVE_PARTICIPANTES' variant="purple" to={`/evento/participantes/${id}`}>
            <i className="fa fa-users-class mr-2" /> INICIAR EVENTO
        </ButtonLink>
        {estatus === 3 ? <ButtonLink permiso='EVE_PARTICIPANTES' variant="maroon" to={`/evento/certificados/${id}`}>
            <i className="fa fa-file-certificate mr-2" /> CERTIFICADOS
        </ButtonLink> : <></>}

    </ButtonGroup>
}
export function Title() {
    const history = useHistory();
    const { codigo, nombre } = useEvento();
    return <Row className="mb-2" >
        <ListButonClick className="mb-2 float-right" permiso='EVENTO' tip='VOLVER'
            variant="primary" click={() => history.goBack()}>
            <i className="fa fa-arrow-left" />
        </ListButonClick>
        <h3 className='text-primary ml-2'>{codigo}</h3>
        <h3 className='ml-2 mr-auto text-info'>{nombre}</h3>
    </Row>
}

function ButtonLink({ to, children, variant, permiso }: { permiso: string, to: string, variant: string, children: any }) {
    const { pathname } = useLocation()
    return <ListButonLink className="text-left" permiso={permiso}
        variant={`${to === pathname ? '' : 'outline-'}${variant}`} to={to}>
        {children}
    </ListButonLink>
}
