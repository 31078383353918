import { Aprobados, Asistencia, Excel } from "../../Ver/Exports";

export function Exports() {
    return <>
        <Asistencia />
        <Aprobados />
        <Excel />
    </>
}

