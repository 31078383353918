/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { State } from '../index';
import { getBTN, getData, getMenu } from './Props';
import Clave from "./Area/Clave";
import Datos from "./Area/Datos";
import Certificados from "./Area/Eventos";
import Pagos from "./Area/Pagos";
import Formaciones from "./Area/Formaciones";
import ProgramasAprobados from "./Area/ProgramasAprobados";
export function Panel({ config: { background, btn, select, size }, setData }: { config: State, setData: (s: Partial<State>) => void }) {
    const selected = (select: string) => setData({ select })
    return <Row style={{ background }}><Container>
        <Row className="p-3 col-12">
            <Col xs={getMenu(size)}>
                <ButtonGroup vertical className={`btn-block ${getBTN(btn)}`}>
                    <Button active={'datos' === select} onClick={() => selected('datos')}><b>Mis datos</b></Button>
                    <Button active={'clave' === select} onClick={() => selected('clave')}><b>Mi Contraseña</b></Button>
                    <Button active={'pagos' === select} onClick={() => selected('pagos')}><b>Pagos Realizados</b></Button>
                    <Button active={'formaciones' === select} onClick={() => selected('formaciones')}><b>Mis Formaciones</b></Button>
                    <Button active={'certicados' === select} onClick={() => selected('certicados')}><b>Mis Programas</b></Button>
                    <Button active={'programas' === select} onClick={() => selected('programas')}><b>Mis Certificados</b></Button>
                </ButtonGroup>
            </Col>
            <Col xs={getData(size)}>
                <GetArea select={select} />
            </Col>
        </Row>
    </Container></Row>
}

const GetArea = ({ select }: { select: string }) => {
    switch (select) {
        case "datos": return <Datos />
        case "clave": return <Clave />
        case "pagos": return <Pagos />
        case "formaciones": return <Formaciones />
        case "certicados": return <Certificados />
        case "programas": return <ProgramasAprobados />
    }
    return <></>
}