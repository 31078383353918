/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Container, Row } from 'react-bootstrap';
import { State } from '.';
import { getImagenURL } from '@victorequena22/conneccion';
import { EditArea } from '@/App/Panel/Component/SlateCustom';
import { useContext } from 'react';
import { ProgramaContext } from '@/App/Capacitacion/Context/Programas';
export function Panel({ config: { background, backCard, TextCard } }: { config: State, setData: (s: Partial<State>) => void }) {
    const { programas } = useContext(ProgramaContext);
    return <Row style={{ background }}><Container>
        <EditArea className="pl-5 pt-3" />
        <Row className="p-3 list-main col-12">
            {programas.filter(e => e.estatus === 2)
                .map(c => <Card key={`buscarPrograma${c.codigo}`} className='link-lista'
                    style={{ background: backCard, color: TextCard }}>
                    <Card.Img src={getImagenURL(c.short)} />
                    <Card.Body>{c.nombre}</Card.Body>
                </Card>)}
        </Row>
    </Container></Row>
}