import { useState, useCallback } from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { InputText } from "@victorequena22/component-bootstrap"
import { conAval, Costo } from "../../Models"
interface Props {
    d: Costo
    update?: () => void
}
const con = conAval();
export function FilaAval({ d: { nombre: n, costo, estatus, id }, update }: Props) {
    const [nombre, setNombre] = useState(n);
    const actualizar = useCallback(() => {
        if (nombre === '') {
            toast.success('DEBE COLOCAR UNA DESCRIPCION');
        } else {
            con.actualizar(id, { nombre, costo }, () => {
                if (update) update();
                toast.success('ACTUALIZADO EXITOSAMENTE');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre, costo]);
    const cancelar = useCallback(() => {
        con.cancelar(id, () => {
            if (update) update();
            toast.success('ACTUALIZADO EXITOSAMENTE');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre, costo])
    return <tr>
        <th style={{ width: "calc(100% - 170px)" }}>
            <ReactTooltip />
            <InputText setData={setNombre} value={nombre} />
        </th>
        <th style={{ width: "100px" }}>
            {estatus ? <span className="badge badge-success">ACTIVO</span> : <span className="badge badge-danger">INACTIVO</span>}
        </th>
        <th style={{ width: "70px" }}>
            <ButtonGroup className="float-right">
                <Button data-tip="GUARDAR CAMBIOS" variant="primary" onClick={actualizar}>
                    <i className="fa fa-save"></i>
                </Button>
                {(estatus === 1) ?
                    <Button data-tip="ELIMINAR" variant="danger" onClick={cancelar}>
                        <i className="fa fa-ban"></i></Button> :
                    <Button data-tip="ACTIVAR" variant="success" onClick={cancelar}>
                        <i className="fas fa-check"></i>
                    </Button>}
            </ButtonGroup>
        </th>
    </tr >
}