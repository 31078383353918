
import { ButtonColor, ButtonConfig, CardConfig, CardMain, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { State } from '../index'
import { Guardar, SizeViewSelector, Title } from '@/App/Panel/Component';
import { CropModal } from '@/App/Media/CropModal';
import { TBElement } from '@/App/Panel/Component/SlateCustom';
import Formulario from './Formulario';
interface Props {
    save: () => void,
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void
} 
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, img, backgroundColor } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='256px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='250px' />
            </RowConfig>
            <RowConfig top={2}>
                <CropModal aspect={1} img={img ? img : ''} setImg={(img) => setConfig({ img })} />
                {img ? <ButtonConfig className='ml-2' action={() => setConfig({ img: false })}
                    icon='far fa-eye-slash' tip='QUITAR IMAGEN' /> : <></>}
                <ButtonColor disableAlpha tip='COLOR DE FONDO DEL PANEL'
                    color={backgroundColor} className='ml-2' gradiend
                    setData={backgroundColor => setConfig({ backgroundColor })} >
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <Formulario config={config} setConfig={setConfig} />
        <TBElement />
        <SizeViewSelector setSize={size => setConfig({ size })} size={size} />
    </CardMain >
}