import { semaforo } from '@victorequena22/utiles';
import { CapacitacionFila } from './Models';
import { ConexionCompleta, don, setCambio } from '@victorequena22/conneccion';
type capRest = [number, string, number, string, string, number, string, number, number, any, number[]]

export function arrayToCapacitacion(f: capRest[]): CapacitacionFila[] {
    return f.map((a) => {
        return {
            id: a[0],
            codigo: a[1],
            categoria: a[2],
            nombre: a[3],
            descripcion: a[4],
            horas: a[5],
            short: a[6],
            estatus: a[7],
            usuario: a[8],
            lista: a[9] ? a[8] : {},
            tags: a[10] ? a[10] : [],
        }
    });
}
class CapacitacionConecction extends ConexionCompleta {
    clase = 'Programa';
    persistencia = 1000;
    delete = (id: number, setData: (d: any) => void, setError?: (d: any) => void) => {
        this.get(`${don}/api/Programa/delete/${id}`, setData, setError);
    }
    publicar = (id: number, setData: (d: any) => void, setError?: (d: any) => void) => {
        this.get(`${don}/api/Programa/publicar/${id}`, setData, setError);
    }
}
export function conCapacitacion() {
    return new CapacitacionConecction();
}
export function addOrUpdateCapacitacion(data: CapacitacionFila[], setData: (d: CapacitacionFila[]) => void, setNot?: () => void) {
    conCapacitacion().cambios((f: capRest[]) => {
        const rest = arrayToCapacitacion(f);
        let dataRest = [...data];
        rest.forEach((r) => {
            if (dataRest.some(c => c.id === r.id)) {
                dataRest = dataRest.map(c => c.id === r.id ? r : c);
            } else {
                dataRest = [...dataRest, r];
            }
        })
        setData(dataRest);
    }, setNot);
}
function setCambioCapacitacion(data: any[], c: CapacitacionFila[]) {
    return setCambio(arrayToCapacitacion(data), c);
}
export const CapacitacionUpdate = (c:CapacitacionConecction,u: CapacitacionFila[], setData: (c: CapacitacionFila[]) => void) => {
    const s = semaforo();
    if (s.isTurn('Capacitacion'))
        c.cambios(
            data => {
                if (data.length) setData(setCambioCapacitacion(data, u));
                s.next();
            },
            () => s.next()
        );

}
class RecursosConeccion extends ConexionCompleta {
    clase = 'Programa/Recursos';
    withProgram = async (id: number, setData: (d: any) => void, setError: (d: any) => void) => {
        try {
            const f = await this.getProxi(`/withProgram/${id}`);
            setData(f)
        } catch (e) { setError(e) }
    }
    delete = async (id: number, setData: (d: any) => void, setError: (d: any) => void) => {
        try {
            const f = await this.getProxi(`/delete/${id}`);
            setData(f)
        } catch (e) { setError(e) }
    }
}
export function conRecursos() {
    return new RecursosConeccion();
}