/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { setLineFun } from '../Config/OnLineCheker';
import { setPermisos } from '../Config/Permisos';
import { don } from '@victorequena22/conneccion';
export interface SeccionType {
    data: {
        User: any
        Permisos: Array<any>
        online: boolean
        cargando: boolean
        seccion: boolean
    }
    logout: () => void,
    user: () => void
}
export const SeccionContext = createContext<SeccionType | null>(null)
export function SeccionProvider({ children }: { children: any }) {
    const [User, setUser] = useState({
        id_usuario: -1,
        usuario: '',
        nivel: 0,
        fecha: '',
        rol: '',
        permisos: []
    });
    const [Permisos, setPermiso] = useState<string[]>([]);
    const [online, setOnline] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [seccion, setSeccion] = useState(true);
    setLineFun(setOnline);
    const user = useCallback(async () => {
        try {
            // //solicitar nuevos datos
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Auth/me', { headers: { 'Authorization': 'Bearer ' + token } });
            let data: any = await response.json();
            setCargando(false);
            if (data !== null) {
                setSeccion(true);
                setUser(data);
                setPermiso(data.permisos);
            } else {
                setSeccion(false);
            }
        } catch (err) {
        }
    }, [])
    const logout = useCallback(async () => {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Auth/close', { headers: { 'Authorization': 'Bearer ' + token } });
            localStorage.removeItem('Authorization');
            await response.json();
            user();
        } catch (err) {
        }
    }, [])
    setPermisos(useCallback((per: string[]) => per.some(p => Permisos.some(a => a === p)), [Permisos]))
    useEffect(() => { user() }, []);
    return <SeccionContext.Provider value={{
        data: { User, Permisos, online, cargando, seccion },
        user, logout,
    }}>{children}</SeccionContext.Provider>
}
export const SeccionConsumer = SeccionContext.Consumer;
export function getSeccionContext() {
    return useContext(SeccionContext) as SeccionType;
}
export function permisoSome(p: string[] = []) {
    const { data: { Permisos } } = getSeccionContext();
    return Permisos.some(d => p.some(p => d === p));
}
export function permisoAny(p: string[] = []) {
    const { data: { Permisos } } = getSeccionContext();
    return Permisos.every(d => p.some(p => d === p));

}
export function valStorage() {
    const valStorage = localStorage.getItem('valStorage');
    const date = (new Date()).getDate() + '';
    if (valStorage !== date) {
        localStorage.clear();
        localStorage.setItem('valStorage', date);
    }
}
