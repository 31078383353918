import { ChangeEvent, useEffect, useState, useCallback, useMemo } from 'react';
import { FormControl } from 'react-bootstrap';
import { CountryList, dataContry } from '../../Config/countrystatecity';
interface Props {
    change: (r: string) => void,
    defaultValue: string,
    width?: string
    isInvalid?: boolean
    pais: string
}
export function StateSelector({ change, defaultValue, width, isInvalid, pais }: Props) {
    const set = useCallback((e: ChangeEvent<HTMLSelectElement>) => change(e.currentTarget.value), [change]);
    const [dataList, setData] = useState<CountryList[]>([]);
    const cargar = useCallback(async () => {
        if (pais !== '') {
            const data = await dataContry().getCountryStates(pais);
            if (data && data !== null) setData(data);
        }
        // eslint-disable-next-line
    }, [dataList, pais])
    // eslint-disable-next-line
    useEffect(() => { cargar() }, [pais]);
    return useMemo(() => (pais !== '' && dataList.length !== 0) ?
        <FormControl as='select' style={{ width }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
            <option value=''>SELECIONAR ESTADO</option>
            {dataList.map(p => <option key={p.iso2} value={p.iso2}>{p.name}</option>)}
        </FormControl> : <></>, [defaultValue, dataList, pais, set, isInvalid, width]);
}