import Drag from './Drag';
import { Props } from '../Props';
import { SVG } from "./qr";
export default function QRImage(p: Props) {
    const { iten, setConfig, setIten } = p;
    return <Drag iten={iten} setConfig={setConfig} setIten={setIten}>
        <SVG {...p} />
    </Drag>
}


