/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Modal, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { Paginador } from "@victorequena22/tabla-bootstrap";
import { InputText } from "@victorequena22/component-bootstrap";
type input = number | string
interface Props {
    values: input[];
    lista: any[];
    row: (data: any) => string;
    label: string;
    open: boolean;
    close: () => void;
    setValues: (tags: any[]) => void;
    buscar: (buscar: string, tags: any) => boolean
    actualizar: () => void;
    save: () => void;
    titleModal: string;
    selectTitle: string;
}
export function SelectorMultiple({ open, close, values, lista, label, titleModal, selectTitle, row, setValues, buscar, actualizar, save }: Props) {
    const [b, setBuscar] = useState('');
    const filter = useCallback((i: any) => buscar(b, i), [b, lista, values]);
    const filter2 = useCallback((i: any) => values.some(a => i[label] === a), [values]);
    const remove = useCallback((a: any) => setValues(values.filter(b => b !== a)), [values]);
    const add = useCallback((a: any) => setValues([...values, a]), [values]);
    useEffect(() => { actualizar() }, [open]);
    return <Modal size="xl" animation centered show={open} onHide={close}>
        <Modal.Header closeButton><h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
            {titleModal}
        </h4></Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={6}>
                    <InputText className='form-control-sm' placeholder='BUSCAR'
                        data-tip='BUSCAR' maxLength={150} upper setData={setBuscar} value={b} />
                </Col>
                <Col md={6}><h5 className="m-1 font-weight-bold text-primary mr-auto align-self-center" >
                    {selectTitle}
                </h5></Col>
            </Row><br />
            <Row>
                <Col md={6}>
                    {useMemo(() => <Paginador key='dataList' mostrar={10} itens={lista.filter(filter)}
                        row={(p) => <RowList click={() => add(p[label])} data={row(p)} icon='arrow-alt-right' />}
                        style={{ height: '300px', overflowY: 'hidden', border: 'solid 1px #ced4da' }} />, [lista, values, b])}
                </Col>
                <Col md={6}>{useMemo(() => <Paginador key='ValuesList' mostrar={10} itens={lista.filter(filter2)}
                    row={(p) => <RowList click={() => remove(p[label])} data={row(p)} icon='times-circlet' />}
                    style={{ height: '300px', overflowY: 'hidden', border: 'solid 1px #ced4da' }} />, [lista, values])}
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <ButtonGroup className="float-right">
                <Button onClick={close} variant="danger">
                    <span className="fa fa-remove-circle"></span> Cancelar
                </Button>
                <Button onClick={() => { save(); close() }} variant="success">
                    <span className="fa fa-check-circle-o"></span> Aceptar
                </Button>
            </ButtonGroup>
        </Modal.Footer>
    </Modal >
}
function RowList({ icon, data, click }: { data: string, icon: string, click: () => void }) {
    return <Button className="text-left m-0" variant='link' block size="sm"
        style={{ height: 25, padding: '.1rem .1rem .1rem .1rem' }} onClick={() => click()}>
        <b className='ml-1'>{data}</b>
        <i className={`fas fa-${icon} float-right mr-1`} />
    </Button>
}