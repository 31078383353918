
import { getPrograma } from "../index";
import { getParticipantes } from "../../../Context/Participante";
import Template from "../Template";
import Tabla from "./Tabla";

export default function ProgramasParticipantes() {
    const pro = getPrograma();
    const pars = getParticipantes();
    const p = pars.filter(p => p.programas.some(s => s === pro.id));
    return <Template icon="fa fa-users-class" title="PARTICIPANTES">
        <Tabla itens={p} />
    </Template >
}