import { BlockElement } from "@victorequena22/slate-bootstrap";
import { State } from '../BaseManager';
export type ItenType = 'QR' | 'TEXT' | 'IMG' | '';
export interface Iten {
    id: number;
    type: ItenType;
    top: number;
    left: number;
    width: number;
    height: number;
    border: number;
    radeus: number;
    padding: number;
    back: string;
    color: string;
    aspect: string;
    data: {} | BlockElement[] | string
}
type newD = (d: Partial<Iten>) => Iten;
const p: Iten = {
    id: 0,
    type: '',
    top: 0,
    left: 0,
    border: 0,
    radeus: 0,
    padding: 0,
    width: 200,
    height: 200,
    back: '#00000000',
    color: '#00000000',
    aspect: '1',
    data: {}
}
export const newData: newD = d => ({ ...p, ...d })
export interface Props {
    iten: Iten;
    config: State;
    setConfig: (d: Partial<State>) => void;
    setIten: (d: Partial<Iten>) => void;
}

export const getWidth = (i: Iten) => i.width - ((i.padding + i.border) * 2)
