import Chart from "react-google-charts"
import { Cargando } from "@victorequena22/component-bootstrap";
import { getSeccionContext } from "@/Template/Seccion";
import { getSizeTorta } from "./GetSize";
import { TortaNull } from "./Nulos";
interface Props {
    labels: string[]
    data: any[]
}
export default function Torta2(p: Props) {
    const { data: { cargando } } = getSeccionContext();
    if (cargando) return <></>;
    return <Carga {...p} />
}
function Carga({ data, labels }: Props) {
    if (!data.length) return <TortaNull />;
    const [width, height] = getSizeTorta();
    return <Chart width={width} height={height} chartType="PieChart"
        loader={<Cargando />}
        data={[labels, ...data]} options={{ title: '' }}
        rootProps={{ 'data-testid': '3' }}
    />
}