import { Col, Modal, Row } from "react-bootstrap"
import { Pago } from "../Models"
import VerPago from "./Ver"
import { MobileUserAgent } from "@victorequena22/utiles"

interface Props {
    pago: Pago
    open: boolean
    close: () => void
    update?: (d?: number) => void
}
export default function ModalPago({ pago, open, close, update }: Props) {
    let className = '';
    if (MobileUserAgent()) {
        className = 'super-modal-movil';
    }
    return <Modal className={className} show={open} size='xl' centered onHide={() => { close(); }}>
        <Modal.Header closeButton>
            <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>PAGOS</b></span>
            </h2>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}>
                    <VerPago pago={pago} update={update} />
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}