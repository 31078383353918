import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import { ParticipanteFila } from "../../../Participantes/Models";
import Fila from "./Fila";
import { IE } from "../../../../../Config/Updater";
import { Pago } from "../../../Pagos/Models";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { getParticipantes } from "../../../Context/Participante";
import { InputText } from "@victorequena22/component-bootstrap";
import { Asistencia } from "../Ver/Exports";
import ParticipantesExcel from "../../../Participantes/Excel";
import {useEvento } from "../index";
export default function TablaParticipantes({ pagos }: { pagos: Pago[] }) {
    const participantes = getParticipantes();
    const lista = useEvento().participantes;
    const par = participantes.filter(pa => pagos.some(p => valPagos(p, pa.id)) || lista.some(e => e.id === pa.id));
    return <Col xs={12}>
        <Row className="m-2">
            <Col className="text-center">
                <b className="text-danger mr-2"> PARTICIPATES NO ESTAN EN EL EVENTO:</b> {par.filter(p => lista.every(e => e.id !== p.id)).length}
            </Col>
            <Col className="text-center">
                <b className="text-warning mr-2">PARTICIPATES PREINSCRITOS:</b>  {lista.filter(p => p.status === 1).length}
            </Col>
            <Col className="text-center">
                <b className="text-success mr-2">PARTICIPATES INSCRITOS:</b>  {lista.filter(p => p.status > 1 || p.status === 0).length}
            </Col>
        </Row>
        <Tabla itens={par} pagos={pagos} />
    </Col>
}
class Tabla extends CardBuscador<{ itens: ParticipanteFila[], pagos: Pago[] }> {
    buscarEn = ['nombre', 'correo', 'telefono'];
    render = () => {
        let { Body, Buscador, } = this;
        return <><Col md={12}><Buscador /></Col><Body /></>
    }
    Row = (d: ParticipanteFila) => <Fila key={IE(d) + 'participante'} data={d} pagos={this.getPagos(d)} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='300px' label='correo'>CORREO</Th>
            <Th width='200px' label='telefono'>TELEFONO</Th>
            <Th width='90px' >ESTATUS</Th>
            <Th width='100px' >MONTO</Th>
            <Th width='200px'>ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps = ({ itens }: any) => this.setState({ itens });
    getPagos = ({ id }: ParticipanteFila) => this.props.pagos.filter(p => valPagos(p, id))
    Buscador() {
        return <Col md={12} className='mr-auto mb-3 pb-1'>
            <InputGroup  >
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        BUSCAR
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <InputText name='buscar' upper placeholder='ESCRIBA SU BUSQUEDA'
                    setData={this.setState} update='buscar' value={this.state.buscar} />
                <InputGroup.Append >
                    <Button variant="primary">
                        <i className="fa fa-search"></i> BUSCAR
                    </Button>
                    <Asistencia />
                    <ParticipantesExcel Participantes={this.state.itens}>
                        EXPORTAR EN EXEL
                    </ParticipantesExcel>
                </InputGroup.Append>
            </InputGroup>
        </Col>
    }
}
const valPagos = (pa: Pago, id: number) => pa.participante === id
