import { ConexionCompleta, setData } from "@victorequena22/conneccion"
export interface AliadoFila {
    id: number,
    nombre: string,
    correo: string,
    clave?: string
    rol: string
    nivel: number
    estado: number
}
export interface Aliado {
    id: number,
    nombre: string,
    correo: string,
    clave?: string
    rol: string
    nivel: number
    estado: number
    acciones: Accion[]
    asignaciones: Asignaciones[]
}
export interface Rol {
    id: number,
    nombre: string
    acciones: Accion[]
}
export interface Accion {
    id: number,
    nombre: string
}
export interface Asignaciones {
    model: string
    id: number,
}

export const AliadoNull: Aliado = {
    id: 0,
    nombre: '',
    correo: '',
    rol: '',
    nivel: 0,
    estado: 0,
    acciones: [],
    asignaciones: []
}
class AliadoConexion extends ConexionCompleta {
    clase = 'Aliado';
    clave(codigo: string | number, data: any, setData?: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi('/clave/' + codigo, data, setData, setError)
    }
    setAsignaciones(codigo: string | number, data: any, setData?: (d: any) => void, setError?: (d: any) => void) {
        this.postProxi('/setAsignaciones/' + codigo, data, setData, setError)
    }

}
export function conAliado() {
    return new AliadoConexion();
}
class RolConexion extends ConexionCompleta {
    clase = 'Aliado/Rol';
    acciones = async (setData: setData) => {
        try {
            const data = await this.getProxi('/acciones')
            setData(data)
        } catch (error) {

        }
    }
}
export function conRol() {
    return new RolConexion();
}