import { ItenBasico } from '@/App/Panel/Component/Commons';
import { iten, State } from '../index';
import { CardConfig, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { ButtonColor } from '@/App/Panel/Component';
interface Props {
    iten: iten,
    config: State,
    setConfig: (d: Partial<iten>) => void,
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void
    copy: (d: number) => void
    addItem: () => void,
    cont: number
}
const style = { width: 32 }
export function ConfigIten(p: Props) {
    const { cont, iten: { id, back, border = '#00bbbb' }, setConfig, config: { card, border1 } } = p;
    const disableBack = card === 'none';
    const disableBorde = disableBack || !border1;
    return <CardConfig width='177px'>
        <ItenBasico {...p} />
        {disableBack ? <div className='mt-2' style={{ height: '24' }} /> : <RowConfig top={2}>
            <ButtonColor gradiend tip='COLOR DE FONDO' style={style}
                color={back} setData={back => setConfig({ back })} >
                <i className="fas fa-ad" />
            </ButtonColor>
            {disableBorde ? <></> : <>
                <ButtonColor tip='COLOR DE BORDE' style={style} className='ml-1'
                    color={border} setData={border => setConfig({ border })} >
                    <i className="fas fa-font" style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor', borderRadius: 5 }} />
                </ButtonColor>
            </>}
        </RowConfig>}
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>OPCIONES ITEN {id}</TitleCard>
    </CardConfig>
}


