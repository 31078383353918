import { defaultBlock } from "@victorequena22/slate-bootstrap";
import { BlockElement } from "../../../Panel/ProtoPanes/DefaultData";

export interface Facilitador {
    id: number;
    nombre: string;
    experiencia: number;
    descripcion: BlockElement[];
    foto: string;
    pais: string;
    estado: string;
    ciudad: string;
    direccion: string;
    alcance: string[];
    programas: number[];
    especialidades: number[];
    redes: any[];
    estudios: any[];
    trabajos: any[];
    laboral: any[];
    publicaciones: any[];
    premios: any[];
    data: any;
    props: any;
    estatus: number
}
export interface FacilitadorFila {
    id: number;
    foto: string;
    nombre: string;
    experiencia: number;
    descripcion: string;
    estatus: number
}
export const facilitadorNull: Facilitador = {
    id: 0,
    nombre: '',
    experiencia: (new Date()).getFullYear(),
    descripcion: defaultBlock,
    foto: '',
    pais: '',
    estado: '',
    ciudad: '',
    direccion: '',
    alcance: [],
    programas: [],
    estudios: [],
    redes: [],
    especialidades: [],
    trabajos: [],
    laboral: [],
    publicaciones: [],
    premios: [],
    data: {},
    props: {},
    estatus: 1
}