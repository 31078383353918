import { getSizeArea, getSizeTortaNull } from "./GetSize";

export function TortaNull() {
    const [width, height] = getSizeTortaNull();
    const p = `calc((${width} - ${height}) / 2)`;
    const p2 = `calc(${height} / 10)`;
    const h = `calc(${height} - 2rem)`;
    return <div style={{ width, paddingInline: p, paddingBlock: '1rem' }} className='center'>
        <div style={{ width: h, height: h, borderRadius: '100%', paddingTop: `calc(${height} / 3.5)`, border: `black calc(${width} / 100) solid`, paddingInline: p2 }} className='no-data text-center'>
            <b style={{ fontSize: `calc(${height} / 10)` }}>No hay datos para mostrar</b>
        </div>
    </div>
}
export function AreaNull() {
    const [width, height] = getSizeArea();
    return <div style={{ width, height, fontSize: `calc(${width} / 10)`, paddingTop: `calc(${height} / 4)`, border: `black calc(${width} / 100) solid`, textAlign: 'center' }}>No hay datos para mostrar</div>
}