import Paralax1 from '../Landing/Paralax1';
import Paralax2 from '../Landing/Paralax2';
import Video from '../Landing/Video';
import Galeria from '../Landing/Galeria';
import Carrusel from '../Landing/Carrusel';
import Cargos from '../Landing/Cargos';
import Servicio from '../Landing/Servicio';
import Servicio2 from '../Landing/Servicio2';
import Preguntas from '../Landing/Preguntas';
import Title from '../Landing/Title';
import Imagen from '../Landing/Imagen';
import Mapa from '../Landing/Mapa';
import Contact from '../Landing/Contact';
import ImagenSola from '../Landing/ImagenSola';
import Testimonio from '../Landing/Testimonio';
import BannerImagen from '../Landing/BannerImagen';
import CardText from '../Landing/CardText';
import CarruselCardText from '../Landing/CarruselCardText';
import { Cargando } from '@victorequena22/component-bootstrap';
import CardCargos from '../Landing/CardCargos';


export default function CrearLanding({ props, url, edit }: { url: string, props?: any, edit?: string }) {
    switch (url) {
        case 'cargos': return <Cargos props={props} edit={edit} />
        case 'carrusel': return <Carrusel props={props} edit={edit} />
        case 'galeria': return <Galeria props={props} edit={edit} />
        case 'imagen': return <Imagen props={props} edit={edit} />
        case 'imagenSola': return <ImagenSola props={props} edit={edit} />
        case 'mapa': return <Mapa props={props} edit={edit} />
        case 'paralax1': return <Paralax1 props={props} edit={edit} />
        case 'paralax2': return <Paralax2 props={props} edit={edit} />
        case 'preguntas': return <Preguntas props={props} edit={edit} />
        case 'servicio': return <Servicio props={props} edit={edit} />
        case 'servicio2': return <Servicio2 props={props} edit={edit} />
        case 'titulo': return <Title props={props} edit={edit} />
        case 'video': return <Video props={props} edit={edit} />
        case 'contacto': return <Contact props={props} edit={edit} />
        case 'testimonio': return <Testimonio props={props} edit={edit} />
        case 'BannerImagen': return <BannerImagen props={props} edit={edit} />
        case 'CardText': return <CardText props={props} edit={edit} />
        case 'CarruselCardText': return <CarruselCardText props={props} edit={edit} />
        case 'CardCargos': return <CardCargos props={props} edit={edit} />
        default: return <Cargando />
    }
}