import { InputText } from "@victorequena22/component-bootstrap"
import { useCallback, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { Datos as d } from "../Models/Model"

interface Props {
    data: d[]
    setData: (d: d[]) => void
}
export default function Datos({ data, setData }: Props) {
    const add = useCallback((d: d) => setData([...data, d]), [data, setData]);
    const change = (i: number, s: d) => {
        setData(data.map((o, j) => j !== i ? o : s));
    }
    return <Table striped hover>
        <thead>
            <tr>
                <th>DESCRIPCIÓN</th>
                <th>INFORMACIÓN</th>
                <th style={{ width: '80px' }}></th>
            </tr>
        </thead>
        <tbody>
            <Add add={add} />
            {data.map((s, i) => <tr key={i}>
                <td>
                    <InputText value={s.descripcion} setData={(descripcion) => change(i, { ...s, descripcion })} />
                </td>
                <td>
                    <InputText value={s.dato} setData={dato => change(i, { ...s, dato })} />
                </td>
                <td>
                    <Button variant='danger' onClick={() => setData(data.filter((_s, j) => j !== i))}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </td>
            </tr>)}
        </tbody>
    </Table>
}
function Add({ add }: { add: (d: d) => void }) {
    const [descripcion, setDescripcion] = useState('');
    const [dato, setDato] = useState('');
    return <tr>
        <td>
            <InputText value={descripcion} setData={setDescripcion} />
        </td>
        <td>
            <InputText value={dato} setData={setDato} />
        </td>
        <td className='text-right'>
            <Button variant='success' onClick={() => {
                add({ descripcion, dato });
                setDescripcion(''); setDato('');
            }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}