
import { Row, Container } from 'react-bootstrap';
import { css } from '@emotion/css';
import { State, iten } from './index'
import { Itens } from './Itens'
import { getImagenURL } from "@victorequena22/conneccion";

interface Props {
    config: State,
    seled: (i: number) => void
    setConfig: (s: Partial<State>) => void
}
export function Panel({ config, seled, setConfig }: Props) {
    const { color, img, itens, inter, seleted } = config;
    const style = { backgroundSize: 'cover', with: '100%', margin: '0 0 0 0', padding: '0 0 0 0' };
    const i = itens[seleted - 1];
    const prev = seleted === 1 ? itens.length - 1 : seleted - 2;
    const next = seleted === itens.length ? 0 : seleted;
    const setPrev = () => {
        setTimeout(() => { seled(prev + 1) }, 250)
    };
    const setNext = () => {
        setTimeout(() => { seled(next + 1) }, 250)
    };
    return <div className="m-0 p-0 w-100" style={{ background: `url(${getImagenURL(img)})` }}>
        <Row style={{ background: color, ...style, padding: '2rem 0' }}>
            <Container className={getCSS(inter)} style={{ padding: '60px' }} >
                <div className="slick-slider slick-initialized">
                    <button onClick={setPrev} className="slick-arrow slick-prev" style={{ display: "block" }}> Next</button>
                    <div className="slick-list">
                        <div className="slick-track">
                            <Itens setConfig={setConfig} key={`iten${i.img}${i.id}`} iten={i} config={config} />
                        </div>
                    </div>
                    <button onClick={setNext} className="slick-arrow slick-next" style={{ display: "block", }}> Next</button>
                    <ul className="slick-dots" style={{ display: "block" }}>
                        {itens.map(i => <Li key={`iten${i.id}`} i={i} s={seleted} />)}
                    </ul>
                </div>
            </Container>
        </Row>
    </div>
}
function getCSS(color: string) {
    return css`button: before{color: ${color} !important;}`;
}
const Li = ({ i: { id }, s }: { i: iten, s: number }) => <li className={id === s ? 'slick-active' : ''}><button>id</button></li>