import { Panel } from './Panel';
import { Config } from './Config';
import { StateS } from '../../ProtoPanes/Single';
import { Tempate } from '../../ProtoPanes/Tempate';
export interface S {
    img: string;
    orientacion: boolean;
    height: number;
    backgroundColor: string;
    color: string;
    backgroundColorHover: string;
    colorHover: string;
}
export type State = StateS & S;
export class Menu extends Tempate<S> {
    titulo = 'EDITAR MENU';
    data = 'Menu';
    randon = Math.random();
    constructor(p: any) {
        super(p);
        this.init({
            img: '', orientacion: true, height: 40,
            backgroundColor: '#f0f0f0CC', color: '#000000',
            backgroundColorHover: '#a0a0a0CC', colorHover: '#000000',
        } as State);
    }
    Config = () => <Config save={this.guardar} setConfig={this.setData} config={this.state} />
    Panel = () => <Panel config={this.state} setData={this.setData} />
}