import { Row, Container, Card, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { getImagenURL } from '@victorequena22/conneccion';
import { getCSSButton } from '@/App/Panel/Props';
import { useFacilitador } from '@/App/Capacitacion/Context/Facilitadores';
import { getSettingCarrusel } from '@/App/Panel/Component/Commons/Carrusel';
export function Facilitadores({ data: { props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const { color, inter } = props;
    const { Facilitadores } = useFacilitador()
    const e = Facilitadores.filter(e => e.estatus && e.estatus === 2)
        // .filter(e => {
        //     if (filtro) {
        //         if (filtroType === 'program') return e.programa === filtro;
        //         if (filtroType === 'categoria') return programas.filter(p => p.categoria === filtro).some(p => e.programa === p.id);
        //         if (fechaToNumber() > fechaToNumber(e.final)) return false;
        //     }
        //     return true;
        // })
        .map(e => <Itens iten={e} props={props} />);
    const slidesToShow = s2.some(s => size === s) ? 4 : s1.some(s => size === s) ? 3 : 2;
    let settings = getSettingCarrusel(e.length, slidesToShow);
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container>
            <Slider className={getCSSButton(inter)} {...settings}>{e}</Slider>
        </Container>
    </Row>
}
function Itens({ props: { gap, cards, text }, iten: { foto, nombre } }: any) {
    return <Col style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
        <Card style={{ background: cards, margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(foto)} />
            <Card.Body style={{ color: text }}>
                <b>{nombre}</b>
            </Card.Body>
        </Card>
    </Col >
}