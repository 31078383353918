/* eslint-disable jsx-a11y/anchor-is-valid */
import { Cargando } from "@victorequena22/component-bootstrap";
import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { TypeAuxiliar, auxiliarNull, conConsideracion } from "../../../Models";

export default function Consideraciones({ id }: { id: number }) {
    const [consideraciones, setConsideraciones] = useState<TypeAuxiliar>(auxiliarNull);
    useEffect(() => {
        if (id !== 0) {
            conConsideracion().detalle(id, setConsideraciones);
        }
    }, [id])
    if (consideraciones.id === 0) return <Cargando />
    return <Col md={12}>
        <h3 className='w-100 text-info'><b>Consideraciones</b></h3>
        {consideraciones.id !== 0 ? <TextToHTML text={consideraciones.texto} /> : <Cargando />}
    </Col>
} 