
import { Route } from "react-router-dom";
import MenuCreacion from "./MenuCreacion";
import { getPrograma } from "../index";
import Crear from "./Crear";
import EditarPanel from "./Editar";
import CopiarPanel from "./Copiar";
import ShortProvider from "../../../../Panel/Context/Shorts";
import { Ver } from "./Ver";
import { getKeyPrograma } from "./Keys/Programa";
import { getKeyCategoria } from "./Keys/Categoria";
import { findCategoria } from "../../../Context/Categorias";
import LandingProvider from "@/App/PanelProxi/SizeLanding/context";
export default function LandingProgama() {
    const c = getPrograma();
    const s = findCategoria(c.categoria)
    const k = getKeyPrograma(c).getKeys();
    const d = getKeyCategoria(s).getKeys();
    return <ShortProvider lista={[...k, ...d]}><LandingProvider id={c.id} modelo="programa">
        <Route path={`/programa/landing/${c.id}`} exact><Ver /></Route>
        <Route path={`/programa/landing/${c.id}/crear`} exact><MenuCreacion /></Route>
        <Route path={`/programa/landing/${c.id}/crear/:type`}><Crear /></Route>
        <Route path={`/programa/landing/${c.id}/edit/:mid`}><EditarPanel /></Route>
        <Route path={`/programa/landing/${c.id}/copy/:mid`}><CopiarPanel /></Route>
    </LandingProvider></ShortProvider >
}