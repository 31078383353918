import { Link } from 'react-router-dom';
import { ListButonLink } from '../../../../../Components/Button/ListBoton';
import { CardBuscador, THFila } from "@victorequena22/tabla-bootstrap";
import { conCertificados, CertificadosFila } from './Model';
import { ButtonGroup } from 'react-bootstrap';

export default class ListaCertificados extends CardBuscador {
    con = conCertificados(); buscarEn = ['id', 'nombre'];
    Title = () => <>
        <h3>BUSCAR CERTIFICADO</h3>
        <Link to="/evento/certificado/nuevo" className="btn btn-primary ml-auto">
            <span className="fa fa-plus"></span> NUEVO CERTIFICADO
        </Link>
    </>
    Row = (d: CertificadosFila) => <Fila data={d} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='90px' label='id'>COD</Th>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>)
    }
    set() { this.con.lista((itens) => this.setState({ itens })) }
    eliminar = (id: number) => this.con.cancelar(id, this.set)
}

export function Fila({ data: { id, nombre } }: { data: CertificadosFila }) {
    return <tr>
        <THFila width=''>{id}</THFila>
        <THFila width='auto'>{nombre}</THFila>
        <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup>
                <ListButonLink permiso='EVE_CERTIFICADOS' tip='EDITAR' variant="primary"
                    to={`/evento/certificado/edit/${id}`}><i className="fa fa-edit" />
                </ListButonLink>
                <ListButonLink permiso='EVE_CERTIFICADOS' tip='EDITAR' variant="info"
                    to={`/evento/certificado/copiar/${id}`}><i className="fa fa-copy" />
                </ListButonLink>
            </ButtonGroup>
        </th>
    </tr >
} 