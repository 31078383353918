import ContactaSubList from "@/App/Panel/Contact/Subs";
import ContactaMeList from "@/App/Panel/Contact/me";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import Subcriptores from "./Subcriptores";
import Interesados from "./Interesados";

export default function MasterContactoProxi() {
    return (<Row style={{ minHeight: "calc(100vh - 145px)" }}>
        <Col md={3} style={{ minHeight: "calc(100vh - 145px)" }}>
            <Card>
                <Card.Header className='bg-info'>
                    <h6>CONTACTOS</h6>
                </Card.Header>
                <ButtonGroup vertical style={{ width: '100%' }}>
                    <Link className='btn btn-default' to='/contacto/subs'>
                        SUBCRIPCIONES
                    </Link>
                    <Link className='btn btn-default' to='/contacto/me'>
                        MENSAJES RECIBIDOS
                    </Link>
                </ButtonGroup>
            </Card>
            {/* <Card>
                <Card.Header className='bg-info'>
                    <h6>TALENTO</h6>
                </Card.Header>
                <ButtonGroup vertical style={{ width: '100%' }}>
                    <Link className='btn btn-default' to='/contacto/subcriptores'>
                        SUSCRIPCIONES
                    </Link>
                    <Link className='btn btn-default' to='/contacto/interesados'>
                        INTERESADOS
                    </Link>
                </ButtonGroup>
            </Card> */}
        </Col>
        <Col md={9} style={{ minHeight: "calc(100vh - 145px)" }}>
            <Route exact path="/contacto/subs"><ContactaSubList /></Route>
            <Route exact path="/contacto/me"><ContactaMeList /></Route>
            <Route exact path="/contacto/subcriptores"><Subcriptores /></Route>
            <Route exact path="/contacto/interesados"><Interesados /></Route>
        </Col>
    </Row>)
}