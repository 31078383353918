import { conCC } from "@/App/Capacitacion/Evento/Models/Certificados";
import { setImage } from "@/App/Capacitacion/Pagos/Nuevo/utils";
import { ParticipanteFila } from "@/App/Capacitacion/Participantes/Models";
import { VerContry, VerEstado } from "@/Components/Contry/Ver";
import { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Button, Col, Modal, Row } from "react-bootstrap";
interface Props {
    user: ParticipanteFila;
    codigo: string;

}
export default function UploadCertificado(p: Props) {
    const [open, setOpen] = useState(false);
    return <>
        <Modal size="xl" show={open} onHide={() => setOpen(false)}>
            <Modal.Body>
                <Subir {...p} close={() => setOpen(false)} />
            </Modal.Body>
        </Modal>
        <Button variant="primary" onClick={() => setOpen(true)}>
            <i className="fa fa-upload" />
        </Button>
    </>
}

function Subir({ codigo, user: { nombre, correo, telefono, direccion, estado, ciudad, pais }, close }: Props & { close: () => void }) {
    const [img, setImg] = useState('');
    const [e, setError] = useState('');
    const send = useCallback(() => {
        if (img !== '') conCC().subir(codigo, img, () => close())
        else setError('DEBE ENVIAR LA IMAGEN DEL CERTIFICADO')
    }, [codigo, img, close])
    useEffect(() => setError(''), [img]);
    return <>
        <Row>
            <Col md={3} className="h-100">
                <h5 className='m-1 mb-3 font-weight-bold text-primary'>
                    DATOS DEL PARTICIPANTE
                </h5>
                <Col md={12}>
                    <Parrafo titulo="NOMBRE">{nombre}</Parrafo>
                    <Parrafo titulo="TELEFONO">{telefono}</Parrafo>
                    <Parrafo titulo="CORREO">{correo}</Parrafo>
                    <Parrafo titulo="PAIS"><VerContry pais={pais} /></Parrafo>
                    <Parrafo titulo="ESTADO"><VerEstado pais={pais} estado={estado} /></Parrafo>
                    <Parrafo titulo="CIUDAD">{ciudad}</Parrafo>
                    <Parrafo titulo="DIRECCION">{direccion}</Parrafo>
                </Col>
                <br /><hr /><br />
                <Col md={12} className='mt-auto'><Parrafo titulo="PESO MAXIMO">1MB</Parrafo></Col>
                <Button as='label' className='mt-auto' variant="primary" block>
                    <input style={{ display: "none" }} type="file" accept='image/*'
                        onChange={(e) => setImage(e, setImg)} />
                    <i className="fa fa-upload" /> SELECCIONAR ARCHIVO
                </Button>
            </Col>
            <Col md={9}>
                <img style={{ border: `1px solid ${e === '' ? 'black' : 'red'}` }}
                    src={img === '' ? '' : img}
                    width={810} alt='img cert' />
            </Col>
        </Row>
        <Row className='mt-3'>
            <Col xs={8}><h5 className='m-1 mb-3 font-weight-bold text-danger'>{e}</h5></Col>
            <Col xs={4}>
                <ButtonGroup className="float-right">
                    <Button variant='success' onClick={send}>
                        <i className="fa fa-save"></i> SUBIR
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    </>
}

function Parrafo({ titulo, children }: { titulo: string, children: any }) {
    return <p className='m-1 p-0'><b>{titulo}:</b> {children}</p>
}