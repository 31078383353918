/* eslint-disable react-hooks/rules-of-hooks */
import { ConexionCompleta } from "@victorequena22/conneccion";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function getUpdater<T>(con: ConexionCompleta, nulo: T): [T, () => void] {
    const { id } = useParams<any>();
    const [val, set] = useState<T>(nulo);
    const update = useCallback(() => { con.detalle(id, p => { if (conp(p, val)) set(p) }) }, [con, id, val]);
    useEffect(() => update(), [update]);
    useEffect(() => getInterval(update), [update]);
    return [val, update];
}
const conp = (a: any, b: any) => JSON.stringify(a) !== JSON.stringify(b)
const getInterval = (update: () => void) => {
    var interval = setInterval(() => update(), 10000)
    return () => clearInterval(interval);
}