import { useCallback, useEffect, useRef, useState } from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";
import { Suscriptores } from "../../Programas/Models/Models";
import { getEstado, getPais } from "@/Components/Contry/Ver";
interface Props {
    size?: "sm" | "lg";
    className?: string;
    Suscriptores: Suscriptores[];
    children?: any;
}

export default function SuscriptoresExcel({ Suscriptores, children, className, size }: Props) {
    const re = useRef<any>();
    const excelExport = () => re.current.save();
    const [sus, set] = useState<any[]>([]);
    const susconver = useCallback(async () => {
        const excel: any[] = []
        for (let i = 0; i < Suscriptores.length; i++) {
            excel[i] = await conver(Suscriptores[i]);
        }
        set(excel)
    }, [Suscriptores]);
    // eslint-disable-next-line
    useEffect(() => { susconver(); }, [Suscriptores]);
    if (sus.length === 0) return <></>;
    return <>
        <Button size={size} className={className} variant='success' onClick={excelExport} style={{ marginRight: '15px' }}>
            <i className="fa fa-file-excel m-auto" />{children}
        </Button>
        <ExcelExport data={sus} ref={re} fileName="Suscriptores" >
            <ExcelExportColumn field="nombre" title="NOMBRE" width={300} />
            <ExcelExportColumn field="correo" title="CORREO" width={300} />
            <ExcelExportColumn field="telefono" title="TELEFONO" width={150} />
            <ExcelExportColumn field="direccion" title="DIRECCION" width={500} />
            <ExcelExportColumn field="pais" title="PAIS" width={300} />
            <ExcelExportColumn field="estado" title="ESTADO" width={300} />
            <ExcelExportColumn field="ciudad" title="CIUDAD" width={300} />
            <ExcelExportColumn field="estatus" title="ESTATUS" width={300} />
        </ExcelExport>
    </>
}

const conver = async ({ id, evento, telefono, correo, nombre, pais, estado, ciudad, estatus }: any) => ({
    id, evento, telefono, correo,
    nombre: sinDiacriticos(nombre),
    pais: await getPais(pais),
    estado: await getEstado(pais, estado),
    ciudad: sinDiacriticos(ciudad),
    estatus: sinDiacriticos(estatus[1])
})
const sinDiacriticos = (function () {
    let de = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç',
        a = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc',
        re = new RegExp('[' + de + ']', 'ug');

    return (texto: string) =>
        texto.replace(
            re,
            match => a.charAt(de.indexOf(match))
        );
})();