import { useContext } from 'react';
// import { Button } from 'react-bootstrap';
import { MenuContext } from './MenuContext';
// import { MobileUserAgent } from '@victorequena22/utiles';
import { getImagenURL } from '@victorequena22/conneccion';
export default function Footer() {
    const { menu } = useContext(MenuContext);
    return <footer style={{ width: menu === 'open' ? 250 : 74, textAlign: 'center', bottom: 0, left: 0, position: 'fixed', background: '#fff', border: '5px solid #2196f3' }}>
        {menu === 'open' ? <>
            <img key='footer-open' src={getImagenURL('/static/media/TalentoeInnovacion.png')} width="120px" alt='logo' />
            <strong> Copyright ©</strong>
        </> : <>
            <img key='footer-close' src={getImagenURL('/static/media/TalentoeInnovacionST.png')} height="40px" alt='logo' />
        </>}
    </footer>
}