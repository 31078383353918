import { CardMain, SizeViewSelector, Title, Guardar, ButtonColor, TitleCard, CardConfig, RowConfig } from '../../Component';
import { State, iten } from './index'
import { ConfigIten } from './Itens/Config';
import { TBElement } from '../../Component/SlateCustom';
import { ConfigCard } from '../../Component/Commons';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
    copy: (d: number) => void
}
export function Config({ save, copy, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, color, itens: { length } } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="300px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor gradiend disableAlpha tip='COLOR DE FONDO DEL PANEL' setData={color => setConfig({ color })} color={color}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ConfigCard config={config} setConfig={setConfig} />
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <ConfigIten cont={length} config={config} iten={iten} addItem={addIten} copy={copy}
            setConfig={setIten} itenUp={itenUp} itenDown={itenDown} removeItem={removeItem} />
        <TBElement />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}