
import { toast } from 'react-toastify';
import { Single, StateS } from './Single';
import { conConfig } from '@victorequena22/conneccion';
import { getText, setText } from '@victorequena22/slate-bootstrap';
import { SizeViewConteiner, TempatesPage } from '../Component';
import SlateProvider from '../Component/SlateCustom';
import ReactTooltip from 'react-tooltip';

export class Tempate<S> extends Single<StateS & S> {
    titulo = '';
    data = '';
    randon = Math.random();
    componentDidMount(): void {
        conConfig(this.data).pull(d => {
            this.randon = Math.random();
            if (d !== null && JSON.stringify(d) !== '[]') {
                const html = setText(d.html);
                this.setData({ ...d, html })
            }
            else conConfig('empresa').pull(({ logo }) => this.setData({ img: `/public/imagenes/${logo}` }));
        });
    }
    render() {
        const { Config, Panel, titulo, setTexto, Top, Left, state: { html, id } } = this;
        return <TempatesPage title={titulo}>
            <SlateProvider key={`panel${id}${this.randon}`} value={html} setValue={setTexto}>
                <ReactTooltip />
                <Config />
                <SizeViewConteiner top={Top()} left={Left()} size={this.state.size}>
                    <Panel />
                </SizeViewConteiner>
            </SlateProvider>
        </TempatesPage>
    }
    guardar = () => conConfig(this.data)
        .push({ ...this.state, html: getText(this.state.html) }, s => {
            this.setState(s); toast.success('CONFIGURACION GUARDADA')
        });

}