import { Row, Col } from "react-bootstrap";
import { Encuesta } from "./Encuesta";
import { Ciudad } from "./Ciudad";
import { useEvento } from "../..";
import Dipositivos from "../../../../Suscripciones/Metricas/Dispositivos";
import Visitas from "../../../../Suscripciones/Metricas/Visitas";
import Pais from "../../../../Suscripciones/Metricas/Pais";
import Estado from "@/App/Capacitacion/Suscripciones/Metricas/Estado";
import City from "@/App/Capacitacion/Suscripciones/Metricas/City";

export default function Graficos() {
    const { id } = useEvento();
    return <Row>
        <Col lg={12} className="pl-5 text-primary"><h1><b>METICAS DE PARTICIPANTES INSCRITOS</b></h1><hr /></Col>
        <Col lg={6}><Encuesta /></Col>
        <Col lg={6}><Ciudad /></Col>
        <Col lg={12} className="pl-5 text-primary"><h1><b>METICAS DE TRAFICO EN LA WEB DE LA SEMANA EN CURSO</b></h1><hr /></Col>
        <Col lg={6}><Dipositivos model="evento" id={id} /></Col>
        <Col lg={6}><Pais model="evento" id={id} /></Col>
        <Col lg={6}><Estado model="evento" id={id} /></Col>
        <Col lg={6}><City model="evento" id={id} /></Col>
        <Col lg={12}><Visitas model="evento" id={id} /></Col>
    </Row>
}