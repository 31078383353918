import { GrupoPago } from "@/App/Capacitacion/Pagos/Models/Model"
import { THFila } from "@victorequena22/tabla-bootstrap"

interface Props {
    data: GrupoPago
}
export default function Fila({ data: { nombre, cupos, participantes, precio, pagos } }: Props) {
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila align="right" width='100px'>{cupos}</THFila>
        <THFila align="right" width='100px'>{participantes.length}</THFila>
        <THFila align="right" width='100px'>{precio}</THFila>
        <THFila align="right" width='150px'>{cupos * precio}</THFila>
        <THFila align="right" width='100px'>{pagos.length}</THFila>
        <THFila width='100px'><></></THFila>
    </tr>
}