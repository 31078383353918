import { Component } from "react";
import { toast } from 'react-toastify';
import { getImagenURL } from "@victorequena22/conneccion";
import { Certificados, conCertificados, Props } from "../Model";
import { newData } from "./Itens/Props";
import { scroollAfter, scroollBefore } from "./Itens/Input";
export interface State extends Props {
    id?: number;
    nombre: string;
    data?: string;
    errors: any;
    Redirect: boolean;
    aspect: string;
    width: number;
    selected: number;
    editable: boolean,
}
export default class BaseManager extends Component<{ data?: Certificados, copi?: Certificados }, State> {
    block = true;
    random = 'false';
    constructor(p: { data?: Certificados }) {
        super(p);
        this.state = {
            Redirect: false, id: undefined, data: undefined, nombre: '',
            editable: false, width: 1080, errors: {}, aspect: '1080/834',
            itens: [], selected: 0
        };
    }
    setData = (d: any) => this.setState(d);
    procesar = true;
    save = async () => {
        if (this.procesar) {
            this.procesar = false;
            let procesar = true;
            const errors: any = {};
            const { nombre, data, aspect, itens, width } = this.state;
            if (nombre === '') {
                procesar = false;
                errors.nombre = 'DEBE COLOCAR UN NOMBRE AL CERTIFICADO';
            }
            if (this.props.data === undefined && data === undefined) {
                procesar = false
                errors.img = 'DEBE SELECCIONAR UNA IMAGEN DE FONDO';
            }
            if (procesar) {
                const posts = { nombre, data: data ? data : '', props: JSON.stringify({ aspect, width, itens }) };
                if (this.props.data === undefined) conCertificados().nuevo(posts, () => this.success());
                else conCertificados().actualizar(this.props.data.id, posts, () => this.success());
            } else {
                this.setData({ errors });
                this.procesar = true;
            }
        } else {
            toast.warn('SUBIENDO DATOS, POR FAVOR ESPERE');
        }
    }
    success = () => {
        this.procesar = true;
        this.setData({ Redirect: true })
        toast.success('CONSIDERACIÓN CREADA');
    }
    init = (data: Certificados) => {
        const { nombre, props } = data;
        if (data.props.itens) {
            const { aspect, width, itens } = props;
            this.setData({ nombre, aspect, width, itens: itens.map(newData) })
        } else {
            const { text, top, left } = props as any;
            var img = new Image();
            img.src = getImagenURL(`/api/Evento/Certificados/imagen/${data.id}`);
            img.onload = () => {
                setTimeout(() => {
                    this.setData({
                        aspect: `${img.width}/${img.height}`, width: img.width,
                        nombre, itens: [newData({
                            width: img.width, type: 'TEXT',
                            data: text, id: 1, top, left,
                        })]
                    });
                }, 100);
            };
        }
    }
    componentDidMount = () => {
        const { data, copi } = this.props;
        if (data) this.init(data);
        if (copi) this.init(copi);
    }
    cdu = (_p: any, { nombre, data, errors: e, editable }: State) => {
        const errors: any = {};
        if (nombre !== this.state.nombre && e.nombre) {
            errors.nombre = undefined;
        }
        if (data !== this.state.data && e.img) {
            errors.img = undefined;
        }
        if (JSON.stringify(errors) !== JSON.stringify(e))
            this.setData({ errors });
        if (editable !== this.state.editable) {
            scroollAfter(this.scroll)
        }
    }
    scroll = 0;
    getSnapshotBeforeUpdate = (_p: any, { editable }: State) => {
        if (editable !== this.state.editable) {
            this.scroll = scroollBefore();
        }
    }
}
