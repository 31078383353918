import { semaforo } from "@victorequena22/utiles"
import { setCambio, ConexionCompleta } from "@victorequena22/conneccion"
export interface Panel {
    id: number
    indice: number
    pagina: number
    title: string
    type: string
    data: any
    text: string
    estado: number
    items: Items[]
}
export interface Items {
    id: number
    panel_id: number
    indice: number
    type: string
    data: any
    text: string
}
export const panelNull: Panel = {
    id: 0,
    indice: 0,
    pagina: 1,
    type: '',
    title: '',
    data: {},
    text: '',
    estado: 0,
    items: []
}
export const itemNull: Items = {
    id: 0,
    panel_id: 0,
    indice: 0,
    type: '',
    data: {},
    text: ''
}
export interface PanelProps {
    data: any
    text: string
    setText: (s: string) => void,
    removeItems?: (n: number) => void,
    items?: Items[]
    Items: JSX.Element
}
class PanelConeccion extends ConexionCompleta {
    clase = 'Web/Panel';
    up = (indice: number, setData: () => void, setError: () => void) =>
        this.get(`/api/${this.clase}/up/${indice}`, setData, setError)
    down = (indice: number, setData: () => void, setError: () => void) =>
        this.get(`/api/${this.clase}/down/${indice}`, setData, setError)
    delete = (id: number, setData: () => void, setError: () => void) =>
        this.get(`/api/${this.clase}/delete/${id}`, setData, setError)
}
export const PanelUpdate = (con: PanelConeccion, u: Panel[], setData: (c: Panel[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Panel')) con.cambios(data => {
        if (data.length) setData(setCambioPanel(data, u));
        s.next();
    }, () => s.next());
}
function setCambioPanel(data: Panel[], c: Panel[]) { return setCambio(data, c); }
export function conPanel() { return new PanelConeccion(); }