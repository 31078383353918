import { CardMain, SizeViewSelector, Title, Guardar, TitleCard, CardConfig, RowConfig, ButtonConfig } from '../../Component';
import { State, iten } from './index'
import { CropModal } from '../../../Media/CropModal';
import { ConfigIten } from './Itens/Config';
import { ItenImagen } from './Itens/Imagen';
import { ColorFondo } from '../../Component/Commons/Simples';
import { ColorDesplazamiento } from '../../Component/Commons/Carrusel';
import { TBElement } from '../../Component/SlateCustom';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
    copy: (id: number) => void,
}
export function Config({ copy, save, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, img, color, inter, itens: { length } } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="227px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ColorFondo gradiend setColor={color => setConfig({ color })} color={color} />
                <ColorDesplazamiento className='ml-1 mr-2' setColor={inter => setConfig({ inter })} color={inter} />
                <CropModal aspect={16 / 9} img={img} setImg={img => setConfig({ img })} />
                <ButtonConfig className='ml-1' icon='far fa-eye-slash' tip='REMOVER IMAGEN' action={() => setConfig({ img: '' })} />
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <ConfigIten cont={length} iten={iten} copy={copy}
            itenDown={itenDown} removeItem={removeItem}
            setIten={setIten} addItem={addIten} itenUp={itenUp} />
        <ItenImagen setIten={setIten} iten={iten} />
        <TBElement />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}