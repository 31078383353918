import { Accordion, ButtonGroup, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
interface Props {
    p: string[]
    permiso: string
    to: string
    children: any
    P?: string[]
}
export function Menus({ title, children }: { title: string, children: any }) {
    return <Card as={Accordion}>
        <Card.Header as={Accordion.Toggle} eventKey='1' className='bg-info'>
            <h6>{title}</h6>
        </Card.Header>
        <Accordion.Collapse eventKey='1'>
            <ButtonGroup vertical style={{ width: '100%' }}>
                {children}
            </ButtonGroup>
        </Accordion.Collapse>
    </Card>
}

export function MenuIten({ p, permiso, to, children }: Props) {
    if (p.some(p => p === permiso))
        return <Link className='btn btn-default' to={to}>
            {children}
        </Link>
    return <></>;
}
interface Props2 {
    p: string[]
    permiso: string[]
    to: string
    children: any
    P?: string[]
}
export function MenuIten2({ p, permiso, to, children }: Props2) {
    if (p.some(p => permiso.includes(p)))
        return <Link className='btn btn-default' to={to}>
            {children}
        </Link>
    return <></>;
} 