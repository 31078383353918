
import { Row, Col, Modal, FormCheck } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { Component, } from 'react';
import { ControlText } from "@victorequena22/component-bootstrap";
import { MetodoPago } from '../Models';
import Datos from './Datos';
interface Props {
    data: MetodoPago,
    error: any,
    setData: (data: Partial<MetodoPago>) => void,
    children?: any;
}
export function Form({ data: { nombre, metodo, dolar, props }, setData, error, children }: Props) {
    return <Modal.Body>
        <ReactTooltip />
        <Row>
            <Col xs={12}>
                <ControlText maxLength={150} before='NOMBRE' value={nombre} setData={setData} update='nombre' error={error} />
            </Col>
            <Col xs={12} className='mt-2'>
                <ControlText before='MÉTODOS DE PAGO' value={metodo} update='metodo' setData={setData} error={error} />
            </Col>
            <Col xs={12} className='mt-2'>
                <FormCheck checked={dolar} type="checkbox"
                    onClick={() => setData({ dolar: !dolar })}
                    label='PAGO EN DOLARES' className='ml-3'
                />
            </Col>
            <Col xs={12} className='mt-2'>
                <Datos data={props} setData={props => setData({ props })} />
            </Col>
            {children}
        </Row>
    </Modal.Body>
}
interface PropsModal {
    open: boolean;
    close: () => void;
}
export class Prototipo<p> extends Component<p & PropsModal, MetodoPago> {
    error: any = [];
    procesar = true;
    validar = () => {
        this.error = [];
        let procesar = true;
        // const { norma, consideracion, avales, facilitadores, codigo, tags, estado, ciudad, nombre, descripcion, short, pais, direccion, cohorte, fecha, programa } = this.state;

        return procesar;
    }
    getDataSave = () => {
        const { nombre, metodo, dolar, props } = this.state;
        return { nombre, metodo, dolar: dolar ? 1 : 0, props };
    }
    setError = (err: any[]) => {
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast.error(er));
            } else {
                Object.keys(e).forEach(t => this.error[t] = e[t])
            }
        })
        this.procesar = true;
        this.forceUpdate();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
    update(_p: any, _s: MetodoPago) { }
}