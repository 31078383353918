import { Shor, conShort } from '../../Models';
import { Component, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Config } from './Config';
import { ToRGB } from "@victorequena22/utiles";
import { ButtonColor } from '../../Component';
import { toast } from 'react-toastify';
interface Props { data?: Shor }
export default class Nuevo extends Component<Props, Shor> {
    constructor(p: Props) {
        super(p);
        this.state = this.getDefault();
    }
    render() {
        return <Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVA ATAJO</h4>
            </Card.Header>
            <Card.Body className='d-flex' style={{ height: 'calc(100vh - 210px)' }}>
                <Config save={this.save} data={this.state} setData={this.setState.bind(this)} default={this.getDefault()} />
                <Preview data={this.state} />
            </Card.Body>
        </Card>
    }
    getDefault = () => (this.props.data !== undefined) ? this.props.data : { id: 0, html: '', descripcion: '', type: '', clave: '', estatus: 0, data: {} };
    save = () => {
        const { id } = this.state
        if (id === 0)
            conShort().nuevo(this.state, (d: Shor) => {
                this.setState(d);
                toast.success('GUARDADO EXITOXAMENTE');
            })
        else
            conShort().actualizar(id, this.state, () => {
                toast.success('ACTUALIZADO EXITOXAMENTE');
            })
    }
}
const init = `<p> Este es un texto de pruebas para ver como de integra la clave que esta agregando </p><p> En este caso: `;
const end = ` y su valor al reeplazarlo</p>`;

function Preview({ data: { html, clave } }: { data: Shor }) {
    const [color, setColor] = useState('#ffffff');
    return <div className='ml-5' style={{ width: 'calc(100% - 550px)', height: 'calc(100vh - 300px)' }}>
        <Card className='h-40 w-100'>
            <Card.Header className='bg-gray row m-0'>
                <h6 className='mr-auto'>VISTA EN EDICION</h6>
                <ButtonColor className="ml-auto" setData={setColor} color={color} disableAlpha={true}>
                    <i className="fa fa-paint-brush"></i>
                </ButtonColor>
            </Card.Header>
            <Card.Body className='h-100  w-100' style={{ backgroundColor: color, color: getColor(color) }}
                dangerouslySetInnerHTML={{ __html: init + `{${clave}}` + end }} />
        </Card>
        <Card style={{ maxHeight: '60%', minHeight: '60%', height: '60%' }} className='w-100'>
            <Card.Header className='bg-gray'>
                <h6 className='mr-auto'>VISTA EN PANEL</h6>
            </Card.Header>
            <Card.Body className='h-100  w-100'
                style={{ overflowY: 'scroll', backgroundColor: color, color: getColor(color) }}
                dangerouslySetInnerHTML={{ __html: init + html + end }} />
        </Card>
    </div>
}
function getColor(color: string) {
    const { r, g, b } = ToRGB(color);
    let r1 = r >= 128 ? 0 : 255;
    let g1 = g >= 128 ? 0 : 255;
    let b1 = b >= 128 ? 0 : 255;
    const max = Math.max(r1, g1, b1);
    const min = Math.min(r1, g1, b1);
    if (max !== min) {
        if (r1 === g1) b1 = r1;
        if (r1 === b1) g1 = r1;
        if (b1 === g1) r1 = b1;
    }
    if ((g === 255 && r < 30 && b < 30)) return '#000000';
    return `rgb(${r1},${g1},${b1})`;
}