
import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { Itens } from "./Itens";
import { EditArea } from "../../../Panel/Component/SlateCustom";
import { useFacilitador } from "@/App/Capacitacion/Context/Facilitadores";
import { Facilitadores } from "../../SizePanel/Facilitadores";
export function Panel({ config }: { config: State }) {
    const { color, size } = config;
    const { Facilitadores: f } = useFacilitador();
    return <>
        <Row style={{ background: color, margin: '0 0 0 0', padding: '0 0 2rem 0', borderBottom: '3px black solid' }}>
            <div style={{ display: 'none' }}><EditArea /></div>
            <Container><Row>
                {f.filter(e => e.estatus && e.estatus === 2)
                    // .filter(e => { return true; })
                    .map(e => <Itens iten={e} config={config} />)}
            </Row></Container>
        </Row>
        <Facilitadores data={{ props: config }} size={size} />
    </>
}