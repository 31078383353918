import { ButtonGroup } from 'react-bootstrap';
import { TitleCard, CardConfig, RowConfig, ButtonColor, ButtonConfig, AddItens } from '../../../Component';
import { IconSelector, UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { iten, State } from '../index';
interface Props {
    iten: iten,
    config: State,
    setConfig: (d: Partial<iten>) => void,
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void
    addItem: () => void,
    copy: (id: number) => void
    cont: number
}
export function ConfigIten(p: Props) {
    const {
        iten: { icon, id, fill, aux, back }, config: { back: iBack },
        setConfig, cont, itenUp, itenDown, removeItem, addItem, copy
    } = p;
    return <CardConfig width='185px'>
        <RowConfig>
            <ButtonGroup>
                <ButtonConfig tip='MOVER ARRIBA' icon='fas fa-arrow-up' action={() => itenUp(id)} />
                <ButtonConfig tip='MOVER ABAJO' icon='fas fa-arrow-down' action={() => itenDown(id)} />
            </ButtonGroup>
            <ButtonConfig className='ml-2 mr-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
            <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
            <ButtonConfig className='ml-1' tip='COPIAR' icon='fas fa-copy' action={() => copy(id)} />
        </RowConfig>
        <RowConfig top={2}>
            <IconSelector value={icon} onChange={icon => setConfig({ icon, id })} />
            <ButtonColor tip='COLOR DEL ICONO' className='ml-1' invert={true} style={{ width: 32, backgroundColor: iBack !== 'none' ? aux : undefined }} color={fill} setData={(fill) => setConfig({ fill, id })} >
                <UseIcon width={15} height={15} icon={icon} fill={fill} />
            </ButtonColor>
            <ButtonColor gradiend tip='COLOR DE FONDO DEL ICONO' disable={iBack === 'none'} className='ml-1' style={{ width: 32 }} color={aux} setData={(aux) => setConfig({ aux, id })} >
                <UseIcon width={15} height={15} icon={icon} fill={fill} />
            </ButtonColor>
            <ButtonColor gradiend tip='COLOR DE FONDO DE LA CARTA' style={{ width: 32 }} className='ml-auto'
                color={back} setData={(back) => setConfig({ back, id })} >
                <i className="fas fa-ad" />
            </ButtonColor>
        </RowConfig>
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>OPCIONES ITEN {id}</TitleCard>
    </CardConfig>
}