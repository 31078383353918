
import { Panel } from '@/App/Panel/Models';
import Eventos from '../Landing/Eventos';
import Programas from '../Landing/Programas';
import EditarLanding2 from '@/App/Panel/Ver/EditarLanding';
import Suscribir from '../Custom/Subcribirse';
import Facilitadores from '../Landing/Facilitadores';
export function EditarLanding({ panel }: { panel: Panel }) {
    switch (panel.type) {
        case 'eventos': return <Eventos key={'panel' + panel.id} data={panel} />
        case 'programas': return <Programas key={'panel' + panel.id} data={panel} />
        case 'suscribir2': return <Suscribir key={'panel' + panel.id} data={panel} />
        case 'TEIFacil': return <Facilitadores key={'panel' + panel.id} data={panel} />
        default: return <EditarLanding2 key={'panel' + panel.id} panel={panel} />
    }
} 