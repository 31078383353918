import { Link } from 'react-router-dom';
import { Fila } from '../Auxiliar/Fila';
import { conConsideracion, TypeAuxiliarLista } from '../../Models';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';

export default class ListaConsideracion extends CardBuscador {
    con = conConsideracion(); buscarEn = ['id', 'nombre'];
    Title = () => <>
        <h3>BUSCAR CONSIDERACIÓN</h3>
        <Link to="/evento/config/consideracion/nueva" className="btn btn-primary ml-auto">
            <span className="fa fa-plus"></span> NUEVA CONSIDERACIÓN
        </Link>
    </>
    Row = (d: TypeAuxiliarLista) => <Fila data={d} eliminar={this.eliminar} link='/evento/config/consideracion/edit/' />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='90px' label='id'>COD</Th>
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>)
    }
    set = () => this.con.lista((itens) => this.setState({ itens }))
    eliminar = (id: number) => this.con.cancelar(id, () => this.set())
}
