import { useState, useCallback, useEffect, useRef } from 'react';
import { Buscador } from './Buscador';
import { Button, Modal, Col } from 'react-bootstrap';
import { ButtonImage } from '../Media';
import { conMedia, arrayToMedia, Media as iMedia } from './Models';
import { CropModal } from './CropModal';
import { getImagenURL } from '@victorequena22/conneccion';
interface Props {
    set: (d: any) => void,
    open: boolean,
    close: () => void
    type?: 'video' | 'image'
}
export function Selector({ set, open, close, type }: Props) {
    const [imgs, setImgs] = useState<iMedia[]>([]);
    const [size, setSize] = useState(0);
    const [max, setMax] = useState(104857600);
    const actualizar = useCallback(() => {
        conMedia().setDisk(({ max, size }: any) => { setMax(max); setSize(size); })
        conMedia().lista((m: any) => setImgs(arrayToMedia(m)))
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(actualizar, [open]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const select = useCallback((s?: string) => { if (s) set(`/api/Media/ver/${s}`); close() }, []);
    return <Modal size='xl' show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center w-100">
                <span><b>SELECCIONAR IMAGEN</b></span>
            </h4>
            <ButtonImage size={size} max={max} multi={true} key='uploadImage' actualizar={actualizar} />
        </Modal.Header>
        <Modal.Body >
            <Buscador type={type} ver={30} actualizar={actualizar} imgs={imgs} GetImagen={(i: any) => <GetImagen {...i} select={select} />} />
        </Modal.Body>
    </Modal>
}
function GetImagen({ img, select }: any) {
    const video = useRef<HTMLVideoElement>(null);
    const hoverIn = () => {
        const v = video.current;
        if (v !== null) v.play();
    }
    const hoverOut = () => {
        const v = video.current;
        if (v !== null) { v.pause(); v.currentTime = 0; }
    }
    return <Col xs={2} style={{ padding: '0 .1rem .1rem .1rem', margin: '0 0 0 0' }}>
        <Button onMouseEnter={hoverIn} onMouseOut={hoverOut}
            style={{ height: '170px' }} onClick={() => select(`${img.id}`)}
            className='m-1 p-1' variant='outline-primary' >
            <small style={{ position: 'absolute', width: '2.5rem', height: '1.6rem', border: '3px solid #17a2b8', color: '#17a2b8', backgroundColor: '#fff' }}>{img.id}</small>
            {img.type.indexOf('image') > -1 ?
                <img width="100%" src={getImagenURL(`/api/Media/short/${img.id}`)} alt='dd' /> :
                <video ref={video} width="100%" loop muted
                    src={getImagenURL(`/api/Media/short/${img.id}`)} />
            }
            <div className='m-auto' style={{ height: '2rem' }}>{img.nombre}</div>
        </Button>
    </Col>
}

export function ImageSelector({ setImg, img, error }: { setImg: (i: string) => void, img: string, error?: string }) {
    return <>
        <Col style={{ aspectRatio: '1 / 1', height: 'fit-content', border: error ? 'solid 1px #dc3545' : 'solid 1px #ced4da', padding: '0 0 0 0' }}>
            <div style={{ marginBottom: '-24px', width: '100%', height: '-24px', zIndex: 5, textAlign: 'right' }}><CropModal aspect={1} img={img} setImg={setImg} /></div>
            {img === '' ?
                <img alt='kk' width='100px' style={{ aspectRatio: '1 / 1', inset: '50% auto auto 50%', position: 'relative', transform: 'translate(-50%, -50%)' }} src='/public/Productos/Add.svg' className='img-responsive' />
                : <img alt='ff' style={{ aspectRatio: '1 / 1' }} className='img-responsive' src={getImagenURL(img)} width='100%' />}
        </Col>
        {error ? <small style={{ color: '#dc3545' }}>{error}</small> : <></>}
    </>
}