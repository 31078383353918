
import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { Itens } from './Itens'

export function Panel({ config, seled, }: { config: State, seled: (i: number) => void }) {
    const { color, itens } = config;
    return <Row style={{ background: color, margin: '0 0 0 0', padding: '0 0 2rem 0' }}>
        <Container>
            <Row>
                {itens.map((i) => <Itens key={`iten${i.img}${i.id}`} iten={i} config={config} seled={seled} />)}
            </Row>
        </Container>
    </Row>
}