import Paralax1 from '../Landing/Paralax1';
import Paralax2 from '../Landing/Paralax2';
import Video from '../Landing/Video';
import Galeria from '../Landing/Galeria';
import Carrusel from '../Landing/Carrusel';
import Cargos from '../Landing/Cargos';
import Servicio from '../Landing/Servicio';
import Servicio2 from '../Landing/Servicio2';
import Preguntas from '../Landing/Preguntas';
import Title from '../Landing/Title';
import Imagen from '../Landing/Imagen';
import Mapa from '../Landing/Mapa';
import Contact from '../Landing/Contact';
import ImagenSola from '../Landing/ImagenSola';
import Testimonio from '../Landing/Testimonio';
import BannerImagen from '../Landing/BannerImagen';
import CardText from '../Landing/CardText';
import CarruselCardText from '../Landing/CarruselCardText';
import { Cargando } from '@victorequena22/component-bootstrap';
import { Panel } from '../Models';
import CardCargos from '../Landing/CardCargos';
export default function EditarLanding({ panel: p }: { panel: Panel }) {
    switch (p.type) {
        case 'cargos': return <Cargos key={'panel' + p.id} data={p} />
        case 'carrusel': return <Carrusel key={'panel' + p.id} data={p} />
        case 'galeria': return <Galeria key={'panel' + p.id} data={p} />
        case 'imagen': return <Imagen key={'panel' + p.id} data={p} />
        case 'imagenSola': return <ImagenSola key={'panel' + p.id} data={p} />
        case 'mapa': return <Mapa key={'panel' + p.id} data={p} />
        case 'paralax1': return <Paralax1 key={'panel' + p.id} data={p} />
        case 'paralax2': return <Paralax2 key={'panel' + p.id} data={p} />
        case 'preguntas': return <Preguntas key={'panel' + p.id} data={p} />
        case 'servivio1': return <Servicio key={'panel' + p.id} data={p} />
        case 'servivio2': return <Servicio2 key={'panel' + p.id} data={p} />
        case 'titulo': return <Title key={'panel' + p.id} data={p} />
        case 'video': return <Video key={'panel' + p.id} data={p} />
        case 'contacto': return <Contact key={'panel' + p.id} data={p} />
        case 'testimonio': return <Testimonio key={'panel' + p.id} data={p} />
        case 'BannerIMG': return <BannerImagen key={'panel' + p.id} data={p} />
        case 'CardText': return <CardText key={'panel' + p.id} data={p} />
        case 'cargosCT': return <CarruselCardText key={'panel' + p.id} data={p} />
        case 'CardCargos': return <CardCargos key={'panel' + p.id} data={p} />
        default: return <Cargando />
    }
}