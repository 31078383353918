import { Col } from 'react-bootstrap';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { getBorderCard } from '@/App/Panel/Props';
import { TextToHTML } from '@/App/Panel/Component/SlateCustom/Html';
interface Props {
    iten: iten,
    config: State,
    seled: (i: number) => void
}
export function Itens({ config: { seleted, size, card, border1 },
    iten: { id, html, color, back, border = '#00bbbb' }, seled }: Props) {
    const s2 = ['1440px', '1320px'];
    const style = getBorderCard(seleted === id, card, border1, border, back)
    return <Col xs={s2.some(s => size === s) ? 4 : 6} style={{ padding: '.5rem' }} >
        <div onClick={() => seled(id)} style={{ height: '100%', ...style }} className="c-content-person-1 c-option-2 mt-2">
            {seleted === id ?
                <div className='m-1' style={{ color }}> <EditArea key={'edit' + id} /></div> :
                <div className='m-1 p-1 pb-4' style={{ color }} key={'html' + id}><TextToHTML text={html} /></div>
            }
        </div>
    </Col >
}
