
export interface Datos {
    descripcion: string
    dato: string
}

export interface MetodoPago {
    id: number
    nombre: string
    metodo: string
    dolar: boolean
    props: Datos[]
    estatus: number
}

export const metodoNull: MetodoPago = {
    id: 0,
    nombre: '',
    metodo: '',
    dolar: false,
    props: [],
    estatus: 0
}

export interface partialPago {
    banco: string
    fecha: string;
    referencia: string;
    monto: number;
    captura: string;
}