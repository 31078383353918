import { Html } from '../Component/Commons';
import { css } from '@emotion/css';
import { Row, Container, Card, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { getImagenURL } from '@victorequena22/conneccion';
import { getCSSButton } from '../Props';
import { getSettingCarrusel } from '../Component/Commons/Carrusel';
export function Cargos({ data: { itens, props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const sts = s2.some(s => size === s) ? 4 : s1.some(s => size === s) ? 3 : 2;
    let settings = getSettingCarrusel(itens.length, sts);
    const { color, inter } = props
    return <Row className='m-0  w-100' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container style={{ background: color }} className={getCSS(color)}>
            <Slider className={getCSSButton(inter)} {...settings}>
                {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} />)}
            </Slider>
        </Container>
    </Row>
}
function Itens({ props: { gap }, iten: { html, props: { img, color, back } } }: any) {
    const style = { background: back, color };
    return <Col className='m-0 p-0'>
        <Card style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(img)} />
            <Html style={style} className='card-body' html={html} />
        </Card>
    </Col >
}


function getCSS(color: string) {
    return css`.slick-prev:before, .slick-next:before{
        color: ${color};
    }`;
}