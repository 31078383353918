import { useContext } from 'react'
import { Button, Card, Modal, Row } from 'react-bootstrap';
import { Panel } from '../../Panel/Models';
import { PanelContext } from '../../Panel/Context/Panel';
import { Slect } from '../Utils';
import { PreviewSize } from './PreviewSize';
import { MenuCreacion } from '../../Panel/Utiles';
import { Link } from 'react-router-dom';
import { EventoSeled, FacilitadorSeled, ProgramaSeled } from '../Utils/Botones';
import { ContentPermisoSome } from '@/Components/Button/Content';
export default function Selector() {
    return <Card>
        <Card.Header className='bg-info d-flex'>
            <Button as={Link} className="mr-2 mb-2" data-tip='VOLVER' variant="primary" to={`/panel`}>
                <i className="fa fa-arrow-left" />
            </Button>
            <h4><b>SECCIONAR EL TIPO DE PANEL</b></h4>
        </Card.Header>
        <Card.Body>
            <Row><h3 className='ml-2 text-info'>Paneles por defecto</h3></Row>
            <MenuCreacion url='/panel' /><hr />
            <ContentPermisoSome permiso={['EVENTO', 'PROGRAMA', 'FACILITADOR']}>
                <Row><h3 className='ml-2 text-info'>Mis Paneles</h3></Row>
                <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
                    <EventoSeled to="/panel/eventos" />
                    <ProgramaSeled to="/panel/programas" />
                    <FacilitadorSeled to="/panel/TEIFacil" />
                </Row>
            </ContentPermisoSome>
        </Card.Body>
    </Card>
}
export function SelectorPanel({ set, filter }: { set: (da: Panel) => void, filter?: (p: Panel) => boolean }) {
    const { panels } = useContext(PanelContext);
    const p = filter ? panels.filter(filter) : panels;
    return <>{p.map(p => <>
        <Slect panel={p} select={set} />
        <div className='w-100'>
            <PreviewSize key={p.type + p.id} panel={p} size='1080px' />
        </div>
    </>)}</>
}
export function SelecPanelModal({ set, open, close, filter }: { filter?: (p: Panel) => boolean, set: (d: any) => void, open: boolean, close: () => void }) {
    return <Modal size='xl' show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header style={{ borderRadius: 0 }} closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                <span><b>SELECCIONAR PANEL</b></span>
            </h4>
        </Modal.Header>
        <Modal.Body >
            <Row style={{ padding: "10px 10px 10px 10px" }}>
                <SelectorPanel set={p => { set(p); close() }} filter={filter} />
            </Row>
        </Modal.Body>
    </Modal>
}




