import { css } from '@emotion/css'
import { BlockElement, BockType, Child } from '@victorequena22/slate-bootstrap'
import { CSSProperties } from 'react'


export const Element = (p: any) => <List {...p} />
const Block = ({ attributes, children, element }: any) => {
    switch (element.type) {
        case 'heading-one':
            return <h1 style={getCSS(element)} {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 style={getCSS(element)} {...attributes}>{children}</h2>
        case 'heading-tre':
            return <h3 style={getCSS(element)} {...attributes}>{children}</h3>
        case 'heading-for':
            return <h4 style={getCSS(element)} {...attributes}>{children}</h4>
        case 'button':
            return <div style={getCSS(element)} {...attributes}>{children}</div>
        default:
            return <p style={getCSS(element)} {...attributes}>{children}</p>
    }
}
const List = (p: any) => {
    const { attributes, children, element, } = p;
    return <Block attributes={attributes} element={element}>
        <ListElement attributes={attributes} element={element}>
            {children}
        </ListElement>
    </Block>
}

const getCSS = ({ align, tabs, type, children }: BlockElement): CSSProperties => {
    const font = getFontUsa(type, children[0]) / 10;
    const t2 = tabs * 2;
    const color = children[0] ? children[0].color : '#000';
    return {
        float: 'right', textAlign: align, padding: '0 0 0 0', fontSize: `${font}rem`, minHeight: `${font}rem`,
        width: `calc(100% - ${t2}rem)`, margin: '.05rem .05rem .05rem .05rem', color, display: 'flex',
        justifyContent: align
    }
}
const getFontSize = (type: BockType) => {
    if (type === 'heading-one') return 26
    if (type === 'heading-two') return 22
    if (type === 'heading-tre') return 18
    if (type === 'heading-for') return 14
    return 10
}
const getFontUsa = (type: BockType, children?: Child) => {
    const b = getFontSize(type)
    const c = children?.fontSize;
    if (c) return c < b ? b : c;
    return b;
}

const ListElement = ({ element, children }: { element: any, attributes: any, children: any }) => {
    const { list, type } = element;
    return type === 'button' ?
        <div className={getCSSBotton(element)}>
            {children}
        </div> : list === 'paragraph' ?
            <div style={getCSSNoList(element)}>
                {children}
            </div> :
            <div style={getCSSList(element)}>
                {children}
            </div>
}
const getCSSBotton = ({ align, background, hover, borderColor, borderHover, borderSize = 0, borderRadius = 0 }: any): string => {
    return `btn ${align === 'justify' ? 'btn-block' : ''} ` + css`
    background: ${background} !important;
    border: ${borderSize}px solid ${borderColor} !important;
    border-radius: ${borderRadius}px !important;
    &:hover { 
        background: ${hover} !important; 
        border-color: ${borderHover} !important;
    }
    &:active { 
        background: ${background} !important; 
        border-color: ${borderColor} !important;
    }
    `;
}

const getCSSNoList = ({ align }: any): CSSProperties => {
    return {
        padding: '0 0 0 0', width: `fit-content`, textAlign: align
    }
}

const getCSSList = ({ tabs, type, list, children, align }: BlockElement): CSSProperties => {
    const font = getFontUsa(type, children[0]) / 10;
    const t2 = tabs * 2;
    const t = list === 'paragraph' ? t2 : t2 + font
    const s = list === 'paragraph' ? {} : { display: 'list-item', listStyleType: list === 'bulleted-list' ? 'disc' : 'square' }
    return {
        padding: '0 0 0 0', width: `fit-content`, ...s, marginLeft: `${t}rem`, textAlign: align
    }
}

