export const aspectImage = (s: string) => new Promise((resolve) => {
    var img = new Image();
    img.src = s;
    img.onload = function () {
        resolve(`${img.width}/${img.height}`);
    };
});
export const base64Image = (s: string) => new Promise(async (resolve) => {
    var img = new Image();
    img.src = s;
    img.onload = function () {
        var canvas = document.createElement('canvas');
        const { width, height } = img
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        if (ctx !== null) ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/png', 1));
    };
});