import { Button, ButtonGroup } from 'react-bootstrap';
import { ConfigIconFontSize, ConfigCard } from '../../Component/Commons';
import { CardMain, SizeViewSelector, Title, Guardar, ButtonColor, TitleCard, CardConfig, RowConfig, ButtonConfig } from '../../Component';
import { State, iten } from './index'
import { ConfigIten } from './Itens/Config';
import { TBElement } from '../../Component/SlateCustom';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
    copy: (d: number) => void
}
export function Config({ save, copy, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, color, orientation, itens: { length } } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="300px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor gradiend tip='COLOR DETRAS DE LAS CARTAS' className='ml-1' setData={color => setConfig({ color })} color={color}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ConfigCard config={config} setConfig={setConfig} />
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width="132px">
            <ConfigIconFontSize max={9} step={.1} min={3} config={config} setConfig={setConfig}>
                <ButtonGroup>
                    <Button data-tip='COLOCAR ICONO EN EL CENTRO' size='sm' active={orientation === 'reverse'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ orientation: 'reverse' })} variant='outline-dark'>
                        <i className='fas fa-compress-alt' style={{ transform: 'rotate(45deg)' }} />
                    </Button>
                    <ButtonConfig tip='COLOCAR ICONO EN LOS BORDES' active={orientation === ''} action={() => setConfig({ orientation: '' })} icon='fas fa-arrows-h' />
                </ButtonGroup>
            </ConfigIconFontSize>
            <TitleCard>CONFIGURACION DE ICONOS</TitleCard>
        </CardConfig>
        <ConfigIten cont={length} config={config} iten={iten} addItem={addIten} copy={copy}
            setConfig={setIten} itenUp={itenUp} itenDown={itenDown} removeItem={removeItem} />
        <TBElement />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}