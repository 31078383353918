import { TitleCard, CardConfig, RowConfig, BoxConfig } from '../../../Component';
import { Button, ButtonGroup } from 'react-bootstrap';
import { iten } from '../index';
import { Inserts } from '../../../Component/SlateCustom';
import { BackButton, ColorButton, FontStyle, MarkMenu } from "@victorequena22/slate-bootstrap";
import { AlingMenu, ListMenu, TabsMenu, TypeMenu } from "@victorequena22/slate-bootstrap";
import { getColors } from '../../../../../Template/Colores';
import { ButtonProps, ButtonSelect } from '@/App/Panel/Component/SlateCustom/BlockProps';
export function Position({ value: p, setData }: { value: number, setData: (d: number) => void }) {
    return <ButtonGroup vertical data-tip='POSICION DEL AREA DE TEXTO'>
        <ButtonGroup>
            <Button onClick={() => setData(0)} active={p === 0} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(45deg)' }} className="fas fa-arrow-left" />
            </Button>
            <Button onClick={() => setData(1)} active={p === 1} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-up" />
            </Button>
            <Button onClick={() => setData(2)} active={p === 2} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(-45deg)' }} className="fas fa-arrow-right" />
            </Button>
        </ButtonGroup>
        <ButtonGroup>
            <Button onClick={() => setData(3)} active={p === 3} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-left" />
            </Button>
            <Button onClick={() => setData(4)} active={p === 4} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <b>O</b>
            </Button>
            <Button onClick={() => setData(5)} active={p === 5} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-right" />
            </Button>
        </ButtonGroup>
        <ButtonGroup>
            <Button onClick={() => setData(6)} active={p === 6} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(-45deg)' }} className="fas fa-arrow-left" />
            </Button>
            <Button onClick={() => setData(7)} active={p === 7} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-down" />
            </Button>
            <Button onClick={() => setData(8)} active={p === 8} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(45deg)' }} className="fas fa-arrow-right" />
            </Button>
        </ButtonGroup>
    </ButtonGroup>
}
export function TextMenu(p: { iten: iten, setConfig: (d: Partial<iten>) => void }) {
    const { id, position } = p.iten;
    return <>
        <CardConfig width='395px'>
            <RowConfig>
                <Position value={position} setData={position => p.setConfig({ position, id })} />
                <BoxConfig margin='0 0 0 5px' width='295px'>
                    <RowConfig>
                        <MarkMenu />
                        <ButtonGroup className='ml-2'>
                            <ColorButton left='0' colores={getColors()} />
                            <BackButton left='0' colores={getColors()} />
                        </ButtonGroup>
                        <AlingMenu left={1} />
                    </RowConfig>
                    <RowConfig top={2}>
                        <TabsMenu left={0} />
                        <ListMenu left={1} />
                        <TypeMenu left={1} />
                        <ButtonSelect />
                    </RowConfig>
                    <RowConfig top={2}>
                        <FontStyle left={0} width='calc(100% - 32px - .25rem)' />
                        <Inserts />
                    </RowConfig>
                </BoxConfig>
            </RowConfig>
            <TitleCard>TEXTO Y FUENTE</TitleCard>
        </CardConfig>
        <ButtonProps />
    </>
}

