import { Table } from "react-bootstrap"
import { Pago } from "../Models"
import Fila from "./Fila"

interface Props {
    pagos: Pago[]
    select: (d: number) => void
}
export default function ListaPagos({ pagos, select }: Props) {
    return <Table>
        <tr>
            <th>Fecha</th>
            <th>Banco</th>
            <th>Referencia</th>
            <th>Monto</th>
            <th>estatus</th>
            <th>Nota</th>
        </tr>
        {pagos.map(p => <Fila select={select} pago={p} key={`pagos${p.id}${p.estatus}`} />)}
    </Table>
}