import { Card, Col, Row } from "react-bootstrap";
import { getPrograma } from "./index";
import { MemuEvento, Title } from "./Menu";
export default function Template({ children, icon, title }: { children: any, icon: string, title: string }) {
    const { id } = getPrograma();
    return <Card>
        <Card.Header className='bg-info'>
            <h4 className='mr-auto'>
                <i className={icon}></i> {title}
            </h4>
        </Card.Header>
        <Card.Body>
            <Title key={`title${id}`} />
            <Row className='d-md-flex flex-row-reverse'>
                <Col className='mt-2' xs={12} lg={3}><MemuEvento key={`menu${id}`} /></Col>
                <Col className='mt-2' xs={12} lg={9}>{children}</Col>
            </Row>
        </Card.Body>
    </Card>
}
export function Template2({ children, icon, title }: { children: any, icon: string, title: string }) {
    const { id } = getPrograma();
    return <Card>
        <Card.Header className='bg-info'>
            <h4 className='mr-auto'>
                <i className={icon}></i> {title}
            </h4>
        </Card.Header>
        <Card.Body>
            <Title key={`title${id}`} />
            <Row className="pb-5"><Col>{children}</Col></Row>
        </Card.Body>
    </Card>
}