/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { CountryList, dataContry } from '../../Config/countrystatecity';
import { SelectorMultiple } from '../Selectors/Multiple';

interface Props {
    alcance: string[];
    setAlcanse: (tags: string[]) => void;
}

export function AlcanceSelector({ alcance, setAlcanse }: Props) {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(alcance);
    const [dataList, setData] = useState<CountryList[]>([]);
    const filter = useCallback((buscar: string, i: CountryList) => {
        if (values.some(a => a === i.iso2)) return false;
        const en = buscar.split(" ");
        const con = en.filter(e => i.name.toUpperCase().indexOf(e) > -1);
        return en.length === con.length;
    }, [values]);
    const cargar = useCallback(async () => {
        const data = await dataContry().getCountryList();
        if (data && data !== null) setData(data);
    }, [dataList]);
    useEffect(() => setValues(alcance), [alcance])
    return <>
        <SelectorMultiple open={open} close={() => setOpen(false)}
            titleModal='SELECIONAR PAISES DE ALCANCE' selectTitle='PAISE DE ALCANCE SELECCIONADOS'
            lista={dataList} values={values} buscar={filter} setValues={setValues}
            actualizar={cargar} save={() => setAlcanse(values)} label='iso2'
            row={(data: any) => data.name} />
        <div className="w-100 mt-2">
            <Row as={Col}>
                <label className="m-0">PAISES DE ALCANCE</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            {useMemo(() => <Col className="w-100 h-90 mt-1" style={{ minHeight: '50px', maxHeight: '100px', borderTop: 'solid 1px #ced4da' }}>
                {dataList.filter((i: CountryList) => alcance.some(a => i.iso2 === a)).map((p) => p.name).join(', ')}
            </Col>, [alcance, dataList])}
        </div>
    </>
}