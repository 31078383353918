import { memo, useCallback, useContext, useState } from 'react';
import { InputGroup, Button, ButtonGroup, Row } from 'react-bootstrap';
import Popover from '@mui/material/Popover'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import { isBlockProps, setPropsOrRemove, useMarkProp } from "@victorequena22/slate-bootstrap";
import BuscarShort from './Buscar';
import { fontsFamily } from './FontsFamily';
import { ButtonConfig } from '@/App/Panel/Component';
import { ButtonColor, InputNumber, InputText } from '@victorequena22/component-bootstrap';
import { ColorContext } from '@/Template/Colores';
import { TitleInput } from '@/App/Panel/Component/Inputs';
export const FontSize = () => {
    const [fontSize, setFontFamily] = useMarkProp('fontSize', 25);
    return <InputNumber setData={(s) => setFontFamily(s)}
        value={fontSize} style={{ height: 24 }} decimal={0}
    />

}
const insertText = (editor: any, text: string) => {
    Editor.insertText(editor, text)
}
export const Inserts = () => {
    const editor = useSlate();
    const [show, setShow] = useState(false);
    return <>
        <BuscarShort open={show} close={() => setShow(false)}
            add={s => insertText(editor, `{${s.clave}}`)} />
        <ButtonConfig icon='fas fa-hashtag' className='ml-auto'
            tip='INSERTAR ATAJO' action={() => setShow(true)} />
    </>
}
export const ColorButton = () => {
    const { colores } = useContext(ColorContext);
    const [color, set, click] = useMarkProp('color', '#00000000');
    const style = { width: 32, height: 24, margin: '0 0 0 0', padding: '.25rem .5rem .25rem .5rem' }
    return <ButtonColor colores={colores} className="ml-0" tip='COLOR DE TEXTO'
        style={style} color={color} invert={true} setData={set} click={click} disableAlpha>
        <i className='fas fa-font'></i>
    </ButtonColor>
}
export const BackButton = () => {
    const { colores } = useContext(ColorContext);
    const [color, set, click] = useMarkProp('backgroundColor', '#00000000');
    const style = { width: 32, height: 24, margin: '0 0 0 0', padding: '.25rem .5rem .25rem .5rem' }
    return <ButtonColor colores={colores} className="ml-0" tip='RESALTAR TEXTO'
        style={style} color={color} setData={set} click={click} disableAlpha>
        <i className='fas fa-paint-roller'></i>
    </ButtonColor>
}
export const ListaButton = () => {
    const editor = useSlate()
    const val = useCallback((e: string) => isBlockProps(editor, 'list', e), [editor]);
    const set = useCallback((e: string) => setPropsOrRemove(editor, 'list', e), [editor]);
    return <ButtonConfig tip='LISTA' icon='fas fa-list-ul'
        active={val('bulleted-list')} action={() => set('bulleted-list')} />
}
export const FontsFamily = () => {
    const [fontFamily, setFontFamily] = useMarkProp('fontFamily', 'Arial');
    const [open, setOpen] = useState<any>(null)
    const [buscar, setBuscar] = useState('')
    const handleClick = useCallback((event: any) => { setOpen(event.currentTarget); }, [])
    const handleClose = () => setOpen(null)
    return <InputGroup.Append style={{ margin: 0, height: 24, width: 'calc(100% - 40px)', backgroundColor: '#fff' }}>
        <Button data-tip='' size='sm' as='label' onClick={handleClick} block variant='outline-dark'
            style={{ margin: 0, padding: 0, height: 24, fontFamily }}>{fontFamily}</Button>
        <Popover open={open} anchorEl={open} onClose={handleClose} style={{ border: '1px solid black' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <Row className='m-0 p-1' style={{ borderBottom: '1px solid black' }}>
                <TitleInput tip='GROSOR DEL BORDE' title='BUSCAR FUENTE' end={<i className="fa-regular fa-search" />}>
                    <InputText style={{ height: '24px' }} className='form-control-sm' value={buscar} setData={setBuscar} />
                </TitleInput>
            </Row>
            <Row className='m-0 p-1' style={{ maxHeight: '50vh', overflowY: 'scroll', margin: 0, padding: 0, width: 700, }}>
                <ButtonGroup vertical className={`btn-block`}>
                    {fontsFamily
                        .filter(c => c.toLowerCase().includes(buscar.toLowerCase()))
                        .map(c => <FontsFamilyOption click={setFontFamily} fontFamily={c} key={c} />)}
                </ButtonGroup>
            </Row>
        </Popover>
    </InputGroup.Append>
}
interface PropsStyle {
    fontFamily: string,
    click: (s: string) => void
}
const FontsFamilyOption = memo(({ fontFamily, click }: PropsStyle) => <Button variant='outline-dark' block style={{ minHeight: '4rem' }}
    onClick={() => click(fontFamily)} >
    <span style={{ fontFamily, fontSize: '2.5rem', position: 'absolute', bottom: 0, left: 10 }}> {fontFamily}</span>
    <span style={{ fontSize: '1rem', position: 'absolute', top: 0, right: 10 }}> {fontFamily}</span>
</Button>)

