import { ButtonColor } from "@/App/Panel/Component";
import { TitleInput } from "@/App/Panel/Component/Inputs";
import { CardConfig, InputNumber, RowConfig, TitleCard } from "@victorequena22/component-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import { iten } from "../index";

export function BackMenu({ iten, setConfig }: { iten: iten, setConfig: (d: Partial<iten>) => void }) {
    const { back, bordeColor, id, borde, redondius, disable } = iten;
    return <CardConfig width='78'>
        <RowConfig>
            <ButtonGroup>
                <ButtonColor gradiend tip='COLOR DE FONDO DEL AREA DE TEXTO' disable={disable} color={back} setData={(back) => setConfig({ back, id })} >
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ButtonColor tip='COLOR DEL BORDE DEL AREA DE TEXTO' disable={disable} disableAlpha={true} color={bordeColor} setData={(bordeColor) => setConfig({ bordeColor, id })} >
                    <i style={{ border: '2px solid currentColor', borderRadius: '2px', width: '13px', height: '13px' }} className="far"></i>
                </ButtonColor>
            </ButtonGroup>
        </RowConfig>
        <RowConfig top={2}>
            <TitleInput title={<i style={{ border: '2px solid currentColor', width: '13px', borderRadius: '2px', height: '13px' }} className="far"></i>}
                tip='GROSOR DEL BORDE DEL AREA DE TEXTO'>
                <InputNumber disabled={disable} decimal={0} style={{ height: 24 }} value={borde} setData={borde => setConfig({ borde, id })} />
            </TitleInput>
        </RowConfig>
        <RowConfig top={2}>
            <TitleInput title={<i className="fas fa-spinner-third"></i>} tip='REDONDEO EN LAS ESQUINAS DEL AREA DE TEXTO'>
                <InputNumber disabled={disable} decimal={0} style={{ height: 24 }} value={redondius} setData={redondius => setConfig({ redondius, id })} />
            </TitleInput>
        </RowConfig>
        <TitleCard>FONDO</TitleCard>
    </CardConfig>

}