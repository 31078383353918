import { CardConfig, RowConfig, TitleCard, InputNumber, ButtonConfig } from "@victorequena22/component-bootstrap";
import { TitleInput } from '@/App/Panel/Component/Inputs';
import { Props as p } from '../Itens/Props'
import { ButtonGroup } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { ColorButton } from "./Conponents";
import { Editable, Text } from "./Text";
import { Image } from "./Imagen";
export interface Props extends p {
    itenDown: () => void;
    itenUp: () => void;
    removeItem: () => void;
}
export default function Iten(p: Props) {
    const { setIten, removeItem, itenDown, itenUp, iten: { left, top, width, type, border, radeus, id, back, color, padding }, config: { itens, width: w, editable } } = p;
    const pInput = { style: { height: '24px' }, className: 'form-control-sm', decimal: 0, min: 0, step: "1" };
    return type === '' ? <></> : <>
        <CardConfig width='285px'>
            <ReactTooltip place="bottom" />
            <RowConfig className=''>
                <TitleInput width={90} tip='POSICION Y' title="Y">
                    <InputNumber {...pInput} value={top} setData={setIten} update="top" />
                </TitleInput>
                <TitleInput className="ml-1" width={90} tip='POSICION X' title="X">
                    <InputNumber {...pInput} value={left} setData={setIten} update="left" />
                </TitleInput>
                <TitleInput className="ml-1" width={90} tip='TAMAÑO' title={<i className="fas fa-arrows-left-right" />}>
                    <InputNumber {...pInput} max={w} value={width} setData={setIten} update="width" />
                </TitleInput>
            </RowConfig>
            <RowConfig className='mt-2'>
                <TitleInput width={90} tip='GROSOR DEL BORDE' title={<i className="fa-regular fa-rectangle"></i>}>
                    <InputNumber {...pInput} value={border} setData={setIten} update="border" />
                </TitleInput>
                <TitleInput className="ml-1" width={90} tip='REDONDEO DE LOS BORDES' title={<i className="fa-regular fa-circle"></i>}>
                    <InputNumber {...pInput} value={radeus} setData={setIten} update="radeus" />
                </TitleInput>
                <TitleInput className="ml-1" width={90} tip='MARGENES INTERNOS'
                    title={<i style={{ transform: 'rotate(45deg)' }} className="fa-regular fa-arrows-minimize fa-rotate-90"></i>}>
                    <InputNumber {...pInput} value={padding} setData={setIten} update="padding" />
                </TitleInput>
            </RowConfig>
            <RowConfig style={{ height: 24 }} className='mt-2'>
                <ButtonGroup>
                    <ColorButton tip='COLOR DE FONDO DEL ELEMENTO' color={back} set={back => setIten({ back })}>
                        <i className="fa fa-paint-roller"></i>
                    </ColorButton>
                    <ColorButton tip='COLOR DE BORDE DEL ELEMENTO' color={color} set={color => setIten({ color })}>
                        <i className="fa-regular fa-rectangle"></i>
                    </ColorButton>
                </ButtonGroup>
                <ButtonGroup className="ml-2">
                    <ButtonConfig tip="MOVER AL FONDO" className="p-0" action={() => itenDown()} icon="fa-duotone fa-clone fa-swap-opacity" />
                    <ButtonConfig tip="MOVER AL FRENTE" className="p-0" action={() => itenUp()} icon="fa-duotone fa-clone" />
                </ButtonGroup>
                <ButtonConfig tip="ELIMINAR ELEMENTO" className="p-0 ml-2" action={() => removeItem()} icon="fas fa-trash" />
                {type === 'IMG' ? <Image {...p} /> : <></>}
                {type === 'TEXT' ? <Editable {...p} /> : <></>}
            </RowConfig>
            <TitleCard>ELEMENTO TIPO {id}/{itens.length}</TitleCard>
        </CardConfig>
        {editable && type === 'TEXT' ? <Text /> : <></>}
    </>
}