import { Html } from '../Component/Commons';
import { css } from '@emotion/css';
import { Row, Container, Card, Col } from 'react-bootstrap';
import { getImagenURL } from '@victorequena22/conneccion';
export function CardCargos({ data: { itens, props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const col = s2.some(s => size === s) ? 3 : s1.some(s => size === s) ? 4 : 6;
    const { color } = props
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container style={{ background: color }} className={getCSS(color)}><Row>
            {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} col={col} />)}
        </Row></Container>
    </Row>
}
function Itens({ props: { gap }, iten: { html, props: { color, back, img } }, col }: any) {
    const style = { background: back, color };
    return <Col xs={col} className='m-0 p-0'>
        <Card style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(img)} />
            <Html style={style} className='card-body' html={html} />
        </Card>
    </Col >
}


function getCSS(color: string) {
    return css`.slick-prev:before, .slick-next:before{
        color: ${color};
    }`;
}