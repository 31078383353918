import { Iten, ItenType, newData } from "./Itens/Props";
import BaseManager, { State } from "./BaseManager";
import { defaultText } from "@/App/Panel/ProtoPanes/DefaultData";
import { getCenter } from "./Itens/Input";
export default class ItensManager extends BaseManager {
    getItenManager = () => {
        const { removeItem, setIten, addIten, itenDown, itenUp } = this;
        return { removeItem, setIten, addIten, itenDown, itenUp };
    }
    newIten = 0;
    getNewIten = (type: ItenType) => {
        const id = this.nexId();
        this.selected(0);
        this.newIten = id;
        switch (type) {
            case "QR": return newData({ type, id, data: {}, back: '#FFFFFF', color: "#000000" })
            case "TEXT": return newData({ type, id, data: defaultText, width: 300, border: 1, back: '#FFFFFF', color: "#000000" })
            case "IMG": return newData({ type, id, data: '' })
        }
    }
    componentDidUpdate = (p: any, s: State) => {
        this.cdu(p, s);
        if (this.newIten > 0) {
            const { left, top } = getCenter(`iten-${this.newIten}`);
            this.setIten({ left, top, id: this.newIten });
            this.newIten = 0;
        }

    }
    removeItem = () => {
        const { itens, selected } = this.state;
        if (itens.length > 0) this.setOrden(itens.filter(i => i.id !== selected))
        this.selected(0);
    }
    getIten = () => {
        const { itens, selected } = this.state;
        for (let i = 0; i < itens.length; i++)
            if (selected === itens[i].id) return itens[i];
        return newData({ data: defaultText });
    }
    setIten = (p: Partial<Iten>) => {
        const { itens, selected } = this.state;
        const id = p.id ? p.id : selected;
        type k = keyof Iten;
        this.setData({
            itens: itens.map(it => {
                if (it.id === id) {
                    const iten: any = { ...it };
                    Object.keys(it).forEach((t) => iten[t] = p[t as k] !== undefined ? p[t as k] : it[t as k]);
                    return iten;
                }
                return it;
            })
        });
        if (id !== selected) this.setData({ selected: id });
    }
    getText = () => {
        const iten = this.getIten();
        if (iten.type === 'TEXT' && this.state.editable) return iten;
        return newData({ data: defaultText });
    }
    itenUp = () => {
        const { itens } = this.state;
        const i = this.getIten();
        const it = [...itens.filter(it => it.id !== i.id), i];
        this.selected(it.length);
        this.setOrden([...itens.filter(it => it.id !== i.id), i]);
    }
    itenDown = () => {
        const { itens } = this.state;
        const i = this.getIten();
        this.setOrden([i, ...itens.filter(it => it.id !== i.id)]);
        this.selected(1);
    }
    setOrden = (itens: Iten[]) => {
        this.setData({ itens: itens.map((a, i) => ({ ...a, id: i + 1 })) });
    }
    addIten = (type: ItenType) => this.setData({ itens: [...this.state.itens, this.getNewIten(type)] })
    nexId = () => Math.max(...this.state.itens.map(({ id }) => id), 0) + 1;
    selected = (selected: number) => this.setData({ selected, editable: false });
}
