import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import { ModalReport } from "../../../../../Components/Modals";
import { conCertificados, CertificadosFila } from "./Model";

interface Props {
    evento?: number
    change: (r: number) => void,
    defaultValue: number,
    width?: string
    isInvalid?: boolean
    className?: string
}
export default function CertificadoSelector({ evento, className, change, defaultValue, width, isInvalid }: Props) {
    const [open, setOpen] = useState(false);
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(parseInt(e.currentTarget.value));
    const [certificados, setCertificados] = useState<CertificadosFila[]>([])
    const actualizarNormas = useCallback(() => { conCertificados().lista(setCertificados) }, [])
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => actualizarNormas(), []);
    return <InputGroup className={className}>
        <InputGroup.Prepend><InputGroup.Text>CERTIFICADO</InputGroup.Text></InputGroup.Prepend>
        <FormControl as='select' style={{ width }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
            <option value='0'>SELECCIONAR CERTIFICADO</option>
            <option value='-1'>SUBIR CERTIFICADO</option>
            {certificados.map((c, i) => <option key={i} value={c.id}>{c.nombre}</option>)}
        </FormControl>
        {(defaultValue > 0) && evento ? <InputGroup.Append>
            <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`/api/Evento/certificadoDemo/${defaultValue}/${evento}`} />
            <Button variant="primary" onClick={() => setOpen(true)}><i className='fa fa-search'></i> VER DEMO</Button>
        </InputGroup.Append> : <></>}
    </InputGroup>
}