/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SelectorMultiple } from '../../../../../Components/Selectors/Multiple';
import { conAval, Costo } from '../../Models';
interface Props {
    avals: number[];
    setavals: (tags: number[]) => void;
}
export function AvalSelectorMultiple({ avals, setavals }: Props) {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(avals);
    const [Avales, setAvales] = useState<Costo[]>([]);
    const filter = useCallback((buscar: string, i: Costo) => {
        if (values.some(a => a === i.id)) return false;
        const en = buscar.split(" ");
        const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
        return en.length === con.length;
    }, [avals]);
    const actualizarAvales = useCallback(() => { conAval().lista(setAvales) }, [])
    return <>
        <SelectorMultiple open={open} close={() => setOpen(false)}
            titleModal='SELECIONAR AVALES' selectTitle='AVALES SELECCIONADOS'
            lista={Avales} values={values} buscar={filter} setValues={setValues}
            actualizar={actualizarAvales} save={() => setavals(values)} label='id'
            row={(data: any) => data.nombre} />
        <div className="w-100 mt-1">
            <Row as={Col}>
                <label className='m-0'>AVALES</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            {useMemo(() => <Col className="w-100 h-90 mt-1"
                style={{ minHeight: '50px', maxHeight: '100px', borderTop: 'solid 1px #ced4da', overflowY: 'auto' }}>
                {Avales.filter(i => avals.some(a => i.id === a)).map((p) => <li>{p.nombre}</li>)}
            </Col>, [avals, Avales])}
        </div>
    </>
}