
import { getPrograma } from "../index";
import Template from "../Template";
import Tabla from "./Tabla";
import { useEventos } from "../../../Context/Eventos";

export default function ProgramasEventos() {
    const pro = getPrograma();
    const e = useEventos();
    const l = e.filter(p => pro.id === p.programa);
    return <Template icon="fa fa-users-class" title="PARTICIPANTES">
        <Tabla itens={l} />
    </Template >
}