/* eslint-disable react-hooks/exhaustive-deps */

import { Col, Button, Row, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { conFacilitador } from '../../Models';
import { InputText } from "@victorequena22/component-bootstrap";
import { getEspecialidades } from '../Especialidades';
interface Props {
    especialidades: number[];
    setEspecialidades: (tags: number[]) => void;
}
export default function SeleccionarEspecialidades({ especialidades, setEspecialidades }: Props) {
    const { actualizar, especialidades: lista } = getEspecialidades();
    const [buscar, setBuscar] = useState('');
    const [open, setOpen] = useState(false);
    const [nuevo, setNuevo] = useState('');
    const s = (id: number) => (especialidades.some(p => p === id));
    const s1 = (id: number) => s(id) ? "success" : "default";
    const s2 = (id: number) => s(id) ? "QUITAR ETIQUETA" : "AGREGAR ETIQUETA";
    const click = (id: number) => setEspecialidades(s(id) ? especialidades
        .filter(p => p !== id) : [...especialidades, id]);
    useEffect(() => actualizar(), [open]);
    return <>
        <Modal size="xl" animation centered show={open} onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                    SELECIONAR AREAS DE EXPERIENCIA
                </h6>
            </Modal.Header>
            <Modal.Body>
                <ReactTooltip key={`tooltiptag${especialidades.join('')}`} />
                <Row>
                    <Col md={12}>
                        <Row className="mb-1 pl-2 pr-2">
                            <Row style={{ width: '400px' }} className="mr-auto pb-1 pt-1">
                                <small className='m-auto'>BUSCAR AREA DE EXPERIENCIA</small>
                                <InputText className='form-control-sm' style={{ width: '200px' }} maxLength={100} upper setData={setBuscar} value={buscar} /><br />
                            </Row>
                            <Row style={{ width: '402px' }} className="ml-auto pb-1 pt-1">
                                <small className='m-auto'>NUEVA AREA DE EXPERIENCIA</small>
                                <InputText className='form-control-sm' style={{ width: '200px' }} maxLength={100} upper setData={setNuevo} value={nuevo} /><br />
                                <Button onClick={() => { if (nuevo !== '') conFacilitador().nuevoEspecialidad(nuevo, actualizar); setNuevo('') }} size='sm' variant='success' >
                                    <i className='fas fa-save' />
                                </Button>
                            </Row>
                        </Row>
                        <Row style={{ height: '300px', overflowY: 'auto' }}>
                            {lista.filter(([, nombre]) => {
                                const en = buscar.split(" ");
                                const con = en.filter(e => nombre.toUpperCase().indexOf(e) > -1);
                                return en.length === con.length;
                            }).map(([id, nombre]) => <Button data-tip={s2(id)}
                                style={{ borderRadius: '1rem', height: 40 }}
                                onClick={() => click(id)} className="col-4"
                                variant={s1(id)}>{nombre}</Button>)}
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
        <div className="w-100 mt-1">
            <Row as={Col}>
                <label className="m-0">AREAS DE EXPERIENCIA</label>
                <Button onClick={() => setOpen(true)} size='sm' className='ml-2'>
                    <i className="fas fa-edit" ></i>
                </Button>
            </Row>
            <Col className="w-100 h-90 mt-1"
                style={{ minHeight: '50px', borderTop: 'solid 1px #ced4da', overflowY: 'auto' }}>
                {lista.filter(([id]) => s(id)).map(([id, nombre]) => <Col as='li' key={id} className="mt-2">{nombre}</Col>)}
            </Col>
        </div>
    </>
}