import { semaforo } from '@victorequena22/utiles';
import { useState, createContext, useEffect } from 'react';
import { conConfig } from '@victorequena22/conneccion';
export interface DolarType {
    tasa: number,
}
export const DolarContext = createContext<DolarType>({ tasa: 1 })
export default function DolarProvider({ children }: { children: any }) {
    const [tasa, setDolar] = useState(1);
    const s = semaforo();
    const update = () => {
        if (s.isTurn('Dolares')) {
            conConfig('').get('/api/Dolares/valor', ([dolar]) => {
                if (tasa !== dolar) setDolar(dolar);
                s.next();
            }, () => s.next());
        }
    }
    let interval: any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { interval = setInterval(update, 100); return () => clearInterval(interval); }, [tasa])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { s.add('Dolares'); return () => s.clean('Dolares'); }, [])
    return <DolarContext.Provider value={{ tasa }}>{children}</DolarContext.Provider>
}
export const SeccionConsumer = DolarContext.Consumer;