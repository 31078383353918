
import { Route } from "react-router-dom";
import MenuCreacion from "./MenuCreacion";
import { getFacilitador } from "../index";
import Crear from "./Crear";
import EditarPanel from "./Editar";
import Copiar from "./Copiar";
import ShortProvider from "../../../../Panel/Context/Shorts";
import { Ver } from "./Ver";
import { getKeyFacilitador } from "./Keys";
import LandingProvider from "@/App/PanelProxi/SizeLanding/context";
export default function LandingFacilitador() {
    const c = getFacilitador();
    const f = getKeyFacilitador(c).getKeys();
    return <ShortProvider lista={f}><LandingProvider id={c.id} modelo="facilitador">
        <Route path={`/facilitador/landing/${c.id}`} exact><Ver /></Route>
        <Route path={`/facilitador/landing/${c.id}/crear`} exact><MenuCreacion /></Route>
        <Route path={`/facilitador/landing/${c.id}/crear/:type`}><Crear /></Route>
        <Route path={`/facilitador/landing/${c.id}/copy/:mid`}><Copiar /></Route>
        <Route path={`/facilitador/landing/${c.id}/edit/:mid`}><EditarPanel /></Route>
    </LandingProvider></ShortProvider >
}