import { Row, Col } from 'react-bootstrap';
import { getImagenURL } from '@victorequena22/conneccion';
import { cols } from '../Custom/Subcribirse/Panel/props';
import { Html } from '@/App/Panel/Component/Commons';
import Formulario from '../Custom/Subcribirse/Panel/Formulario';

export function Suscribir({ data: { props, html }, size }: any) {
    const { backgroundColor, img, hideText, cardTitle } = props;
    const { i, l, r, t, h } = cols(size, img);
    return <div style={{ padding: '60px 0 0 0', background: backgroundColor }}>
        <div className="container">
            <Row>
                <Col md={r}><Row className='d-flex flex-row-reverse text-center'>
                    <Col md={t}>
                        <Row className='m-2 p-2'><Html html={html} /></Row>
                        {h ? <Row className='m-2 p-2'><Html html={hideText} /></Row> : <></>}
                    </Col>
                    {img ? i ? <Col md={i}><img loading="lazy" key={img} src={getImagenURL(img)} width='100%' alt='panel a' /></Col> : <></> : <></>}
                </Row></Col>
                <Col md={l} style={{ padding: '30px 0' }}>
                    <Formulario config={props as any}><Html html={cardTitle} /></Formulario>
                </Col>
            </Row>
        </div>
    </div>
}