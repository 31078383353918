/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Row } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { NuevoTag } from '../../../../../Components/Selectors/BotonesTags';
import{ Paginador } from "@victorequena22/tabla-bootstrap";
import { InputText } from "@victorequena22/component-bootstrap";
import { Etiqueta, setData } from './Botones';
export default function Tags() {
    const [lista, setLista] = useState<any[]>([]);
    const [buscar, setBuscar] = useState('');
    const actualizarLista = useCallback(() => setData(setLista), []);
    useEffect(() => { actualizarLista(); }, []);
    return <Card>
        <Card.Header className='bg-info'>
            <h4 className='mr-auto'><i className="fa fa-edit"></i> EDITAR ETIQUETAS </h4>
        </Card.Header>
        <Card.Body>
            <Row className="mb-1 pl-2 pr-2">
                <Row style={{ width: '240px' }} className="mr-auto pb-1 pt-1">
                    <small className='m-auto'>BUSCAR ETIQUETAS</small>
                    <InputText className='form-control-sm' style={{ width: '120px' }} maxLength={20} upper setData={setBuscar} value={buscar} /><br />
                </Row>
                <NuevoTag actualizar={actualizarLista} />
            </Row>
            <Row>
                <Paginador mostrar={50} itens={lista.filter(i => {
                    const en = buscar.split(" ");
                    const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
                    return en.length === con.length;
                })} bodyStyle={{
                    display: 'grid', gap: '.5rem', gridAutoFlow: 'dense',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))'
                }} row={(p) => <Etiqueta p={p} actualizar={actualizarLista} />} />
            </Row>
        </Card.Body>
    </Card>
}
