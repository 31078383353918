import { Row, Container, Card, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ProgramaContext } from '../../Capacitacion/Context/Programas';
import { getImagenURL } from '@victorequena22/conneccion';
import { getCSSButton } from '@/App/Panel/Props';
import { getSettingCarrusel } from '@/App/Panel/Component/Commons/Carrusel';
export function Programas({ data: { props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const { color, filtroType, filtro, inter } = props;
    const { programas } = useContext(ProgramaContext);
    const e = programas.filter(e => e.estatus === 2)
        .filter(e => {
            if (filtroType !== 'none') {
                if (filtroType === 'categoria' && filtro !== 0)
                    return e.categoria === filtro;
            }
            return true;
        }).map(e => <Itens iten={e} props={props} />);
    const slidesToShow = s2.some(s => size === s) ? 4 : s1.some(s => size === s) ? 3 : 2;
    let settings = getSettingCarrusel(e.length, slidesToShow);
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container>
            <Slider className={getCSSButton(inter)} {...settings}>{e}</Slider>
        </Container>
    </Row>
}
function Itens({ props: { gap }, iten: { short, id } }: any) {
    return <Col className='m-0 p-0'>
        <Card as={Link} to={`/programa/edit/${id}`} style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(short)} />
        </Card>
    </Col >
}