import { memo, useState } from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import FormUsuario from "./FormUsuario"
import FormClave from "./FormClave"
import { THFila } from "@victorequena22/tabla-bootstrap"
import { conAliado, AliadoFila } from "./Aliado"
import { ListButonLink } from "../../../Components/Button/ListBoton"
interface Props {
    d: AliadoFila
    update: () => void
}
export default memo(function Fila({ update, d }: Props) {
    const { nombre, correo, rol, estado, id } = d;
    const [edit, setEdit] = useState(false);
    const [clave, setClave] = useState(false);
    const status = estado === 1 ?
        <span className="badge badge-success">ACTIVO</span>
        : <span className="badge badge-danger">INACTIVO</span>
    const cancelar = () => conAliado().cancelar(id, update);
    const btnCancelar = estado === 1 ?
        <Button data-tip="ELIMINAR" variant="danger" onClick={cancelar} >
            <i className="fa fa-ban"></i>
        </Button> : <Button data-tip="ACTIVAR" variant="success" onClick={cancelar} >
            <i className="fa fa-check"></i>
        </Button>
    return <tr>
        <THFila width="calc(100% - 680px)">
            {nombre}
        </THFila>
        <THFila width="350px">
            {correo}
        </THFila>
        <THFila width="150px">
            {rol}
        </THFila>
        <THFila width="70px">
            {status}
        </THFila>
        <th style={{ width: "110px" }}>
            <ButtonGroup className="float-right">
                <Button data-tip="EDITAR" variant="primary"
                    onClick={() => setEdit(true)}>
                    <i className="fa fa-edit"></i></Button>
                <ListButonLink permiso='ALIADO' variant="info"
                    tip='ASIGNAR RESPOSABILIDADES'
                    to={`/aliado/asignaciones/${id}`}>
                    <i className="fa fa-plus" />
                </ListButonLink>
                <FormUsuario close={() => setEdit(false)}
                    title={'EDITAR USUARIO DE ' + nombre}
                    update={update} open={edit} user={d} />
                <FormClave open={clave} close={() => setClave(false)}
                    user={d} title={'CAMBIAR CONTRASEÑA ' + nombre} />
                <Button data-tip="CAMBIAR CONTRASEÑA" variant="warning"
                    onClick={() => setClave(true)}>
                    <i className="fa fa-lock" ></i>
                </Button>
                {btnCancelar}
            </ButtonGroup>
        </th>
    </tr>
})