import { useEvento } from "../../index";
import { useMemo } from "react";
import { Row } from "react-bootstrap";
import Torta2 from "@/Components/Graficos/Torta2";
export function Encuesta() {
    const evento = useEvento();
    const values = useMemo(() => {
        const values: [string, number][] = [];
        evento.encuesta.forEach(e => {
            const i = values.findIndex(x => x[0] === e.respuesta);
            if (i > -1) values[i][1]++;
            else values.push([e.respuesta, 1]);
        })
        return values;
    }, [evento])
    return <Row>
        <div style={{ textAlign: 'center' }}>
            <h5>ENCUESTA</h5>
            <Torta2 labels={['ESTADOS', 'CANT']} data={values} />
        </div>
    </Row>
}
