import { toast } from 'react-toastify';
import { Component } from 'react';
import { defaultText, sizeWidth } from '../Component/DefaultData';
import { BlockElement, getText, setText } from '@victorequena22/slate-bootstrap';
import { conPanel } from '@/App/Panel/Models';
import { conLanding } from '@/App/Panel/Models/Landing';
export interface StateS {
    id?: number;
    size: sizeWidth;
    title: string;
    html: BlockElement[];
}
export interface Props<State> {
    data?: State,
    copy?: State,
    edit?: string
    model?: string;
    id?: number;
}
export class Interface<S> extends Component<Props<StateS & S & { id: number }>, (StateS & S)> {
    stateDefault: StateS = { size: '1440px', title: '', html: defaultText };
    statePanel!: any;
    #procesar = true;
    constructor(p: Props<StateS & S & { id: number }>) {
        super(p);
        this.state = { ...this.stateDefault, ...this.statePanel };
    }
    setTexto = (text: BlockElement[]) => { this.setConfig({ html: getText(text) } as Partial<StateS & S>); }
    getTexto = () => setText(this.state.html)
    setConfig = (d: any) => this.setState(d as any);
    save = async () => {
        if (this.initPropcesar()) this.#send();
    }
    getData = (): any => { }
    initPropcesar = () => {
        if (this.#procesar) {
            this.#procesar = false;
            return true;
        }
        toast.warning('SUBIENDO DATOS, POR FAVOR ESPERE');
        return false;
    }
    #send = () => {
        const send = this.#getDataSave();
        const { data } = this.props;
        const { model } = send;
        let con = (model !== undefined) ? conPanel() : conLanding();
        if (data === undefined) con.nuevo(send, p => this.success('PANEL CREADO', p.id), () => this.#procesar = true);
        else con.actualizar(data.id, send, () => this.success('PANEL ACTUALIZADO'), () => this.#procesar = true);
    }
    success = (msn: string, id?: number) => {
        this.setState({ id } as any);
        toast.success(msn);
        this.#procesar = true;
    }
    #getDataSave = (): any => {
        const a = this.getData();
        const { model, id: model_id } = this.props;
        return { ...a, page: 1, model, model_id };
    }
    componentDidMount(): void {
        this.setConfig({ ...this.stateDefault, ...this.statePanel });
        const { data, copy } = this.props;
        if (data !== undefined) this.#setInit(data);
        else if (copy !== undefined) this.#setInit(copy);
    }
    #setInit = ({ props, data: d, title, html: h }: any) => {
        const html = h !== null ? setText(h) : [];
        this.setState({ ...props, ...d, html, title });
    }
}