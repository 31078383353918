import { useContext } from "react"
import { UsuarioFila } from "../../../Usuarios/Usuario"
import { UserContext } from "../../../../Template/UserContex"
import { AliadoFila } from "../../Aliado/Aliado"
import { formatoFecha, formatoHora } from "@victorequena22/utiles"
import { SeccionContext } from "../../../../Template/Seccion"

export interface sms {
    id: number
    mensaje: string
    usuario: number
    tipo: string
    fecha: string
}

export default function Mensaje({ sms }: { sms: sms }) {
    const { usuario, tipo } = sms;
    const { aliados, usuarios, tipo: t } = useContext(UserContext);
    const { data: { User: { id_usuario } } } = useContext(SeccionContext) as any;
    if (t === tipo && usuario === id_usuario) {
        return <MYSNS sms={sms} />
    }
    if (tipo === 'TALENTO') {
        const i = usuarios.find(u => u.codigo === usuario);
        if (i) return <Mensaje1 sms={sms} usuario={i} />
    }
    const i = aliados.find(u => u.id === usuario);
    if (i) return <Mensaje2 sms={sms} usuario={i} />
    return <></>
}
export function Mensaje1({ sms, usuario }: { sms: sms, usuario: UsuarioFila }) {
    const { tipo, mensaje, fecha } = sms;
    const { nombre } = usuario;
    return <>
        <div className="d-flex mt-2 justify-content-between w-100">
            <p className="small mb-1"><b className="text-primary">{tipo}:</b><b>{nombre}</b></p>
            <p className="small mb-1 text-muted">{formatoFecha(fecha)} {formatoHora(fecha)}</p>
        </div>
        <div className="d-flex flex-row justify-content-start">
            <img src="/logos/logo.png" alt="avatar 1" style={{ width: '45px' }} />
            <div style={{ width: 'calc(100% - 45px)' }}>
                <p style={{ borderRadius: '.25rem' }}
                    className="small h-100 ml-2 p-2 ms-3 mb-3 rounded-3 bg-primary">
                    <b>{mensaje}</b>
                </p>
            </div>
        </div>
    </>
}
export function Mensaje2({ sms, usuario }: { sms: sms, usuario: AliadoFila }) {
    const { mensaje, fecha, tipo } = sms;
    const { nombre } = usuario;
    return <>
        <div className="d-flex mt-2 justify-content-between w-100">
            <p className="small mb-1"><b className="text-warning">{tipo}:</b><b>{nombre}</b></p>
            <p className="small mb-1 text-muted">{formatoFecha(fecha)} {formatoHora(fecha)}</p>
        </div>
        <div className="d-flex flex-row justify-content-end mb-4 pt-1">
            <img src="/logos/logo.png" alt="avatar 1" style={{ width: '45px' }} />
            <div style={{ width: 'calc(100% - 45px)' }}>
                <p style={{ borderRadius: '.25rem' }}
                    className="small h-100 ml-2 p-2 ms-3 mb-3 rounded-3 bg-warning">
                    <b>{mensaje}</b>
                </p>
            </div>
        </div>
    </>
}
export function MYSNS({ sms }: { sms: sms }) {
    const { mensaje, fecha } = sms;
    return <>
        <div className="d-flex mt-2 justify-content-between w-100">
            <p className="small mb-1 text-muted">{formatoFecha(fecha)} {formatoHora(fecha)}</p>
        </div>
        <div className="d-flex flex-row justify-content-end mb-4 pt-1">
            <div style={{ width: 'calc(100% - 45px)' }}>
                <p style={{ borderRadius: '.25rem', backgroundColor: '#f5f6f7' }}
                    className="small h-100 ml-2 p-2 ms-3 mb-3 rounded-3">
                    <b>{mensaje}</b>
                </p>
            </div>
            <img src="/logos/logo.png" alt="avatar 1" style={{ width: '45px', height: '100%' }} />
        </div>
    </>
}