import { VerContry, VerEstado } from "../../../../Components/Contry/Ver";
import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import { findParticipanteFila } from "@/App/Capacitacion/Context/Participante";
import { Interesado } from "@/App/Capacitacion/Programas/Models/Models";

interface Props {
    i: Interesado;
}

export default function Fila({ i: { id, estatus: [bg, st] } }: Props) {
    const { nombre, correo, telefono, pais, estado, ciudad } = findParticipanteFila(id);
    // const estatus = useCallback((d: any) => getStatus(url, id, d, () => { }), [id, url]);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='auto'>{correo}</THFila>
        <THFila width='auto'>{telefono}</THFila>
        <THFila width='auto'><VerContry key={pais} pais={pais} /></THFila>
        <THFila width='auto'><VerEstado key={pais + estado} pais={pais} estado={estado} /></THFila>
        <THFila width='auto'>{ciudad}</THFila>
        <THFila width='auto'><span className={`badge bg-${bg}`}>{st}</span></THFila>
        <THInput width='auto'>
            <ButtonGroup>
                {/* <StatusChange bg={bg} status={st} update={estatus} /> */}
                {/* <Chat id={id} url={url} /> */}
            </ButtonGroup>
        </THInput>
    </tr>
}