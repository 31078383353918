
import { Button, Col, Modal } from 'react-bootstrap';
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { conMetodoPago, MetodoPago } from '../Models';
interface Props {
    data: MetodoPago
    update?: () => void
}
export default class EditMetodoPago extends Prototipo<Props> {
    constructor(p: any) {
        super(p)
        this.state = p.data;
    }
    render = () => {
        const { setData, error, state, create, props: { open, close } } = this;
        return (<Modal onHide={close} show={open} size='xl' >
            <Modal.Header className='bg-info' closeButton>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> ACTUALIZAR MÉTODO DE PAGO </h4>
            </Modal.Header>
            <Form data={state} setData={setData as any} error={error}>
                <Col xs={12}>
                    <Button variant='success' className='float-right' onClick={create}>
                        <i className="fa fa-save"></i> Guardar
                    </Button>
                </Col>
            </Form>
        </Modal>)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                const { update, close, data: { id } } = this.props;
                conMetodoPago().actualizar(id, this.getDataSave(), () => {
                    this.procesar = true;
                    toast.success('METODO DE PAGO ACTUALIZADO');
                    if (update) update();
                    close();
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}