import { getParticipantes } from "@/App/Capacitacion/Context/Participante";
import { useEvento } from "../index";
import Template from "../Template";
import Tabla from "./Tabla";

export default function Certicados() {
    const { participantes: p } = useEvento();
    const l = getParticipantes();
    return <Template icon="fas fa-file-certificate" title="GENERAR CERTIFICADOS">
        <Tabla itens={l.filter(e => p.some(s => s.id === e.id && s.status === 3))} />
    </Template >
}