import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { conConsideracion } from '../../Models';
interface Props {
    change: (r: number) => void,
    defaultValue: number,
    width?: string
    tipo?: 'TODOS LAS CONSIDERACIONES' | 'SELECCIONAR CONSIDERACION'
    isInvalid?: boolean
    className?: string
}
export default function ConsideracionesSelector({ className, change, defaultValue, width, tipo = 'SELECCIONAR CONSIDERACION', isInvalid }: Props) {
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(parseInt(e.currentTarget.value));
    const [consideraciones, setConsideraciones] = useState<any[]>([])
    const actualizarConsideraciones = useCallback(() => { conConsideracion().lista(setConsideraciones) }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => actualizarConsideraciones(), []);
    return <InputGroup className={className}>
        <InputGroup.Prepend><InputGroup.Text>CONSIDERACION</InputGroup.Text></InputGroup.Prepend>
        <FormControl onClick={actualizarConsideraciones} as='select' style={{ width }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
            <option value='0'>{tipo}</option>
            {consideraciones.map((c, i) => <option key={i} value={c.id}>{c.nombre}</option>)}
        </FormControl>
    </InputGroup>
}