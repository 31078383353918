import { Button, Card, Col, Row } from "react-bootstrap";
import { State } from '../index'
import { getCSS } from "./props";
import { FieldText } from "@/Components/Field";
import { Box } from "@mui/material";
import { SelectBanderaFronTel } from "@/App/PanelProxi/Utils/CountrySelector";
const set = (_s: string) => { }
export default function Suscribir({ config, children }: { config: State, children: any }) {
    const { status } = config;
    return <Card style={{ width: '100%' }} className={`${getCSS(config)} m-0 p-0`}>
        <Row className='m-2 p-2' >
            {status === 'Suscrito' ? <Suscrito /> : <>
                <Col className='m-2 p-0' md={12}>{children}</Col>
                {status === 'Login' ? <Login /> : <Formulario />}
            </>}
        </Row>
    </Card>
}


export function Formulario() {
    return <>
        <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Nombre*" value='' /></Col>
        <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Correo Electronico*" value='' /></Col>
        <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Pais*" value='' /></Col>
        <Col className='input m-2 p-0' md={12}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <SelectBanderaFronTel value={58} change={_p => { }} />
                <span style={{ paddingTop: ".5rem" }}>+58-</span>
                <FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Whatsapp*" value='' />
            </Box>
        </Col>
        <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Estado*" value='' /></Col>
        <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Ciudad*" value='' /></Col>
        <Col className='boton m-2 p-0' md={12}><Button block variant="primary"><b>¡Inicia ahora!</b></Button></Col>
    </>
}
export function Login() {
    return <>
        <Col className='m-2 p-0' xs={12}>NOMBRE: Victor Requena</Col>
        <Col className='m-2 p-0' xs={12}>TELEFONO: ****-***-****</Col>
        <Col className='m-2 p-0' xs={12}>CORREO ELECTRONICO: ****@*****.***</Col>
        <Col className='m-2 p-0' xs={12}><b>PAIS:</b> Venezuela</Col>
        <Col className='m-2 p-0' xs={12}><b>Estado:</b> Lara</Col>
        <Col className='boton m-2 p-0' md={12}><Button block variant="primary"><b>¡Inicia ahora!</b></Button></Col>
    </>
}
export function Suscrito() {
    return <>
        <Col className='boton m-2 p-0' md={12}><Button block variant="primary"><b>Gracias por suscribirte</b></Button></Col>
    </>
}
