import { IFrame } from "@/Components/Contens/IFrame";
import { Button, Col, Modal, Row } from "react-bootstrap";
import CertificadoSelector from "../../Parametros/Certificado/Selector";
import { useCallback, useEffect, useState } from "react";
import { useEvento, usePrograma } from "../index";
import { ControlText } from "@victorequena22/component-bootstrap";
import { zfill } from "@victorequena22/utiles";
import { don } from "@victorequena22/conneccion";
import { conCC } from "../../Models/Certificados";

export default function GenerarCertificados() {
    const [open, setOpen] = useState(false)
    return <>
        <Modal show={open} size='xl' centered onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>GENERAR CERTIFICADOS</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body><Form close={() => setOpen(false)} />
            </Modal.Body>
        </Modal>
        <Col className="text-right">
            <Button onClick={() => setOpen(true)} variant='info'>
                <i className="fas fa-file-certificate" /> GENERAR CERTIFICADOS
            </Button>
        </Col>
    </>
}
function useCodigo() {
    const { codigo: ce, cohorte } = useEvento();
    const { codigo: cp } = usePrograma();
    return useState(`${cp}${ce}${zfill(cohorte, 5)}`);
}
function Form({ close }: { close: () => void }) {
    const { id } = useEvento();
    const [certificado, setCertificado] = useState(0);
    const [codigo, setCodigo] = useCodigo();
    const [sendMail, setSendMail] = useState(false);
    const [error, setError] = useState<any>({});
    const send = useCallback(() => {
        const error: any = {};
        let send = true;
        if (certificado === 0) { error.certificado = 'DEBE CELECCIONAR UN CERTIFICADO A GENERAR'; send = false; }
        if (codigo === '') { error.codigo = 'DEBE COLOCAR UN CODIGO BASE PARA PODER GENERAR LOS CERTIFICADOS'; send = false; }
        if (send) {
            conCC().nuevo({ evento: id, codigo, certificado, sendMail: sendMail ? 1 : 0 });
            close();
        } else setError(error)
    }, [id, certificado, codigo, sendMail, close]);
    useEffect(() => {
        const e: any = {};
        if (certificado === 0) { e.certificado = error.certificado }
        if (codigo === '') { e.codigo = error.codigo }
        setError(e)
    }, [certificado, codigo, error]);
    return <>
        <Row>
            <Col xs={7}>
                <CertificadoSelector isInvalid={error.certificado} change={setCertificado} defaultValue={certificado} />
                {error.certificado ? <span className="text-danger">{error.certificado}</span> : <></>}
            </Col>
            <Col xs={5}>
                <label className="mt-1" onClick={() => setSendMail(!sendMail)}>
                    <input className="mr-1" type="checkbox" value={sendMail ? 'true' : 'false'} />
                    ENVIAR EMAIL A LOS PARTICIPANTES APROBADOS
                </label>
            </Col>
            <Col xs={10} className="mt-2">
                <ControlText before="CODIGO BASE PARA LOS CERTIFICADOS" error={error.codigo} value={codigo} setData={c => setCodigo(c)} />
                {error.codigo ? <span className="text-danger">{error.certificado}</span> : <></>}
            </Col>
            <Col xs={2} className="mt-2 text-right">
                <Button onClick={send} variant='info'>GENERAR</Button>
            </Col>
        </Row>
        <Row className="mt-2">
            {certificado > 0 ? <IFrame url={`${don}/api/Evento/Certificados/pdf/Demo/${certificado}/${id}`} /> : <></>}
        </Row>
    </>
}