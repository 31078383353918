import { CardMain, SizeViewSelector, Title, Guardar, ButtonColor, TitleCard, CardConfig, RowConfig } from '../../Component';
import { State, iten } from './index'
import { ItenImagen } from '../../Component/Commons';
import { ColorFondo } from '../../Component/Commons/Simples';
import { ColorDesplazamiento } from '../../Component/Commons/Carrusel';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    copy: (d: number) => void
    removeItem: (d: number) => void
}
export function Config({ save, copy, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, color, inter, up, down, itens: { length }, } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="227px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ColorFondo gradiend setColor={color => setConfig({ color })} color={color} />
                <ColorDesplazamiento className='ml-1' setColor={inter => setConfig({ inter })} color={inter} />
                <ButtonColor className='ml-1' gradiend tip='PANEL SUPERIOR' invert
                    setData={up => setConfig({ up })} color={up}>
                    <i className="fas fa-arrow-alt-from-bottom"></i>
                </ButtonColor>
                <ButtonColor gradiend className='ml-1' tip='PANEL INFERIOR' invert
                    setData={down => setConfig({ down })} color={down}>
                    <i className="fas fa-arrow-alt-from-top"></i>
                </ButtonColor>
            </RowConfig>
            <div className='mt-2' style={{ height: '24px' }} />
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <ItenImagen copy={copy}
            itenDown={itenDown} removeItem={removeItem}
            setImagen={(img) => setIten({ img })}
            cont={length} iten={iten} aspect={1}
            addItem={addIten} itenUp={itenUp} />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}