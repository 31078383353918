import { getPrograma } from "../../index";
import { useCallback, useMemo, useState, useEffect } from "react";
import { getParticipantes } from "../../../../Context/Participante";
import { getEstado } from "../../../../../../Components/Contry/Ver";
import Torta2 from "@/Components/Graficos/Torta2";
import { Row } from "react-bootstrap";
export function Estados() {
    const estados = useCount();
    const values = useCountry(estados);
    return <Row>
        <div style={{ textAlign: 'center' }}>
            <h5>ESTADOS</h5>
            <Torta2 labels={['ESTADOS', 'CANT']} data={values} />
        </div>
    </Row>
}
function useEstado(): [string, string][] {
    const participantes = getParticipantes();
    const lista = getPrograma().participantes;
    const par = useMemo(() => participantes.filter(p => lista.some(e => e === p.id)), [participantes, lista])
    return par.map(p => [p.pais, p.estado]);
}
function useCount(): [[string, string], number][] {
    const estados = useEstado();
    const cont: [[string, string], number][] = [];
    estados.forEach(e => {
        const i = cont.findIndex(x => x[0][0] === e[0] && x[0][1] === e[1]);
        if (i > -1) cont[i][1]++;
        else cont.push([e, 1]);
    })
    return cont;
}
function useCountry(estados: [[string, string], number][]): any {
    const [values, setValues] = useState<[string, number][]>([])
    const set = useCallback(() => {
        const v: [string, number][] = [];
        const p: Promise<void>[] = [];
        const pushEstado = async ([[p, e], c]: any) => {
            const estado = await getEstado(p, e);
            console.log(estado)
            if (estado) {
                v.push([estado, c]);
            }
        }
        estados.forEach(e => p.push(pushEstado(e)))
        Promise.all(p).then(() => setValues(v))
    }, [estados])
    // eslint-disable-next-line
    useEffect(() => { set() }, []);
    return values;
}



