
import { Row, Col, Card } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { Component } from 'react';
import { ControlText } from "@victorequena22/component-bootstrap";
import { TypeAuxiliar } from '../../Models';
import { Texto } from "@victorequena22/slate-bootstrap";
import { getColors } from '../../../../../Template/Colores';
interface Props {
    data: TypeAuxiliar,
    error: any,
    setData: (data: Partial<TypeAuxiliar>) => void,
    children?: any;
    codigo?: string;
    random?: number;
}
export function Form({ data: { nombre, texto }, setData, error, children }: Props) {
    return <Card.Body style={{ minHeight: 'calc(100vh - 210px)' }}>
        <ReactTooltip />
        <Row>
            <Col xs={10}>
                <ControlText maxLength={45} before='NOMBRE' value={nombre} setData={setData} update='nombre' error={error} />
            </Col>
            <Col xs={2}>{children}</Col>
        </Row>
        <Row><Col xs={12} className='mt-2'>
            <label>CONTENIDO</label>
            <Texto colores={getColors()} error={error['texto']} value={texto} onChange={texto => setData({ texto })} />
        </Col></Row>
    </Card.Body>
}
export class Prototipo<p> extends Component<p, TypeAuxiliar & { Redirect: boolean }> {
    error: any = [];
    procesar = true;
    random = 0;
    validar = () => {
        this.error = [];
        let procesar = true;
        const { nombre, texto } = this.state;
        if (nombre === '') {
            this.error['nombre'] = 'DEBE COLOCAR UN NOMBRE';
            procesar = false;
        } if (texto.length === 0) {
            this.error['texto'] = 'DEBE COLOCAR UN CONTENIDO';
            procesar = false;
        }
        return procesar;
    }
    getDataSave = () => {
        const { nombre, texto } = this.state;
        return { nombre, texto };
    }
    setError = (err: any[]) => {
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast.error(er));
            } else {
                Object.keys(e).forEach(t => this.error[t] = e[t])
            }
        })
        this.procesar = true;
        this.forceUpdate();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}