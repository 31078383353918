import { GrupoPago } from "@/App/Capacitacion/Pagos/Models/Model";
import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import { Col } from "react-bootstrap";
import Fila from "./Fila";

export default class Tabla<p> extends CardBuscador<{ itens: GrupoPago[] } & p> {
    buscarEn = ['nombre', 'correo', 'telefono'];
    render() {
        let { Body, Buscador, } = this;
        return <><Col md={12}><Buscador /></Col><Body /></>
    }
    Row = (g: GrupoPago) => <Fila data={g} /> 
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th aling="right" width='100px'>CUPOS</Th>
            <Th aling="right" width='100px'>PARTI</Th>
            <Th aling="right" width='100px'>PRECIO</Th>
            <Th aling="right" width='150px'>MONTO</Th>
            <Th aling="right" width='100px'>PAGOS</Th>
            <Th width='110px'>ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}