import { ConexionCompleta } from "@victorequena22/conneccion";
import { Iten } from "./From/Itens/Props";

class CertificadosConecction extends ConexionCompleta {
    clase = 'Evento/Certificados';
}
export function conCertificados() {
    return new CertificadosConecction();
}

export interface CertificadosFila {
    id: number;
    nombre: string;
    creacion: string;
}
export interface Certificados extends CertificadosFila {
    data?: string;
    props: Props;
}
export const certificadosNull: Certificados = {
    id: 0,
    nombre: '',
    creacion: '',
    props: {
        aspect: '1080/834',
        width: 1080,
        itens: []
    }
}

export interface Props {
    aspect: string,
    width: number,
    text?: number,
    itens: Iten[]
}