/* eslint-disable react-hooks/rules-of-hooks */
import { semaforo } from '@victorequena22/utiles';
import { useState, createContext, useEffect, useCallback, useContext } from 'react'
import { conParticipante, ParticipanteFila, participanteUpdate, ParticipanteNull } from '../Participantes/Models';

export interface ParticipanteType {
    participantes: ParticipanteFila[]
    actualizar: () => void
}
export const ParticipanteContext = createContext<ParticipanteType>({ participantes: [], actualizar: () => { } });
export default function ParticipanteProvider({ children }: { children: any }) {
    const [participantes, setParticipantes] = useState<ParticipanteFila[]>([]);
    const [con] = useState(conParticipante());
    const actualizar = useCallback(() => participanteUpdate(con, participantes, setParticipantes), [con, participantes]);
    useEffect(() => {
        const s = semaforo();
        s.add('Participante');
        return () => s.clean('Participante');
    }, []);
    useEffect(() => {
        let interval = setInterval(actualizar, 50);
        return () => clearInterval(interval);
    }, [actualizar]);
    return <ParticipanteContext.Provider value={{ participantes, actualizar }}>
        {children}
    </ParticipanteContext.Provider>
}
export const SeccionConsumer = ParticipanteContext.Consumer;

export function findParticipante(id: number) {
    const { participantes } = useContext(ParticipanteContext);
    const [participante, setParticipante] = useState(participantes.find(p => p.id === id));
    useEffect(() => { conParticipante().detalle(id, setParticipante) }, [id]);
    if (participante) return ParticipanteNull;
    if (id === 0) return ParticipanteNull;
    return participante ? participante : ParticipanteNull;
}
export function findParticipanteFila(id: number) {
    const { participantes } = useContext(ParticipanteContext);
    if (id === 0) return ParticipanteNull;
    const participante = participantes.find(p => p.id === id);
    return participante ? participante : ParticipanteNull;
}
export function getParticipantes() {
    return useContext(ParticipanteContext).participantes;
}