import { useState, useEffect } from 'react';
import Tags from './Tags';
interface Props {
    herencia?: number[];
    tags: number[];
    setTags: (tags: number[]) => void;
}
export default function SeleccionarTags({ tags, setTags, herencia }: Props) {
    const [values, setValues] = useState(tags);
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setValues(tags), [open])
    return <Tags save={() => setTags(values)} herencia={herencia}
        setTags={setValues} tags={values} open={open} setOpen={setOpen} />
}