import { Col, Row } from 'react-bootstrap';
import { RutasWeb, MenuWeb } from './Web';
import { useContext } from 'react';
import { UnAutorization } from "@victorequena22/component-bootstrap";
import { SeccionContext, SeccionType } from "../../Template/Seccion";
import { MenuGenerales, RutasGenerales } from "./Generales";

export default function MasterConfig() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => p === 'CONFIG'))
        return (<Row style={{ minHeight: "calc(100vh - 145px)" }}>
            <Col md={3} style={{ minHeight: "calc(100vh - 145px)" }}>
                <MenuGenerales />
                {/* <MenuImpresiones /> */}
                <MenuWeb />
            </Col>
            <Col md={9} style={{ minHeight: "calc(100vh - 145px)" }}>
                <RutasGenerales />
                <RutasWeb />
            </Col>
        </Row>)
    return <UnAutorization />
}