import { Button } from "react-bootstrap";
import { Props } from "../Itens/Props";
import { setImage } from "./SetImagen";

export function Image({ setIten, config: { width } }: Props) {
    return <Button style={{ margin: 0, paddingBlock: 0, paddingInline: 5, height: 24 }}
        data-tip="SELECCIONAR IMAGEN" as='label' className="ml-auto" size="sm" variant="outline-primary">
        <input style={{ display: "none" }} type="file" accept='image/*' onChange={(e) => setImage(e, setIten, width / 3)} />
        <i className="fa fa-image" />
    </Button>
}