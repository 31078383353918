import { Row, Tab, Tabs } from "react-bootstrap";
import Subcriptores from "./Subcriptores";
import Interesados from "./Interesados";
import Graficos from "./Graficos";
import Template from "../Template";
import Encuesta from "./Encuesta";
export default function Estadisticas() {
    return <Template icon="fa fa-chart-bar" title="ESTADISTICAS DEL PROGRAMA">
        <Tabs variant="tabs" defaultActiveKey="graficos" id="Participantes" className='taps-Participantes'>
            <Tab eventKey="graficos" title="GRAFICOS"><Row className="p-3"><Graficos /></Row></Tab>
            <Tab eventKey="encuesta" title="ENCUESTA"><Row className="p-3"><Encuesta /></Row></Tab>
            <Tab eventKey="interesados" title="REGISTRADOS"><Row className="p-3"><Interesados /></Row></Tab>
            <Tab eventKey="suscriptores" title="INTERESADOS"><Row className="p-3"><Subcriptores /></Row></Tab>
        </Tabs>
    </Template> 
}