import { Button, ProgressBar, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { conMedia, getSize } from '../Models';
interface Props {
    accept?: string;
    actualizar: () => void;
    multi?: boolean;
    max: number;
    size: number;
}
export function ButtonImage({ max, actualizar, size, multi = false, accept = 'image/*,video/*' }: Props) {
    const now = (size / max) * 100;
    return <div style={{ width: '500px' }} className='ml-auto d-flex'>
        <div style={{ width: '85px', padding: '.3rem 0 .3rem 0' }}>CAPACIDAD</div>
        <Col><ProgressBar label={`${getSize(size)}/${getSize(max)}`} now={now}
            style={{ margin: '.6rem 0 .6rem 0', border: '.1rem solid currentColor' }}
            variant={now > 50 ? now > 80 ? 'danger' : 'warning' : 'success'} /></Col>
        <Button as='label' size='lg'>
            <i className="fas fa-upload"></i>
            <input style={{ display: "none" }} multiple={multi} type="file"
                accept={accept} onChange={(e) => setImage(e, max - size, () => { actualizar(); })} />
        </Button>
    </div>
}
const error = (s: string, z: string) =>
    toast.error(`${s} QUE INTENTA SUBIR ES DEMASIADO GRANDE, EL TAMAÑO MAXIMO ES DE ${z}`);
const error2 = (s: string, z: string) =>
    toast.error(`${s} QUE INTENTA SUBIR ES DEMASIADO GRANDE, EL TAMAÑO DISPONIBLE ES DE ${z}`);
function ToString(file: any, setData: (d: string, file: any) => void) {
    let reader = new FileReader()
    reader.onloadend = () => setData(reader.result as string, file);
    reader.readAsDataURL(file);
}
const setImage = (e: React.ChangeEvent<HTMLInputElement>, max: number, actualizar: () => void) => {
    const upload = (data: string, file: any) => {
        const up = (s: string, tags: number[]) => conMedia().nuevo({ data, tags }, () => {
            actualizar();
            toast.success(`${s} HA SIDO SUBIDO`);
        }, () => { });
        const val = (s: string, size: number, tags: number[]) => {
            if (file.size > max) error2(s, getSize(max));
            else if (file.size > size) error(s, getSize(size));
            else up(s, tags);
        }
        if (data.indexOf('video') > -1) val('EL VIDEO', 7340032, [-1, -4]);
        else if (data.indexOf('image') > -1) val('LA IMAGEN', 1048576, [-1, -3]);
        else toast.error(`EL TIPO DE ARCHIVO QUE INTENTA SUBIR NO ES COMPATIBLE`);
    };
    let f = e.currentTarget.files;
    if (f !== null) Array.from(f).forEach(f => ToString(f, upload));
}