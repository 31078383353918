import { getImagenURL } from "@victorequena22/conneccion";
import { CSSProperties } from "react";

export function getStyle({ data, aspect, width, top, left, right, bottom }: any, edit: any): CSSProperties {
    let img = edit !== undefined ? getImagenURL(`/api/Evento/Certificados/imagen/${edit.id}`) : '';
    if (data) img = data;
    return {
        aspectRatio: aspect, width, border: '1px solid #555',
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
        marginBottom: 0, backgroundImage: `url(${img})`
    }
}