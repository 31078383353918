import { ControlText } from "@victorequena22/component-bootstrap";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { conRecursos } from "../../Models";
export function Nuevo({ id, update }: { id: number, update: () => void }) {
    const [open, setOpen] = useState(false);
    return <>
        <ModalNuevo id={id} open={open} close={() => setOpen(false)} update={update} />
        <Button onClick={() => setOpen(true)}>NUEVO RECURSO</Button>
    </>
}
interface Props {
    open: boolean,
    close: () => void,
    id: number
    update: () => void
}
function ModalNuevo({ open, close, id, update }: Props) {
    const [nombre, setNombre] = useState('');
    const [data, setData] = useState('');
    const [preview, setPreview] = useState('');
    const [file, setFile] = useState<any>(null);
    const [error, setErr] = useState<any>({});
    useEffect(() => () => {
        const err: any = {};
        if (data !== '') err.data = undefined; else err.data = error.data;
        if (nombre !== '') err.nombre = undefined; else err.nombre = error.nombre;
        if (err.nombre !== error.nombre || err.data !== error.data) setErr(err);
    }, [error, data, nombre])
    console.log(preview)
    return <Modal show={open} size='xl' centered onHide={close}>
        <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>NUEVO RECURSO</b></span>
            </h6>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={7}>
                    <ControlText error={error.nombre} before='NOMBRE DEL ARCHIVO' setData={setNombre} value={nombre} />
                </Col>
                <Col md={3}>
                    <Button as='label' block variant={error.data ? 'danger' : 'info'}>
                        <input style={{ display: 'none' }} type="file" accept='application/pdf'
                            onChange={(e: any) => ToString(e.currentTarget.files[0], (d, f) => {
                                setData(d)
                                setFile(f)
                            }, setPreview)} />
                        SELECCIONAR ARCHIVO
                    </Button>
                </Col>
                <Col md={2}>
                    <Button variant="success" block onClick={() => {
                        conRecursos().nuevo({ nombre, data, programa: id }, () => {
                            setErr({}); close(); setNombre(''); setData(''); update();
                        }, (s: any) => setError(s, setErr))
                    }}>GUARDAR</Button>
                </Col>
            </Row>
            {file !== null ? <Row>
                <Col className="col-12">
                    <b>ARCHIVO SELECCIONADO:</b> {file.name}<br />
                    <b>PESO DEL ARCHIVO:</b> {mb(file.size).toFixed(2)}MB<br />
                </Col>
                {mb(file.size) > 15 ? <Col className="col-12 text-danger">
                    <b>ARCHIVO MAS PESADOS QUE 15MB NO SE PUEDEN SUBIR</b><br />
                </Col> : <></>}
            </Row> : <></>}
            <Row>
                <iframe src={preview} title="print" frameBorder='0' width='100%' height='700wh' scrolling='no' />
            </Row>
        </Modal.Body>
    </Modal>
}
function ToString(file: any, setData: (d: string, file: any) => void, setPreviw: (d: string) => void) {
    let reader = new FileReader()
    reader.onloadend = () => {
        const b: any = reader.result;
        setData(b, file);
    };
    setPreviw(URL.createObjectURL(file));
    reader.readAsDataURL(file);
}
function setError(err: any[], setError: (d: any) => void) {
    const error: any = {};
    err.forEach((e: any) => {
        if (e.sql) {
            e.sql.forEach((er: string) => toast.error(er));
        } else {
            Object.keys(e).forEach(t => error[t] = e[t])
        }
    })
    setError(error);
} function mb(bytes: number) {
    return (bytes / (1024 * 1024));
}
