import { Shor } from "../../Models";
import { getBlockToHTML } from "./BlockToHTML";

export default class keys {
    keys: Shor[] = []
    getKeys() {
        return this.keys;
    }
    addKey(clave: string, descripcion: string, html: any) {
        this.keys.push({ clave, descripcion, html, data: {}, estatus: 1, id: 0, type: '' });
    }
    addKeyToString(clave: string, descripcion: string, html: string) {
        this.addKey(clave, descripcion, html);
        this.addKey(`${clave}_lower`, `${descripcion} EN MINUSCULAS`, html.toLowerCase());
        this.addKey(`${clave}_upper`, `${descripcion} EN MAYUSCULAS`, html.toUpperCase());
        this.addKey(`${clave}_uc`, `${descripcion} SOLO LA PRIMERA LETRA DE CADA PALABRA EN MAYUSCULAS`, html.toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase()));
    }
    getBlock(block: any[]) {
        return getBlockToHTML(block).getHTML();
    }
    getIcon(icon: any) {
        let path = '';
        if (Array.isArray(icon.path)) {
            path = icon.path.map((p: any, i: any) => `<path style='fill:currentColor;opacity:${i === 0 ? .7 : 1};' d='${p}' />`).join('');
        } else {
            path += `<path style='fill:currentColor;' d='${icon.path} />`;
        }
        return `<svg width='3rem' height='3rem' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${icon.width} ${icon.height}'>${path}</svg>`;
    }
}
