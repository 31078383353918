import ListaCapacitaciones from "./Programas";
import NuevoCap from "./Programas/GestionPrograma/From/Nuevo";
import ListaFacilitadores from "./Facilitadores";
import NuevoFa from "./Facilitadores/GestionFailitador/From/Nuevo";
import ListCategorias from "./Programas/Categorias";
import { Ruta } from "../../Template/Ruta";
import ConfigEvento from "./Evento/Config";
import ListaEvento from "./Evento";
import ListaParticipante from "./Participantes";
import FromCertificado from "./Evento/Parametros/Certificado/From";
import EditarCertificado from "./Evento/Parametros/Certificado/Edit";
import CopiarCertificado from "./Evento/Parametros/Certificado/Copiar";
import { Route } from "react-router-dom";
import GestionEvento from "./Evento/GestionarEvento";
import ListaPago from "./Pagos";
import ListaMetodoPago from "./Pagos/MetodosPago";
import NuevoEvento from "./Evento/GestionarEvento/From/Nuevo";
import GestionPrograma from "./Programas/GestionPrograma";
import GestionFacilitador from "./Facilitadores/GestionFailitador";
import EspecialidadProvider from "./Facilitadores/GestionFailitador/Especialidades";
import ListAliado from "./Aliado";
import ListaRoles from "./Aliado/Rol";
import Asignaciones from "./Aliado/Asignaciones";
import CertificadosTabla from "./Programas/Certificados";
export const rutasTalento = ["/programa", "/facilitador", "/evento", "/participante", "/pago", "/aliado"];
export default function Master() {
    return (<>
        <Ruta permiso="PROGRAMA" path="/programa" component={MasterProgramas} />
        <Ruta permiso="FACILITADOR" path="/facilitador" component={MasterFacilitador} />
        <Ruta permiso="EVENTO" path="/evento" component={MasterEvento} />
        <Ruta permiso="PARTICIPANTE" path="/participante" component={ListaParticipante} />
        <Ruta permiso="PAGOS" path="/pago" component={MasterPagos} />
        <Ruta permiso="ALIADO" path="/aliado" component={MasterAliado} />
    </>);
}

function MasterPagos() {
    return (<>
        <Ruta permiso="PAGOS" exact path="/pago" component={ListaPago} />
        <Ruta permiso="PAGOS" path="/pago/metodos" component={ListaMetodoPago} />
    </>);
}
function MasterProgramas() {
    return (<>
        <Route path="/programa/:opcion/:id" component={GestionPrograma} />
        <Ruta permiso="PROGRAMA" exact path="/programa" component={ListaCapacitaciones} />
        <Ruta permiso="PRO_CATEGORIA" exact path="/programa/categorias" component={ListCategorias} />
        <Ruta permiso="PRO_CATEGORIA" exact path="/programa/cetificados" component={CertificadosTabla} />
        <Ruta permiso="PRO_CREAR" path="/programa/nueva" component={NuevoCap} />
    </>);
}
function MasterEvento() {
    return (<>
        <Route path="/evento/:opcion/:id" component={GestionEvento} />
        <Ruta permiso="EVENTO" exact path="/evento" component={ListaEvento} />
        <Ruta permiso="EVENTO" path="/evento/config" component={ConfigEvento} />
        <Ruta permiso="EVE_CREAR" path="/evento/nueva" component={NuevoEvento} />
        <Ruta permiso="EVE_CERTIFICADOS" path="/evento/certificado/nuevo" component={FromCertificado} />
        <Ruta permiso="EVE_CERTIFICADOS" path="/evento/certificado/edit/:id" component={EditarCertificado} />
        <Ruta permiso="EVE_CERTIFICADOS" path="/evento/certificado/copiar/:id" component={CopiarCertificado} />
    </>);
}
function MasterFacilitador() {
    return (<EspecialidadProvider>
        <Route path="/facilitador/:opcion/:id" component={GestionFacilitador} />
        <Ruta permiso="FACILITADOR" exact path="/facilitador" component={ListaFacilitadores} />
        <Ruta permiso="FAC_CREAR" path="/facilitador/nueva" component={NuevoFa} />
    </EspecialidadProvider>);
}
function MasterAliado() {
    return (<>
        <Ruta permiso='ALIADO' exact path="/aliado" component={ListAliado} />
        <Ruta permiso='ALIADO' path="/aliado/rol" component={ListaRoles} />
        <Ruta permiso='ALIADO' path="/aliado/asignaciones/:id" component={Asignaciones} />
    </>);
}