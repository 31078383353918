import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import Fila from "./Fila";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { IE } from "../../../../../../Config/Updater";
import { ParticipanteFila } from "../../../../Participantes/Models";
import GenerarCertificados from "../GenerarCertificados";
import { InputText } from "@victorequena22/component-bootstrap";

export default class Tabla extends CardBuscador<{ itens: ParticipanteFila[] }> {
    buscarEn = ['nombre', 'correo', 'telefono'];
    render() {
        let { Body } = this;
        return <>
            <Row className="mb-4">
                <Col md={8}>
                    <InputGroup className="w-100">
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{ fontSize: '.9rem' }}>BUSCAR</InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText update="buscar" value={this.state.buscar} setData={this.setState.bind(this)} />
                        <InputGroup.Append>
                            <Button variant="primary" style={{ fontSize: '.9rem' }}>
                                <i className="fa fa-search" /> BUSCAR
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <GenerarCertificados />
            </Row>
            <Body />
        </>
    }
    Row = (d: ParticipanteFila) => <Fila key={IE(d) + 'participante'} data={d} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='300px' label='correo'>CORREO</Th>
            <Th width='200px' aling="center" label='telefono'>TELEFONO</Th>
            <Th width='300px' aling="right">ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}


