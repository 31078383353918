import { Col } from 'react-bootstrap';
import { TextToHTML } from '@/App/Panel/Component/SlateCustom/Html';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { getBorderCard } from '@/App/Panel/Props';
interface Props {
    iten: iten,
    config: State,
    seled: (i: number) => void
}
export function Itens({ config: { seleted, size, card, border1, gap },
    iten: { id, html, color, back, border }, seled }: Props) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const style = getBorderCard(seleted === id, card, border1, border, back)
    return <Col xs={s2.some(s => size === s) ? 3 : s1.some(s => size === s) ? 4 : 6} >
        <div className='w-100 h-100 pt-2 pb-2'>
            <div onClick={() => seled(id)} style={{ height: '100%', ...style, marginBlock: `${gap}px` }} className="c-option-2 mt-2">
                {seleted === id ?
                    <div className='m-0 p-0' style={{ color }}> <EditArea key={'edit' + id} /></div> :
                    <div className='m-1 p-1 pb-3' style={{ color }} key={'html' + id}><TextToHTML text={html} /></div>
                }
            </div>
        </div>
    </Col >
}
