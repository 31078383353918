import { Col, Row } from "react-bootstrap";
import { useEvento } from "../index";
import Template from "../Template";
import NuevoGrupoPago from "@/App/Capacitacion/Pagos/Grupos/Nuevo";
import Tabla2 from "./Tabla";
export var setPagosList = () => { }
export default function GestionGrupoPago() {
    const { id, gruposPago } = useEvento();
    return <Template icon="fa fa-tag" title="GRUPOS DE PAGOS">
        <Col xs={12} className="mt-2">
            <Tabla itens={gruposPago} id={id} />
        </Col>
    </Template>
}
class Tabla extends Tabla2<{ id: number }> {
    render() {
        let { Body, Buscador, } = this;
        return <>
            <Row>
                <Col md={9}><Buscador /></Col>
                <Col className="text-right" md={3}><NuevoGrupoPago model="evento" id={this.props.id} /></Col>
            </Row>
            <Body />
        </>
    }
}

