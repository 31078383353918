import { Col, Image, Row } from "react-bootstrap";
import { findProgram } from "../../../Context/Programas";
import { Precios } from "../../Models";
import Facilitadores from "./Data/Facilitadores";
import Avales from "./Data/Avales";
import { fechaToNumber } from "@victorequena22/utiles";
import Programa from "./Data/Programa";
import Normas from "./Data/Normas";
import Consideraciones from "./Data/Consideraciones";
import { useEvento } from "../index";
import Template from "../Template";
import { getImagenURL } from "@victorequena22/conneccion";
import { Copi, LinkEvento } from "./Exports";

export default function VerEvento() {
    const evento = useEvento();
    const programa = findProgram(evento.programa);
    return <Template icon="fa fa-eye" title="INFORMACIÓN DEL EVENTO">
        <Row>
            <Col lg={4}>
                <Image fluid src={getImagenURL(evento.short)} />
            </Col>
            <Col lg={8} as={Row}>
                <Col lg={12}><Copi /></Col>
                <Col lg={12}><LinkEvento /></Col>
                <Col lg={9}><b>Programa: </b>{programa.id ? programa.nombre : ''}</Col>
                <Col lg={3}><b>Cohorte: </b>{evento.cohorte}</Col>
                <Col lg={12}><b>Fecha: </b>{evento.fecha}</Col>
                <Col lg={12}><b>Descripcion: </b>{programa.id ? programa.descripcion : ''}</Col>
                {evento.facilitadores.length ? <Col lg={12}><b>Facilitado por: </b><br />
                    <Facilitadores ids={evento.facilitadores} />
                </Col> : <></>}
                {evento.avales.length ? <Col lg={12}><b>Avalado por: </b><br />
                    <Avales ids={evento.avales} />
                </Col> : <></>}
                {evento.precios.length ? <Col lg={12}><b>Precios: </b><br />
                    <ListPrecio data={evento.precios} />
                </Col> : <></>}
            </Col>
            {programa.id ? <Programa programa={programa} /> : <></>}
            <Normas id={evento.norma} />
            <Consideraciones id={evento.consideracion} />
        </Row>
    </Template >
}
function ListPrecio({ data }: { data: Precios[] }) {
    return <ul>
        {data.filter(p => {
            if (p.estatus === 2) return true;
            const dia = fechaToNumber();
            if ((fechaToNumber(p.inicio) >= dia) && fechaToNumber(p.final) <= dia) return true;
            return false;
        }).map(p => <li key={p.nombre}>
            {p.nombre} <b className="ml-auto">{p.precio}$</b>
        </li>)}
    </ul>
}