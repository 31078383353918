import { RowConfig, ButtonConfig } from '../index'
import { ButtonGroup, Button } from 'react-bootstrap';
import { InputNumber } from "@victorequena22/component-bootstrap";
import { TitleInput } from '../Inputs';
const buttonCSS = { width: 32, height: 24 };
const IconCSS = { fontSize: 10, width: 16, height: 16, paddingTop: 1 };
const border = 'solid 2px currentColor';
interface BackIconProps {
    setConfig: (d: any) => void
    config: any
}
function BackIcon({ config: { back }, setConfig }: BackIconProps) {
    return <RowConfig top={2}>
        <ButtonGroup>
            <ButtonGroup>
                <Button data-tip="SIN FONDO PARA EL ICONO" size='sm' active={back === 'none'} style={buttonCSS} onClick={() => setConfig({ back: 'none' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ ...IconCSS, fontSize: 12 }} />
                </Button>
                <Button data-tip="FONDO RECTANGULAR" size='sm' active={back === 'rectangle'} style={buttonCSS} onClick={() => setConfig({ back: 'rectangle' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ ...IconCSS, border }} />
                </Button>
                <Button data-tip="FONDO CON ESQUINAS REDONDEADAS" size='sm' active={back === 'rounded'} style={buttonCSS} onClick={() => setConfig({ back: 'rounded' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ ...IconCSS, border, borderRadius: 5 }} />
                </Button>
                <Button data-tip="FONDO CIRCULAR" size='sm' active={back === 'circle'} style={buttonCSS} onClick={() => setConfig({ back: 'circle' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ ...IconCSS, border, borderRadius: 10 }} />
                </Button>
            </ButtonGroup>
        </ButtonGroup>
    </RowConfig>
}
interface ConfigIconBasicProps extends BackIconProps {
    top: any
    down?: any
}
export function ConfigIconBasic(p: ConfigIconBasicProps) {
    const { config: { back, border }, top, down, setConfig } = p;
    return <>
        <RowConfig key={`borderIcon${border ? 'SI' : 'NO'}`}>
            {top}
            <ButtonConfig tip={`COLOCAR BORDE PARA EL FONDO DEL ICONO: ${border ? 'SI' : 'NO'}`} className='ml-auto' disabled={back === 'none'} active={border} action={() => setConfig({ border: !border })} icon='far fa-question-circle' />
        </RowConfig>
        <BackIcon {...p} />
        <RowConfig top={2}>
            {down}
        </RowConfig>
    </>
}
interface ConfigIconFontSizeProps extends BackIconProps {
    max: number
    min: number
    step: number
    decimal?: number
}
export function ConfigIconFontSize(p: ConfigIconFontSizeProps & { children: any }) {
    const { config: { font }, children, max, min, step, setConfig, decimal = 0 } = p;
    return <ConfigIconBasic {...p}
        top={<TitleInput width='60px' tip='TAMAÑO DEL ICONO' title={<i className="fas fa-text-height" />}>
            <InputNumber max={max} step={step} min={min} decimal={decimal} style={{ height: 24 }}
                value={font} setData={font => setConfig({ font })} />
        </TitleInput>} down={children} />
}
export function ConfigPosicion(p: ConfigIconFontSizeProps) {
    const { config: { orientation }, setConfig } = p;
    return <ConfigIconFontSize {...p}>
        <ButtonGroup>
            <ButtonConfig tip='ICONO A LA IZQUIERDA' active={orientation === 'row'} action={() => setConfig({ orientation: 'row' })} icon='fas fa-arrow-left' />
            <ButtonConfig tip='ICONO ARRIBA' active={orientation === 'column'} action={() => setConfig({ orientation: 'column' })} icon='fas fa-arrow-up' />
            <ButtonConfig tip='ICONO DERECHA' active={orientation === 'row-reverse'} action={() => setConfig({ orientation: 'row-reverse' })} icon='fas fa-arrow-right' />
            <ButtonConfig tip='ICONO ABAJO' active={orientation === 'column-reverse'} action={() => setConfig({ orientation: 'column-reverse' })} icon='fas fa-arrow-down' />
        </ButtonGroup>
    </ConfigIconFontSize>
}
export function ConfigCard({ config: { card, border1 }, setConfig }: BackIconProps) {
    return <>
        <ButtonGroup className='ml-1'>
            <Button data-tip='SIN FONDO' size='sm' active={card === 'none'} style={buttonCSS} onClick={() => setConfig({ card: 'none' })} variant='outline-dark'>
                <i className="fas fa-font" style={{ ...IconCSS, fontSize: 12 }} />
            </Button>
            <Button data-tip='FONDO DE LA CARTA CUADRADO' size='sm' active={card === 'rectangle'} style={buttonCSS} onClick={() => setConfig({ card: 'rectangle' })} variant='outline-dark'>
                <i className="fas fa-font" style={{ ...IconCSS, border }} />
            </Button>
            <Button data-tip='FONDO DE LA CARTA REDONDEADO' size='sm' active={card === 'rounded'} style={buttonCSS} onClick={() => setConfig({ card: 'rounded' })} variant='outline-dark'>
                <i className="fas fa-font" style={{ ...IconCSS, border, borderRadius: 5 }} />
            </Button>
        </ButtonGroup>
        {card === 'none' ? <></> :
            <ButtonConfig className='ml-1' icon='fal fa-credit-card-front'
                active={border1} action={() => setConfig({ border1: !border1 })}
                tip={`COLOCAR BORDE A LA CARTA ${border1 ? 'SI' : 'NO'}`} />
        }
    </>
}
